import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    formControl: {
      '& .MuiInputBase-root': {
        height: 44,
      },
    },
    placeholder: {
      top: -6,
      '&.MuiInputLabel-shrink': {
        display: 'none',
      },
    },
    link: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
      position: 'relative',
      top: 26,
      right: 186,
    },
    error: {
      color: Colors.Error,
    },
  }),
);
