import { useMemo } from 'react';
import {
  InfiniteList,
  TableWrapper,
  TableToolbar,
  Tabs,
  ListCard,
  Button,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FilterButton,
  FiltersInModal,
  SelectFilter,
  ShopItemsFilter,
  SuppliersFilter,
} from 'components/filters';
import { usePredefinedToasts } from 'utils/Toast';
import { useSearchParams } from 'utils/navigation';
import {
  recognitionRewardsFiltersSchema,
  useAddRecognitionReward,
  useAddRecognitionRewards,
  useInfiniteRecognitionRewards,
  useRecognitionRewardsConfig,
  useRemoveRecognitionReward,
  useRemoveRecognitionRewards,
} from 'store/recognition-rewards';
import { shopItemCategories } from 'types/MarketplaceItems';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import getPriceLabel from 'components/marketplace/ItemCard/common/getPriceLabel';
import { useModalContext } from 'store/modal';
import { ShopItemDetailsModal } from 'components/marketplace/shop-item-details-modal';
import { calculateFiltersTotal } from 'utils/Filters';
import { RewardsConfigModal } from './atoms';

export const RecognitionsRewardsList = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { defaultLanguage } = useCompanyLanguages();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    recognitionRewardsFiltersSchema,
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isPreviousData,
    isLoading,
  } = useInfiniteRecognitionRewards(filters);
  const { data: config } = useRecognitionRewardsConfig();

  const rewards = useMemo(() => {
    return {
      items: (data?.pages.map(({ items }) => items) ?? []).flat(),
      total: data?.pages[0]?.total,
    };
  }, [data]);

  const { mutate: addReward, isLoading: isAdding } = useAddRecognitionReward();
  const { mutate: removeReward, isLoading: isRemoving } =
    useRemoveRecognitionReward();
  const { mutate: addRewards, isLoading: isAddingMany } =
    useAddRecognitionRewards();
  const { mutate: removeRewards, isLoading: isRemovingMany } =
    useRemoveRecognitionRewards();

  const { variant, ...baseFilters } = filters;

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <Stack spacing={2} p={1}>
            <Typography variant="h2">{t('recognition.rewardsTab')}</Typography>
            <Typography variant="p1" color="textSecondary">
              {t('recognition.rewardsDescription')}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <Tabs
              value={variant}
              onChange={(v) => navigate({ params: { variant: v } })}
              items={[
                {
                  value: 'all',
                  label: t('marketplace.all'),
                },
                {
                  value: 'selected',
                  label: t('marketplace.selected'),
                },
              ]}
            />

            <Stack direction="row" alignItems="center" gap="10px">
              {config ? (
                <Button
                  label={t('recognitions.rewards.actions.set_config')}
                  variant="tertiary"
                  size="sm"
                  onClick={() => {
                    openModal({
                      component: RewardsConfigModal,
                      size: 'lg',
                      props: {
                        config: {
                          suppliers: config.suppliers,
                          categories: config.categories,
                          countries: config.countries,
                          cities: config.cities,
                        },
                      },
                    });
                  }}
                  badge={calculateFiltersTotal(config ?? {})}
                />
              ) : null}
              <FiltersInModal
                value={baseFilters}
                initialValues={recognitionRewardsFiltersSchema.parse({
                  variant,
                })}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('marketplaceItems.supplier')}
                      value={value.suppliers}
                      initialValue={initialValues.suppliers}
                      onChange={(v) => setFilter('suppliers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SuppliersFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceOrders.itemName')}
                      value={value.items}
                      initialValue={initialValues.items}
                      onChange={(v) => setFilter('items', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <ShopItemsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.benefit_type')}
                      value={value.categories}
                      initialValue={initialValues.categories}
                      onChange={(v) => setFilter('categories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="categories"
                          value={value}
                          multiple
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.city.label')}
                      value={filters.cities}
                      initialValue={initialValues.cities}
                      onChange={(v) => setFilter('cities', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          multiple
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={APP.cities.map((city) => ({
                            key: city,
                            label: city,
                          }))}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>

              {rewards.total ? (
                <>
                  <Divider orientation="vertical" variant="middle" flexItem />

                  {variant === 'all' ? (
                    <Button
                      label={t('marketplace.selectFilteredItems', {
                        count: rewards.total,
                      })}
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        addRewards(
                          { filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isAddingMany ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : variant === 'selected' ? (
                    <Button
                      label={t('marketplace.removeFilteredItems', {
                        count: rewards.total,
                      })}
                      variant="danger-outline"
                      size="sm"
                      onClick={() =>
                        removeRewards(
                          { filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isRemovingMany ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <TableWrapper.InfiniteListWrapper>
        <InfiniteList
          data={rewards.items}
          renderItem={(reward) => {
            const translations = resolveTranslation(
              i18n.language,
              defaultLanguage,
              reward.translations,
            );
            return (
              <ListCard
                image={reward.image?.url}
                title={translations?.title}
                label={getPriceLabel(reward)}
                onClick={() => {
                  openModal({
                    component: ShopItemDetailsModal,
                    size: 'lg',
                    props: {
                      item: {
                        title: translations?.title ?? '',
                        description: translations?.description ?? '',
                        benefitCategories: reward.benefitPlanTypes,
                        categories: reward.categories,
                        countries: reward.countries,
                        cities: reward.cities,
                        currency: reward.currency,
                        prices: reward.prices,
                        supplier: {
                          name: reward.supplier.name,
                          termsAndConditionsLink:
                            reward.supplier.termsAndConditionsLink,
                        },
                        image: reward.image?.url,
                        logo: reward.supplier.logo?.url,
                      },
                    },
                  });
                }}
                action={
                  reward.isWhitelisted
                    ? {
                        label: t('common.remove'),
                        variant: 'danger-outline',
                        onClick: () =>
                          removeReward(
                            { marketplaceItemId: reward.id },
                            {
                              onSuccess: () => {
                                predefinedToasts.success.updated();
                              },
                            },
                          ),
                        disabled:
                          isRemoving ||
                          reward.autoAssigned ||
                          !!(isFetching && isPreviousData),
                        tooltip: reward.autoAssigned
                          ? t('marketplace.autoAssignedNotRemovable')
                          : undefined,
                      }
                    : {
                        label: t('common.add'),
                        variant: 'secondary',
                        onClick: () =>
                          addReward(
                            { marketplaceItemId: reward.id },
                            {
                              onSuccess: () => {
                                predefinedToasts.success.updated();
                              },
                            },
                          ),
                        disabled: isAdding || !!(isFetching && isPreviousData),
                      }
                }
              />
            );
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          noDataMessage={[
            t('recognition.rewardsNoItemsTitle'),
            t('recognition.rewardsNoItemsSubtitle'),
          ].join(' ')}
        />
      </TableWrapper.InfiniteListWrapper>
    </TableWrapper>
  );
};
