import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { z } from 'zod';
import { recognitionTypes } from './recognitions.types';

export const recognitionsFiltersSchema = z
  .object({
    type: z.enum(recognitionTypes).nullable().catch(null),
    achievements: z.array(z.string()).catch([]),
    employee: z.string().catch(''),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);
