import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import {
  AutocompleteInput,
  DateTimeInput,
  Modal,
  NumberInput,
  TextField,
} from 'melp-design/components';
import {
  useAdminClientCreateDeposit,
  useAdminClientDeposits,
} from 'store/admin-client-deposits';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { useAdminClient } from 'store/admin-clients';
import { usePredefinedToasts } from 'utils/Toast';

interface Values {
  date: string;
  company: string;
  amount: number;
  comment: string;
  invoice: string;
}

interface Props extends ModalProps {
  type: 'add' | 'deduct';
  clientId: string;
  companyId: string;
}

export const CreateDepositModal: FC<Props> = ({
  type,
  clientId,
  companyId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      date: '',
      company: companyId,
      amount: NaN,
      comment: '',
      invoice: '',
    },
  });

  const { data: companies } = useAdminClientDeposits(clientId);
  const { data: client } = useAdminClient(clientId);

  const { mutate: createDeposit, isLoading } = useAdminClientCreateDeposit();

  const onSubmit: SubmitHandler<Values> = (values) => {
    if (!client) return;

    createDeposit(
      {
        companyId: values.company,
        data: {
          amount: type === 'add' ? values.amount : -values.amount,
          currency: client.defaultCurrency,
          invoice: values.invoice,
          comment: values.comment,
          date: values.date,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={
          type === 'add'
            ? t('clients.actions.add_deposit')
            : t('clients.actions.deduct_deposit')
        }
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isLoading || !client,
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="company"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('employees.company')}
                name={name}
                value={value}
                onChange={onChange}
                options={
                  companies?.items.map((company) => ({
                    label: company.name,
                    value: company.id,
                  })) ?? []
                }
                disabled
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="date"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <DateTimeInput
                label={t('common.date')}
                name={name}
                value={value}
                onChange={onChange}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="amount"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('employees.amount')}
                name={name}
                value={value}
                onChange={onChange}
                required
                key={value}
                ref={ref}
                InputProps={{
                  endAdornment: client?.defaultCurrency,
                }}
              />
            )}
          />

          <Controller
            name="invoice"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('common.invoice_number.label')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('clients.comment')}
                  multiline
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
