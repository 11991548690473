import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    companyListItemRoot: {
      '& > .MuiBox-root': {
        paddingLeft: 10,
        marginLeft: 90,
      },
      '&:not(:last-child)::after': {
        width: 'calc(100% - 45px)',
      },
      '&.Mui-selected': {
        background: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    selectedWithoutBg: {
      '&.Mui-selected': {
        background: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
      },
    },
  }),
);
