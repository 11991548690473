import { FC } from 'react';
import { AlertColor, Box, Paper, Stack } from '@mui/material';
import {
  Typography,
  TypographyColor,
  TypographyVariant,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';

const BACKGROUND_COLOR_BY_STATUS: Record<AlertColor, string> = {
  info: SystemColors.primary.base,
  success: SystemColors.success.base,
  warning: SystemColors.warning.base,
  error: SystemColors.danger.base,
};

const COLOR_BY_STATUS: Record<AlertColor, TypographyColor> = {
  info: 'textWhite',
  success: 'textPrimary',
  warning: 'textPrimary',
  error: 'textWhite',
};

type Size = 'sm' | 'md';

const ITEM_TYPOGRAPHY_BY_SIZE: Record<Size, TypographyVariant> = {
  sm: 'h3',
  md: 'h2',
};

interface Props {
  title: string;
  items: (string | number)[];
  label?: string;
  status?: AlertColor;
  size?: Size;
}

export const Tile: FC<Props> = ({
  title,
  items,
  label,
  status = 'info',
  size = 'md',
}) => {
  return (
    <Paper elevation={2} sx={{ minWidth: 200 }}>
      <Stack p={2} gap={0.5}>
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
          minHeight={20}
        >
          <Typography variant="p2">{title}</Typography>

          {label ? (
            <Box
              sx={{
                backgroundColor: BACKGROUND_COLOR_BY_STATUS[status],
                px: 1,
                py: 0.5,
                borderRadius: 1,
              }}
            >
              <Typography
                variant="p2"
                color={COLOR_BY_STATUS[status]}
                sx={{ lineHeight: '1 !important' }}
              >
                {label}
              </Typography>
            </Box>
          ) : null}
        </Stack>

        {items.map((item, i) => (
          <Typography variant={ITEM_TYPOGRAPHY_BY_SIZE[size]} key={i}>
            {item}
          </Typography>
        ))}
      </Stack>
    </Paper>
  );
};
