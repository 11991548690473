import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-discount-codes.loaders';
import { converters } from './admin-discount-codes.converters';
import { AdminDiscountCodesFilters } from './admin-discount-codes.types';

export const adminDiscountCodesKeys = queryKeyFactory('admin-discount-codes');

export const useAdminDiscountCodes = (
  discountId: string,
  filters: Partial<AdminDiscountCodesFilters> = {},
) => {
  return useQuery({
    queryKey: adminDiscountCodesKeys.list(filters),
    queryFn: () => loaders.getDiscountCodes({ discountId, filters }),
    select: converters.getDiscountCodes,
    enabled: !!discountId,
  });
};
