import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import moment from 'moment';
import { loaders } from './logs.loaders';
import { converters } from './logs.converters';
import { LogsFilters } from './logs.types';

export const logsKeys = queryKeyFactory('logs');

export const useLogs = (filters: Partial<LogsFilters> = {}) => {
  const query = useQuery({
    queryKey: logsKeys.list(filters),
    queryFn: () => loaders.getLogs({ filters }),
    select: converters.getLogs,
  });

  return query;
};

export const useFailedAutomationLogsPreview = (
  options: { enabled?: boolean } = {},
) => {
  const filters: Partial<LogsFilters> = {
    actions: ['automationRuleError'],
    date: {
      from: moment().subtract(1, 'month').toISOString(true).split('T')[0],
      to: undefined,
    },
  };
  const key = logsKeys.list(filters);

  const query = useQuery({
    queryKey: key,
    queryFn: () => loaders.getLogs({ filters }),
    select: converters.getLogs,
    enabled: options.enabled,
  });

  return { key: JSON.stringify(key), ...query };
};
