import { Typography } from '@mui/material';
import { CellProps } from 'react-table';
import { useRendererOptions } from '@mp-react/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../../utils/Common';

export default function EmployeeGroup(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (value === null) {
      return t('common.dash');
    } else if (!!value?.length && value?.length > 1) {
      return `${value.length} ${t('menu.groups').toLowerCase()}`;
    } else if (value?.length === 0) {
      return t('common.dash');
    } else {
      if (!!value?.[0]) return truncate(value[0], 20);
      return value;
    }
  }, [value, t]);

  return (
    <Typography variant="body2" style={{ textAlign: 'left' }}>
      {text}
    </Typography>
  );
}
