import { EmbeddedCustomCategory } from 'store/custom-categories';
import { UploadedFile } from 'store/files';
import { Translations } from 'types/Common';
import { Currency } from 'types/general';
import { z } from 'zod';
import { ordersFiltersSchema } from './orders.schemas';

export const orderStatuses = [
  'pending',
  'in_progress',
  'done',
  'rejected',
  'invoiced',
  'paid',
  'failed',
] as const;
export type OrderStatus = (typeof orderStatuses)[number];

export const orderVariants = ['melp-shop', 'internal', 'all'] as const;
export type OrderVariant = (typeof orderVariants)[number];

export interface Order {
  id: string;
  status: OrderStatus;
  orderNr: string;
  coupon: UploadedFile | null;
  couponCode: string;
  item: {
    id: string;
    name: string;
    translations: Translations<{ title: string; description: string }>;
  };
  variantName: string;
  employee: {
    id: string;
    fullName: string;
    email: string;
    phone: string;
    jobTitle: string;
    department: string;
    categories: EmbeddedCustomCategory[];
  } | null;
  company: {
    id: string;
    name: string;
  } | null;
  admin: {
    id: string;
    name: string;
  } | null;
  supplier: {
    id: string;
    name: string;
  } | null;
  price: number;
  currency: Currency;
  commentForEmployee: string;
  commentForAdmin: string;
  createdAt: string;
  lastModifiedAt: string | null;
}

export type OrdersFilters = z.infer<typeof ordersFiltersSchema>;
