import { ReactNode } from 'react';
import { ConfirmationModal } from 'components/modals';
import { useModalContext } from 'store/modal';

export const useConfirm = () => {
  const { openModal } = useModalContext();

  const confirmation = async (
    prompt: ReactNode,
    title?: string,
  ): Promise<{ confirmed: boolean }> => {
    const { action } = await openModal({
      component: ConfirmationModal,
      props: { prompt, title },
    });

    if (action === 'CONFIRM') return { confirmed: true };

    return { confirmed: false };
  };

  return {
    confirmation,
  };
};
