import { Box, IconButton, Stack } from '@mui/material';
import {
  Button,
  DropdownMenu,
  FOOTER_HEIGHT,
  FOOTER_ID,
  Typography,
} from 'melp-design/components';
import { Close } from 'melp-design/icons';
import { SystemColors } from 'melp-design/style';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedItems?: string[];
  actions?: Array<{
    label: string;
    onClick?: () => void;
  }>;
  onClear?: () => void;
  isLoading?: boolean;
  offsetLeft?: number;
}

const OFFSET = 10;

export const Floater: FC<Props> = ({
  actions = [],
  selectedItems = [],
  onClear,
  isLoading,
  offsetLeft = 0,
}) => {
  const { t } = useTranslation();
  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const isFooterVisible = !!document.getElementById(FOOTER_ID);

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1,
        bottom: isFooterVisible ? FOOTER_HEIGHT + OFFSET : OFFSET,
        left: offsetLeft + OFFSET,
      }}
    >
      <Stack
        sx={{
          p: 2,
          backgroundColor: SystemColors.primary.base,
          borderRadius: 1,
          width: 250,
        }}
        gap={1}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{ color: SystemColors.white }}
        >
          <Typography variant="h3" color="textWhite">
            {t('common.selectedRowsCount', { count: selectedItems.length })}
          </Typography>
          {onClear ? (
            <IconButton
              size="small"
              color="inherit"
              onClick={() => onClear()}
              aria-label={t('common.close')}
            >
              <Close />
            </IconButton>
          ) : null}
        </Stack>

        <Box>
          <Button
            variant="neutral-outline"
            label={t('common.actions')}
            onClick={() => setIsOpen((prev) => !prev)}
            disabled={isLoading}
            ref={anchorRef}
          />

          <DropdownMenu
            anchorEl={anchorRef.current}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            menuItems={actions.map((action) => ({
              key: action.label,
              label: action.label,
              onClick: action.onClick,
            }))}
            onItemClick={({ onClick }) => {
              onClick?.();
              setIsOpen(false);
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
