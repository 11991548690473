import { FC, PropsWithChildren } from 'react';
import { ShopInternalDisabledFeatureAlert } from 'containers/shop';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useRouteMatch } from 'react-router-dom';

export const ShopInternalLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <Stack gap={3}>
      <ShopInternalDisabledFeatureAlert />

      <Stack>
        <DetailsPageHeader title={t('shop.internal.title')} />

        <DetailsPageContent>
          <SideMenu
            tabs={[
              {
                key: 'items',
                label: t('menu.items'),
                url: ROUTES.shop.internal.items.list,
                isActive: path === ROUTES.shop.internal.items.list,
              },
              {
                key: 'inventory',
                label: t('shop.inventory.title'),
                url: ROUTES.shop.internal.inventory.list,
                isActive: path === ROUTES.shop.internal.inventory.list,
              },
            ]}
          />
          {children}
        </DetailsPageContent>
      </Stack>
    </Stack>
  );
};
