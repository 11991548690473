import { Grid, Box, GridProps, BoxProps } from '@mui/material';
import useStyles from './SidebarContainer.styles';

const SidebarContainer = ({ children, ...rest }: GridProps & BoxProps) => {
  const classes = useStyles();
  return (
    <Grid
      classes={{
        root: classes.sidebar,
      }}
      component={Box}
      item
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default SidebarContainer;
