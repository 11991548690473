import { TextField } from '../../TextField/TextField';
import { InternalFieldRenderer } from '../Types';

export const renderNumberField: InternalFieldRenderer = (
  props,
  { value, ...field },
  fieldState,
) => (
  <TextField
    fullWidth
    error={fieldState.invalid}
    helperText={fieldState.message}
    type="number"
    value={value ?? ''}
    {...field}
    {...props}
  />
);
