import moment from 'moment';

const isIsoDate = (dateString: string): boolean => {
  const isoDateRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
  return (
    moment(dateString, moment.ISO_8601, true).isValid() ||
    isoDateRegex.test(dateString)
  );
};

const validateISO8601DateString = (dateString: string) => {
  if (!isIsoDate(dateString)) {
    throw new Error(`Invalid ISO 8601 date string: ${dateString}`);
  }
};

/**
 * Removes the time offset from a valid ISO date string.
 *
 * @param dateString A valid ISO date string with or without an offset, such as
 *  "2023-05-10T14:30:00+03:00" or "2023-05-10T14:30:00Z".
 * @returns A date string in ISO format without the time offset, such as
 *  "2023-05-10T14:30:00".
 * @throws An error if the input string is not a valid ISO date string.
 */
export const removeOffset = (dateString: string): string => {
  validateISO8601DateString(dateString);

  // Has an offset defined
  if (/[+-]\d{2}:\d{2}$/.test(dateString)) {
    return dateString.slice(0, -6);
  }

  return moment(dateString).utc().format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * Extracts the UTC offset from an ISO 8601 date string.
 *
 * @param dateString - The ISO 8601 date string to extract the offset from.
 * @throws If the input date string is not a valid ISO 8601 date string.
 * @returns The UTC offset in the format ±HH:mm.
 */
export const extractOffset = (dateString: string) => {
  validateISO8601DateString(dateString);

  // Has an offset defined
  if (/[+-]\d{2}:\d{2}$/.test(dateString)) {
    return dateString.slice(-6);
  }

  const date = moment.utc(dateString);
  return date.format('Z');
};
