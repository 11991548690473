import {
  Box,
  InputBaseComponentProps,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  MpControlProps,
  useErrorMessages,
  controlRegisterOptions,
} from '@mp-react/form';
import { useState, useMemo, useCallback } from 'react';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';

const inputLabelProps = { shrink: true };

export default function Password({
  control,
  size,
  layout,
  variant,
  register,
  error,
  defaultMessages,
}: MpControlProps) {
  const { startAdornment, format } = control;
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handlePassword = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const inputProps = useMemo(() => {
    const props: InputBaseComponentProps & {
      mask?: string;
      maskChar?: string;
    } = {
      ref: register(controlRegisterOptions(control)),
      type: isVisible ? 'text' : 'password',
    };
    if (format != null) {
      props.mask = format;
      props.maskChar = '';
    }
    return props;
  }, [register, control, format, isVisible]);

  const autocompleteProp = useMemo(
    () =>
      control?.key === 'newPassword' ||
      control?.key === 'verifyPassword' ||
      control?.key === 'repeatPassword'
        ? 'new-password'
        : 'current-password',
    [control?.key],
  );

  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const InputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handlePassword}
            size="large"
          >
            {isVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
      startAdornment,
    }),
    [startAdornment, isVisible, handlePassword],
  );
  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <TextField
        error={!!error}
        helperText={firstMessage}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === 'separated' ? '' : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        inputProps={inputProps}
        autoComplete={autocompleteProp}
        InputProps={InputProps}
        variant={variant}
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}
