import { t } from 'i18next';
import { InternalFieldRenderer } from '../Types';
import { renderSwitchField } from './switch';

export const renderYesNoField: InternalFieldRenderer = (
  props,
  { value, ...field },
  formState,
) =>
  renderSwitchField(
    props,
    { ...field, value, label: !!value ? t('common.yes') : t('common.no') },
    formState,
  );
