import { FC } from 'react';
import { AdminShopItemLayout } from 'pages/atoms/admin-shop-item-layout';
import MarketplaceItemContent from 'containers/MarketplaceItems/MarketplaceItem/views/content/MarketplaceItemContent';

const AdminShopItemContentPage: FC = () => {
  return (
    <AdminShopItemLayout>
      <MarketplaceItemContent />
    </AdminShopItemLayout>
  );
};

export default AdminShopItemContentPage;
