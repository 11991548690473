import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from '@mp-react/form';
import { Box, ClickAwayListener } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useBenefitStore } from '../../../state/Benefits';
import { useEmployeeStore } from '../../../state/Employees';
import { CustomFormControl } from '../../../types/Common';
import { browserLanguage, useDateFormat } from '../../../utils/Common';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';

const inputLabelProps = { shrink: true };

function DateView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { keyboardDatePickerFormat } = useDateFormat();
  const controlKey = useMemo(() => control?.key, [control]);
  const disablePast = useMemo(
    () => !!(control as CustomFormControl)?.disablePast,
    [control],
  );
  const disableFuture = !!(control as CustomFormControl)?.disableFuture;
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);

  const { setStartDate, setEndDate, endDate, startDate } = useEmployeeStore(
    (state) => state,
  );
  const {
    activationDate,
    setActivationDate,
    setDeactivationDate,
    setPublishDate,
    publishDate,
    deactivationDate,
    setVotingEndDate,
    setVotingStartDate,
    votingEndDate,
    votingStartDate,
  } = useBenefitStore();

  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (date: any) => {
      const dateString = moment(date).toISOString(true);
      onChange(dateString);
      switch (controlKey) {
        case 'publishDate':
          if (moment(deactivationDate).diff(date) < 0)
            setDeactivationDate(dateString);
          setPublishDate(dateString, 'date');
          break;
        case 'activationDate':
          if (moment(deactivationDate).diff(date) < 0) {
            setDeactivationDate(dateString);
          }
          setActivationDate(dateString, 'date');
          break;
        case 'deactivationDate':
          setDeactivationDate(dateString, 'date');
          break;
        case 'startDate':
          if (moment(endDate).diff(date) < 0) setEndDate(dateString);
          setStartDate(dateString, 'date');
          break;
        case 'endDate':
          setEndDate(dateString, 'date');
          break;
        case 'votingStartDate':
          if (moment(votingEndDate).diff(dateString) < 0)
            setVotingEndDate(dateString);
          setVotingStartDate(dateString, 'date');
          break;
        case 'votingEndDate':
          setVotingEndDate(dateString, 'date');
          break;
      }
    },
    [
      onChange,
      controlKey,
      deactivationDate,
      setDeactivationDate,
      setPublishDate,
      endDate,
      setEndDate,
      setStartDate,
      votingEndDate,
      setVotingEndDate,
      setVotingStartDate,
      setActivationDate,
    ],
  );

  const minDate = useMemo(() => {
    switch (controlKey) {
      case 'deactivationDate':
        const latestMoment = moment.max(
          moment(publishDate ?? '1900-01-01'),
          moment(activationDate ?? '1900-01-01'),
        );
        return latestMoment.locale(browserLanguage);
      case 'votingEndDate':
        return moment(
          !!votingStartDate ? votingStartDate : '1900-01-01',
        ).locale(browserLanguage);
      case 'endDate':
        return moment(!!startDate ? startDate : '1900-01-01').locale(
          browserLanguage,
        );
      default:
        return moment('1900-01-01').locale(browserLanguage);
    }
  }, [activationDate, controlKey, publishDate, startDate, votingStartDate]);

  const dateValue = useMemo(() => {
    if (!value) return value;
    return moment.parseZone(value);
  }, [value]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={browserLanguage}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <DatePicker
            value={dateValue}
            onChange={handleChange}
            onClose={() => setOpen(false)}
            slotProps={{
              openPickerButton: { style: { display: 'none' } },
              textField: {
                inputProps: {
                  // force date selection only via picker menu
                  readOnly: true,
                },
                error: !!error,
                helperText: firstMessage,
                name: control.key,
                required: control.required,
                placeholder: control.placeholder,
                label: layout === 'separated' ? '' : control.label,
                size,
                fullWidth: true,
                InputLabelProps: inputLabelProps,
                variant,
                onMouseEnter: openTooltip,
                onMouseLeave: closeTooltip,
                onClick: () => setOpen(true),
              },
            }}
            format={keyboardDatePickerFormat}
            minDate={minDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
            open={open}
            sx={{
              '.MuiOutlinedInput-input, .MuiOutlinedInput-input::placeholder': {
                textTransform: 'lowercase',
              },
            }}
          />
          <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
}

export default function Date({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <DateView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
