import { useMemo } from 'react';
import useSWR from 'swr';
import { Endpoints } from '../api/constants';
import {
  AdminHierarchyRequest,
  HierarchyRequest,
} from '../types/ParentCompany';
import { useHierarchyUtils } from '../utils/Hierarchy';

export const useHierarchy = ({
  level,
  companyGroupId,
  module,
  permissions,
}: HierarchyRequest) => {
  const apiUrl = useMemo(() => {
    if (!module) return null;
    const modulesPart = `module=${module}`;
    const permissionsPart = `permissions=${permissions}`;
    const companyGroupIdPart = !!companyGroupId
      ? `&companyGroupId=${companyGroupId}`
      : '';

    return `${Endpoints.getParentCompanyHierarchy}/${level}?${modulesPart}&${permissionsPart}${companyGroupIdPart}`;
  }, [companyGroupId, level, module, permissions]);

  const { data, error } = useSWR(apiUrl);
  const { parsedUngroupedCompanies, hierarchy, breadcrumbsHierarchy } =
    useHierarchyUtils(data, level);

  const loading = useMemo(() => !data && !error, [data, error]);

  return {
    hierarchy,
    loading,
    parsedUngroupedCompanies,
    breadcrumbsHierarchy,
  };
};
/*** Admin Hierarchy ***/
export const useAdminHierarchy = ({
  level,
  parentCompanyId,
}: AdminHierarchyRequest) => {
  const apiUrl = useMemo(() => {
    if (!parentCompanyId) return null;
    const endpoint = Endpoints.getParentCompanyHierarchyById.replace(
      '{id}',
      parentCompanyId,
    );
    return `${endpoint}/${level}`;
  }, [level, parentCompanyId]);

  const { data, error } = useSWR(apiUrl);
  const { parsedUngroupedCompanies, hierarchy } = useHierarchyUtils(
    data,
    level,
  );

  const loading = useMemo(() => !data && !error, [data, error]);

  return {
    hierarchy,
    loading,
    parsedUngroupedCompanies,
  };
};
