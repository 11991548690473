import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    sidebar: {
      maxWidth: 300,
      width: '100%',
    },
  }),
);
