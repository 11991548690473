import { forwardRef } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

interface Props {
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  info?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CheckboxInput = forwardRef<HTMLInputElement, Props>(
  (
    { label, name, checked, onChange, info, error, required, disabled },
    forwardedRef,
  ) => {
    return (
      <FormControl error={!!error}>
        <FormControlLabel
          control={
            <Checkbox
              required={required}
              inputRef={forwardedRef}
              sx={{ p: 0.5, mr: 0.5 }}
            />
          }
          label={label}
          name={name}
          checked={checked}
          onChange={(_e, checked) => onChange?.(checked)}
          disabled={disabled}
          sx={{ mx: 0 }}
        />
        {info && <FormHelperText error={false}>{info}</FormHelperText>}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  },
);

CheckboxInput.displayName = 'CheckboxInput';
