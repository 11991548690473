import { makeStyles } from '@mui/styles';
import { Colors } from '../../../../constants/Style';

export default makeStyles((theme) => ({
  naked: {},
  emptyAnchor: {
    cursor: 'pointer',
    userSelect: 'none',
    lineHeight: '14px',
    maxWidth: 70,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  activeSearchIcon: {
    '& path': {
      fill: '#000',
    },
  },
  selectWrapper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fullWidth: {
    width: '100%',
  },
  listItem: {
    width: '100%',
    margin: 0,
    padding: '2px 8px',
  },
  activeRow: {
    backgroundColor: Colors.LightPrimary,
  },
  checkbox: {
    padding: '11px',
  },
}));
