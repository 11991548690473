import i18n from 'i18n';
import { IExpense, ILiteBenefit } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { normalizeString } from 'utils/general';
import { RECOGNITION_SHOP_ID } from 'store/recognitions';
import { loaders as benefitsLoaders } from 'store/benefits';
import { loaders } from './expenses.loaders';
import { Expense, ExpenseSource, ExpenseType } from './expenses.types';

export const convertExpenseSource = (
  benefits: Array<{ id: string; name: string }>,
  isRecognition: boolean,
) => {
  return [
    ...benefits,
    ...(isRecognition
      ? [
          {
            id: RECOGNITION_SHOP_ID,
            name: i18n.t('wallet.employeeWalletFundsSourceRecognition'),
          },
        ]
      : []),
  ];
};

export const convertExpenseType = (type: IExpense['type']): ExpenseType => {
  switch (type) {
    case 'manualExpense':
      return 'manual';
    case 'marketplaceOrder':
      return 'shopOrder';
    case 'reimbursementClaim':
      return 'claimReimbursement';
    case 'internalMerchandising':
      return 'companyItems';
    default:
      return type satisfies never;
  }
};

export const revertExpenseType = (type: ExpenseType): IExpense['type'] => {
  switch (type) {
    case 'manual':
      return 'manualExpense';
    case 'shopOrder':
      return 'marketplaceOrder';
    case 'claimReimbursement':
      return 'reimbursementClaim';
    case 'companyItems':
      return 'internalMerchandising';
    default:
      return type satisfies never;
  }
};

const convertExpense = (expense: IExpense): Expense => {
  return {
    id: expense.id,
    employee: expense.employeeId
      ? {
          id: expense.employeeId,
          fullName: expense.fullName ?? '',
        }
      : null,
    description: expense.description,
    date: expense.date,
    source: convertExpenseSource(
      expense.sourceBenefits,
      expense.hasRecognitionReward,
    ),
    categories: expense.itemCategories,
    amount: expense.amount,
    status: expense.status,
    type: convertExpenseType(expense.type),
  };
};

const convertExpenses = (expenses: IExpense[]): Expense[] => {
  return expenses.map(convertExpense);
};

const convertSource = (source: ILiteBenefit): ExpenseSource => {
  return {
    id: source.id,
    name: source.name,
  };
};

const convertSources = (
  sources: ILiteBenefit[],
  name: string,
): ExpenseSource[] => {
  return [
    ...(normalizeString(
      i18n.t('wallet.employeeWalletFundsSourceRecognition'),
    ).includes(normalizeString(name))
      ? [
          {
            id: RECOGNITION_SHOP_ID,
            name: i18n.t('wallet.employeeWalletFundsSourceRecognition'),
          },
        ]
      : []),
    ...sources.map(convertSource),
  ];
};

export const converters = {
  getExpenses: ({ data }: Awaited<ReturnType<typeof loaders.getExpenses>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertExpenses(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getSources: (
    { data }: Awaited<ReturnType<typeof benefitsLoaders.getLiteBenefits>>,
    name: string,
  ) => {
    return convertSources(data.data, name);
  },
};
