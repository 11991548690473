import { Header as AppBar } from 'melp-design/components';
import { ROUTES } from 'config';
import { useMe } from '../../../state/Administrators';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import MenuMap from '../MenuMap/MenuMap';
import UserDropdown from '../UserDropdown/UserDropdown';

export default function Header() {
  const { isHrAdmin, isMelpAdmin } = useMe();

  return (
    <AppBar
      homePageURL={isMelpAdmin ? ROUTES.admin.clients.list : ROUTES.root.index}
      search={isHrAdmin && <GlobalSearch />}
      user={<UserDropdown />}
    >
      <MenuMap />
    </AppBar>
  );
}
