import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-shop-vouchers.loaders';
import { converters } from './admin-shop-vouchers.converters';
import { AdminShopVouchersFilters } from './admin-shop-vouchers.types';

export const adminShopVouchersKeys = queryKeyFactory('admin-shop-vouchers');

export const useAdminShopVouchers = (
  shopItemId: string,
  filters: Partial<AdminShopVouchersFilters> = {},
) => {
  return useQuery({
    queryKey: adminShopVouchersKeys.list(filters),
    queryFn: () => loaders.getShopVouchers({ shopItemId, filters }),
    select: converters.getShopVouchers,
    enabled: !!shopItemId,
  });
};

export const useAdminShopVoucher = (voucherId: string) => {
  return useQuery({
    queryKey: adminShopVouchersKeys.detail(voucherId),
    queryFn: () => loaders.getShopVoucher({ voucherId }),
    select: converters.getShopVoucher,
    enabled: !!voucherId,
  });
};
