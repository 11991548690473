import { Box, Stack } from '@mui/material';
import { Children, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const DetailsPageContent = (props: Props) => {
  const [side, main] = Children.toArray(props.children).filter(
    (_, index) => index < 2,
  );
  return (
    <Stack direction="row" gap="20px">
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'sticky', top: '20px', width: 300 }}>{side}</Box>
      </Box>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>{main}</Box>
    </Stack>
  );
};

export default DetailsPageContent;
