import { FC } from 'react';
import { Badge as MuiBadge, BadgeProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SystemColors } from 'melp-design/style';
import clsx from 'clsx';

type Color = 'primary' | 'neutral' | 'warning' | 'error';

const useStyles = makeStyles(() => ({
  standaloneBadge: {
    fontSize: 12,
    lineHeight: 1,
    fontWeight: 500,
    borderRadius: 10,
    height: 20,
    minWidth: 20,
    padding: '0 6px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryBadge: {
    color: SystemColors.white,
    backgroundColor: SystemColors.primary.base,
  },
  warningBadge: {
    color: SystemColors.warning.base,
    backgroundColor: SystemColors.warning.tint,
  },
  errorBadge: {
    color: SystemColors.white,
    backgroundColor: SystemColors.danger.base,
  },
  neutralBadge: {
    color: SystemColors.black,
    backgroundColor: SystemColors.grey[96],
  },
}));

interface Props extends Omit<BadgeProps, 'color'> {
  color?: Color;
}

const Badge: FC<Props> = ({ color = 'primary', ...props }) => {
  const classes = useStyles();

  const CLASS_BY_COLOR: Record<Color, string> = {
    primary: classes.primaryBadge,
    neutral: classes.neutralBadge,
    warning: classes.warningBadge,
    error: classes.errorBadge,
  };

  if (!props.children) {
    return (
      <span
        className={clsx(
          classes.standaloneBadge,
          CLASS_BY_COLOR[color],
          props.className,
        )}
        style={props.style}
      >
        {props.badgeContent}
      </span>
    );
  }

  return (
    <MuiBadge
      {...props}
      color="default"
      classes={{
        ...props.classes,
        badge: clsx(CLASS_BY_COLOR[color], props.classes?.badge),
      }}
    />
  );
};

export default Badge;
