import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  Loader,
  Menu,
  NothingFoundAlert,
  SideMenu,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import { useConfirm } from 'store/confirm';
import { useAdminShopItem, useRemoveShopItem } from 'store/admin-shop';

export const AdminShopItemLayout: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { id } = useRouteParams(ROUTES.admin.shopItems.details.settings);
  const { confirmation } = useConfirm();

  const { data: shopItem, isLoading } = useAdminShopItem(id);

  const { mutate: removeShopItem, isLoading: isRemoving } = useRemoveShopItem();

  if (isLoading) return <Loader />;
  if (!shopItem) return <NothingFoundAlert />;

  return (
    <Stack gap={3}>
      <Stack>
        <DetailsPageHeader
          title={shopItem.name}
          backTo={ROUTES.admin.shopItems.list}
          side={
            <Menu
              label={t('common.actions')}
              items={[
                {
                  label: t('actions.delete'),
                  onClick: async () => {
                    const { confirmed } = await confirmation(
                      t('common.areYouSureToDelete', {
                        title: shopItem.name,
                        interpolation: { escapeValue: false },
                      }),
                    );

                    if (confirmed) {
                      removeShopItem(
                        { shopItemId: id },
                        {
                          onSuccess: () => {
                            history.push(ROUTES.admin.shopItems.list);
                          },
                        },
                      );
                    }
                  },
                  disabled: isRemoving,
                },
              ]}
            />
          }
        />

        <DetailsPageContent>
          <SideMenu
            tabs={[
              {
                key: 'settings',
                label: t('marketplaceItems.marketplaceItemSettings'),
                url: ROUTES.admin.shopItems.details.settings.replace(':id', id),
                isActive: path === ROUTES.admin.shopItems.details.settings,
              },
              {
                key: 'prices',
                label: t('marketplaceItems.marketplaceItemPriceSettings'),
                url: ROUTES.admin.shopItems.details.prices.replace(':id', id),
                isActive: path === ROUTES.admin.shopItems.details.prices,
              },
              {
                key: 'content',
                label: t('marketplaceItems.marketplaceItemContent'),
                url: ROUTES.admin.shopItems.details.content.replace(':id', id),
                isActive: path === ROUTES.admin.shopItems.details.content,
              },
              ...(![
                'rSNsoltYPWADykKH6OXS1',
                '7QwtuXKgphbS2XXkZnhfm',
                'iOoEwjyXfBBEDRKj76paj',
                'Hnn-wm79H_AA_hfSEupNP',
              ].includes(shopItem.supplier.id)
                ? [
                    {
                      key: 'vouchers',
                      label: t('shop.vouchers.title'),
                      url: ROUTES.admin.shopItems.details.vouchers.replace(
                        ':id',
                        id,
                      ),
                      isActive:
                        path === ROUTES.admin.shopItems.details.vouchers,
                    } as const,
                  ]
                : []),
            ]}
          />
          {children}
        </DetailsPageContent>
      </Stack>
    </Stack>
  );
};
