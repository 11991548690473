import { useTranslation } from 'react-i18next';
import { Button } from 'melp-design/components';
import { useIntegrationTypeForm } from 'configs/Forms/IntegrationTypeForm';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';
import FormDialog from 'components/dialogs/FormDialog/FormDialog';
import { useCreateIntegrationModel } from 'store/integrations';

export const IntegrationCreate = () => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const creationDialog = useDialog();
  const integrationTypeForm = useIntegrationTypeForm();

  const { mutate: createIntegrationModel, isLoading } =
    useCreateIntegrationModel();

  const handleSubmit = (data: {
    name: string;
    comment?: string;
    logoId: string | null;
    type: string;
  }) => {
    createIntegrationModel(
      {
        name: data.name,
        type: data.type,
        logoId: data.logoId,
        comment: data.comment,
      },
      {
        onSuccess: () => {
          creationDialog.closeDialog();
          predefinedToasts.success.added();
        },
      },
    );
  };

  return (
    <>
      <Button
        label={t('integrations.newIntegration')}
        onClick={creationDialog.openDialog}
      />

      <FormDialog
        actionLabel={t('common.confirm')}
        onCancelClick={creationDialog.closeDialog}
        onSubmitForm={handleSubmit}
        id="create-integration-form-dialog"
        title={t('integrations.newIntegration')}
        form={integrationTypeForm}
        open={creationDialog.open}
        loading={isLoading}
      />
    </>
  );
};
