import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './orders.loaders';
import { ordersKeys } from './orders.queries';

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.updateOrder, {
    onSuccess: (_, { orderId }) => {
      return queryClient.invalidateQueries(ordersKeys.detail(orderId));
    },
  });

  return mutation;
};

export const useExportToXls = () => {
  const mutation = useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });

  return mutation;
};
