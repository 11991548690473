import { FC } from 'react';
import { useAdministrators } from 'store/administrators';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const AdministratorsFilter: FC<Props> = (props) => {
  const { data: administrators, isLoading } = useAdministrators();

  return (
    <SelectFilter
      {...props}
      options={administrators?.items.map((administrator) => ({
        key: administrator.id,
        label: administrator.fullName,
      }))}
      loadingOptions={isLoading}
      multiple
    />
  );
};
