import { Box } from '@mui/material';
import { CellProps } from 'react-table';
import { useRendererOptions } from '@mp-react/table';
import { useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';

export default function Checked(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);

  const isChecked = useMemo(() => {
    if (value === 'accepted') {
      return <Checkmark />;
    } else if (value === 'notAccepted' || value === 'noAccepted') {
      return <Close color="error" fontSize="small" />;
    } else {
      return '-';
    }
  }, [value]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      {isChecked}
    </Box>
  );
}
