import { useTranslation } from 'react-i18next';
import { useRecognitionsSettings } from 'store/recognitions';

export const usePointsLabel = () => {
  const { t } = useTranslation();

  const { data: settings } = useRecognitionsSettings();

  return settings ? t(`recognition.pointNames-${settings.pointName}`) : '';
};
