import { IRecognitionReward, IRecognitionRewardsConfig } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertShopItem } from 'store/shop';
import { loaders } from './recognition-rewards.loaders';
import {
  RecognitionReward,
  RecognitionRewardsConfig,
} from './recognition-rewards.types';

const convertConfig = (
  config: IRecognitionRewardsConfig,
): RecognitionRewardsConfig => {
  return {
    name: config.name ?? '',
    price:
      config.priceFrom && config.priceTo
        ? {
            from: config.priceFrom,
            to: config.priceTo,
          }
        : null,
    items: config.itemIds ?? [],
    categories: config.categories ?? [],
    benefitCategories: config.benefitPlanTypes ?? [],
    countries: config.countries ?? [],
    cities: config.cities ?? [],
    suppliers: config.supplierIds ?? [],
  };
};

const convertReward = (reward: IRecognitionReward): RecognitionReward => {
  return {
    ...convertShopItem(reward),
    isWhitelisted: reward.isWhitelisted,
    autoAssigned: reward.autoAssigned,
  };
};

const convertRewards = (rewards: IRecognitionReward[]): RecognitionReward[] => {
  return rewards.map(convertReward);
};

export const converters = {
  getRewards: ({ data }: Awaited<ReturnType<typeof loaders.getRewards>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRewards(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getConfig: ({ data }: Awaited<ReturnType<typeof loaders.getConfig>>) => {
    return convertConfig(data);
  },
};
