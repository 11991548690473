import { Box, BoxProps } from '@mui/material';

export default function Toolbar({ children, ...rest }: BoxProps) {
  return (
    <Box
      paddingBottom="25px"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      {...rest}
    >
      {children}
    </Box>
  );
}
