import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adminClientsKeys } from 'store/admin-clients';
import { loaders } from './admin-clients.loaders';

export const useAdminCreateClient = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createClient, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientsKeys.all);
    },
  });
};
