import { PhoneInput } from 'melp-design/components';
import { FormControl, FormHelperText } from '@mui/material';
import { InternalFieldRenderer } from '../Types';

export const renderPhoneField: InternalFieldRenderer = (
  props,
  field,
  { message, invalid },
) => (
  <FormControl error={invalid}>
    <PhoneInput error={invalid} {...field} {...props} />
    {!!message && <FormHelperText>{message}</FormHelperText>}
  </FormControl>
);
