import { TypographyColor } from 'melp-design/components';
import { OrderStatus } from 'store/orders';

export const COLOR_BY_STATUS: Record<OrderStatus, TypographyColor> = {
  done: 'textPrimary',
  failed: 'error',
  in_progress: 'primary',
  invoiced: 'textPrimary',
  paid: 'textPrimary',
  pending: 'warning',
  rejected: 'textPrimary',
};
