import { TypographyColor } from 'melp-design/components';
import { RecognitionApprovalStatus } from 'store/recognitions';

export const RECOGNITION_SHOP_ID = 'recognition';

export const COLOR_BY_STATUS: Record<
  RecognitionApprovalStatus,
  TypographyColor
> = {
  approved: 'success',
  pending: 'warning',
  rejected: 'error',
  not_required: 'textPrimary',
};
