import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAdminSupplier } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { AdminSuppliersFilters } from './admin-suppliers.types';

interface GetSuppliers {
  filters: Partial<AdminSuppliersFilters>;
}

interface GetSuppliersStatistics {
  filters: Partial<AdminSuppliersFilters>;
}

export const loaders = {
  getSuppliers: ({ filters }: GetSuppliers) => {
    return api<TableDataResponse<IAdminSupplier>>({
      url: Endpoints.melpAdmin.suppliers.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
          systemName: filters.systemName || undefined,
          status: filters.status,
        },
      },
    });
  },
  getSuppliersStatistics: ({ filters }: GetSuppliersStatistics) => {
    return api<TableDataResponse<IAdminSupplier>>({
      url: Endpoints.melpAdmin.suppliers.statistics,
      params: {
        filter: {
          name: filters.name || undefined,
          systemName: filters.systemName || undefined,
          status: filters.status,
        },
      },
    });
  },
};
