import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { useAuthContext } from 'auth';
import { loaders } from './auth.loaders';
import { converters } from './auth.converters';

export const authKeys = queryKeyFactory('auth');

export const useCurrentUser = () => {
  const { loggedIn } = useAuthContext();

  const query = useQuery({
    queryKey: authKeys.detail('current-user'),
    queryFn: () => loaders.getCurrentUser(),
    select: converters.getCurrentUser,
    enabled: loggedIn,
    staleTime: 1000 * 60 * 60,
  });

  return query;
};
