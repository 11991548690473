import { ComponentProps, ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../ButtonOld/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '../Dialog/Dialog';
import Form from './Form';

type FormDialogDialogProps = Pick<DialogProps, 'open' | 'width'>;
type FormDialogFormProps<F extends FieldValues> = Required<
  Pick<ComponentProps<typeof Form<F>>, 'onSubmit'>
>;
type FormDialogSubmitButtonProps = Pick<
  ComponentProps<typeof Button>,
  'loading'
>;

interface FormDialogOwnProps extends Pick<DialogProps, 'width'> {
  /**
   * Callback to execute when cancel is clicked or dialog closed.
   */
  onCancel: () => void;
  /**
   * Dialog content containing form fields.
   */
  children: ReactNode;
  /**
   * Submit button label. If not provided, "Submit" is used.
   */
  submitLabel?: string;
  /**
   * Dialog title. If not provided, submitLabel is used.
   */
  title?: string;
}

export type FormDialogProps<F extends FieldValues = any> = FormDialogOwnProps &
  FormDialogDialogProps &
  FormDialogFormProps<F> &
  FormDialogSubmitButtonProps;

/**
 * Component which enables a quick creation of a dialog for data submission.
 */
const FormDialog = <F extends FieldValues>(props: FormDialogProps<F>) => {
  const { t } = useTranslation();

  const submitLabel = props.submitLabel ?? t('common.submit');

  return (
    <Dialog
      width={props.width ?? 450}
      open={props.open}
      onClose={props.onCancel}
    >
      <Form onSubmit={props.onSubmit}>
        <DialogTitle>{props.title ?? submitLabel}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <Button minWidth={120} onClick={props.onCancel} type="reset">
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            minWidth={120}
            type="submit"
            loading={props.loading}
          >
            {submitLabel}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default FormDialog;
