import { useMemo } from 'react';

export const useTableDialogActions = () =>
  useMemo(() => ['changeBenefitType'], []);

export const useDefaultHighlights = () =>
  useMemo(
    () => ({
      inactive: 'inactive',
    }),
    [],
  );
