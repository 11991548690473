import { BackButton } from 'melp-design/components';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionOptions } from '../../../constants/Administrators';
import ActionsPopper from '../../common/ActionsPopper/ActionsPopper';

interface Props {
  onChangeAll: (permission: string) => void;
  isSelected: boolean;
  backTo?: string;
}

export default function PermissionsActionHeader({
  onChangeAll,
  isSelected,
  backTo,
}: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const id = open ? 'permissions-popper' : undefined;

  const closePopper = () => setOpen(false);

  return (
    <Stack
      marginBottom="30px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <BackButton to={backTo} />
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpen(true);
        }}
        aria-describedby={id}
        variant="contained"
        color="primary"
        disabled={!isSelected}
      >
        {t('permissions.set_all_to')} ...
      </Button>
      <ActionsPopper
        onActionClick={(action) => {
          closePopper();
          onChangeAll(action);
        }}
        onClose={closePopper}
        actions={Object.values(PermissionOptions)}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      />
    </Stack>
  );
}
