import { Button } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { FuzzySearch } from 'utils/fuzzy-search/fuzzy-search';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

const labelsMap = new Map<string, string>();

interface LabeledOption<Option extends string> {
  key: Option;
  label: string;
}

export interface SelectFilterProps<
  Option extends string,
  Multiple extends boolean,
> {
  name?: string;
  onClearFilter: () => void;
  onCancel: () => void;
  options?: LabeledOption<Option>[];
  loadingOptions?: boolean;
  /**
   * Skips local options search and relies on externally provided options.
   * To attach to search events please see onSearch property.
   */
  asyncSearch?: boolean;
  /**
   * Is called when search box is idling for 300ms. It's only invoked, if
   * asyncSearch is set to true.
   */
  onSearch?: (request: string) => void;
  /**
   * Minimal amount of symbols to perform search.
   */
  searchMinLength?: number;
  /**
   * Indicates whether multiple options can be selected
   */
  multiple: Multiple;
  value?: (Multiple extends true ? Option[] : Option) | null;
  onApplyFilter: (value: Multiple extends true ? Option[] : Option) => void;
  /**
   * Indicates whether search field is displayed
   */
  searchable?: boolean;
}

export const SelectFilter = <Option extends string, Multiple extends boolean>({
  value,
  options,
  name,
  multiple,
  searchMinLength = 0,
  asyncSearch = false,
  searchable = true,
  loadingOptions,
  onApplyFilter,
  onClearFilter,
  onCancel,
  onSearch,
}: SelectFilterProps<Option, Multiple>) => {
  const { t } = useTranslation();

  const searchInputRef = useRef<HTMLDivElement>(null);

  const [selectedKeys, setSelectedKeys] = useState<Option[]>(
    // @ts-expect-error
    (typeof value === 'string' ? [value] : value) ?? [],
  );

  const [searchText, setSearchText] = useState('');
  const debouncedSearchHandler = useDebouncedCallback((request: string) => {
    onSearch?.(request);
  }, 300);
  const handleSearchTextChange = (newValue: string) => {
    setSearchText(newValue);
    if (asyncSearch) {
      if (newValue.length >= searchMinLength) {
        debouncedSearchHandler(newValue);
      } else {
        // Cancel any pending search invocation if search text threshold is not reached
        if (debouncedSearchHandler.isPending()) {
          debouncedSearchHandler.cancel();
        }
      }
    }
  };

  const renderInDefaultContainer = (node: ReactNode) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      p={2}
      minHeight={150}
    >
      {node}
    </Box>
  );

  const addLabelToMap = (key: string, label: string) => {
    if (!asyncSearch || key === label) {
      return;
    }
    labelsMap.set(key, label);
  };

  const renderOptions = () => {
    if (loadingOptions || debouncedSearchHandler.isPending()) {
      return renderInDefaultContainer(<CircularProgress />);
    }
    if (
      asyncSearch &&
      (searchText.length > 0 || !value?.length) &&
      searchText.length < searchMinLength
    ) {
      return renderInDefaultContainer(
        <Typography>
          {t('common.start_search', { count: searchMinLength })}
        </Typography>,
      );
    }
    const getLabeledOptions = () => {
      if (asyncSearch && value?.length && searchText.length === 0) {
        const valueOptions = Array.isArray(value) ? value : [value];
        return valueOptions.map((valueOption) => ({
          key: valueOption,
          label:
            labelsMap.get(valueOption) ??
            options?.find((o) => o.key === valueOption)?.label ??
            valueOption,
        }));
      }
      if (!options) {
        return [];
      }
      return options.map((option) => {
        if (typeof option === 'string') {
          return { key: option, label: option };
        }
        return option;
      });
    };
    const labeledOptions = getLabeledOptions();
    if (!labeledOptions.length) {
      return renderInDefaultContainer(
        <Typography>{t('common.noFilterOptions')}</Typography>,
      );
    }
    const searcher = new FuzzySearch(searchText);
    const filteredOptions = asyncSearch
      ? labeledOptions
      : labeledOptions.filter((option) => searcher.search(option.label));
    if (!filteredOptions.length) {
      return renderInDefaultContainer(
        <Typography>{t('common.noFilterOptions')}</Typography>,
      );
    }
    return filteredOptions.map(({ key, label }) => {
      return (
        <FormControlLabel
          key={key}
          label={label}
          sx={{
            width: '100%',
            py: 0.75,
            m: 0,
            transition: 'background 100ms ease-in-out',
            alignItems: 'flex-start',
            '&:hover': {
              background: SystemColors.grey[96],
            },
            ...(selectedKeys.includes(key)
              ? { background: SystemColors.primary.tint }
              : {}),
            '& > .MuiFormControlLabel-label': { p: 1, pl: multiple ? 0 : 2 },
          }}
          control={
            <Checkbox
              color="primary"
              sx={{ ...(multiple ? {} : { display: 'none' }) }}
              checked={selectedKeys.includes(key)}
              name={key}
              onChange={(e) => {
                const { checked, name } = e.target;
                const selected = name as Option;
                if (multiple) {
                  setSelectedKeys((currentKeys) => {
                    if (checked) {
                      labelsMap.set(selected, label);
                      return [...currentKeys, selected];
                    } else {
                      return currentKeys.filter(
                        (currentKey) => currentKey !== selected,
                      );
                    }
                  });
                  if (searchable && searchInputRef.current) {
                    searchInputRef.current.focus();
                  }
                } else {
                  addLabelToMap(selected, label);
                  // @ts-expect-error: 'multiple' extends false here
                  onApplyFilter(selected);
                }
              }}
            />
          }
        />
      );
    });
  };

  return (
    <>
      {searchable && (
        <Stack sx={{ p: 1 }}>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <IconButton
                      onClick={() => handleSearchTextChange('')}
                      size="large"
                    >
                      <Cross />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              ref: searchInputRef,
            }}
            name={name}
            placeholder={t('common.searchOptionsPlaceholder')}
            autoFocus
            value={searchText}
            onChange={({ target }) => handleSearchTextChange(target.value)}
          />
        </Stack>
      )}
      <Stack
        sx={{
          maxHeight: 260,
          minWidth: 300,
          overflow: 'auto',
        }}
      >
        {renderOptions()}
      </Stack>
      <Stack
        sx={{ p: 1 }}
        flexDirection="row"
        justifyContent="flex-end"
        gap={1}
      >
        {value?.length ? (
          <Button
            label={t('table.clear')}
            variant="neutral-outline"
            onClick={onClearFilter}
          />
        ) : null}
        <Button
          label={t('common.cancel')}
          variant="neutral-outline"
          onClick={onCancel}
        />
        {multiple ? (
          <Button
            label={t('common.applyFilter')}
            variant="primary"
            // @ts-expect-error: 'multiple' extends true here
            onClick={() => onApplyFilter(selectedKeys)}
            disabled={!selectedKeys.length}
          />
        ) : null}
      </Stack>
    </>
  );
};
