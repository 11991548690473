import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './orders.loaders';
import { converters } from './orders.converters';
import { OrdersFilters } from './orders.types';

export const ordersKeys = queryKeyFactory('orders');

export const useOrders = (filters: Partial<OrdersFilters> = {}) => {
  return useQuery({
    queryKey: ordersKeys.list(filters),
    queryFn: () => loaders.getOrders({ filters }),
    select: converters.getOrders,
  });
};

export const useOrder = (orderId: string) => {
  return useQuery({
    queryKey: ordersKeys.detail(orderId),
    queryFn: () => loaders.getOrder({ orderId }),
    select: converters.getOrder,
    enabled: !!orderId,
  });
};
