import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Label } from 'melp-design/components';
import { isDefined } from '../../../utils/isDefined';

const useStyles = makeStyles(() => ({
  titleWithCount: {
    '&::before': {
      content: '"+"',
    },
  },
}));

interface Props {
  /**
   * Indicated whether all content is displayed or not
   */
  contentVisible: boolean;
  /**
   * Callback to execute when value changes
   */
  onChange: (value: boolean) => void;
  /**
   * Shows how many more items there are. Useful when cutting the content of a
   * list.
   */
  count?: number;
  fullWidth?: boolean;
}

const ContentVisibilityToggle = ({
  count,
  contentVisible,
  ...props
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    props.onChange(!contentVisible);
  };

  const renderTitle = () => {
    if (contentVisible) {
      return t('common.showLess');
    }
    if (isDefined(count)) {
      return (
        <span className={classes.titleWithCount}>
          {t('common.nMore', { count })}
        </span>
      );
    }
    return t('common.showMore');
  };

  return (
    <Label
      onClick={handleClick}
      textColor="primary"
      fullWidth={props.fullWidth}
    >
      {renderTitle()}
    </Label>
  );
};

export default ContentVisibilityToggle;
