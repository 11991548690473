import { LocalStorageFTManager } from './LocalStorageFTManager';
import { features } from './Features';
import { FeatureKey, FeatureToggleManager, Env } from './Types';

const FTManager: FeatureToggleManager = new LocalStorageFTManager(features);

/**
 * Feature toggles runtime CLI
 */
// Casting to any to avoid setting explicit types for window.
(window as any).__FTManager__ = {
  availableFeatures: FTManager.availableFeatures(),
  getEnabledFeatures: FTManager.getEnabledFeatureKeys,
  enableFeature: FTManager.enableFeature,
  disableFeature: FTManager.disableFeature,
  disableAll: FTManager.disableAll,
  forceProd: () => FTManager.setEnv(Env.production),
  resetToDev: () => FTManager.setEnv(Env.development),
};

/**
 * A hook to setup a component tree based on feature toggles configuration.
 * @param key A context feature key. All functions will be executed with a specified feature in mind.
 * @returns Utility functions to get information about feature toggle.
 */
export const useFeatureToggle = (key: FeatureKey) => {
  const isFeatureEnabled = () => FTManager.isFeatureEnabled(key);
  return { isFeatureEnabled };
};

export { FeatureKey } from './Types';
