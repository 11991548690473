import { TextField } from 'melp-design/components';
import { IconButton, InputAdornment, InputAdornmentProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { ComponentProps, useEffect, useState } from 'react';
import { Close } from '../../../icons';
import { FieldRenderProps, FieldState, InternalFieldRenderer } from '../Types';

const areEqual = (a: Moment, b: Moment | null) => a.isSame(b, 'day');

interface CustomInputAdornmentProps extends InputAdornmentProps {
  clearButtonProps?: ComponentProps<typeof IconButton>;
}

const CustomInputAdornment = ({
  clearButtonProps,
  children,
  ...rest
}: CustomInputAdornmentProps) => (
  <InputAdornment {...rest}>
    {clearButtonProps && (
      <IconButton {...clearButtonProps}>
        <Close />
      </IconButton>
    )}
    {children}
  </InputAdornment>
);

export interface LocalStateDatePickerProps
  extends Omit<DatePickerProps<Moment>, 'value' | 'onChange'> {
  /**
   * Date picker allows to set only date (without time), but under the hood it
   * relies on date with time. This property allows to set the time part for a
   * date.
   *
   * 1) current - sets the time to the moment when date was selected.
   * 2) startOfDay - sets the time to 00:00:00:000. Default.
   * 3) endOfDay - sets the time to 23:59:59:999
   * 4) none - removes time entirely (e.g., "1991-06-27T00:00:00:000" will become "1991-06-27").
   *    This option is useful when only date is required (e.g., date of birth).
   */
  setTime?: 'current' | 'startOfDay' | 'endOfDay' | 'none';
}

interface Props {
  props: LocalStateDatePickerProps;
  field: FieldRenderProps<string | null>;
  fieldState: FieldState;
}

const LocalStateDatePicker = ({
  props: { setTime, ...propsRest },
  field: { required, value, onChange, onBlur, ...fieldRest },
  fieldState,
}: Props) => {
  const [localValue, setLocalValue] = useState(value ? moment(value) : null);

  const handleChange = (newValue: typeof localValue) => {
    if (!newValue) {
      if (!!value) {
        // local date changed to null
        onChange(null);
      }
    } else if (newValue.isValid()) {
      if (!areEqual(newValue, moment(value))) {
        let timeAdjustedValue = newValue;

        if (setTime === 'startOfDay') {
          timeAdjustedValue = timeAdjustedValue.startOf('day');
        }

        if (setTime === 'endOfDay') {
          timeAdjustedValue = timeAdjustedValue.endOf('day');
        }

        if (setTime === 'current') {
          const now = moment();
          timeAdjustedValue = timeAdjustedValue.set({
            hour: now.get('hour'),
            minute: now.get('minute'),
            second: now.get('second'),
            millisecond: now.get('millisecond'),
          });
        }

        let newValueAsString = timeAdjustedValue.toISOString(true);

        if (setTime === 'none') {
          newValueAsString = moment(newValueAsString).format('YYYY-MM-DD');
        }

        // local date has changed compared to form date
        onChange(newValueAsString);
      }
    } else {
      setLocalValue(newValue);
    }
  };

  useEffect(() => {
    // both equal to null
    if ((value ?? null) === localValue) {
      return;
    }
    // form state value became null
    if (value === null) {
      setLocalValue(null);
      return;
    }
    const valueAsMoment = moment(value);
    // form state value is the same as local state value
    if (areEqual(valueAsMoment, localValue)) {
      return;
    }
    // form state value differs from local state value
    setLocalValue(valueAsMoment);
    // this side effect should react only to form date value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const clearDates = () => {
    onChange(null);
    setLocalValue(null);
  };

  return (
    <DatePicker
      slots={{
        // Currently there is no good option to properly override the text field
        textField: TextField as any,
        inputAdornment: CustomInputAdornment,
      }}
      slotProps={{
        textField: {
          fullWidth: true,
          error: fieldState.invalid,
          helperText: fieldState.message,
          required,
          onBlur: () => {
            if (localValue === null || localValue.isValid()) {
              return;
            }
            if (value !== null) {
              onChange(null);
            } else {
              setLocalValue(null);
            }
            onBlur();
          },
        },
        inputAdornment: {
          clearButtonProps:
            !!value || !!localValue
              ? {
                  onClick: clearDates,
                }
              : undefined,
        } as any, // currently there is no good way to extend InputAdornment
      }}
      sx={{
        '.MuiOutlinedInput-input, .MuiOutlinedInput-input::placeholder': {
          textTransform: 'lowercase',
        },
      }}
      value={localValue}
      onChange={handleChange}
      {...fieldRest}
      {...propsRest}
    />
  );
};

export const renderDateField: InternalFieldRenderer = (
  props,
  field,
  fieldState,
) => {
  return (
    <LocalStateDatePicker props={props} field={field} fieldState={fieldState} />
  );
};
