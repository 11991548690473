import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Dropdown } from 'melp-design/icons';
import { Colors, Effects } from 'melp-design/style';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import Typography from '../Typography/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  parent: {
    height: 70,
    padding: '15px 10px',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: Colors.grey,
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: Colors.primary,
    },
  },
  active: {
    color: Colors.primary,
  },
  arrow: {
    color: Colors.primary,
    marginBottom: 1,
  },
  menuContainer: {
    marginTop: 5,
    boxShadow: Effects.smallElementsShadow,
    borderRadius: 6,
  },
  list: {
    padding: 5,
    minWidth: 110,
    gap: 5,
  },
  item: {
    padding: '5px 10px !important', // To override another !important
    borderRadius: 6,
    color: Colors.black,
    transition: theme.transitions.create(['color', 'backGround-color']),
    '&:hover': {
      backGroundColor: Colors.screenBackgroundGrey,
      color: Colors.primary,
    },
  },
  marker: {
    height: 1,
    width: 'calc(100% - 18px)',
    backgroundColor: Colors.primary,
    marginBottom: '-1px',
  },
}));

interface ChildRoute {
  id: string;
  label: string;
  path: string;
}

interface Props {
  label: string;
  id: string;
  path?: string;
  childrenRoutes?: ChildRoute[];
  active?: boolean;
}

const NavMenuItem = ({ active = false, ...props }: Props) => {
  const classes = useStyles();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const withChildrenRoutes = !!props.childrenRoutes?.length;

  const handleParentClick = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const renderParent = () => {
    const text = (
      <Typography variant="p1" color={active ? 'primary' : 'inherit'}>
        {props.label}
      </Typography>
    );

    if (withChildrenRoutes) {
      return (
        <ButtonBase
          onClick={handleParentClick}
          className={clsx(classes.parent, open && classes.active)}
          data-test={props.id}
        >
          <span ref={anchorRef}>{text}</span>
          {withChildrenRoutes && <Dropdown className={classes.arrow} />}
        </ButtonBase>
      );
    }

    return (
      <NavLink to={props.path ?? '#'} className={clsx(classes.parent)}>
        {text}
      </NavLink>
    );
  };

  return (
    <div className={classes.root}>
      {renderParent()}
      {active && <div className={classes.marker} />}
      {!!props.childrenRoutes && (
        <DropdownMenu
          anchorEl={anchorRef.current}
          open={open}
          onClose={closeMenu}
          onItemClick={closeMenu}
          menuItems={props.childrenRoutes}
        />
      )}
    </div>
  );
};

export default NavMenuItem;
