import { FC } from 'react';
import { Alert } from 'melp-design/components';
import { useMe } from 'state/Administrators';
import { useTranslation } from 'react-i18next';

export const ShopInternalDisabledFeatureAlert: FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useMe();

  return isFeatureEnabled('companyItems') ? null : (
    <Alert severity="warning">{t('shop.info.internal_disabled')}</Alert>
  );
};
