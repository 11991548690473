import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IRecognitionReward, IRecognitionRewardsConfig } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { RecognitionRewardsFilters } from './recognition-rewards.types';

const getFilters = (filters: Partial<RecognitionRewardsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    name: filters.name || undefined,
    itemIds: filters.items,
    countries: filters.countries,
    cities: filters.cities,
    benefitPlanTypes: filters.benefitCategories,
    categories: filters.categories,
    supplierIds: filters.suppliers,
    priceFrom: filters.price?.from,
    priceTo: filters.price?.to,
    isWhitelisted:
      filters.variant === 'selected'
        ? true
        : filters.variant === 'not-selected'
        ? false
        : undefined,
  },
  sort: filters.sort
    ? `${filters.sort.order === 'desc' ? '-' : ''}${filters.sort.columnKey}`
    : undefined,
});

interface GetRewards {
  filters: Partial<RecognitionRewardsFilters>;
}

interface AddReward {
  marketplaceItemId: string;
}

interface RemoveReward {
  marketplaceItemId: string;
}

interface AddRewards {
  filters: Partial<RecognitionRewardsFilters>;
}

interface RemoveRewards {
  filters: Partial<RecognitionRewardsFilters>;
}

interface UpdateConfig {
  config: Partial<RecognitionRewardsFilters>;
}

export const loaders = {
  getRewards: ({ filters }: GetRewards) => {
    return api<TableDataResponse<IRecognitionReward>>({
      url: Endpoints.clientAdmin.recognitions.rewards.root,
      params: getFilters(filters),
    });
  },
  addReward: ({ marketplaceItemId }: AddReward) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.byId.replace(
        ':marketplaceItemId',
        marketplaceItemId,
      ),
      method: 'post',
    });
  },
  removeReward: ({ marketplaceItemId }: RemoveReward) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.byId.replace(
        ':marketplaceItemId',
        marketplaceItemId,
      ),
      method: 'delete',
    });
  },
  addRewards: ({ filters }: AddRewards) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.link,
      method: 'post',
      params: {
        filter: getFilters(filters).filter,
      },
    });
  },
  removeRewards: ({ filters }: RemoveRewards) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.unlink,
      method: 'delete',
      params: {
        filter: getFilters(filters).filter,
      },
    });
  },
  getConfig: () => {
    return api<IRecognitionRewardsConfig>({
      url: Endpoints.clientAdmin.recognitions.rewards.config,
    });
  },
  updateConfig: ({ config }: UpdateConfig) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.config,
      method: 'put',
      params: {
        filter: getFilters(config).filter,
      },
    });
  },
  removeConfig: () => {
    return api({
      url: Endpoints.clientAdmin.recognitions.rewards.config,
      method: 'delete',
    });
  },
};
