import { ButtonOld } from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import {
  PickersActionBarAction,
  PickersActionBarProps,
} from '@mui/x-date-pickers/PickersActionBar';
import { useTranslation } from 'react-i18next';

const CustomDatePickerActionBar = ({
  actions,
  onSetToday,
  onAccept,
  onCancel,
  onClear,
  ...rest
}: PickersActionBarProps) => {
  const { t } = useTranslation();

  if (!actions || actions.length === 0) {
    return null;
  }

  const configsMap: Record<
    PickersActionBarAction,
    { label: string; handler: () => void }
  > = {
    clear: {
      label: 'Clear',
      handler: onClear,
    },
    cancel: {
      label: 'Cancel',
      handler: onCancel,
    },
    accept: {
      label: 'Accept',
      handler: onAccept,
    },
    today: {
      label: t('common.now'),
      handler: onSetToday,
    },
  };

  return (
    <Box p="0px 10px 10px" mt="-10px" {...rest}>
      <Stack gap="10px">
        {actions.map((action) => {
          const config = configsMap[action];
          return (
            <ButtonOld key={action} variant="outlined" onClick={config.handler}>
              {config.label}
            </ButtonOld>
          );
        })}
      </Stack>
    </Box>
  );
};

export default CustomDatePickerActionBar;
