import { styled } from '@mui/system';
import { MenuList, MenuItem } from '@mui/material';
import { Colors } from 'melp-design/style';

export const OptionsList = styled(MenuList)({
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
});

export const OptionsItem = styled(MenuItem)({
  padding: '8px 10px !important', // To override another !important
  borderRadius: 6,
  color: Colors.black,
  transitionProperty: 'color, backGround-color',
  transitionDuration: '100ms',
  transitionTimingFunction: 'ease-in-out',
  backgroundColor: 'transparent',

  '&:focus': {
    backgroundColor: Colors.screenBackgroundGrey,
  },

  '&.Mui-selected': {
    color: Colors.primary,
    backgroundColor: 'transparent',
  },

  '&:hover, &.Mui-selected:hover': {
    backgroundColor: Colors.screenBackgroundGrey,
  },

  // To override existing styles without impacting other elements
  '&::after': {
    display: 'none',
  },
});
