import { Box, ButtonBase, Paper, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { SystemColors } from 'melp-design/style';
import { Typography } from 'melp-design/components';

interface TabConfig<Tab extends string> {
  key: Tab;
  label: string;
  url?: string;
  isActive?: boolean;
  disabled?: boolean;
}

interface Props<Tab extends string> {
  activeTab?: Tab;
  onTabChange?: (tabKey: Tab) => void;
  tabs: TabConfig<Tab>[];
  readOnly?: boolean;
}

export const SideMenu = <Tab extends string>({
  activeTab,
  tabs,
  onTabChange,
  readOnly,
}: Props<Tab>) => {
  return (
    <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
      <Stack>
        {tabs.map((tab, index, self) => {
          const isActive = tab.isActive ?? tab.key === activeTab;

          return (
            <ButtonBase
              {...(tab.url
                ? {
                    component: Link,
                    to: tab.url,
                    disabled: readOnly || tab.disabled,
                  }
                : {
                    onClick: () => onTabChange?.(tab.key),
                    disabled: readOnly || tab.disabled,
                  })}
              sx={{
                position: 'relative',
                color: SystemColors.grey[90],

                '&:first-of-type': {
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                },

                '&:last-of-type': {
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                },

                '&:not(:last-of-type)::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '67px',
                  right: 0,
                  height: '1px',
                  backgroundColor: SystemColors.grey[90],
                },
              }}
              key={tab.key}
            >
              <Stack direction="row" width="100%" px="15px" py="10px">
                <Box
                  ml="5px"
                  mr="13px"
                  mt="8px"
                  sx={{
                    position: 'relative',

                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: '12px',
                      top: '-18px',
                      height: '30px',
                      width: '1px',
                      backgroundColor: SystemColors.grey[90],
                      display: index === 0 ? 'none' : 'initial',
                    },

                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: '12px',
                      top: '12px',
                      bottom: '-10px',
                      width: '1px',
                      backgroundColor: SystemColors.grey[90],
                      display: index === self.length - 1 ? 'none' : 'initial',
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: '25px',
                      width: '25px',
                      borderRadius: '50%',
                      display: 'grid',
                      placeItems: 'center',
                      position: 'relative',
                      zIndex: 1,
                      backgroundColor: isActive
                        ? SystemColors.primary.tint
                        : 'transparent',
                      transition: 'background-color 100ms ease-in-out',
                    }}
                  >
                    <Box
                      sx={{
                        height: '9px',
                        width: '9px',
                        borderRadius: '50%',
                        backgroundColor: isActive
                          ? SystemColors.primary.base
                          : SystemColors.grey[90],
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  flexGrow={1}
                  p="10px"
                  borderRadius="10px"
                  sx={{
                    backgroundColor: isActive
                      ? SystemColors.primary.tint
                      : 'transparent',
                    transition: 'background-color 100ms ease-in-out',
                  }}
                >
                  <Typography
                    color={isActive ? 'primary' : 'textSecondary'}
                    align="left"
                  >
                    {tab.label}
                  </Typography>
                </Box>
              </Stack>
            </ButtonBase>
          );
        })}
      </Stack>
    </Paper>
  );
};
