import { useCallback, useMemo, useState, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Divider, Stack } from '@mui/material';
import { Button, Typography } from 'melp-design/components';
import { formatBytes, formatList } from 'utils/format';
import { SystemColors } from 'melp-design/style';
import { FilesList } from './atoms';
import { DragState, Props } from './file-input.types';
import { MIME_TYPE_BY_FILE } from './file-input.constants';

export const FileInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      accept,
      maxSize = 25 * 1024 * 1024,
      maxFiles = 2,
      name,
      disabled,
      required,
      onChange,
      error,
      previewSelectedFiles = true,
    },
    forwardedRef,
  ) => {
    const { t, i18n } = useTranslation();

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleChange = (files: File[]) => {
      setUploadedFiles(files);
      onChange?.(files);
    };

    const onDrop = useCallback<typeof handleChange>(handleChange, [
      handleChange,
    ]);

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      open,
    } = useDropzone({
      accept: accept?.reduce(
        (acc, filetype) => ({
          ...acc,
          [MIME_TYPE_BY_FILE[filetype]]: [`.${filetype}`],
        }),
        {},
      ),
      onDrop,
      disabled,
      maxSize,
      maxFiles,
      noClick: true,
      noKeyboard: true,
    });

    const dragState = useMemo<DragState>(() => {
      return isDragAccept
        ? 'accept'
        : isDragReject
        ? 'reject'
        : isDragActive
        ? 'active'
        : 'inactive';
    }, [isDragAccept, isDragReject, isDragActive]);

    return (
      <Stack sx={{ fontSize: 16 }} gap={1}>
        {label ? (
          <Typography variant="p1" color="textSecondary">
            {label}
          </Typography>
        ) : null}

        <Box
          {...getRootProps({
            disabled,
            'data-drag-state': dragState,
          })}
          p={2}
          sx={{
            width: '100%',
            background: SystemColors.white,
            border: `1px dashed ${
              error ? SystemColors.danger.base : SystemColors.grey[55]
            }`,
            borderRadius: 1,
            "&[data-drag-state='accept'],&[data-drag-state='active']": {
              borderColor: SystemColors.success.base,
              background: SystemColors.grey[96],
            },
            "&[data-drag-state='reject']": {
              background: SystemColors.danger.base,
            },
            '&[disabled]': {
              background: SystemColors.grey[96],
            },
          }}
        >
          <input
            {...getInputProps({
              multiple: maxFiles > 1,
              name,
              disabled,
              required: required ? !uploadedFiles.length : undefined,
              ref: forwardedRef,
            })}
            // hack to replace `display: none` so input can be focused on required error
            // https://github.com/react-dropzone/react-dropzone/issues/1268
            style={{
              display: 'initial',
              opacity: '0',
              position: 'absolute',
              zIndex: -1,
            }}
          />
          <Stack
            flexDirection="column"
            alignItems="center"
            gap={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography variant="h3">
              {t('components.file_input.drag_n_drop')}
            </Typography>
            <Box
              sx={{
                width: 150,
                maxWidth: '100%',
              }}
            >
              <Divider
                sx={{
                  fontSize: 14,
                  textTransform: 'uppercase',
                  color: SystemColors.grey[75],
                  '&::before, &::after': {
                    borderColor: 'currentColor',
                  },
                }}
              >
                {t('common.or')}
              </Divider>
            </Box>
            <Button
              label={t('components.file_input.browse')}
              onClick={open}
              size="sm"
              disabled={disabled}
            />

            <Box mt={1.5}>
              {!!accept?.length && (
                <Typography variant="p1">
                  <Trans
                    i18nKey="components.file_input.supported"
                    values={{
                      filetypes: formatList(
                        i18n.language,
                        accept.map((filetype) => `.${filetype}`),
                      ),
                    }}
                    components={[
                      <Box component="span" sx={{ fontWeight: 500 }} key="0" />,
                    ]}
                  />
                </Typography>
              )}
              <Typography variant="p1">
                <Trans
                  i18nKey="components.file_input.max"
                  values={{
                    maxSize: formatBytes(maxSize),
                  }}
                  components={[
                    <Box component="span" sx={{ fontWeight: 500 }} key="0" />,
                  ]}
                />
              </Typography>
            </Box>
          </Stack>
        </Box>

        {error ? (
          <Typography variant="p1" color="error">
            {error}
          </Typography>
        ) : null}

        {!!uploadedFiles.length && previewSelectedFiles ? (
          <FilesList
            files={uploadedFiles}
            onRemove={(name) =>
              handleChange(
                uploadedFiles.filter((uploaded) => uploaded.name !== name),
              )
            }
          />
        ) : null}
      </Stack>
    );
  },
);

FileInput.displayName = 'FileInput';
