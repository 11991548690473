import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDiscounts } from 'store/discounts';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const DiscountsFilter: FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const [search, setSearch] = useState('');

  const { data: discounts, isLoading } = useDiscounts({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={discounts?.items.map((discount) => {
        const translation = resolveTranslation(
          i18n.language,
          defaultLanguage,
          discount.translations,
        );

        return {
          key: discount.id,
          label: translation?.title || discount.name,
        };
      })}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
