import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../ButtonOld/Button';
import Typography from '../Typography/Typography';

interface ItemRenderProps<F> {
  index: number;
  itemsCount: number;
  prefix: string;
  field: Partial<F>;
  remove: () => void;
  move: (from: number, to: number) => void;
}

interface AddButtonProps {
  /**
   * Callback to execute when add button is clicked.
   * By default it appends an empty object to an array.
   */
  onClick: () => void;
  /**
   * Custom label for add button
   */
  children: string;
}

interface Props<F> {
  /**
   * Field to bind to
   */
  name: string;
  /**
   * Renders list items
   */
  children: (props: ItemRenderProps<F>) => ReactNode;
  /**
   * Custom properties for add button.
   */
  addButtonProps?: Partial<AddButtonProps>;
  /**
   * Renders a custom add button.
   */
  renderAddButton?: (
    props: AddButtonProps,
    listUtils: {
      append: (item: F) => void;
      itemsCount: number;
    },
    renderDefault: () => ReactNode,
  ) => ReactNode;
}

const FieldsList = <F extends {} = any>(props: Props<F>) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const arrayName = props.name as string;
  const {
    fields,
    append,
    remove: removeByIndex,
    move,
  } = useFieldArray<F>({
    control,
    name: arrayName,
  });

  const handleAdd = () => {
    append({});
  };

  const addButtonProps = {
    onClick: handleAdd,
    children: t('common.add'),
    ...props.addButtonProps,
  };
  const renderDefaultAddButton = () => <Button {...addButtonProps} />;
  const renderAddButton = props.renderAddButton ?? renderDefaultAddButton;
  const addButton = renderAddButton(
    addButtonProps,
    { append, itemsCount: fields.length },
    renderDefaultAddButton,
  );

  if (!fields.length) {
    return (
      <Stack
        gap="20px"
        minHeight="100px"
        p="10px"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2" color="textSecondary" align="center">
          {t('common.emptyListMessage')}
        </Typography>
        {addButton}
      </Stack>
    );
  }

  return (
    <Stack gap="20px">
      {fields.map((field, index, arr) => {
        const prefix = `${arrayName}.${index}`;
        const remove = () => removeByIndex(index);
        return (
          <div key={field.id}>
            {props.children({
              prefix,
              field,
              remove,
              index,
              itemsCount: arr.length,
              move,
            })}
          </div>
        );
      })}
      <Box alignSelf="start">{addButton}</Box>
    </Stack>
  );
};

export default FieldsList;
