import { useCallback, useMemo } from 'react';
import {
  StatusAction,
  StatusActionKey,
  StatusActions,
  StatusTypes,
} from '../types/Status';

export default function useStatus() {
  const benefitActions = useMemo(
    (): StatusActions => ({
      inactive: ['save_publish', 'save_publish_on_date'],
      draft: ['save_publish', 'save_publish_on', 'save_publish_on_date'],
      will_be_inactive_on: ['save_deactivate', 'save_deactivate_on_date'],
      active: ['save_deactivate', 'save_deactivate_on_date'],
      will_be_active_on: [
        'save_publish',
        'save_deactivate',
        'save_deactivate_on_date',
      ],
      table: ['now', 'on_specific_date'],
    }),
    [],
  );

  const baseActions = useMemo(
    (): StatusActions => ({
      active: ['deactivate_now', 'deactivate_specific_date'],
      inactive: ['activate_now', 'activate_specific_date'],
      draft: ['activate_now', 'activate_specific_date'],
      will_be_active_on: [
        'deactivate_now',
        'deactivate_specific_date',
        'activate_now',
      ],
      will_be_inactive_on: [
        'activate_now',
        'activate_specific_date',
        'deactivate_now',
      ],
      table: ['now', 'on_specific_date'],
    }),
    [],
  );

  const simpleActions = useMemo(
    (): StatusActions => ({
      active: ['deactivate_now'],
      inactive: ['activate_now'],
    }),
    [],
  );

  const marketplaceItemActions = useMemo(
    (): StatusActions => ({
      active: ['deactivate_now', 'archive'],
      inactive: ['activate_now', 'archive'],
      archived: ['activate_now', 'deactivate_now'],
    }),
    [],
  );

  const actions: StatusTypes = useMemo(
    () => ({
      benefits: benefitActions,
      administrators: simpleActions,
      integrations: simpleActions,
      employee: baseActions,
      companies: baseActions,
      benefitTemplates: simpleActions,
      simpleActions,
      marketplaceItem: marketplaceItemActions,
    }),
    [simpleActions, baseActions, benefitActions, marketplaceItemActions],
  );

  const getStatus = useCallback(
    (action: StatusActionKey): StatusAction | null => {
      switch (action) {
        case 'save_deactivate':
          return 'inactive';
        case 'save_deactivate_on_date':
          return 'will_be_inactive_on';
        case 'save_publish':
          return 'active';
        case 'save_publish_on_date':
          return 'will_be_active_on';
        case 'activate_now':
          return 'active';
        case 'deactivate_now':
          return 'inactive';
        case 'activate_specific_date':
          return 'will_be_active_on';
        case 'deactivate_specific_date':
          return 'will_be_inactive_on';
        case 'archive':
          return 'archived';
        default:
          return null;
      }
    },
    [],
  );

  const actionsWithDatepicker = useMemo(
    () => [
      'save_publish_on_date',
      'save_deactivate_on_date',
      'deactivate_specific_date',
      'activate_specific_date',
      'on_specific_date',
      'deactivate_specific_date_employee',
      'activate_specific_date_employee',
    ],
    [],
  );

  return {
    getStatus,
    actions,
    actionsWithDatepicker,
  };
}
