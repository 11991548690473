import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './news.loaders';
import { converters } from './news.converters';
import { NewsCategoriesFilters, NewsFilters } from './news.types';

export const newsKeys = queryKeyFactory('news');

export const useNews = (filters: Partial<NewsFilters> = {}) => {
  const query = useQuery({
    queryKey: newsKeys.list(filters),
    queryFn: () => loaders.getNews({ filters }),
    select: converters.getNews,
  });

  return query;
};

export const useNew = (newId: string) => {
  const query = useQuery({
    queryKey: newsKeys.detail(newId),
    queryFn: () => loaders.getNew({ newId }),
    select: converters.getNew,
    enabled: !!newId,
  });

  return query;
};

export const useNewsCategories = (
  filters: Partial<NewsCategoriesFilters> = {},
) => {
  const query = useQuery({
    queryKey: newsKeys.list('categories', filters),
    queryFn: () => loaders.getCategories({ filters }),
    select: converters.getCategories,
  });

  return query;
};

export const useNewsCategory = (categoryId: string) => {
  const query = useQuery({
    queryKey: newsKeys.detail('categories', categoryId),
    queryFn: () => loaders.getCategory({ categoryId }),
    select: converters.getCategory,
    enabled: !!categoryId,
  });

  return query;
};
