import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-clients.loaders';
import { converters } from './admin-clients.converters';
import { AdminClientsFilters } from './admin-clients.types';

export const adminClientsKeys = queryKeyFactory('admin-clients');

export const useAdminClients = (filters: Partial<AdminClientsFilters> = {}) => {
  return useQuery({
    queryKey: adminClientsKeys.list(filters),
    queryFn: () => loaders.getClients({ filters }),
    select: (data) => converters.getClients(data),
    keepPreviousData: true,
  });
};

export const useAdminClient = (clientId: string) => {
  return useQuery({
    queryKey: adminClientsKeys.detail(clientId),
    queryFn: () => loaders.getClient({ clientId }),
    select: (data) => converters.getClient(data),
    enabled: !!clientId,
  });
};
