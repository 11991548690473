import {
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from 'melp-design/components';
import { MpForm, MpFormType } from '@mp-react/form';
import { CircularProgress, FormHelperText } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormUtils } from '../../../utils/Form';
import useStyles from './FormDialog.styles';

interface IProps<D extends FieldValues> extends DialogProps {
  actionLabel: string | JSX.Element;
  onCancelClick: () => void;
  onSubmitForm: SubmitHandler<D>;
  id: string;
  title: string;
  form: MpFormType | ((methods: ReturnType<typeof useForm>) => MpFormType);
  loading: boolean;
  additionalError?: string;
  defaultValues?: Record<string, any>;
  subtitle?: string | React.ReactNode;
  disabled?: boolean;
  altCancelText?: string;
}

const FormDialog = <D extends {}>({
  actionLabel,
  onCancelClick,
  onSubmitForm,
  id,
  title,
  form,
  loading,
  additionalError,
  defaultValues,
  subtitle,
  disabled,
  altCancelText,
  ...rest
}: IProps<D>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { overridables } = useFormUtils();
  const useFormMethods = useForm();
  const { handleSubmit, clearErrors, reset, formState } = useFormMethods;

  const hasChanges = formState.isDirty;

  useEffect(() => {
    if (!!defaultValues) reset(defaultValues);
  }, [defaultValues, reset]);

  const handleError: SubmitErrorHandler<Record<string, any>> = () => {};

  const handleCancelClick = () => {
    clearErrors();
    onCancelClick();
  };

  const handleClose = () => {
    if (hasChanges) {
      if (window.confirm(t('errors.user_leaving_edited_page'))) {
        handleCancelClick();
      }
    } else {
      handleCancelClick();
    }
  };

  const submitData = () => {
    handleSubmit(onSubmitForm, handleError)();
  };

  const getButtonLabel = () => {
    if (loading)
      return (
        <>
          <CircularProgress size={20} classes={{ root: classes.loader }} />
          {actionLabel}
        </>
      );
    return actionLabel;
  };

  return (
    <FormProvider {...useFormMethods}>
      <Dialog
        classes={{ paperWidthSm: classes.root }}
        aria-labelledby={id}
        onClose={handleClose}
        data-test="form-dialog"
        {...rest}
      >
        <DialogTitle>
          <Typography gutterBottom={!!subtitle} variant="h2" id={id}>
            {title}
          </Typography>
          {!!subtitle && (
            <Typography variant="p1" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent
          classes={{
            root: clsx(classes.dialogContentRoot, {
              [classes.inputsDisable]: disabled,
            }),
          }}
        >
          <MpForm
            overridables={overridables}
            {...(typeof form === 'function' ? form(useFormMethods) : form)}
            useFormMethods={useFormMethods as any}
          />
          {!!additionalError && (
            <FormHelperText className={classes.additionalError} error>
              {additionalError}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonOld onClick={handleClose}>
            {altCancelText ?? t('common.cancel')}
          </ButtonOld>
          <ButtonOld
            onClick={submitData}
            variant="contained"
            color="primary"
            disabled={loading}
            data-test="form-dialog-submit"
          >
            {getButtonLabel()}
          </ButtonOld>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default FormDialog;
