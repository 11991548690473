import { IAdminShopItem, IAdminShopItemsInfo } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertShopItem } from 'store/shop';
import { loaders } from './admin-shop.loaders';
import { AdminShopItem, AdminShopItemsInfo } from './admin-shop.types';

const convertAdminShopItem = (item: IAdminShopItem): AdminShopItem => {
  return {
    ...convertShopItem(item),
    ordersCount: item.orderCount ?? 0,
  };
};

const convertAdminShopItems = (item: IAdminShopItem[]): AdminShopItem[] => {
  return item.map(convertAdminShopItem);
};

const convertShopItemsInfo = (
  info: IAdminShopItemsInfo,
): AdminShopItemsInfo => {
  return {
    countries: info.countries,
  };
};

export const converters = {
  getShopItems: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getShopItems>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAdminShopItems(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getShopItem: ({ data }: Awaited<ReturnType<typeof loaders.getShopItem>>) => {
    return convertAdminShopItem(data);
  },
  getShopItemsInfo: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getShopItemsInfo>>) => {
    return convertShopItemsInfo(data);
  },
};
