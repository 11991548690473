export enum FormEndAdornmentTypes {
  Clear = 'clear',
}

export const Matchers = {
  // The most basic email regex
  EMAIL: /\S+@\S+\.\S+/,
  /**
   * Matches following conditions:
   *   1) At least 6 characters long
   *   2) Includes at lease:
   *     a) 1 uppercase letter
   *     b) 1 lowercase letter
   *     c) 1 special symbol (^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -)
   *     d) 1 number
   */
  PASSWORD:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\':;|_~`])\S{6,}$/,
  /**
   * Verifies URL patter (http or https protocols are not required)
   */
  URL: /(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
};
