import { FormField, Typography } from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { ComponentProps, useEffect } from 'react';
import { get, useFormContext } from 'react-hook-form';

type FieldProps = Pick<
  ComponentProps<typeof FormField>,
  'name' | 'label' | 'rules'
>;

interface Props {
  from: FieldProps;
  to: FieldProps;
}

const DateRange = (props: Props) => {
  const { watch, trigger, errors } = useFormContext();

  const rangeStartName = props.from.name;
  const rangeEndName = props.to.name;

  const rangeStartString: string | null = watch(rangeStartName) ?? null;
  const rangeEndString: string | null = watch(rangeEndName) ?? null;
  const rangeStart = moment(rangeStartString);
  const rangeEnd = moment(rangeEndString);

  useEffect(() => {
    if (get(errors, rangeEndName)) {
      trigger(rangeEndName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeStartString]);

  useEffect(() => {
    if (get(errors, rangeStartName)) {
      trigger(rangeStartName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeEndString]);

  return (
    <Stack direction="row" gap="5px">
      <FormField
        {...props.from}
        render={{
          type: 'date',
          props: {
            maxDate: rangeEnd.isValid() ? rangeEnd : undefined,
          },
        }}
      />
      <Box display="flex" alignItems="center" height="50px">
        <Typography variant="p1">-</Typography>
      </Box>
      <FormField
        {...props.to}
        render={{
          type: 'date',
          props: {
            minDate: rangeStart.isValid() ? rangeStart : undefined,
            setTime: 'endOfDay',
          },
        }}
      />
    </Stack>
  );
};

export default DateRange;
