import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { SystemColors } from 'melp-design/style';
import { getBorder } from '../../../constants/Style';

const InfiniteListWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      p={2}
      sx={{
        backgroundColor: SystemColors.white,
        border: `1px solid ${SystemColors.grey[90]}`,
        borderTop: 0,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
    >
      {children}
    </Box>
  );
};

const TableWrapper: FC<PropsWithChildren<{}>> & {
  InfiniteListWrapper: typeof InfiniteListWrapper;
} = ({ children }) => {
  return (
    <Box
      sx={{
        '.MuiTable-root': {
          border: getBorder(SystemColors.grey[90]),
          borderTop: 'none',

          '& .MuiTableCell-head': {
            borderBottom: getBorder(SystemColors.grey[90]),
          },

          '& .MuiTableRow-root': {
            '&:first-of-type': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
          },

          '& .MuiTableRow-root:first-child > .MuiTableCell-body': {
            borderTop: 'none',
            borderRadius: 0,
          },

          '& .MuiTableCell-body': {
            borderLeft: 'none',
            borderRight: 'none',
          },

          '& > *:not(:first-child) > .MuiTableRow-root:last-child > .MuiTableCell-root':
            {
              border: 'none',
            },

          // '& > *:last-child > .MuiTableRow-root:last-child > .MuiTableCell-root':
          //   {
          //     '&:first-child': {
          //       borderBottomLeftRadius: '10px',
          //     },
          //     '&:last-child': {
          //       borderBottomRightRadius: '10px',
          //     },
          //   },
        },
        '.NoData-root, .TableLoader': {
          border: getBorder(SystemColors.grey[90]),
          borderTop: 'none',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          backgroundColor: SystemColors.white,
        },
      }}
    >
      {children}
    </Box>
  );
};

TableWrapper.InfiniteListWrapper = InfiniteListWrapper;

export default TableWrapper;
