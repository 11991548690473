import { AppLanguage, appLanguages } from 'types/general';
import { APP } from 'config';
import { getName } from 'i18n-iso-countries';

export const formatCountry = (language: string, alpha3: string): string => {
  const locale: AppLanguage = appLanguages.includes(language as any)
    ? (language as AppLanguage)
    : APP.locales.default;

  return getName(alpha3, locale) || alpha3;
};
