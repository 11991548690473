import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { Box, IconButton, List, ListItem } from '@mui/material';
import { Typography } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { formatBytes } from 'utils/format';
import { getFiletype } from 'utils/general';
import { FileIcon } from '..';

interface Props {
  files: File[];
  onRemove: (id: string) => void;
}

export const FilesList: FC<Props> = ({ files, onRemove }) => {
  const { t } = useTranslation();

  return (
    <List sx={{ py: 0 }}>
      {files.map((file, i) => (
        <ListItem sx={{ px: 0 }} key={i}>
          <FileIcon type={getFiletype(file.name)} />

          <Box ml={2} flex={1}>
            <Typography variant="h3">{file.name}</Typography>
            <Typography variant="p1" color="textSecondary">
              {formatBytes(file.size)}
            </Typography>
          </Box>

          <IconButton
            size="small"
            sx={{
              color: SystemColors.black,
            }}
            onClick={() => onRemove(file.name)}
            title={t('common.remove')}
          >
            <Close />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};
