import { useAdminSuppliers } from 'store/admin-suppliers';
import { useParentCompanies } from 'store/companies';

export const useOptions = () => {
  const { data: suppliers, isLoading: isSuppliersLoading } = useAdminSuppliers({
    pageSize: 99999,
  });
  const { data: companies, isLoading: isCompaniesLoading } = useParentCompanies(
    { pageSize: 99999 },
  );

  return {
    suppliers: {
      items:
        suppliers?.items.map((supplier) => ({
          value: supplier.id,
          label: supplier.name,
        })) ?? [],
      isLoading: isSuppliersLoading,
    },
    companies: {
      items:
        companies?.items.map((supplier) => ({
          value: supplier.id,
          label: supplier.name,
        })) ?? [],
      isLoading: isCompaniesLoading,
    },
  } satisfies Record<
    string,
    {
      items: Array<{ value: string; label: string }>;
      isLoading: boolean;
    }
  >;
};
