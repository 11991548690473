import {
  Sort,
  TableOld,
  TableToolbar,
  TableWrapper,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeFilter, FilterButton, Filters } from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useRecognitionGivers } from 'state/Recognition';
import { DateRange } from 'types/Common';
import { usePagination } from 'utils/Pagination';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import SenderRecipientsDetails from './SenderRecipientsDetails';

interface GiversFilters {
  employeeName?: string;
  recognitionDateRange?: DateRange;
}

export const RecognitionsGiversList = () => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<Sort>(null);
  const [filters, setFilters] = useState<GiversFilters>({});
  const { page, pageSize, setPage, setPageSize } = usePagination();
  const givers = useRecognitionGivers({
    page,
    pageSize,
    employeeName: filters.employeeName,
    dateFrom: filters.recognitionDateRange?.from,
    dateTo: filters.recognitionDateRange?.to,
    sort: sort
      ? `${sort.order === 'desc' ? '-' : ''}${sort.columnKey}`
      : undefined,
  });

  const pointsLabel = usePointsLabel();

  const handleFilterChange = (newFilters: GiversFilters) => {
    setPage(1);
    setFilters(newFilters);
  };

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack direction="row" justifyContent="end" gap="10px" p="10px">
          <Filters value={filters} onChange={handleFilterChange}>
            {({ value, setFilter }) => (
              <>
                <FilterButton
                  label={t('recognition.recognitionDateRangeFilter')}
                  value={value.recognitionDateRange}
                  onChange={(newValue) =>
                    setFilter('recognitionDateRange', newValue)
                  }
                >
                  {({ value, applyFilter, clearFilter, close }) => (
                    <DateRangeFilter
                      value={value}
                      onApply={applyFilter}
                      onClear={clearFilter}
                      onCancel={close}
                      suggestions={['week', 'month', 'year']}
                    />
                  )}
                </FilterButton>
                <TextFilter
                  value={value.employeeName}
                  onApplyFilter={(newValue) =>
                    setFilter('employeeName', newValue)
                  }
                  hint={t('recognition.recipientsListFiltersSearchHint')}
                />
              </>
            )}
          </Filters>
          <Divider orientation="vertical" variant="middle" flexItem />
          <DownloadExcelButton
            listLength={givers.data?.count ?? 0}
            loading={givers.xlsExport.exporting}
            exportToExcel={givers.xlsExport.exportToXLS}
          />
        </Stack>
      </TableToolbar>
      <TableOld
        data={givers.data?.employees}
        loading={givers.loading}
        rowKey="id"
        columns={[
          {
            key: 'name',
            title: t('entity_type.employee'),
            render: (_, { firstName, lastName }) =>
              [firstName, lastName].join(' '),
          },
          {
            key: 'givenRecognitionPoints',
            title: t('recognition.giversListTotalGiven', {
              recognitionCurrency: pointsLabel,
            }),
            sort: true,
            align: 'right',
          },
          {
            key: 'participationPercentage',
            title: t('recognition.giversListParticipation'),
            render: (value: number) => Math.round(value),
            align: 'right',
          },
          {
            key: 'givenRecognitionCount',
            title: t('recognition.giversListGivenCount'),
            align: 'right',
          },
          {
            key: 'recipientsDetails',
            title: t('recognition.giversListRecipientsDetails'),
            whiteSpace: 'nowrap',
            render: (_, record) => (
              <SenderRecipientsDetails
                employeeId={record.id}
                recognitionDateRange={filters.recognitionDateRange}
              />
            ),
          },
          {
            key: 'lastGivenRecognitionAt',
            title: t('recognition.giversListLastActivity'),
            render: (value?: string) =>
              value ? moment(value).format('l') : '-',
          },
        ]}
        sort={sort}
        onSortChange={setSort}
        pagination={
          givers.data
            ? {
                count: givers.data.count,
                page: givers.data.page,
                pageSize: givers.data.pageSize,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }
            : undefined
        }
      />
    </TableWrapper>
  );
};
