import { TextField } from '../../TextField/TextField';
import { InternalFieldRenderer } from '../Types';

export const renderSecureTextField: InternalFieldRenderer = (
  props,
  { value, ...field },
  fieldState,
) => {
  return (
    <TextField
      fullWidth
      error={fieldState.invalid}
      helperText={fieldState.message}
      value={value ?? ''}
      {...field}
      {...props}
      secure
    />
  );
};
