import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-discount-codes.loaders';
import { adminDiscountCodesKeys } from './admin-discount-codes.queries';

export const useExportXlsTemplate = () => {
  return useMutation(loaders.exportXlsTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useImportDiscountCodesXls = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importCodesXls, {
    onSuccess: ({ data }) => {
      if (data.success) {
        return queryClient.invalidateQueries(adminDiscountCodesKeys.lists);
      }
    },
  });
};

export const useUpdateDiscountCodesStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateStatus, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminDiscountCodesKeys.lists);
    },
  });
};
