import { Box, Button, CircularProgress } from '@mui/material';
import { MpForm } from '@mp-react/form';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { Loader } from 'melp-design/components';
import { useAdminClient } from 'store/admin-clients';
import { ParentCompany } from 'store/companies';
import StatusButton from '../../../../components/common/StatusButton/StatusButton';
import FormFooter from '../../../../components/layouts/FormFooter/FormFooter';
import FormPaper from '../../../../components/layouts/FormPaper/FormPaper';
import Title from '../../../../components/sidebar/Title/Title';
import { useClientForm } from '../../../../configs/Forms/ClientForm';
import { useClient } from '../../../../state/Clients';
import { Status } from '../../../../types/Common';
import { StatusActionKey } from '../../../../types/Status';
import { useFormUtils } from '../../../../utils/Form';
import useStatus from '../../../../utils/Status';

export const AdminClientSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: client, isLoading } = useAdminClient(id);
  const { loading, updateClient } = useClient(id);
  const { overridables } = useFormUtils();
  const formMethods = useForm({
    defaultValues: client as FieldValues,
  });
  const clientForm = useClientForm();
  const { handleSubmit, formState, reset } = formMethods;

  const { getStatus } = useStatus();
  const [statusValue, setStatusValue] = useState<Status>({
    name: client?.status ?? 'active',
  });

  useEffect(() => {
    if (!!client) {
      reset({
        ...client,
        creationDate: moment(client.creationDate).format('l LT'),
      });
      setStatusValue({
        name: client.status,
      });
    }
  }, [client, reset]);

  const statusChanged = useMemo(
    () => statusValue.name !== client?.status,
    [client?.status, statusValue.name],
  );

  const hasChanges: boolean = useMemo(
    () => formState.isDirty || statusChanged,
    [formState.isDirty, statusChanged],
  );

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? '0' : '70px'})`,
    [hasChanges],
  );

  const removeFooter = useCallback(() => {
    reset(client, { dirtyFields: false });
  }, [client, reset]);

  const submitData = useCallback(
    () =>
      handleSubmit(async (data: Partial<ParentCompany>) => {
        if (!client) return;

        await updateClient({
          name: client.name,
          code: client.code,
          country: client.country,
          defaultCurrency: client.defaultCurrency,
          defaultLanguage: client.settings.defaultLanguage,
          internalComment: client.internalComment,
          features: client.features,
          discountPlan: client.settings.discountPlan,
          ...data,
          deactivationDate:
            statusValue.name === 'inactive' ? moment().toISOString() : null,
        });
        reset(client, { dirtyFields: false });
      })(),
    [client, handleSubmit, reset, statusValue.name, updateClient],
  );

  const handleStatusChange = useCallback(
    (action: StatusActionKey, date?: string) => {
      const status = getStatus(action);
      if (status) {
        setStatusValue({
          name: status,
          date,
        });
      }
    },
    [getStatus],
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <Prompt
        when={hasChanges}
        message={t('errors.user_leaving_edited_page')}
      />
      <Box display="flex">
        <FormPaper marginTop={0} marginRight="30px">
          <Title
            marginBottom="35px"
            title={t('common.contact_details')}
            padding="0px !important"
          />
          <MpForm
            overridables={overridables}
            useFormMethods={formMethods}
            {...clientForm}
          />
        </FormPaper>
        <StatusButton
          status={statusValue}
          onActionChange={handleStatusChange}
          id="administrators"
        />
      </Box>

      <FormFooter
        justifyContent="flex-end"
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
        maxWidth="600px"
        marginLeft={0}
        paddingRight="20px"
      >
        <Box marginRight="10px">
          <Button
            onClick={removeFooter}
            variant="text"
            color="info"
            disabled={loading}
          >
            {t('common.cancel')}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={submitData}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            t('common.confirm_changes')
          )}
        </Button>
      </FormFooter>
    </>
  );
};
