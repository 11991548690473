import { Box } from '@mui/material';
import CustomizeColumnButton from '../../CustomizeColumnButton/CustomizeColumnButton';

export default function BenefitsAssignEmployeeHeader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <CustomizeColumnButton />
    </Box>
  );
}
