import { useTranslation } from 'react-i18next';
import { MpFormType, MpControlType } from '@mp-react/form';
import { SupplierStatus } from '../../../types/Suppliers';

export const useSupplierForm = () => {
  const { t } = useTranslation();

  const form: MpFormType = {
    controls: [
      {
        key: 'status',
        type: 'select' as MpControlType,
        label: t('common.status'),
        children: [
          {
            key: SupplierStatus.active,
            label: t('marketplaceSuppliers.statusActive'),
            type: '' as MpControlType,
          },
          {
            key: SupplierStatus.inactive,
            label: t('marketplaceSuppliers.statusInactive'),
            type: '' as MpControlType,
          },
        ],
        messages: {
          required: t('form.required'),
        },
        required: true,
      },
      {
        key: 'name',
        type: MpControlType.text,
        label: t('marketplaceSuppliers.name'),
        messages: {
          required: t('form.required'),
        },
        required: true,
      },
      {
        key: 'logoId',
        type: 'image' as MpControlType,
        label: t('marketplaceSuppliers.logoId'),
      },
      {
        key: 'description',
        type: MpControlType.multiline,
        label: t('marketplaceSuppliers.description'),
      },
      {
        key: 'additionalInfo',
        type: MpControlType.multiline,
        label: t('marketplaceSuppliers.additionalInfo'),
      },
      {
        key: 'termsAndConditionsLink',
        type: MpControlType.text,
        label: t('marketplaceSuppliers.termsAndConditionsLink'),
        validations: {
          pattern:
            '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
        },
        messages: {
          pattern: t('form.pattern'),
        },
      },
    ],
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };

  return form;
};
