import {
  Alert,
  ButtonOld,
  Loader,
  NoData,
  Typography,
} from 'melp-design/components';
import { Colors } from 'melp-design/style';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config';
import { useRouteParams } from 'utils/useIdParam';
import { useAdminShopItem } from 'store/admin-shop';
import { useItemPrices } from '../../../../../state/MarketplaceItems';
import { useDialog } from '../../../../../utils/Dialog';
import PriceDialog, { PriceFormData } from './PriceDialog';
import PriceRow from './PriceRow';
import { toRequestData } from './Utils';

const useStyles = makeStyles(() => ({
  pricesTable: {
    '& > .MuiTableBody-root > .MuiTableRow-root:nth-child(even)': {
      background: Colors.lightBackgroundGrey,
      '&::before': {
        opacity: 0,
      },
    },
  },
}));

const INITIAL_PRICE_CREATION_VALUES: Partial<PriceFormData> = {
  priceType: 'fixedPrice',
};

const MarketplaceItemPrice = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const priceDialog = useDialog();
  const { id } = useRouteParams(ROUTES.admin.shopItems.details.prices);
  const prices = useItemPrices(id);

  const { data: shopItem } = useAdminShopItem(id);

  if (!prices.data && !prices.error) return <Loader />;
  if (prices.error)
    return (
      <Alert severity="error">
        {JSON.stringify(prices.error, undefined, 2)}
      </Alert>
    );
  if (!shopItem?.currency)
    return <Alert severity="info">{t('marketplaceItems.noCurrency')}</Alert>;

  const handleSubmit = async (data: PriceFormData) => {
    const requestData = toRequestData(data);
    await prices.create.execute(requestData);
    priceDialog.closeDialog();
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="30px"
      >
        <Typography variant="h2">
          {t('marketplaceItems.marketplaceItemPriceSettings')}
        </Typography>
        <ButtonOld
          variant="contained"
          color="primary"
          onClick={priceDialog.openDialog}
        >
          {t('marketplaceItems.addPrice')}
        </ButtonOld>
      </Box>
      <Table className={classes.pricesTable}>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderRight: 'none' }} />
            <TableCell>{t('marketplaceItems.pricesTablePriceType')}</TableCell>
            <TableCell align="right">
              {t('marketplace.ordersTablePrice')}
            </TableCell>
            <TableCell align="right" style={{ borderRight: 'none' }}>
              {t('marketplaceItems.discountPercentage')}
            </TableCell>
            <TableCell align="right" width={150} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!prices.data?.length && (
            <TableRow>
              <TableCell colSpan={5}>
                <NoData />
              </TableCell>
            </TableRow>
          )}
          {!!prices.data?.length &&
            prices.data.map((price) => (
              <PriceRow
                key={price.id}
                itemId={id}
                price={price}
                currency={shopItem.currency}
              />
            ))}
        </TableBody>
      </Table>
      <PriceDialog
        title={t('marketplaceItems.createPrice')}
        initialData={INITIAL_PRICE_CREATION_VALUES}
        currency={shopItem.currency}
        open={priceDialog.open}
        onClose={priceDialog.closeDialog}
        onSubmit={handleSubmit}
        loading={prices.create.loading}
      />
    </div>
  );
};

export default MarketplaceItemPrice;
