import { useParentCompany } from '../state/ParentCompany';

export const useLocalStorage = () => {
  const { companyId } = useParentCompany();
  const keySuffix = `_${companyId}`;

  const keyWithSuffix = (key: string) => {
    return `${key}${keySuffix}`;
  };

  const storeData = (data: any, key: string) => {
    if (!window.localStorage || !window.JSON || !key) {
      return;
    }
    localStorage.setItem(keyWithSuffix(key), JSON.stringify(data));
  };

  const getData = (key: string) => {
    if (!window.localStorage || !window.JSON || !key) {
      return;
    }
    var item = localStorage.getItem(keyWithSuffix(key));

    if (!item) {
      return;
    }

    return JSON.parse(item);
  };

  const removeData = (key: string) => {
    if (!window.localStorage || !window.JSON || !key) {
      return;
    }
    localStorage.removeItem(keyWithSuffix(key));
  };

  return { storeData, getData, removeData, keyWithSuffix };
};
