import { useParams } from 'react-router-dom';

export const useIdParam = () => {
  const params = useParams<{ id: string }>();
  return params.id;
};

type Params<Url> = Url extends `${string}:${infer Param}/${infer Rest}`
  ? Param | Params<Rest>
  : Url extends `${string}:${infer Param}`
  ? Param
  : never;

export const useRouteParams = <Route extends string>(_route: Route) => {
  return useParams<
    Params<Route> extends never ? never : Record<Params<Route>, string>
  >();
};
