import { Modal } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Filters, FiltersProps } from 'components/filters';
import { useState } from 'react';
import { Box } from '@mui/material';
import { calculateFiltersTotal } from 'utils/Filters';

interface Props<F extends Record<string, any>>
  extends ModalProps,
    Pick<
      FiltersProps<F>,
      'initialValues' | 'value' | 'onChange' | 'onChangeCallback' | 'children'
    > {
  closeModal: (param?: { action: 'CLOSE' }) => void;
}

export const FiltersModal = <F extends Record<string, any>>({
  initialValues,
  value,
  onChange,
  onChangeCallback,
  children,
  closeModal,
}: Props<F>) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<F>(value);

  return (
    <Modal.Content
      title={t('marketplace.filters')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        ...(initialValues && calculateFiltersTotal(value)
          ? [
              {
                variant: 'neutral-fill' as const,
                label: t('table.clear'),
                onClick: () => {
                  onChange(initialValues);
                  closeModal({ action: 'CLOSE' });
                },
              },
            ]
          : []),
        {
          variant: 'primary',
          label: t('common.applyFilter'),
          onClick: () => {
            onChange(filters);
            closeModal({ action: 'CLOSE' });
          },
          autoFocus: true,
        },
      ]}
    >
      <Box sx={{ pt: 2 }}>
        <Filters
          value={filters}
          onChange={setFilters}
          onChangeCallback={onChangeCallback}
          initialValues={initialValues}
          showClearButton={false}
          showLabel={false}
        >
          {children}
        </Filters>
      </Box>
    </Modal.Content>
  );
};
