import { Delete } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import { styled } from '@mui/system';
import { forwardRef } from 'react';
import Button, { ButtonProps } from '../ButtonOld/Button';

const PrimaryLightIconButton = styled(Button)({
  '&.DeleteIconButton': {
    padding: '11px 13px',

    '&:hover': {
      color: Colors.red,
    },
  },
});

interface Props extends Omit<ButtonProps, 'textColor' | 'className' | 'sx'> {}

const DeleteIconButton = forwardRef((props: Props, ref) => {
  return (
    <PrimaryLightIconButton
      ref={ref}
      {...props}
      textColor="grey"
      className="DeleteIconButton"
    >
      <Delete style={{ width: 'auto', height: 16 }} />
    </PrimaryLightIconButton>
  );
});

DeleteIconButton.displayName = 'DeleteIconButton';

export default DeleteIconButton;
