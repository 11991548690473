import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

interface Props {
  onSelect: (nativeEmoji: string) => void;
}

const StaticEmojiPicker = (props: Props) => {
  return (
    <Picker
      data={data}
      theme="light"
      onEmojiSelect={(e: any) => props.onSelect(e.native)}
    />
  );
};

export default StaticEmojiPicker;
