import { useMutation, useQueryClient } from '@tanstack/react-query';
import { expensesKeys } from 'store/expenses';
import { loaders } from './employees.loaders';
import { employeesKeys } from './employees.queries';

export const useCreateEmployeeExpense = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.createExpense, {
    onSuccess: (_, { employeeId }) => {
      queryClient.invalidateQueries(employeesKeys.detail(employeeId));
      queryClient.invalidateQueries(expensesKeys.lists);
    },
  });

  return mutation;
};
