import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip, Box } from '@mui/material';
import { XLS } from 'melp-design/icons';

interface IProps {
  marginRight?: string;
  marginLeft?: string;
  listLength: number;
  loading: boolean;
  exportToExcel: () => void;
}

export default function DownloadExcelButton(props: IProps) {
  const { exportToExcel, loading, listLength, marginRight, marginLeft } = props;
  const { t } = useTranslation();
  const handleDownload = useCallback(() => {
    exportToExcel();
  }, [exportToExcel]);

  return (
    <Box sx={{ marginRight, marginLeft }}>
      <Tooltip title={t('dashboard.download_xls') || 'XLS'}>
        {/* Wrapped in span to avoid warning regarding tooltip on disabled button */}
        <Box display="inline-block">
          <IconButton
            onClick={handleDownload}
            disabled={!listLength || loading}
            size="large"
            sx={{ margin: '-12px' }}
          >
            {loading ? (
              <CircularProgress size={20} style={{ margin: 5 }} />
            ) : (
              // eslint-disable-next-line react/jsx-pascal-case
              <XLS />
            )}
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
}
