import { Typography } from 'melp-design/components';
import { Dropdown } from 'melp-design/icons';
import {
  Box,
  ButtonBase,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { formatLanguage } from 'utils/format';
import { useCurrentUser } from 'store/auth';
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { auth } from '../../../auth/index';
import { StorageKeys } from '../../../types/LocalStorage';
import { useLoading } from '../../../utils/Loading';
import { APP, ROUTES } from '../../../config';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import useStyles from './UserDropdown.styles';

export default function UserDropdown() {
  const history = useHistory();
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { data: me, isLoading } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [isLanguageView, setIsLanguageView] = useState<boolean>(false);
  const anchorRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const localLang = localStorage.getItem(StorageKeys.lang);
    const defaultLang =
      me?.parentCompany.settings.defaultLanguage.toLocaleLowerCase() ??
      me?.language.toLocaleLowerCase() ??
      APP.locales.default;
    i18n.changeLanguage(localLang ?? defaultLang);
  }, [i18n, me]);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  const handlePasswordChangeClick = () => {
    handleClose();
    history.push(ROUTES.auth.changePassword);
  };

  const logoutState = useLoading();
  const handleLogoutClick = async () => {
    handleClose();
    logoutState.startLoading();
    try {
      await auth.logout();
    } catch (e) {
      toast.error(e instanceof Error ? e.message : JSON.stringify(e));
    } finally {
      logoutState.stopLoading();
    }
  };

  const openLanguageView = () => setIsLanguageView(true);
  const closeLanguageView = () => setIsLanguageView(false);

  if (isLoading) {
    return <CircularProgress size={20} color="primary" />;
  }

  const getUserTitle = () => {
    if (me?.firstName && me?.lastName) {
      return [me.firstName, me.lastName[0]].join(' ');
    }

    return me?.parentCompany.name;
  };

  return (
    <Box>
      <ButtonBase
        classes={{ root: classes.button }}
        onClick={handleToggle}
        data-test="account"
      >
        <span ref={anchorRef}>
          <Typography
            variant="p1"
            color="inherit"
            className={classes.buttonInner}
          >
            {getUserTitle()}
            <Dropdown className={classes.buttonIcon} />
          </Typography>
        </span>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper variant="outlined" classes={{ root: classes.paperRoot }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Collapse
                    in={!isLanguageView}
                    timeout={250}
                    mountOnEnter
                    unmountOnExit
                  >
                    <MenuList autoFocusItem={open} disablePadding>
                      <MenuItem
                        classes={{ root: classes.vAlignCenter }}
                        onClick={openLanguageView}
                        data-test="language-select"
                      >
                        <Typography variant="p1" color="textPrimary">
                          {formatLanguage(i18n.language, i18n.language)}
                        </Typography>
                        <ChevronRight />
                      </MenuItem>
                      <MenuItem
                        onClick={handlePasswordChangeClick}
                        classes={{ root: classes.vAlignCenter }}
                      >
                        <Typography variant="p1" color="textPrimary">
                          {t('actions.change_password')}
                        </Typography>
                        <ChevronRight />
                      </MenuItem>
                      <MenuItem
                        onClick={handleLogoutClick}
                        classes={{ root: classes.vAlignCenter }}
                      >
                        <Typography variant="p1" color="textPrimary">
                          {t('common.logout')}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </Collapse>
                  <Collapse
                    in={isLanguageView}
                    timeout={250}
                    mountOnEnter
                    unmountOnExit
                  >
                    <MenuList autoFocusItem={open} disablePadding>
                      <LanguageSwitcher handleBack={closeLanguageView} />
                    </MenuList>
                  </Collapse>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
