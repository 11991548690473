import { ComponentPropsWithoutRef } from 'react';
import { useCompanies } from 'store/companies';
import { SelectFilter } from 'components/filters';

interface Props<Multiple extends boolean>
  extends Pick<
    ComponentPropsWithoutRef<typeof SelectFilter>,
    'onClearFilter' | 'onCancel'
  > {
  value?: Multiple extends true ? string[] : string;
  multiple: Multiple;
  onApplyFilter: (value: Multiple extends true ? string[] : string) => void;
}

export const CompaniesFilter = <Multiple extends boolean>({
  multiple,
  value,
  onApplyFilter,
  onClearFilter,
  onCancel,
}: Props<Multiple>) => {
  const { data: companies, isLoading } = useCompanies();

  return (
    <SelectFilter
      options={companies?.items
        .filter((company) => company.status !== 'inactive')
        .map((company) => ({
          key: company.id,
          label: company.name,
        }))}
      value={value}
      onApplyFilter={onApplyFilter}
      onClearFilter={onClearFilter}
      onCancel={onCancel}
      loadingOptions={isLoading}
      multiple={multiple}
    />
  );
};
