import { ReactNode, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonOld, Typography } from 'melp-design/components';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  DialogContent,
} from 'melp-design/components';

interface Props extends Pick<DialogProps, 'open' | 'width'> {
  id?: string;
  title?: ReactNode;
  content: ReactNode;
  onCancel: () => void;
  onConfirm: (close: () => void) => void;
  loading?: boolean;
  confirmLabel?: string;
  confirmColor?: ComponentProps<typeof ButtonOld>['color'];
}

const ConfirmationDialog = ({
  id = String(Math.random()),
  title,
  content,
  onCancel,
  onConfirm,
  loading,
  confirmLabel,
  confirmColor = 'primary',
  width = 500,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const titleId = `${id}-title`;
  const contentId = `${id}-content`;

  const handleConfirmation = () => {
    onConfirm(onCancel);
  };

  return (
    <Dialog
      aria-labelledby={titleId}
      aria-describedby={contentId}
      onClose={onCancel}
      width={width}
      {...rest}
    >
      <DialogTitle id={titleId}>
        {title ?? t('common.confirmationModalTitle')}
      </DialogTitle>
      <DialogContent>
        <Typography
          id={contentId}
          variant="p1"
          color="textSecondary"
          // To prevent runtime warnings about invalid DOM when content is
          // a components node
          component="span"
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonOld
          onClick={onCancel}
          variant="outlined"
          disabled={loading}
          minWidth={140}
        >
          {t('common.cancel')}
        </ButtonOld>
        <ButtonOld
          onClick={handleConfirmation}
          variant="contained"
          color={confirmColor}
          minWidth={140}
          loading={loading}
          autoFocus
        >
          {confirmLabel ?? t('common.confirm')}
        </ButtonOld>
      </DialogActions>
    </Dialog>
  );
};

export type ConfirmationDialogProps = Props;
export default ConfirmationDialog;
