import { useHistory } from 'react-router-dom';
import { Button } from 'melp-design/components';
import { ArrowBack } from '@mui/icons-material';

interface Props {
  to?: string;
}

const BackButton = ({ to }: Props) => {
  const history = useHistory();

  return (
    <Button
      variant="neutral-outline"
      size="md"
      icon={ArrowBack}
      onClick={() => {
        if (to) {
          history.push(to);
        } else {
          history.goBack();
        }
      }}
    />
  );
};

export default BackButton;
