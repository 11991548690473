import { ROUTES } from 'config';
import { Menu } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDeleteDiscount } from 'store/admin-discounts';
import { useConfirm } from 'store/confirm';
import { usePredefinedToasts } from 'utils/Toast';

interface Props {
  id: string;
  name: string;
}

export const DiscountActions: FC<Props> = ({ id, name }) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const history = useHistory();
  const { confirmation } = useConfirm();

  const { mutate: deleteDiscount, isLoading: isDeleting } = useDeleteDiscount();

  return (
    <Menu
      label={t('common.actions')}
      items={[
        {
          label: t('actions.delete'),
          onClick: async () => {
            const { confirmed } = await confirmation(
              t('common.areYouSureToDelete', {
                title: name,
                interpolation: { escapeValue: false },
              }),
            );

            if (confirmed) {
              deleteDiscount(
                { discountId: id },
                {
                  onSuccess: () => {
                    predefinedToasts.success.deleted();
                    history.push(ROUTES.admin.discounts.list);
                  },
                },
              );
            }
          },
          disabled: isDeleting,
        },
      ]}
    />
  );
};
