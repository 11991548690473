import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    bottomBorders: {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
    borderRadius: {
      borderRadius: '8px',
    },
  }),
);
