import { FC, ReactNode, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { changePosition } from 'utils/general';

interface Props {
  items: Array<{ id: string; content: ReactNode }>;
  onPositionChange?: (source: number, destination: number) => void;
}

export const SortableList: FC<Props> = ({ items, onPositionChange }) => {
  const [order, setOrder] = useState<string[]>(items.map((item) => item.id));

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { index: destination } = result.destination;
    const { index: source } = result.source;

    if (destination === source) return;

    setOrder((prev) => changePosition(prev, source, destination));
    onPositionChange?.(source, destination);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <ol
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ listStyle: 'none', padding: 0 }}
          >
            {order.map((orderId, index) => {
              const item = items.find((item) => item.id === orderId);

              return item ? (
                <Draggable draggableId={item.id} index={index} key={item.id}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item.content}
                    </li>
                  )}
                </Draggable>
              ) : null;
            })}
            {provided.placeholder}
          </ol>
        )}
      </Droppable>
    </DragDropContext>
  );
};
