import PasswordInput from '../../PasswordInput/PasswordInput';
import { InternalFieldRenderer } from '../Types';

export const renderPasswordField: InternalFieldRenderer = (
  props,
  field,
  fieldState,
) => (
  <PasswordInput
    fullWidth
    error={fieldState.invalid}
    helperText={fieldState.message}
    {...field}
    {...props}
  />
);
