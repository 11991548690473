import { ContentLanguage } from 'types/general';
import { useMe } from '../state/Administrators';

export const useSortedLanguages = (languages: Uppercase<ContentLanguage>[]) => {
  const { me } = useMe();
  const defaultLanguage = me?.parentCompany.settings.defaultLanguage;
  const alphabeticallySorted = [...languages].sort();

  if (!defaultLanguage || !alphabeticallySorted.includes(defaultLanguage)) {
    return alphabeticallySorted;
  }

  const withoutDefaultLanguage = alphabeticallySorted.filter(
    (lang) => lang !== defaultLanguage,
  );

  return [defaultLanguage, ...withoutDefaultLanguage];
};
