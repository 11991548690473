import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'config';
import { Loader } from 'melp-design/components';
import { useAuthContext } from '../../auth/index';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from '../../constants/Administrators';
import { useMe } from '../../state/Administrators';
import { AdminRoles } from '../../types/Administrators';

interface Props {
  /**
   * Route path
   */
  path: string;
  /**
   * Route content
   */
  children: ReactElement;
  /**
   * Roles by which a route is accessible
   */
  roles?: AdminRoles[];
  /**
   * Modules, which a user has to have access to, in order to access a route
   */
  module?:
    | LocalPermissionModules
    | GlobalPermissionModules
    | Array<LocalPermissionModules | GlobalPermissionModules>;
  /**
   * Permission level, which a user must have, to access a route.
   */
  permissionLevel?: PermissionLevels;
}

const ProtectedRoute = ({
  roles,
  permissionLevel,
  module,
  ...props
}: Props) => {
  const { loggedIn } = useAuthContext();

  const { me, loading, getPermissionsByModule, isRoot } = useMe();

  if (!loggedIn) {
    return (
      <Redirect
        to={{ pathname: ROUTES.auth.login, state: { from: props.path } }}
      />
    );
  }

  const currentRole = me?.role;

  if (loading) return <Loader />;

  const hasRequiredRole =
    !!currentRole && !!roles && roles.includes(currentRole);

  const hasRequiredPermissions = () => {
    if (isRoot) return true;
    if (!permissionLevel) return true;

    const getRequiredPermissions = () => {
      if (!module) return [];
      return getPermissionsByModule(module);
    };

    return getRequiredPermissions().some(
      (permission) =>
        permission.permission === 'edit' || permission.permission === 'view',
    );
  };

  if (!hasRequiredRole || !hasRequiredPermissions()) {
    return <Redirect to={ROUTES.root.notFound} />;
  }

  return props.children;
};

export default ProtectedRoute;
