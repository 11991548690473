import i18n from 'i18n';
import { converters as tableConverters } from 'store/table';
import { ILog } from 'types/income';
import { APP } from 'config';
import { AUTOMATION_ID } from 'store/automations';
import { loaders } from './logs.loaders';
import { Log } from './logs.types';

const convertLogOwner = (log: ILog) => {
  return log.doneBy === 'automation'
    ? {
        id: AUTOMATION_ID,
        name: i18n.t('clients.feature-automation'),
        role: log.doneBy,
      }
    : log.employee &&
      [
        'employeeLoggedIn',
        'employeeSelectedAlternativeBenefit',
        'employeePlacedMarketplaceOrder',
      ].includes(log.action)
    ? {
        id: log.employee.id,
        name: log.employee.name,
        role: 'employee' as const,
      }
    : log.admin
    ? {
        id: log.admin.id,
        name: log.admin.role === 'melpAdmin' ? APP.name : log.admin.name,
        role:
          log.admin.role === 'hrAdmin'
            ? log.admin.role
            : ('melpAdmin' as const),
      }
    : null;
};

const convertLog = (log: ILog): Log => {
  return {
    ...log,
    id: log.id,
    employee: log.employee?.id ? log.employee : null,
    admin: log.admin?.id ? log.admin : null,
    owner: convertLogOwner(log),
    createdAt: log.createdAt,
  };
};

const convertLogs = (logs: ILog[]): Log[] => {
  return logs.map(convertLog);
};

export const converters = {
  getLogs: ({ data }: Awaited<ReturnType<typeof loaders.getLogs>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertLogs(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
