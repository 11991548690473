import { TableOld, TableWrapper, TableToolbar } from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAllowanceOverview } from 'state/Recognition';
import { usePagination } from 'utils/Pagination';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import AssignedAllowanceDetails from './AssignedAllowanceDetails';

export const RecognitionsAllowancesList = () => {
  const { t } = useTranslation();

  const { page, pageSize, setPage, setPageSize } = usePagination();
  const allowanceOverview = useAllowanceOverview({ page, pageSize });

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack direction="row" justifyContent="end" p="10px">
          <DownloadExcelButton
            listLength={allowanceOverview.data?.count ?? 0}
            loading={allowanceOverview.xlsExport.exporting}
            exportToExcel={allowanceOverview.xlsExport.exportToXLS}
          />
        </Stack>
      </TableToolbar>
      <TableOld
        data={allowanceOverview.data?.employees}
        loading={allowanceOverview.loading}
        rowKey="id"
        columns={[
          {
            key: 'employee',
            title: t('entity_type.employee'),
            render: (_, record) =>
              [record.firstName, record.lastName].join(' '),
          },
          {
            key: 'assignedAllowance',
            title: t('recognition.allowanceOverviewAssignedAllowance'),
            align: 'right',
            render: (value: number, record) =>
              value ? (
                <Stack direction="row" gap="5px" justifyContent="right">
                  {value}
                  <AssignedAllowanceDetails employeeId={record.id} />
                </Stack>
              ) : (
                value
              ),
          },
          {
            key: 'spentPoints',
            title: t('recognition.allowanceOverviewSpentPoints'),
            align: 'right',
          },
          {
            key: 'balance',
            title: t('benefitExpenses.remaining'),
            align: 'right',
          },
        ]}
        pagination={
          allowanceOverview.data
            ? {
                count: allowanceOverview.data.count,
                page: allowanceOverview.data.page,
                pageSize: allowanceOverview.data.pageSize,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }
            : undefined
        }
      />
    </TableWrapper>
  );
};
