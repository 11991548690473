import { IBaseNewItem, INewItem, INewsCategory } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './news.loaders';
import { NewItem, NewsCategory } from './news.types';

const convertNewDetails = (item: INewItem): NewItem => {
  return {
    id: item.id,
    name: item.name,
    status: item.draft ? 'draft' : 'published',
    parentCompanyId: item.parentCompanyId,
    publishedAt: item.publishDate,
    inactiveAt: item.inactiveDate,
    seenCount: 0,
    sentCount: item.sentTo,
    sendMethods: item.sendMethods,
    translations: item.translations,
    pinned: item.pinned,
    categories: item.categories ?? [],
  };
};

const convertNew = (item: IBaseNewItem): NewItem => {
  return {
    id: item.id,
    name: item.name,
    status: item.status,
    parentCompanyId: item.parentCompanyId,
    publishedAt: item.publishDate,
    inactiveAt: null,
    seenCount: Number(item.seen) || 0,
    sentCount: item.sentTo,
    sendMethods: [],
    translations: [],
    pinned: item.pinned,
    categories: item.categories ?? [],
  };
};

const convertNews = (news: IBaseNewItem[]): NewItem[] => {
  return news.map(convertNew);
};

const convertCategory = (category: INewsCategory): NewsCategory => {
  return {
    id: category.id,
    name: category.name,
    translations: category.translations,
    createdAt: category.createdAt,
    updatedAt: category.updatedAt,
  };
};

const convertCategories = (categories: INewsCategory[]): NewsCategory[] => {
  return categories.map(convertCategory);
};

export const converters = {
  getNews: ({ data }: Awaited<ReturnType<typeof loaders.getNews>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertNews(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getNew: ({ data }: Awaited<ReturnType<typeof loaders.getNew>>) => {
    return convertNewDetails(data);
  },
  getCategories: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCategories>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCategories(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getCategory: ({ data }: Awaited<ReturnType<typeof loaders.getCategory>>) => {
    return convertCategory(data);
  },
};
