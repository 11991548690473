import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { useMemo, useCallback } from 'react';
import { MpBulkActionCallback, MpBulkActionMethods } from '@mp-react/table';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ROUTES } from 'config';
import { makeRequest } from '../api/api';
import { getTimezoneFromDate } from '../utils/Common';
import { UseEmployeesBulkActions } from './../types/Employees';
import { Endpoints } from './../api/constants';
import { useLoading } from './../utils/Loading';
import { useEmployeesLegacy } from './Employees';

const useEmployeesBulkActions = (
  id?: string,
  query?: string,
  preventCall = false,
): UseEmployeesBulkActions => {
  const { t } = useTranslation();
  const now = useMemo(() => moment(), []);
  const history = useHistory();
  const { mutateEmployeeTableData } = useEmployeesLegacy(
    id,
    query,
    preventCall,
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const statusToastText = useCallback(
    (selectedRows: any) =>
      t('common.statuses_updated', { count: selectedRows?.length }),
    [t],
  );

  const deactivate: MpBulkActionCallback = useCallback(
    async (selected) => {
      const { selectedRows } = selected;
      const employeeIds = selectedRows?.map((item) => {
        const { original } = item as any;
        return original.id;
      });
      startLoading();
      try {
        await makeRequest('patch', Endpoints.bulkActionEmployee, {
          employee: { endDate: now },
          employeeIds,
        });
        toast(statusToastText(selectedRows), { type: 'success' });
        mutateEmployeeTableData();
      } finally {
        stopLoading();
      }
    },
    [startLoading, now, statusToastText, mutateEmployeeTableData, stopLoading],
  );

  const activate: MpBulkActionCallback = useCallback(
    async (selected) => {
      const { selectedRows } = selected;
      const employeeIds = selectedRows?.map((item) => {
        const { original } = item as any;
        return original.id;
      });
      startLoading();
      try {
        await makeRequest('patch', Endpoints.bulkActionEmployee, {
          employee: { startDate: now, endDate: null },
          employeeIds,
        });
        toast(statusToastText(selectedRows), { type: 'success' });
        mutateEmployeeTableData();
      } finally {
        stopLoading();
      }
    },
    [startLoading, now, statusToastText, mutateEmployeeTableData, stopLoading],
  );

  const deactivateAction = useCallback(() => {
    startLoading();
    makeRequest('patch', Endpoints.bulkActionEmployee, {
      employee: { endDate: now },
      employeeIds: [id],
    })
      .then(() => {
        toast(t('common.updated_succesfully'), { type: 'success' });
        mutate(`${Endpoints.employee}/${id}`);
        mutateEmployeeTableData();
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, now, id, t, mutateEmployeeTableData, stopLoading]);

  const activateAction = useCallback(() => {
    startLoading();
    makeRequest('patch', Endpoints.bulkActionEmployee, {
      employee: { startDate: now, endDate: null },
      employeeIds: [id],
    })
      .then(() => {
        toast(t('common.updated_succesfully'), { type: 'success' });
        mutate(`${Endpoints.employee}/${id}`);
        mutateEmployeeTableData();
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, now, id, t, mutateEmployeeTableData, stopLoading]);

  const deactivateOnSpecificDate = useCallback(
    async (date: string, selectedRowIds: string[]) => {
      startLoading();
      try {
        await makeRequest('patch', Endpoints.bulkActionEmployee, {
          employee: { endDate: date, endDateTZ: getTimezoneFromDate(date) },
          employeeIds: selectedRowIds,
        });
        toast(statusToastText(selectedRowIds), { type: 'success' });
        mutateEmployeeTableData();
        mutate(`${Endpoints.employee}/${id}`);
      } finally {
        stopLoading();
      }
    },
    [startLoading, statusToastText, mutateEmployeeTableData, id, stopLoading],
  );

  const activateOnSpecificDate = useCallback(
    async (date: string, selectedRowIds: string[]) => {
      startLoading();
      try {
        await makeRequest('patch', Endpoints.bulkActionEmployee, {
          employee: { startDate: date, startDateTZ: getTimezoneFromDate(date) },
          employeeIds: selectedRowIds,
        });
        toast(statusToastText(selectedRowIds), { type: 'success' });
        mutateEmployeeTableData();
        mutate(`${Endpoints.employee}/${id}`);
      } finally {
        stopLoading();
      }
    },
    [startLoading, statusToastText, mutateEmployeeTableData, id, stopLoading],
  );

  const changeHireDateAction = useCallback(
    async (data: Record<'hireDate', string>, employeeIds: string[]) => {
      startLoading();
      try {
        await makeRequest('patch', Endpoints.bulkActionEmployee, {
          employee: data,
          employeeIds,
        });
        toast(t('common.updated_succesfully'), { type: 'success' });
        mutateEmployeeTableData();
      } finally {
        stopLoading();
      }
    },
    [mutateEmployeeTableData, startLoading, stopLoading, t],
  );

  const deleteInnerEmployee = useCallback(() => {
    startLoading();
    makeRequest('delete', `${Endpoints.employee}/${id}`)
      .then(() => {
        toast(t('common.deleted_succesfully'), { type: 'success' });
        history.push(ROUTES.employees.list);
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, stopLoading, t, id, history]);

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      deactivate,
      activate,
    }),
    [deactivate, activate],
  );

  return {
    loading,
    bulkMethods,
    changeHireDateAction,
    deactivateOnSpecificDate,
    activateOnSpecificDate,
    activateAction,
    deactivateAction,
    deleteInnerEmployee,
  };
};

export default useEmployeesBulkActions;
