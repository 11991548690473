import { FC } from 'react';
import { Trans } from 'react-i18next';
import { getSanitizedTranslationParams, Log } from 'store/logs';
import { FileLink } from 'melp-design/components';

interface Props {
  log: Log;
}

export const LogDescription: FC<Props> = ({ log }) => {
  return (
    <Trans
      i18nKey={`logs.action.log.${log.action}`}
      values={{
        ...getSanitizedTranslationParams(log),
        interpolation: { escapeValue: false },
      }}
      components={[
        <strong key="0" />,
        log.action === 'itemUpdatedFromExcel' ? (
          <FileLink key="1" id={log.data.fileId} />
        ) : (
          <strong key="1" />
        ),
      ]}
    />
  );
};
