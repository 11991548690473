import { FeatureKey } from './Types';

export const features: Record<FeatureKey, { isEnabled: boolean }> = {
  /**
   * This key is to prevent Typescript errors. It must not be used as a real
   * key.
   */
  [FeatureKey.dummy]: {
    isEnabled: false,
  },
};
