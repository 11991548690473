import React, { useMemo, useState } from 'react';
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from '@mp-react/form';
import { Controller } from 'react-hook-form';
import { Box, FormControl, InputLabel } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { FormHelperText, TextField } from '@mui/material';
import { MpControl } from '@mp-react/form';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import { ReactComponent as ArrowDropDownIcon } from '../../../assets/icons/arrow-down.svg';
import { CustomFormControl } from '../../../types/Common';
import useStyles from './MultiAutocomplete.styles';

function MultiAutocomplete({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  error,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: string[] | null;
  name: string;
  inputRef: React.MutableRefObject<any>;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const selectOptions = useMemo(() => control?.children ?? [], [control]);
  const placeholder = useMemo(() => control?.placeholder, [control]);
  const id = useMemo(() => control?.key, [control]);

  const selectedOptions = value
    ?.map((key) => selectOptions.find((option) => option.key === key))
    .filter((option): option is MpControl => !!option);

  const [inputValue, setInputValue] = useState('');

  const handleChange = (
    _: any,
    newSelectedOptions: MpControl[],
    reason: string,
  ) => {
    if (['selectOption', 'blur'].includes(reason)) {
      setInputValue('');
    }
    const selectedValues = newSelectedOptions.map((option) => option.key);
    onChange(selectedValues);
  };

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControl
        id="multi-autocomplete"
        variant={variant ?? 'outlined'}
        fullWidth={true}
        required={control.required}
        className={classes.formControl}
        error={!!error}
      >
        {placeholder && (
          <InputLabel className={classes.placeholder} id={id}>
            {placeholder}
          </InputLabel>
        )}
        <Autocomplete
          multiple
          id={name}
          ref={inputRef}
          value={selectedOptions ?? []}
          onChange={handleChange}
          options={selectOptions}
          getOptionLabel={(option) => option.label ?? ''}
          renderInput={(params) => (
            <TextField {...params} variant={variant} error={!!error} />
          )}
          inputValue={inputValue}
          onInputChange={(_, newInputValue, reason) => {
            if (reason !== 'reset') {
              setInputValue(newInputValue);
            }
          }}
          size="small"
          popupIcon={<ArrowDropDownIcon />}
          disabled={(control as CustomFormControl)?.disabled}
        />
        <FormHelperText>
          {error?.type
            ? control?.messages?.[error.type as ValidationKeys]
            : undefined}
        </FormHelperText>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function MultiAutocompleteControl({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value, name, ref }) => (
        <MultiAutocomplete
          name={name}
          inputRef={ref}
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
