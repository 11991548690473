import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientCompanyBalanceLogsList } from 'containers/admin';

const AdminClientCompanyBalanceLogsPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientCompanyBalanceLogsList />
    </AdminClientLayout>
  );
};

export default AdminClientCompanyBalanceLogsPage;
