import { Button } from 'melp-design/components';
import { Stack } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../../../types/Common';
import { browserLanguage, useDatePlaceholder } from '../../../utils/Common';

type Suggestion = 'week' | 'month' | 'quarter' | 'year';

interface Props {
  value?: DateRange;
  onApply: (value: DateRange) => void;
  onClear: () => void;
  onCancel: () => void;
  suggestions?: Suggestion[];
  withTimezone?: boolean;
}

export const DateRangeFilter: FC<Props> = ({
  value,
  suggestions = [],
  withTimezone = false,
  onApply,
  onClear,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { datePlaceholder } = useDatePlaceholder();

  const [selectedDate, setSelectedDate] = useState<{
    from: moment.Moment | undefined;
    to: moment.Moment | undefined;
  }>({
    from: value?.from ? moment(value.from).startOf('day') : undefined,
    to: value?.to ? moment(value.to).endOf('day') : undefined,
  });
  const [isOpen, setIsOpen] = useState<{ from: boolean; to: boolean }>({
    from: false,
    to: false,
  });

  const commonProps: (onClick: () => void) => DatePickerProps<moment.Moment> = (
    onClick,
  ) => ({
    slotProps: {
      textField: {
        variant: 'outlined',
        size: 'small',
        fullWidth: true,
        placeholder: datePlaceholder,
        onClick,
      },
      popper: { style: { zIndex: 1501 } },
    },
  });

  const presets = [
    ...[1, 2, 3, 4].map((quarter) => ({
      label: `q${quarter}, ${moment()
        .subtract(1, 'year')
        .year()}`.toUpperCase(),
      getValues: () => ({
        from: moment().subtract(1, 'year').quarter(quarter).startOf('quarter'),
        to: moment().subtract(1, 'year').quarter(quarter).endOf('quarter'),
      }),
      isVisible: suggestions.includes('quarter'),
    })),
    ...Array.from({ length: moment().quarter() }, (_, i) => i + 1).map(
      (quarter) => ({
        label: `q${quarter}, ${moment().year()}`.toUpperCase(),
        getValues: () => ({
          from: moment().quarter(quarter).startOf('quarter'),
          to: moment().quarter(quarter).endOf('quarter'),
        }),
        isVisible: suggestions.includes('quarter'),
      }),
    ),
    {
      label: t('common.dateRangeQuickFilters-thisWeek'),
      getValues: () => ({
        from: moment().startOf('week'),
        to: moment().endOf('week'),
      }),
      isVisible: suggestions.includes('week'),
    },
    {
      label: t('common.dateRangeQuickFilters-lastMonth'),
      getValues: () => ({
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      }),
      isVisible: suggestions.includes('month'),
    },
    {
      label: t('common.dateRangeQuickFilters-thisMonth'),
      getValues: () => ({
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      }),
      isVisible: suggestions.includes('month'),
    },
    {
      label: t('common.dateRangeQuickFilters-lastYear'),
      getValues: () => ({
        from: moment().subtract(1, 'year').startOf('year'),
        to: moment().subtract(1, 'year').endOf('year'),
      }),
      isVisible: suggestions.includes('year'),
    },
    {
      label: t('common.dateRangeQuickFilters-thisYear'),
      getValues: () => ({
        from: moment().startOf('year'),
        to: moment().endOf('year'),
      }),
      isVisible: suggestions.includes('year'),
    },
  ];

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={browserLanguage}
    >
      <Stack gap={1} p={1} minWidth="300px">
        <DatePicker
          {...commonProps(() => setIsOpen((prev) => ({ ...prev, from: true })))}
          value={selectedDate.from}
          onChange={(value) =>
            setSelectedDate((prev) => ({
              ...prev,
              from: value?.startOf('day'),
            }))
          }
          maxDate={selectedDate.to ?? undefined}
          onClose={() => setIsOpen((prev) => ({ ...prev, from: false }))}
          open={isOpen.from}
        />
        <DatePicker
          {...commonProps(() => setIsOpen((prev) => ({ ...prev, to: true })))}
          value={selectedDate.to}
          onChange={(value) =>
            setSelectedDate((prev) => ({
              ...prev,
              to: value?.endOf('day'),
            }))
          }
          minDate={selectedDate.from ?? undefined}
          onClose={() => setIsOpen((prev) => ({ ...prev, to: false }))}
          open={isOpen.to}
        />

        <Stack flexDirection="row" flexWrap="wrap" gap={1}>
          {presets.map((preset) => {
            const values = preset.getValues();
            const isActive =
              !selectedDate.from || !selectedDate.to
                ? false
                : selectedDate.from.isSame(values.from) &&
                  selectedDate.to.isSame(values.to);

            if (!preset.isVisible) return null;

            return (
              <Button
                label={preset.label}
                onClick={() => setSelectedDate(values)}
                variant={isActive ? 'secondary' : 'neutral-outline'}
                size="sm"
                key={preset.label}
              />
            );
          })}
        </Stack>

        <Stack direction="row" justifyContent="end" gap={1}>
          {value?.from || value?.to ? (
            <Button
              variant="neutral-outline"
              label={t('table.clear')}
              onClick={onClear}
            />
          ) : null}
          <Button
            variant="neutral-outline"
            label={t('common.cancel')}
            onClick={onCancel}
          />
          <Button
            label={t('common.applyFilter')}
            variant="primary"
            onClick={() => {
              const from = selectedDate.from?.toISOString(true);
              const to = selectedDate.to?.toISOString(true);

              onApply({
                from: withTimezone ? from : from?.split('T')[0],
                to: withTimezone ? to : to?.split('T')[0],
              });
            }}
            disabled={!selectedDate.from && !selectedDate.to}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};
