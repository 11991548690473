import { TextField } from 'melp-design/components';
import { Autocomplete, Checkbox, RadioGroup, Switch } from '@mui/material';
import { ComponentProps, ReactNode, useContext } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PhoneInputProps } from 'react-phone-input-2';
import RichTextEditor from '../../../components/common/RichTextEditor/RichTextEditor';
import PasswordInput from '../PasswordInput/PasswordInput';
import { ReadonlyFormContext } from './ReadonlyContext';
import { FieldRenderProps, FieldState, InternalFieldRenderer } from './Types';
import { renderAutocompleteField } from './renderers/autocomplete';
import { renderCheckboxField } from './renderers/checkbox';
import { CurrencyInputProps, renderCurrencyField } from './renderers/currency';
import { LocalStateDatePickerProps, renderDateField } from './renderers/date';
import {
  LocalStateDateTimePickerProps,
  renderDateTimeField,
} from './renderers/dateTime';
import { ImageUpload, renderImageUploadField } from './renderers/imageUpload';
import { renderNumberField } from './renderers/number';
import { renderPasswordField } from './renderers/password';
import { renderPhoneField } from './renderers/phone';
import { renderRadioGroupField } from './renderers/radioGroup';
import { renderRichTextField } from './renderers/richText';
import { SelectFieldProps, renderSelectField } from './renderers/select';
import { renderSwitchField } from './renderers/switch';
import { renderTextField } from './renderers/text';
import { renderSecureTextField } from './renderers/secure-text';
import { renderYesNoField } from './renderers/yesNo';
import getEventValue from './utils/getEventValue';

const DEFAULT_MESSAGE_KEYS: Partial<Record<FieldError['type'], string>> = {
  required: 'form.required',
  min: 'common.minValueValidationError',
  max: 'common.maxValueValidationError',
  minLength: 'common.minLengthValidationError',
  maxLength: 'common.maxLengthValidationError',
  pattern: 'common.patternValidationError',
};

type PredefinedRenderType =
  | 'text'
  | 'secureText'
  | 'select'
  | 'number'
  | 'date'
  | 'radioGroup'
  | 'switch'
  | 'dateTime'
  | 'imageUpload'
  | 'yesNo'
  | 'checkbox'
  | 'autocomplete'
  | 'richText'
  | 'phone'
  | 'password'
  | 'currency';

interface GenericCustomizedRender<
  T extends PredefinedRenderType,
  P extends {},
> {
  type: T;
  props:
    | Partial<P>
    | ((field: FieldRenderProps, fieldState: FieldState) => Partial<P>);
}

type CustomizedRender =
  | GenericCustomizedRender<'text', ComponentProps<typeof TextField>>
  | GenericCustomizedRender<'select', SelectFieldProps>
  | GenericCustomizedRender<'number', ComponentProps<typeof TextField>>
  | GenericCustomizedRender<'date', LocalStateDatePickerProps>
  | GenericCustomizedRender<'radioGroup', ComponentProps<typeof RadioGroup>>
  | GenericCustomizedRender<'switch', ComponentProps<typeof Switch>>
  | GenericCustomizedRender<'yesNo', ComponentProps<typeof Switch>>
  | GenericCustomizedRender<'imageUpload', ComponentProps<typeof ImageUpload>>
  | GenericCustomizedRender<'dateTime', LocalStateDateTimePickerProps>
  | GenericCustomizedRender<'checkbox', ComponentProps<typeof Checkbox>>
  | GenericCustomizedRender<'autocomplete', ComponentProps<typeof Autocomplete>>
  | GenericCustomizedRender<'richText', ComponentProps<typeof RichTextEditor>>
  | GenericCustomizedRender<'phone', PhoneInputProps>
  | GenericCustomizedRender<'password', ComponentProps<typeof PasswordInput>>
  | GenericCustomizedRender<'currency', CurrencyInputProps>;

type FieldRenderer = (
  field: FieldRenderProps,
  fieldState: FieldState,
) => React.ReactElement;

const rendererMap: Record<PredefinedRenderType, InternalFieldRenderer> = {
  text: renderTextField,
  secureText: renderSecureTextField,
  select: renderSelectField,
  number: renderNumberField,
  date: renderDateField,
  dateTime: renderDateTimeField,
  radioGroup: renderRadioGroupField,
  switch: renderSwitchField,
  yesNo: renderYesNoField,
  imageUpload: renderImageUploadField,
  checkbox: renderCheckboxField,
  autocomplete: renderAutocompleteField,
  richText: renderRichTextField,
  phone: renderPhoneField,
  password: renderPasswordField,
  currency: renderCurrencyField,
};

type ControllerProps = ComponentProps<typeof Controller>;

interface ValueConverter<FormValue, ComponentValue> {
  toFormValue: (value: ComponentValue) => FormValue;
  toComponentValue: (value: FormValue) => ComponentValue;
}

export interface FormFieldProps<FormValue, ComponentValue = FormValue>
  extends Pick<ControllerProps, 'name' | 'rules'> {
  /**
   * Human-readable input label
   */
  label?: string;
  /**
   * Defines what element to render for the given field
   */
  render: PredefinedRenderType | CustomizedRender | FieldRenderer;
  /**
   * Children elements passed to the element defined in render property
   */
  children?: ReactNode;
  /**
   * Does conversions between form state value and component value. It is useful
   * when component value differs from form state value (e.g., select component
   * expects string, but in a form state we have boolean).
   */
  valueConverter?: ValueConverter<FormValue, ComponentValue>;
  /**
   * Default value of a field
   */
  defaultValue?: FormValue;
  /**
   * Callback to execute on change event
   */
  onChange?: (value: FormValue) => void;
  /**
   * Puts a form field into disabled state.
   */
  disabled?: boolean;
}

const FormField = <
  FormValue extends any,
  ComponentValue extends any = FormValue,
>({
  render,
  label,
  name,
  children,
  defaultValue,
  ...props
}: FormFieldProps<FormValue, ComponentValue>) => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation();

  const readOnly = useContext(ReadonlyFormContext);

  const getFieldError = (
    errors: Partial<Record<string, any>>,
    path: string,
  ): FieldError | undefined => {
    const pathTokens = path.split('.');
    const currentPropertyName = pathTokens[0];
    const currentPropertyValue = errors[currentPropertyName];
    if (!currentPropertyValue) {
      return undefined;
    }
    if (pathTokens.length === 1) {
      return currentPropertyValue;
    }
    return getFieldError(currentPropertyValue, pathTokens.slice(1).join('.'));
  };

  const getFieldMessage = () => {
    const error = getFieldError(formState.errors, name);

    if (!error) {
      return '';
    }

    if (error.message) {
      return error.message;
    }

    const defaultTypeMessageKey = DEFAULT_MESSAGE_KEYS[error.type];
    const messagePayload = {
      min: props.rules?.min,
      max: props.rules?.max,
      minLength: props.rules?.minLength,
      maxLength: props.rules?.maxLength,
    };
    return t(defaultTypeMessageKey ?? 'common.invalidField', messagePayload);
  };

  const renderField: ControllerProps['render'] = (field, fieldState) => {
    const {
      toFormValue = (v: ComponentValue) => v as unknown as FormValue,
      toComponentValue = (v: FormValue) => v as unknown as ComponentValue,
    } = props.valueConverter ?? {};
    const value = toComponentValue(field.value);
    const onChange = (event: unknown) => {
      const eventValue: ComponentValue = getEventValue(event);
      const convertedValue = toFormValue(eventValue);
      field.onChange(convertedValue);
      props.onChange?.(convertedValue);
    };
    const fieldRenderProps = {
      ...field,
      value,
      onChange,
      label,
      required: !!props.rules?.required,
      disabled: props.disabled || readOnly,
    };
    const extendedFieldState = {
      ...fieldState,
      message: getFieldMessage(),
    };

    if (typeof render === 'function') {
      return render(fieldRenderProps, extendedFieldState);
    }

    const type = typeof render === 'string' ? render : render.type;
    const fieldRenderer = rendererMap[type];

    const resolvePropsToPass = () => {
      const defaultProps = { children };

      if (typeof render !== 'object') {
        return defaultProps;
      }

      if (typeof render.props === 'function') {
        return render.props(fieldRenderProps, extendedFieldState);
      }

      return { ...defaultProps, ...render.props };
    };

    return fieldRenderer(
      resolvePropsToPass(),
      fieldRenderProps,
      extendedFieldState,
    );
  };

  const getDefaultValue = () => {
    if (defaultValue !== undefined) {
      return defaultValue;
    }

    const renderType = typeof render === 'object' ? render.type : render;

    switch (renderType) {
      case 'text':
      case 'select':
      case 'phone':
      case 'password':
        return '';
      case 'number':
      case 'date':
      case 'dateTime':
      case 'radioGroup':
      case 'imageUpload':
      case 'currency':
        return null;
      case 'switch':
      case 'checkbox':
        return false;
      default:
        return undefined;
    }
  };

  const isNumberField =
    render === 'number' ||
    (typeof render === 'object' &&
      'type' in render &&
      render.type === 'number');

  return (
    <Controller
      control={control}
      name={name}
      render={renderField}
      rules={{ valueAsNumber: isNumberField, ...props.rules }}
      defaultValue={getDefaultValue()}
    />
  );
};

export default FormField;
