import { forwardRef } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';

interface Props {
  label: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  info?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
}

const INPUT_WIDTH = 35;
const GAP = 10;

export const SwitchInput = forwardRef<HTMLInputElement, Props>(
  (
    { label, value, onChange, info, error, required, disabled, name },
    forwardedRef,
  ) => {
    return (
      <FormControl error={!!error}>
        <FormControlLabel
          control={
            <Switch
              inputRef={forwardedRef}
              required={required}
              sx={{ margin: 0, mr: `${GAP}px`, width: INPUT_WIDTH }}
            />
          }
          label={label}
          name={name}
          checked={value}
          onChange={(_e, checked) => onChange?.(checked)}
          disabled={disabled}
          sx={{ margin: 0, alignItems: 'flex-start' }}
        />
        {info ? (
          <FormHelperText
            error={false}
            sx={{
              ml: `${INPUT_WIDTH + GAP}px`,
              fontSize: 12,
              letterSpacing: 'inherit',
            }}
          >
            {info}
          </FormHelperText>
        ) : null}
        {error ? (
          <FormHelperText
            error
            sx={{
              ml: `${INPUT_WIDTH + GAP}px`,
              fontSize: 12,
              letterSpacing: 'inherit',
            }}
          >
            {error}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  },
);

SwitchInput.displayName = 'SwitchInput';
