import { useMemo, useCallback, useState, FC, PropsWithChildren } from 'react';
import { v4 as uuid } from 'uuid';
import { Modal, ModalSize } from 'melp-design/components';
import { contextFactory } from 'utils/general';
import { PromiseResolvePayload, ModalProps } from './modal.types';

interface ModalContext {
  openModal: <Props extends ModalProps>(options: {
    component: FC<Props>;
    props?: Omit<Props, 'closeModal'>;
    size?: ModalSize;
  }) => Promise<
    | NonNullable<Parameters<Props['closeModal']>[0]>
    | PromiseResolvePayload<'CLOSE'>
  >;
  closeModal: (data?: PromiseResolvePayload<'CLOSE'>) => void;
}

interface ModalOptions {
  id: string;
  component: FC<any>;
  props?: { [key: string]: unknown };
  size?: ModalSize;
  resolve: (data: PromiseResolvePayload<'CLOSE'>) => void;
}

const [useModalContext, ModalContextProvider] = contextFactory<ModalContext>();

export const ModalProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [modals, setModals] = useState<ModalOptions[]>([]);

  const openModal = useCallback<ModalContext['openModal']>(
    ({ component, props, size }) => {
      return new Promise((resolve) => {
        setModals((prev) => [
          ...prev,
          { component, props, size, resolve, id: uuid() },
        ]);
      });
    },
    [],
  );

  const closeModal = useCallback<ModalContext['closeModal']>((data) => {
    setModals((prev) => {
      prev.at(-1)?.resolve(data || { action: 'CLOSE' });

      return prev.slice(0, -1);
    });
  }, []);

  const value = useMemo(
    () => ({ openModal, closeModal }),
    [closeModal, openModal],
  );

  return (
    <ModalContextProvider value={value}>
      {children}

      {modals.map((modal) => {
        const CustomModal = modal.component;

        return (
          <Modal
            id={modal.id}
            onClose={() => closeModal({ action: 'CLOSE' })}
            isOpen
            size={modal.size}
            key={modal.id}
          >
            <CustomModal {...modal.props} closeModal={closeModal} />
          </Modal>
        );
      })}
    </ModalContextProvider>
  );
};

export { useModalContext };
