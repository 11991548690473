import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { RecognitionsFilters } from './recognitions.types';
import { loaders } from './recognitions.loaders';
import { converters } from './recognitions.converters';

export const recognitionsKeys = queryKeyFactory('recognitions');

export const useRecognitions = (filters: Partial<RecognitionsFilters> = {}) => {
  return useQuery({
    queryKey: recognitionsKeys.list(filters),
    queryFn: () => loaders.getRecognitions({ filters }),
    select: converters.getRecognitions,
  });
};

export const useRecognitionsSettings = () => {
  return useQuery({
    queryKey: recognitionsKeys.detail('settings'),
    queryFn: () => loaders.getRecognitionsSettings(),
    select: converters.getRecognitionsSettings,
  });
};
