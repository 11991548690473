import { ReactNode } from 'react';
import { FieldValues, UseFormMethods, useFormContext } from 'react-hook-form';

interface Props<FormData extends FieldValues> {
  /**
   * A node renderer
   */
  children: (form: UseFormMethods<FormData>) => ReactNode;
}

/**
 * Provides access to the form context via render prop. It is an alternative to
 * useFormContext when it's not convenient to use hook (e.g., when using a hook
 * requires to extract element to a separate component).
 */
const FormConnector = <FormData extends FieldValues>(
  props: Props<FormData>,
) => {
  const form = useFormContext<FormData>();
  return <>{props.children(form)}</>;
};

export default FormConnector;
