import { Box, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import {
  useErrorMessages,
  MpControlProps,
  controlRegisterOptions,
} from '@mp-react/form';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';
import { CustomFormControl, TooltipControl } from '../../../types/Common';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import useTooltip from '../../../utils/Tooltip';
import FormSufleris from '../../common/FormSufleris/FormSufleris';
import useStyles from '../../../styles/Form.styles';

const inputLabelProps = { shrink: true };

function TextView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const classes = useStyles();
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const { endAdornment, startAdornment } = control;
  const sufleris = useMemo(
    () => (control as TooltipControl)?.sufleris,
    [control],
  );
  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control],
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control],
  );
  const InputProps = useMemo(
    () => ({ endAdornment, startAdornment }),
    [endAdornment, startAdornment],
  );
  const [inputValue, setInputValue] = React.useState(value ?? '');
  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 300);

  useEffect(() => {
    if (value !== null && value !== undefined && value !== inputValue)
      setInputValue(value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <Box position="relative">
      <TextField
        error={!!error}
        helperText={firstMessage}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === 'separated' ? '' : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          debounced(e.target.value);
        }}
        InputProps={InputProps as any}
        variant={variant}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        disabled={disabled}
        className={clsx({
          [classes.textInput]: true,
          [classes.readonly]: readonly,
        })}
      />
      {sufleris && <FormSufleris sufleris={sufleris} />}
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Text(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <TextView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
