import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IListOrder, IOrder } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { exportXlsxConfig } from 'utils/general';
import { OrderStatus, OrdersFilters } from './orders.types';

interface GetOrders {
  filters: Partial<OrdersFilters>;
}

interface GetOrder {
  orderId: string;
}

interface UpdateOrder {
  orderId: string;
  data: {
    couponFileId: string;
    couponCode: string;
    status: OrderStatus;
    commentForAdmin: string;
    commentForEmployee: string;
  };
}

interface ExportXls {
  filters: Partial<OrdersFilters>;
}

export const loaders = {
  getOrders: ({ filters }: GetOrders) => {
    return api<TableDataResponse<IListOrder>>({
      url: Endpoints.clientAdmin.orders.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          creationDateFrom: filters.createdAt?.from,
          creationDateTo: filters.createdAt?.to,
          employee: filters.employees,
          companyIds: filters.companies,
          supplierIds: filters.suppliers,
          itemIds: filters.items,
          orderId: filters.orders,
          status: filters.status,
          isInternalItem:
            filters.variant === 'melp-shop'
              ? false
              : filters.variant === 'internal'
              ? true
              : undefined,
          search: filters.search || undefined,
        },
      },
    });
  },
  getOrder: ({ orderId }: GetOrder) => {
    return api<IOrder>({
      url: Endpoints.clientAdmin.orders.byId.replace(':orderId', orderId),
    });
  },
  updateOrder: ({ orderId, data }: UpdateOrder) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.orders.byId.replace(':orderId', orderId),
      data: {
        couponFileId: data.couponFileId,
        couponCode: data.couponCode,
        status: data.status,
        commentForAdmin: data.commentForAdmin,
        commentForEmployee: data.commentForEmployee,
      },
      method: 'patch',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.orders.export.xls,
      params: {
        filter: {
          creationDateFrom: filters.createdAt?.from,
          creationDateTo: filters.createdAt?.to,
          employee: filters.employees,
          companyIds: filters.companies,
          supplierIds: filters.suppliers,
          itemIds: filters.items,
          orderId: filters.orders,
          status: filters.status,
          isInternalItem:
            filters.variant === 'melp-shop'
              ? false
              : filters.variant === 'internal'
              ? true
              : undefined,
          search: filters.search || undefined,
        },
      },
      ...exportXlsxConfig,
    });
  },
};
