import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';

export default function BenefitTypeTranslations(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { t } = useTranslation();

  const data = useMemo(() => {
    const buildTranslationKey = (key?: string) =>
      `menu.${key?.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()}`;
    if (Array.isArray(value)) {
      return value.map((key) => t(buildTranslationKey(key))).join(', ');
    }
    return t(buildTranslationKey(value));
  }, [t, value]);

  return <Typography variant="body2">{data && data}</Typography>;
}
