import { useCallback, useState } from 'react';
import { UseLoading } from './../types/Common';

export const useLoading = (initialValue: boolean = false): UseLoading => {
  const [loading, setLoading] = useState<boolean>(initialValue);

  const startLoading = useCallback(() => setLoading(true), []);

  const stopLoading = useCallback(() => setLoading(false), []);

  return {
    loading,
    startLoading,
    stopLoading,
  };
};
