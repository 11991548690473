import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

interface StyleProps {
  editable?: boolean;
  avatar?: boolean;
  withSubtitle?: boolean;
}

export default makeStyles((theme) =>
  createStyles({
    titleContainer: ({ avatar, withSubtitle }: StyleProps) => ({
      alignItems: withSubtitle ? 'flex-start' : 'center',
      transition: theme.transitions.create('all'),
      paddingTop: avatar ? '35px' : '25px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: avatar ? '30px' : '24px',
      display: 'flex',
    }),
    editTitleContainer: ({ avatar }: StyleProps) => ({
      paddingTop: avatar ? '28px' : '15px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: avatar ? '23px' : '14px',
    }),
    titleInnerContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      fontSize: 20,
      lineHeight: '24px',
    },
    avatarContainer: {
      marginRight: 16,
      minWidth: 54,
      height: 54,
      backgroundColor: Colors.Default,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    avatar: {
      color: Colors.TextSecondary,
    },
    titleWithEdit: {
      cursor: 'pointer',
      transition: theme.transitions.create('background-color'),
      borderRadius: 8,
      width: '100%',
      padding: 10,
      '&:hover': {
        backgroundColor: Colors.Hover,
      },
    },
    editIcon: {
      minWidth: 25,
      minHeight: 25,
      height: 25,
      width: 25,
    },
    formWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editableTitle: {
      paddingRight: 0,
    },
    editButton: {
      marginLeft: 10,
      marginRight: 5,
      height: 28,
      width: 28,
    },
    editableCollapse: ({ editable }: StyleProps) => ({
      display: editable ? 'block' : 'none',
    }),
    progress: {
      minWidth: 25,
      minHeight: 25,
    },
    subtitle: {
      fontSize: 12,
    },
  }),
);
