export enum Colors {
  White = '#FFFFFF',
  Dark = '#001933',
  Default = '#D3D9DF',
  Primary = '#0098CB',
  LightPrimary = '#EDF9FF',
  Secondary = '#73BF4C',
  Disable = '#F0F3F9',
  Inactive = '#F4F8FC',
  TextPrimary = '#000000',
  TextSecondary = '#69717D',
  TextElement = '#929AA6',
  LightGray = '#9EA9B9',
  Background = '#E5E5E5',
  MainBackground = '#EEF1F6',
  BorderDefault = 'rgba(211, 217, 215, 1)',
  BorderDefaultLight = 'rgba(211, 217, 215, 0.5)',
  BorderPrimary = 'rgba(0, 152, 203, 1)',
  BorderPrimaryLight = 'rgba(0, 152, 203, 0.5)',
  BorderSecondary = 'rgba(115, 191, 76, 1)',
  BorderSecondaryLight = 'rgba(115, 191, 76, 0.5)',
  BorderDisabled = 'rgba(240, 243, 249, 1)',
  BorderDisabledLight = 'rgba(240, 243, 249, 0.5)',
  BorderTable = 'rgba(230, 233, 236, 1)',
  BorderOutlinedInput = 'rgba(0, 32, 64, 0.2)',
  Error = '#D71D24',
  Success = '#4DA621',
  Warning = '#F77C00',
  StatusYellow = '#F5AF00',
  StatusGreen = '#73BF4C',
  LightGrad = 'linear-gradient(180deg, #FEFEFF 0%, #EDF2F7 100%)',
  DarkGrad = 'linear-gradient(180deg, #0C1A27 0%, #132940 74.48%)',
  Divider = 'rgba(0, 0, 0, 0.1)',
  Hover = 'rgba(0, 0, 0, 0.04)',
  HoverPrimary = 'rgba(0, 152, 203, 0.1)',
  HoverDark = 'rgba(0, 25, 51, 0.8)',
  TabsBackground = 'rgba(0, 32, 64, 0.09)',
  TabsBorder = 'rgba(0, 32, 64, 0.15)',
  Overlay = 'rgba(0, 0, 0, 0.3)',
}

export enum Shadows {
  ThumbShadow = '0px 1.96154px 5.23077px rgba(0, 0, 0, 0.1), 0px 0.653846px 0.653846px rgba(0, 0, 0, 0.01), 0px 1.96154px 0.653846px rgba(0, 0, 0, 0.03)',
  TableFooter = '0px -10px 30px rgba(0, 0, 0, 0.08)',
  TableFooterBorder = '1px 0 0px rgba(224, 224, 224, 1)',
  PaperElevation8 = '0px 30px 30px rgba(0, 0, 0, 0.3)',
  BulkAction = '0px 20px 40px rgba(0, 0, 0, 0.4)',
  Tooltip = '0px 20px 40px rgba(0, 0, 0, 0.4)',
}

export enum ShadowOpacities {
  Dark = '0.1',
  Medium = '0.03',
  Light = '0.06',
}

export const getBorder = (color: string) => `1px solid ${color}`;
