import { forwardRef, useMemo, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { TextField } from 'melp-design/components';

interface Option {
  value: string;
  label: string;
}

interface BaseProps {
  label?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  onInputChange?: (value: string) => void;
  options: Option[];
  preselectedOptions?: Option[];
}

interface Multiple extends BaseProps {
  multiple: true;
  value?: string[];
  onChange?: (value: string[]) => void;
}

interface Single extends BaseProps {
  multiple?: false;
  value?: string;
  onChange?: (value: string) => void;
}

type Props = Multiple | Single;

export const AutocompleteInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      placeholder,
      name,
      options,
      preselectedOptions = [],
      disabled,
      required,
      multiple,
      error,
      value,
      onInputChange,
      onChange,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

    const allOptions = useMemo(() => {
      return [...preselectedOptions, ...selectedOptions, ...options].filter(
        (option, index, self) =>
          self.findIndex((o) => o.value === option.value) === index,
      );
    }, [options, preselectedOptions, selectedOptions]);

    const currentValue = useMemo(() => {
      if (multiple) {
        return allOptions.filter((option) => value?.includes(option.value));
      }

      if (!multiple) {
        return allOptions.find((option) => option.value === value) ?? null;
      }

      return null;
    }, [allOptions, multiple, value]);

    return (
      <Autocomplete
        fullWidth
        multiple={multiple}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            errorMessage={error}
            required={!required ? false : multiple ? !value?.length : !value}
            label={label}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              id: uuid(),
              autoComplete: 'new-password',
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        options={allOptions}
        getOptionLabel={(option) => option.label}
        value={currentValue}
        onChange={(_, v) => {
          if (multiple && Array.isArray(v)) {
            onChange?.(v.map((option) => option.value));
            setSelectedOptions(v);
            setInputValue('');
          }

          if (!multiple) {
            const newValue = v && 'value' in v ? v : null;
            onChange?.(newValue?.value ?? '');
            setSelectedOptions(newValue ? [newValue] : []);
            setInputValue(newValue?.label ?? '');
          }

          onInputChange?.('');
        }}
        // disable the built-in filtering
        filterOptions={onInputChange ? (x) => x : undefined}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        inputValue={
          multiple
            ? inputValue
            : currentValue && !Array.isArray(currentValue)
            ? currentValue.label
            : inputValue
        }
        onInputChange={(_, v, reason) => {
          if (reason !== 'reset') {
            setInputValue(v);
            onInputChange?.(v);

            if (!multiple && value) {
              onChange?.('');
            }
          }
        }}
        ref={ref}
      />
    );
  },
);

AutocompleteInput.displayName = 'AutocompleteInput';
