import { isDefined } from '../../../../utils/isDefined';

const isDateObject = (value: unknown): value is Date => value instanceof Date;

const isObjectType = (value: unknown) => typeof value === 'object';

const isObject = <T extends object>(value: unknown): value is T =>
  isDefined(value) &&
  !Array.isArray(value) &&
  isObjectType(value) &&
  !isDateObject(value);

const isCheckBoxInput = (element: any): element is HTMLInputElement =>
  element.type === 'checkbox';

type Event = { target: any };

const getEventValue = (event: unknown) =>
  isObject(event) && (event as Event).target
    ? isCheckBoxInput((event as Event).target)
      ? (event as Event).target.checked
      : (event as Event).target.value
    : event;

/**
 * Gets the value from an event. It does the same thing as
 * https://github.com/react-hook-form/react-hook-form/blob/master/src/logic/getEventValue.ts
 * As this function is not exposed in react-hook-form, it was decided to take
 * the existing code into this repository to preserve existing functionality
 * and extend it with a value conversion capability within FormField component.
 */
export default getEventValue;
