import { TextField, TextFieldProps } from 'melp-design/components';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Currency } from 'types/general';
import { InternalFieldRenderer } from '../Types';
import { useParentCompany } from '../../../../state/ParentCompany';
import {
  getAllowedDecimalPlaces,
  currencyConverters,
} from '../../../../utils/Currency';
import { isDefined } from '../../../../utils/isDefined';

interface CurrencyFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  currency: Currency;
}

const CurrencyFormat = forwardRef<NumericFormatProps, CurrencyFormatProps>(
  function NumericFormatCustom(props, ref) {
    const allowedDecimalPlaces = getAllowedDecimalPlaces(props.currency);
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        thousandSeparator=" "
        valueIsNumericString
        allowedDecimalSeparators={[',']}
        decimalScale={allowedDecimalPlaces}
      />
    );
  },
);

interface AdditionalCurrencyInputProps {
  /**
   * Amount in fractional unit (e.g., cents). Null means that value is not set.
   */
  value?: number | null;
  /**
   * Callback to execute when a value changes.
   * @param newValue A new value. Null means that an input was cleared.
   */
  onChange?: (newValue: number | null) => void;
}

type SupportedTextFieldProps = Omit<
  TextFieldProps,
  keyof AdditionalCurrencyInputProps | 'InputProps'
>;

export type CurrencyInputProps = AdditionalCurrencyInputProps &
  SupportedTextFieldProps;

/**
 * Input for entering a money value. It relies on account currency.
 */
const CurrencyInput = forwardRef(
  ({ value, ...props }: CurrencyInputProps, ref) => {
    const { parentCompany } = useParentCompany();
    const accountCurrency = parentCompany?.defaultCurrency as Currency;

    return (
      <TextField
        {...props}
        ref={ref}
        value={
          isDefined(value)
            ? String(currencyConverters.toMainUnitAmount(value))
            : ''
        }
        InputProps={{
          endAdornment: accountCurrency,
          // casting to any as emulating an event is unnecessarily complex
          inputComponent: CurrencyFormat as any,
          inputProps: {
            currency: accountCurrency,
          },
        }}
        onChange={(e) => {
          const newValueAsString = e.target.value;
          if (newValueAsString.endsWith('.')) {
            return;
          }
          let newValue: number | null = null;
          if (newValueAsString.length) {
            const newValueAsFloat = parseFloat(newValueAsString);
            newValue =
              currencyConverters.toFractionalUnitAmount(newValueAsFloat);
          }
          props.onChange?.(newValue);
        }}
      />
    );
  },
);

CurrencyInput.displayName = 'CurrencyInput';

export const renderCurrencyField: InternalFieldRenderer = (
  props,
  field,
  fieldState,
) => (
  <CurrencyInput
    fullWidth
    error={fieldState.invalid}
    helperText={fieldState.message}
    {...field}
    {...props}
  />
);
