import { createStyles, makeStyles } from '@mui/styles';
import { Shadows } from '../../../constants/Style';
import { Colors } from './../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    iconSpacer: {
      marginRight: 10,
    },
    menuPaper: {
      padding: '5px',
      marginTop: 5,
      boxShadow: Shadows.BulkAction,
    },
    menuItem: {
      paddingTop: 14,
      paddingBottom: 0,
      paddingRight: 0,
      minWidth: 150,
    },
    overlay: {
      zIndex: 2,
    },
    itemDivider: {
      borderBottom: 'rgba(0, 0, 0, 0.1) solid 1px',
      marginLeft: 20,
      paddingRight: 20,
      width: '100%',
      paddingBottom: 14,
    },
    noBorder: {
      borderBottom: 0,
    },
    disabled: {
      '&.Mui-disabled': {
        backgroundColor: Colors.Default,
        color: Colors.TextElement,
      },
    },
  }),
);
