import { converters as tableConverters } from 'store/table';
import { converters as filesConverters } from 'store/files';
import { IIntegration, IIntegrationModel } from 'types/income';
import { loaders } from './integrations.loaders';
import { Integration, IntegrationModel } from './integrations.types';

const convertIntegrationModel = (
  model: IIntegrationModel,
): IntegrationModel => {
  return {
    id: model.id,
    name: model.name,
    status: model.status,
    variant: model.type,
    logo: model.logo ? filesConverters.getUploadedFile(model.logo) : null,
    comment: model.comment ?? '',
    createdAt: model.creationDate,
  };
};

const convertIntegrationModels = (
  models: IIntegrationModel[],
): IntegrationModel[] => {
  return models.map(convertIntegrationModel);
};

const convertIntegration = (integration: IIntegration): Integration => {
  return {
    id: integration.id,
    name: integration.name,
    status: integration.status,
    parentCompanyId: integration.parentCompanyId,
    model: convertIntegrationModel(integration.integrationType),
  };
};

const convertIntegrations = (integrations: IIntegration[]): Integration[] => {
  return integrations.map(convertIntegration);
};

export const converters = {
  getIntegrations: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getIntegrations>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertIntegrations(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getIntegration: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getIntegration>>) => {
    return convertIntegration(data);
  },
  getIntegrationModels: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getIntegrationModels>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertIntegrationModels(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getIntegrationModel: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getIntegrationModel>>) => {
    return convertIntegrationModel(data);
  },
};
