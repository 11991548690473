import {
  usePickerLayout,
  PickersLayoutRoot,
  PickersLayoutProps,
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
} from '@mui/x-date-pickers/PickersLayout';
import CustomDatePickerActionBar from './CustomDatePickerActionBar';

const CustomDatePickerLayout = (props: PickersLayoutProps<any, any, any>) => {
  const { toolbar, tabs, content } = usePickerLayout(props);

  const handleSetToday = () => {
    props.onSetToday();
    props.onClose();
  };

  // Put the action bar before the content
  return (
    <PickersLayoutRoot className={pickersLayoutClasses.root} ownerState={props}>
      {toolbar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {tabs}
        {content}
      </PickersLayoutContentWrapper>
      <CustomDatePickerActionBar
        {...props.slotProps?.actionBar}
        onAccept={props.onAccept}
        onCancel={props.onAccept}
        onClear={props.onAccept}
        onSetToday={handleSetToday}
        className={pickersLayoutClasses.actionBar}
      />
    </PickersLayoutRoot>
  );
};

export default CustomDatePickerLayout;
