import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    inactive: {
      color: Colors.LightGray,
    },
    formControlLabel: {
      paddingTop: 0,
    },
  }),
);
