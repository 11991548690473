import { FC, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { Delete, DragIndicator, Edit } from '@mui/icons-material';
import { APP, ROUTES } from 'config';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  DetailsPageHeader,
  FileInput,
  Footer,
  ImageList,
  Loader,
  MultiLanguageInputContainer,
  NothingFoundAlert,
  NumberInput,
  Panel,
  RichTextInput,
  SelectInput,
  SortableList,
  SwitchInput,
  TextField,
} from 'melp-design/components';
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useUploadFile } from 'store/files';
import {
  InternalShopItemTranslations,
  PurchaseLimitPeriod,
  PurchaseLimitVariant,
  purchaseLimitPeriods,
  purchaseLimitVariants,
  useCreateInternalShopItem,
  useInternalShopItem,
  useUpdateInternalShopItem,
} from 'store/internal-shop';
import { ContentLanguage } from 'types/general';
import { ShopItemCategory, shopItemCategories } from 'types/MarketplaceItems';
import { usePredefinedToasts } from 'utils/Toast';
import { useCompanyLanguages } from 'state/Administrators';
import { useIdParam } from 'utils/useIdParam';
import { Translations } from 'types/Common';
import { SystemColors } from 'melp-design/style';
import { ShopItemStatus, shopItemStatuses } from 'store/shop';
import { formatCountry, formatLanguage } from 'utils/format';

interface Values {
  name: string;
  categories: ShopItemCategory[];
  status: ShopItemStatus;
  languages: Array<Uppercase<ContentLanguage>>;
  translations: InternalShopItemTranslations;
  imageId: string;
  trackInventory: boolean;
  prices: Array<{
    priceId: string;
    price: number;
    initialInventory: number;
    translations: Translations<{ description: string }>;
  }>;
  countries: string[];
  purchaseRestrictions: boolean;
  purchaseLimit: number;
  purchaseLimitVariant: PurchaseLimitVariant;
  purchaseLimitPeriod: PurchaseLimitPeriod;
  notifyOnOrder: boolean;
}

const INITIAL_VALUES = (
  defaultLanguage: Uppercase<ContentLanguage>,
  country?: string,
): Values => ({
  name: '',
  categories: [],
  status: 'active',
  languages: [defaultLanguage],
  translations: [{ language: defaultLanguage, title: '', description: '' }],
  imageId: '',
  trackInventory: false,
  prices: [
    {
      priceId: '',
      price: NaN,
      initialInventory: NaN,
      translations: [{ language: defaultLanguage, description: '' }],
    },
  ],
  countries: country ? [country] : [],
  purchaseRestrictions: false,
  purchaseLimit: 1,
  purchaseLimitVariant: 'perItem',
  purchaseLimitPeriod: 'monthly',
  notifyOnOrder: true,
});

const PriceTranslationsList: FC<{
  control: Control<Values>;
  priceIndex: number;
}> = ({ control, priceIndex }) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    name: `prices.${priceIndex}.translations`,
    control,
  });

  return (
    <MultiLanguageInputContainer
      fields={fields.map((field, i) => ({
        id: field.id,
        language: field.language,
        component: (
          <Controller
            name={`prices.${priceIndex}.translations.${i}.description`}
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.variant.title')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />
        ),
      }))}
    />
  );
};

export const ShopInternalItemDetails: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const itemId = useIdParam();
  const predefinedToasts = usePredefinedToasts();
  const { defaultLanguage, supportedLanguages, country } =
    useCompanyLanguages();

  const { data: item, isLoading } = useInternalShopItem(itemId);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES(defaultLanguage, country),
  });
  const { fields: translationsFields, replace: replaceTranslations } =
    useFieldArray({ name: 'translations', control });
  const {
    fields: pricesFields,
    replace: replacePrices,
    append: appendPrice,
    move: movePrices,
    remove: removePrice,
  } = useFieldArray({
    name: 'prices',
    control,
  });

  useEffect(() => {
    if (item) {
      reset({
        name: item.name,
        categories: item.categories,
        status: item.status,
        languages: item.translations.map((trans) => trans.language),
        translations: item.translations,
        imageId: item.image?.id,
        countries: item.countries,
        prices: item.prices.map((price) => ({
          priceId: price.id,
          price: price.price,
          initialInventory: price.inventory,
          translations: price.translations,
        })),
        trackInventory: item.trackInventory,
        purchaseRestrictions: item.purchaseLimitVariant !== 'none',
        purchaseLimit: item.purchaseLimit,
        purchaseLimitVariant: item.purchaseLimitVariant,
        purchaseLimitPeriod: item.purchaseLimitPeriod,
        notifyOnOrder: item.notifyOnOrder,
      });
    }
  }, [item, reset]);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: createItem, isLoading: isCreating } =
    useCreateInternalShopItem();
  const { mutate: updateItem, isLoading: isUpdating } =
    useUpdateInternalShopItem();

  const onSubmit: SubmitHandler<Values> = (values) => {
    const valuesToData = {
      name: values.name,
      categories: values.categories,
      status: values.status,
      translations: values.translations,
      imageId: values.imageId,
      prices: values.prices.map((price, i) => ({
        id: price.priceId,
        price: price.price,
        initialInventory: price.initialInventory,
        index: i,
        translations: price.translations.map((trans) => ({
          language: trans.language,
          title: trans.description,
        })),
      })),
      countries: values.countries,
      trackInventory: values.trackInventory,
      purchaseLimitType: values.purchaseRestrictions
        ? values.purchaseLimitVariant
        : 'none',
      purchaseLimit: values.purchaseLimit,
      purchaseLimitPeriod: values.purchaseLimitPeriod,
      notifyOnOrder: values.notifyOnOrder,
    };

    if (item) {
      updateItem(
        { itemId, data: valuesToData },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
          },
        },
      );
    } else {
      createItem(valuesToData, {
        onSuccess: () => {
          predefinedToasts.success.added();
          history.push(ROUTES.shop.internal.items.list);
        },
      });
    }
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  const [
    languages,
    translations,
    prices,
    imageId,
    trackInventory,
    purchaseRestrictions,
  ] = watch([
    'languages',
    'translations',
    'prices',
    'imageId',
    'trackInventory',
    'purchaseRestrictions',
  ]);

  if (itemId && isLoading) return <Loader />;
  if (itemId && !item) return <NothingFoundAlert />;

  return (
    <>
      <Stack>
        <DetailsPageHeader
          title={item ? item.name : t('marketplaceItems.addMarketplaceItem')}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Panel title={t('benefits.benefitGeneralSettingsSection')}>
              <Stack spacing={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <TextField
                        label={t('common.systemName')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />
                <Controller
                  name="categories"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <AutocompleteInput
                        label={t('benefits.benefit_type')}
                        name={name}
                        value={value[0] ?? ''}
                        onChange={(v) => onChange([v])}
                        options={shopItemCategories.map((category) => ({
                          label: t(`marketplaceItems.itemCategory-${category}`),
                          value: category,
                        }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SelectInput
                        label={t('common.status')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={shopItemStatuses
                          .filter((status) => status !== 'archived')
                          .map((status) => ({
                            label: t(`status.${status}`),
                            value: status,
                          }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />
              </Stack>
            </Panel>
            <Panel title={t('benefitTemplates.templateContent')}>
              <Stack spacing={4}>
                <Controller
                  name="languages"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SelectInput
                        label={t('companies.supported_languages')}
                        name={name}
                        value={value}
                        onChange={(value) => {
                          const selectedLanguages = value as Array<
                            Uppercase<ContentLanguage>
                          >;
                          replaceTranslations(
                            selectedLanguages.map(
                              (language) =>
                                translations.find(
                                  (t) => t.language === language,
                                ) ?? { language, title: '', description: '' },
                            ),
                          );
                          replacePrices(
                            prices.map((price) => ({
                              priceId: price.priceId,
                              price: price.price,
                              initialInventory: price.initialInventory,
                              translations: selectedLanguages.map(
                                (language) =>
                                  price.translations.find(
                                    (t) => t.language === language,
                                  ) ?? { language, description: '' },
                              ),
                            })),
                          );
                          onChange(selectedLanguages);
                        }}
                        options={supportedLanguages.map((language) => ({
                          value: language,
                          label: formatLanguage(i18n.language, language),
                          disabled:
                            languages.includes(language) &&
                            language === defaultLanguage,
                        }))}
                        multiple
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <MultiLanguageInputContainer
                  label={t('common.name')}
                  info={t('shop.item.name.info')}
                  required
                  fields={translationsFields.map((field, i) => ({
                    id: field.id,
                    language: field.language,
                    component: (
                      <Controller
                        name={`translations.${i}.title`}
                        control={control}
                        render={({ field: { name, value, onChange, ref } }) => {
                          return (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              required
                              ref={ref}
                            />
                          );
                        }}
                      />
                    ),
                  }))}
                />

                <MultiLanguageInputContainer
                  label={t('employees.details')}
                  required
                  fields={translationsFields.map((field, i) => ({
                    id: field.id,
                    language: field.language,
                    component: (
                      <Controller
                        name={`translations.${i}.description`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('form.required'),
                          },
                        }}
                        render={({ field: { value, onChange, ref } }) => {
                          return (
                            <RichTextInput
                              value={value}
                              onChange={onChange}
                              error={
                                errors.translations?.[i]?.description?.message
                              }
                              ref={ref}
                            />
                          );
                        }}
                      />
                    ),
                  }))}
                />
              </Stack>
            </Panel>
            <Panel title={t('shop.internal.media.title')}>
              <Controller
                name="imageId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return imageId ? (
                    <ImageList
                      items={[
                        {
                          imageId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      name={name}
                      onChange={async ([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      ref={ref}
                    />
                  );
                }}
              />
            </Panel>
            <Panel title={t('shop.internal.inventory.title')}>
              <Stack spacing={4}>
                <Controller
                  name="trackInventory"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SwitchInput
                        label={t('shop.internal.trackInventory.label')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                      />
                    );
                  }}
                />

                <SortableList
                  key={JSON.stringify(pricesFields.map((field) => field.id))}
                  onPositionChange={movePrices}
                  items={pricesFields.map((field, i) => ({
                    id: field.id,
                    content: (
                      <Stack
                        direction="row"
                        spacing={2}
                        py={1}
                        px={2}
                        sx={{ backgroundColor: SystemColors.white }}
                        borderRadius={1}
                        boxShadow={` 0 0 0 1px ${SystemColors.grey[90]}`}
                        key={field.id}
                      >
                        <Box flexShrink={0} height={52}>
                          <DragIndicator
                            sx={{
                              height: '100%',
                              color: SystemColors.grey[55],
                            }}
                          />
                        </Box>
                        <Controller
                          control={control}
                          name={`prices.${i}.priceId`}
                          render={({ field: { value } }) => (
                            <input type="hidden" value={value} />
                          )}
                        />
                        <Box flex={1}>
                          <PriceTranslationsList
                            priceIndex={i}
                            control={control}
                          />
                        </Box>
                        <Box flex={0.35}>
                          <Controller
                            name={`prices.${i}.price`}
                            control={control}
                            render={({
                              field: { name, value, onChange, ref },
                            }) => (
                              <NumberInput
                                label={t('marketplaceItems.price')}
                                name={name}
                                value={value}
                                onChange={onChange}
                                required
                                key={value}
                                ref={ref}
                              />
                            )}
                          />
                        </Box>
                        {trackInventory ? (
                          <Box flex={0.35}>
                            <Controller
                              name={`prices.${i}.initialInventory`}
                              control={control}
                              render={({
                                field: { name, value, onChange, ref },
                              }) => (
                                <NumberInput
                                  label={t('shop.variant.inventory')}
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  disabled={!!field.priceId}
                                  required
                                  key={value}
                                  ref={ref}
                                />
                              )}
                            />
                          </Box>
                        ) : null}

                        {field.priceId ? (
                          <Box flexShrink={0}>
                            <Button
                              variant="neutral-outline"
                              size="lg"
                              icon={Edit}
                              onClick={() =>
                                history.push(
                                  ROUTES.shop.internal.inventory.details.replace(
                                    ':id',
                                    field.priceId,
                                  ),
                                )
                              }
                              title={t('common.edit')}
                            />
                          </Box>
                        ) : null}

                        <Box flexShrink={0}>
                          <Button
                            variant="danger-outline"
                            size="lg"
                            icon={Delete}
                            onClick={() => removePrice(i)}
                            disabled={prices.length <= 1}
                            title={t('common.remove')}
                          />
                        </Box>
                      </Stack>
                    ),
                  }))}
                />

                <Box>
                  <Button
                    label={t('shop.actions.add_variant')}
                    variant="tertiary"
                    onClick={() => {
                      appendPrice({
                        ...INITIAL_VALUES(defaultLanguage, country).prices[0],
                        translations: translations.map((trans) => ({
                          language: trans.language,
                          description: '',
                        })),
                      });
                    }}
                  />
                </Box>
              </Stack>
            </Panel>
            <Panel title={t('common.settings')}>
              <Stack spacing={4}>
                <Controller
                  name="countries"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <AutocompleteInput
                        label={t('marketplaceOrders.itemCountries')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={APP.countries.alpha3.map((alpha3) => ({
                          value: alpha3,
                          label: formatCountry(i18n.language, alpha3),
                        }))}
                        required
                        multiple
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="purchaseRestrictions"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SwitchInput
                        label={t('shop.internal.purchaseRestrictions.label')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                      />
                    );
                  }}
                />

                {purchaseRestrictions ? (
                  <Stack flexDirection="row" gap={1}>
                    <Controller
                      name="purchaseLimit"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <NumberInput
                            label={t('shop.purchaseLimit.label')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            required
                            sx={{ minWidth: 75 }}
                            key={value}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="purchaseLimitVariant"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={purchaseLimitVariants
                              .filter((variant) => variant !== 'none')
                              .map((variant) => ({
                                label: t(
                                  `shop.internal.purchaseLimit.${variant}`,
                                ),
                                value: variant,
                              }))}
                            required
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <TextField
                      value={t('recognition.allowanceRulesListPoints', {
                        pointsName: undefined,
                      })}
                      sx={{ minWidth: 'fit-content' }}
                      readonly
                    />
                    <Controller
                      name="purchaseLimitPeriod"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            label={t('recognition.allowanceRulesListFrequency')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={purchaseLimitPeriods.map((period) => ({
                              label: t(
                                `recognition.allowanceRuleFrequency-${period}`,
                              ),
                              value: period,
                            }))}
                            required
                            ref={ref}
                          />
                        );
                      }}
                    />
                  </Stack>
                ) : null}

                <Controller
                  name="notifyOnOrder"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SwitchInput
                        label={t('shop.internal.notifyOnOrder.label')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                      />
                    );
                  }}
                />
              </Stack>
            </Panel>
          </Stack>

          <Footer visible={isDirty}>
            <Stack direction="row" justifyContent="end" gap="10px">
              <Button
                label={t('common.cancel')}
                variant="neutral-outline"
                onClick={() => {
                  reset(undefined, { keepDirty: false });
                }}
              />
              <Button
                label={t('common.save')}
                type="submit"
                disabled={isCreating || isUpdating}
              />
            </Stack>
          </Footer>
        </form>
      </Stack>

      <DataLossPrompt when={isDirty && !isCreating} />
    </>
  );
};
