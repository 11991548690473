import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableOld,
} from 'melp-design/components';
import { Link } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useRecognitionAllowanceEmployeeOverview } from 'state/Recognition';
import { useDialog } from 'utils/Dialog';
import { usePagination } from 'utils/Pagination';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';

interface Props {
  employeeId: string;
}

const Content = (props: Props) => {
  const { t } = useTranslation();

  const { page, pageSize, setPage, setPageSize } = usePagination();
  const employeeOverview = useRecognitionAllowanceEmployeeOverview(
    props.employeeId,
    { page, pageSize },
  );

  const pointsLabel = usePointsLabel();

  return (
    <>
      <DialogTitle>
        {t('recognition.allowanceOverviewAssignedAllowanceDetailsTitle')}
      </DialogTitle>
      <DialogContent>
        <TableOld
          data={employeeOverview.data?.allowances}
          loading={employeeOverview.loading}
          rowKey="id"
          columns={[
            {
              key: 'pointsReceived',
              title: t(
                'recognition.allowanceEmployeeOverviewListPointsReceived',
                { recognitionCurrency: pointsLabel },
              ),
            },
            {
              key: 'pointsSpent',
              title: t('recognition.allowanceEmployeeOverviewListPointsSpent', {
                recognitionCurrency: pointsLabel,
              }),
            },
            {
              key: 'validFrom',
              title: t('recognition.allowanceEmployeeOverviewListValidFrom'),
              render: (value: string) =>
                // ignore time
                moment(value.split('T')[0]).format('l'),
            },
            {
              key: 'validTo',
              title: t('recognition.allowanceEmployeeOverviewListValidTo'),
              render: (value: string) =>
                // ignore time
                moment(value.split('T')[0]).format('l'),
            },
            {
              key: 'frequency',
              title: t('recognition.allowanceEmployeeOverviewListFrequency'),
              render: (value) =>
                t(`recognition.allowanceRuleFrequency-${value}`),
            },
            {
              key: 'status',
              title: t('common.status'),
              render: (_, record) => {
                if (moment().isAfter(record.validTo)) {
                  return t('recognition.allowanceStatus-expired');
                }
                return t('recognition.allowanceStatus-valid');
              },
            },
          ]}
          isInactive={(record) => moment().isAfter(record.validTo)}
          pagination={
            employeeOverview.data
              ? {
                  count: employeeOverview.data.count,
                  page: employeeOverview.data.page,
                  pageSize: employeeOverview.data.pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }
              : undefined
          }
        />
      </DialogContent>
    </>
  );
};

const AssignedAllowanceDetails = (props: Props) => {
  const { t } = useTranslation();
  const allowanceDetailsDialog = useDialog();

  return (
    <>
      <Link component="button" onClick={allowanceDetailsDialog.openDialog}>
        {t('recognition.allowanceOverviewAssignedAllowanceDetailsLink')}
      </Link>
      <Dialog
        open={allowanceDetailsDialog.open}
        onClose={allowanceDetailsDialog.closeDialog}
        width={800}
      >
        <Content {...props} />
      </Dialog>
    </>
  );
};

export default AssignedAllowanceDetails;
