import { converters as tableConverters } from 'store/table';
import { ISupplier } from 'types/income';
import { loaders } from './suppliers.loaders';
import { Supplier } from './suppliers.types';

const convertSupplier = (supplier: ISupplier): Supplier => {
  return {
    id: supplier.id,
    name: supplier.name,
    systemName: supplier.systemName,
    logo: supplier.logoId,
    description: supplier.description ?? '',
    additionalInfo: supplier.additionalInfo ?? '',
    termsAndConditionsLink: supplier.termsAndConditionsLink ?? '',
    status: supplier.status,
    items: Number(supplier.activeItems ?? 0),
    orders: Number(supplier.completedOrders ?? 0),
    isParentCompany: supplier.isParentCompany ?? false,
    createdAt: supplier.creationDate,
  };
};

const convertSuppliers = (suppliers: ISupplier[]): Supplier[] => {
  return suppliers.map(convertSupplier);
};

export const converters = {
  getSuppliers: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getSuppliers>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertSuppliers(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
