import { ReactNode, useEffect, useRef } from 'react';
import { Button, Typography } from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isDefined } from 'utils/isDefined';

interface FiltersRenderProps<F extends Record<string, any>> {
  value: F;
  initialValues: F;
  setFilter: <K extends keyof F>(filterName: K, newValue?: F[K]) => void;
}

export interface Props<F extends Record<string, any>> {
  value: F;
  initialValues?: F;
  onChange: (value: F) => void;
  onChangeCallback?: () => void;
  showClearButton?: boolean;
  showLabel?: boolean;
  children: (props: FiltersRenderProps<F>) => ReactNode;
}

export const Filters = <F extends Record<string, any>>({
  value,
  initialValues = value,
  showClearButton,
  showLabel = true,
  onChange,
  onChangeCallback,
  children,
}: Props<F>) => {
  const { t } = useTranslation();

  const activeFilterExists = !!Object.keys(value).some((key) =>
    isDefined(value[key as keyof F]),
  );

  const tempFilters = useRef(value);
  // Sync temporary filters variable when filters value changes externally
  useEffect(() => {
    if (tempFilters.current !== value) {
      tempFilters.current = value;
    }
  }, [value]);

  const commitChanges = (nextFilters: F) => {
    onChange(nextFilters);
    onChangeCallback?.();
  };

  const setFilter = <K extends keyof F>(filterName: K, newValue?: F[K]) => {
    const newFilters = {
      ...tempFilters.current,
      [filterName]: newValue,
    };
    // remove this crap when old tables are removed and initialValues becomes required
    if (!initialValues && !isDefined(newFilters[filterName])) {
      delete newFilters[filterName];
    }
    tempFilters.current = newFilters;
    commitChanges(tempFilters.current);
  };

  const handleClear = () => {
    if (initialValues) {
      commitChanges(initialValues);
    } else {
      commitChanges({} as F);
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap="10px" flexWrap="wrap">
      {showLabel ? (
        <Typography variant="p1" color="textSecondary">
          {`${t('marketplace.filters')}:`}
        </Typography>
      ) : null}

      {children({ value, initialValues, setFilter })}

      {showClearButton ?? activeFilterExists ? (
        <Button
          label={t('table.clear')}
          variant="danger-outline"
          size="sm"
          onClick={handleClear}
        />
      ) : null}
    </Stack>
  );
};
