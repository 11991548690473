import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './employee-groups.loaders';
import { converters } from './employee-groups.converters';
import {
  EmployeeGroupBenefitsFilters,
  EmployeeGroupEmployeesFilters,
  EmployeesGroupsFilters,
} from './employee-groups.types';

export const employeeGroupsKeys = queryKeyFactory('employee-groups');

export const useEmployeesGroups = (
  filters: Partial<EmployeesGroupsFilters> = {},
) => {
  const query = useQuery({
    queryKey: employeeGroupsKeys.list(filters),
    queryFn: () => loaders.getEmployeesGroups({ filters }),
    select: converters.getEmployeesGroups,
  });

  return query;
};

export const useEmployeeGroupBenefits = (
  employeesGroupId: string,
  filters: Partial<EmployeeGroupBenefitsFilters> = {},
  options: { enabled?: boolean } = {},
) => {
  const query = useQuery({
    queryKey: employeeGroupsKeys.list('benefits', employeesGroupId, filters),
    queryFn: () =>
      loaders.getEmployeeGroupBenefits({
        employeesGroupId,
        filters,
      }),
    select: converters.getEmployeeGroupBenefits,
    enabled: options.enabled,
  });

  return query;
};

export const useEmployeeGroupEmployees = (
  employeesGroupId: string,
  filters: Partial<EmployeeGroupEmployeesFilters> = {},
  options: { enabled?: boolean } = {},
) => {
  const query = useQuery({
    queryKey: employeeGroupsKeys.list('employees', employeesGroupId, filters),
    queryFn: () =>
      loaders.getEmployeeGroupEmployees({
        employeesGroupId,
        filters,
      }),
    select: converters.getEmployeeGroupEmployees,
    enabled: options.enabled,
  });

  return query;
};
