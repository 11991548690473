import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './employees.loaders';
import { converters } from './employees.converters';
import {
  EmployeeExpensesFilters,
  EmployeeWalletBenefitsFilters,
  EmployeesFilters,
} from './employees.types';

export const employeesKeys = queryKeyFactory('employees');

export const useEmployees = (filters: Partial<EmployeesFilters> = {}) => {
  const query = useQuery({
    queryKey: employeesKeys.list(filters),
    queryFn: () => loaders.getEmployees({ filters }),
    select: converters.getEmployees,
  });

  return query;
};

export const useEmployeeExpenses = (
  employeeId: string,
  filters: Partial<EmployeeExpensesFilters> = {},
) => {
  const query = useQuery({
    queryKey: employeesKeys.detail(employeeId, 'expenses', filters),
    queryFn: () => loaders.getExpenses({ employeeId, filters }),
    select: converters.getExpenses,
    enabled: !!employeeId,
  });

  return query;
};

export const useEmployeeWalletBenefits = (
  employeeId: string,
  filters: Partial<EmployeeWalletBenefitsFilters> = {},
) => {
  const query = useQuery({
    queryKey: employeesKeys.detail(employeeId, 'wallet-benefits', filters),
    queryFn: () => loaders.getWalletBenefits({ employeeId, filters }),
    select: converters.getWalletBenefits,
    enabled: !!employeeId,
  });

  return query;
};

export const useEmployeeWalletRecognitions = (employeeId: string) => {
  const query = useQuery({
    queryKey: employeesKeys.detail(employeeId, 'wallet-recognitions'),
    queryFn: () => loaders.getWalletRecognitions({ employeeId }),
    select: converters.getWalletRecognitions,
    enabled: !!employeeId,
  });

  return query;
};
