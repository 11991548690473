import { FC } from 'react';
import { ShopInternalInventoryList } from 'containers/shop';
import { ShopInternalLayout } from 'pages/atoms';

const ShopInternalInventoryPage: FC = () => {
  return (
    <ShopInternalLayout>
      <ShopInternalInventoryList />
    </ShopInternalLayout>
  );
};

export default ShopInternalInventoryPage;
