import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-shop.loaders';
import { adminShopKeys } from './admin-shop.queries';

export const useCreateShopItem = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createShopItem, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopKeys.lists);
    },
  });
};

export const useUpdateShopItem = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateShopItem, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopKeys.all);
    },
  });
};

export const useRemoveShopItem = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeShopItem, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopKeys.lists);
    },
  });
};

export const useImportShopItems = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importShopItems, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopKeys.lists);
    },
  });
};

export const useExportShopItemsTemplate = () => {
  return useMutation(loaders.exportShopItemsTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
