import { ComponentProps } from 'react';
import { SWRConfig } from 'swr';
import { AxiosError } from 'axios';
import { fetcher } from './api';

export const SWROptions: ComponentProps<typeof SWRConfig>['value'] = {
  fetcher,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  errorRetryInterval: 500,
  errorRetryCount: 3,
  onErrorRetry: (
    error: AxiosError,
    _key,
    config,
    revalidate,
    { retryCount },
  ) => {
    // Never retry on:
    // * 400 - retrying with badly formed request will lead to the same result
    // * 404 - it's very likely that non-existing resource will not appear out of the blue
    if (error.response?.status && [400, 404].includes(error.response.status))
      return;

    // Retry up to number of times defined in config
    if (retryCount >= (config.errorRetryCount ?? 0)) return;

    // Retry on specified interval
    setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
  },
};
