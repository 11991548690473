import { z } from 'zod';
import { useSearchParams } from './navigation';

export const guidedSchema = z.object({
  guided: z.enum(['true', 'false']).optional(),
});

export const useGuidedModeParam = () => {
  const { searchParams, navigate } = useSearchParams(guidedSchema);

  return {
    value: searchParams.guided === 'true' ? true : false,
    setValue: (guided: z.infer<typeof guidedSchema>['guided']) => {
      navigate({ params: { guided } });
    },
  };
};
