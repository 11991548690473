import { forwardRef, useState } from 'react';
import { TextField, TextFieldProps } from 'melp-design/components';

// dot or comma is used as a separator only once in the float number
const FLOAT_REGEX = /^([+-]?\d+([.,]\d*)?|-)$/;
// match strings that consist entirely of one or more digits
const INTEGER_REGEX = /^[0-9]+$/;

type ValueType = 'float' | 'integer';

interface NumberInputProps {
  key: number;
  value: number;
  onChange: (value: number) => void;
  type?: ValueType;
}

const REGEX_BY_VALUE_TYPE: Record<ValueType, RegExp> = {
  float: FLOAT_REGEX,
  integer: INTEGER_REGEX,
};

type Props = Omit<
  TextFieldProps,
  'multiline' | 'secure' | 'emoji' | keyof NumberInputProps
> &
  NumberInputProps;

/** Provide stable "key" prop to have value in sync */
export const NumberInput = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, type = 'float', ...textInputProps }, forwardedRed) => {
    const [displayedValue, setDisplayedValue] = useState(
      Number.isNaN(value) ? '' : String(value),
    );

    return (
      <TextField
        value={displayedValue}
        onChange={({ target }) => {
          if (!target.value || REGEX_BY_VALUE_TYPE[type].test(target.value)) {
            setDisplayedValue(target.value);
          }
        }}
        onBlur={({ target }) => {
          const parsed = Number(target.value.replace(',', '.'));
          onChange(parsed);
          setDisplayedValue(String(parsed));
        }}
        {...textInputProps}
        ref={forwardedRed}
      />
    );
  },
);

NumberInput.displayName = 'NumberInput';
