import { z } from 'zod';
import { benefitAssignmentStatuses } from 'store/benefits';
import { paginationFiltersSchema, sortFiltersSchema } from 'utils/schemas';
import { employeeInGroupStatuses } from './employee-groups.types';

export const employeeGroupBenefitsFiltersSchema = z
  .object({
    benefits: z.array(z.string()).catch([]),
    categories: z.array(z.string()).catch([]),
    assignmentStatus: z.enum(benefitAssignmentStatuses).nullable().catch(null),
  })
  .merge(sortFiltersSchema);

export const employeeGroupEmployeesFiltersSchema = z
  .object({
    position: z.array(z.string()).catch([]),
    employees: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    customCategory: z.string().catch(''),
    customCategoryValue: z.array(z.string()).catch([]),
    inGroupStatus: z.enum(employeeInGroupStatuses).nullable().catch(null),
  })
  .merge(paginationFiltersSchema);
