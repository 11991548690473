import { TextField, TimeOffsetPicker } from 'melp-design/components';
import { Close } from 'melp-design/icons';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
  Stack,
} from '@mui/material';
import {
  DatePicker,
  DateTimePickerProps,
  TimePicker,
} from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { extractOffset, removeOffset } from '../../../../utils/date/date';
import { FieldRenderProps, FieldState, InternalFieldRenderer } from '../Types';

interface CustomInputAdornmentProps extends InputAdornmentProps {
  timeOffsetProps?: ComponentProps<typeof TimeOffsetPicker>;
}

const CustomInputAdornment = ({
  timeOffsetProps,
  children,
  ...rest
}: CustomInputAdornmentProps) => (
  <InputAdornment {...rest}>
    {timeOffsetProps && <TimeOffsetPicker {...timeOffsetProps} />}
    {children}
  </InputAdornment>
);

const areEqual = (a: Moment, b: Moment | null) => a.isSame(b);
export interface LocalStateDateTimePickerProps
  extends Omit<
    DateTimePickerProps<Moment>,
    | 'value'
    | 'onChange'
    | 'slots'
    | 'slotProps'
    | 'components'
    | 'componentsProps'
    | 'viewRenderers'
    | 'openTo'
    | 'views'
    | 'view'
  > {
  /**
   * Allows changing the time offset.
   */
  withTimeOffsetPicker?: boolean;
  /**
   * Shows the local time offset of a provided date. If it is set to true, then
   * changing time offset is not allowed.
   */
  showTimeOffset?: boolean;
}

interface Props {
  props: LocalStateDateTimePickerProps;
  field: FieldRenderProps<string | null>;
  fieldState: FieldState;
}

const LocalStateDateTimePicker = ({
  props: {
    withTimeOffsetPicker = false,
    showTimeOffset = false,
    maxDateTime,
    minDateTime,
    ...propsRest
  },
  field: { required, value, onChange, onBlur, label, ...fieldRest },
  fieldState: { invalid, ...fieldStateRest },
}: Props) => {
  const convertValueDateStringToMoment = (valueDateString: string) => {
    const valueToConvert = withTimeOffsetPicker
      ? removeOffset(valueDateString)
      : valueDateString;
    return moment(valueToConvert);
  };

  const [localValue, setLocalValue] = useState(
    value ? convertValueDateStringToMoment(value) : null,
  );
  const timeOffset = (() => {
    if (!value) {
      return;
    }

    if (withTimeOffsetPicker) {
      return extractOffset(value);
    }

    return moment(value).format('Z');
  })();

  const withTimeOffset = withTimeOffsetPicker || showTimeOffset;
  const timePickerRef = useRef<HTMLDivElement>(null);
  const timePickerInputRef = useRef<HTMLInputElement>(null);

  const handleOffsetChange = (newOffset: string) => {
    if (!value) {
      throw new Error('Cannot set time offset for a date that is not defined.');
    }
    const dateWithoutOffset = removeOffset(value);
    onChange(`${dateWithoutOffset}${newOffset}`);
  };

  const handleChange = (newValue: typeof localValue) => {
    if (!newValue) {
      if (!!value) {
        // local date changed to null
        onChange(null);
      }
    } else if (newValue.isValid()) {
      const valueAsMoment = value
        ? convertValueDateStringToMoment(value)
        : null;
      // Check if local date has changed compared to form date
      if (!areEqual(newValue, valueAsMoment)) {
        let newValueString = newValue.toISOString(true);
        // Keep the original offset if it is defined
        if (withTimeOffsetPicker && timeOffset) {
          newValueString = `${removeOffset(newValueString)}${timeOffset}`;
        }
        onChange(newValueString);
      }
    } else {
      setLocalValue(newValue);
    }
  };

  useEffect(() => {
    // both equal to null
    if ((value ?? null) === localValue) {
      return;
    }
    // form state value became null
    if (value === null) {
      setLocalValue(null);
      return;
    }
    const valueAsMoment = convertValueDateStringToMoment(value);
    // form state value is the same as local state value
    if (areEqual(valueAsMoment, localValue)) {
      return;
    }
    // form state value differs from local state value
    setLocalValue(valueAsMoment);
    // this side effect should react only to form date value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const clearDates = () => {
    onChange(null);
    setLocalValue(null);
  };

  const handleInputBlur = () => {
    if (localValue === null || localValue.isValid()) {
      return;
    }
    if (value !== null) {
      onChange(null);
    } else {
      setLocalValue(null);
    }
    onBlur();
  };

  return (
    <FormControl error={invalid}>
      <Stack direction="row" alignItems="center">
        <DatePicker
          slots={{
            // Currently there is no good option to properly override the text field
            textField: TextField as any,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: invalid,
              required,
              onBlur: handleInputBlur,
            },
            layout: {
              onSetToday: () => handleChange(moment()),
            },
          }}
          value={localValue}
          onChange={handleChange}
          onAccept={() => {
            // Focus time picker input when date value is accepted
            const inputElement = timePickerInputRef.current;
            if (inputElement) {
              // A short timeout is required to wait for date input to be focused
              // after a date picker popper is closed.
              setTimeout(() => {
                const savedTabIndex = inputElement.getAttribute('tabindex');
                inputElement.setAttribute('tabindex', '-1');
                inputElement.focus();
                inputElement.setSelectionRange(0, 2);
                if (savedTabIndex) {
                  inputElement.setAttribute('tabindex', savedTabIndex);
                } else {
                  inputElement.removeAttribute('tabindex');
                }
              }, 100);
            }
          }}
          label={label}
          maxDate={maxDateTime}
          minDate={minDateTime}
          sx={{
            flexGrow: 1,

            '.MuiOutlinedInput-input, .MuiOutlinedInput-input::placeholder': {
              textTransform: 'lowercase',
            },

            '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            },

            '.MuiInputLabel-root': {
              paddingRight: '28px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
          {...fieldRest}
          {...propsRest}
        />
        <TimePicker
          slots={{
            // Currently there is no good option to properly override the text field
            textField: TextField as any,
            inputAdornment: CustomInputAdornment,
          }}
          slotProps={{
            textField: {
              error: invalid,
              onBlur: handleInputBlur,
            },
            inputAdornment: {
              timeOffsetProps: withTimeOffset
                ? {
                    value: timeOffset,
                    onChange: handleOffsetChange,
                    disabled:
                      !timeOffset ||
                      !localValue ||
                      !localValue.isValid() ||
                      fieldRest.disabled ||
                      propsRest.disabled ||
                      showTimeOffset,
                  }
                : undefined,
            } as any, // currently there is no good way to extend InputAdornment
          }}
          value={localValue}
          onChange={handleChange}
          thresholdToRenderTimeInASingleColumn={100}
          timeSteps={{ minutes: 30 }}
          skipDisabled
          maxTime={maxDateTime}
          minTime={minDateTime}
          sx={{
            minWidth: withTimeOffset ? 190 : undefined,

            '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
              borderLeft: '0',
            },
          }}
          {...fieldRest}
          ref={timePickerRef}
          inputRef={timePickerInputRef}
        />
        {(!!value || !!localValue) && (
          <IconButton onClick={clearDates} sx={{ ml: '1px' }}>
            <Close />
          </IconButton>
        )}
      </Stack>
      {!!fieldStateRest.message && (
        <FormHelperText>{fieldStateRest.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export const renderDateTimeField: InternalFieldRenderer = (
  props,
  field,
  fieldState,
) => {
  return (
    <LocalStateDateTimePicker
      props={props}
      field={field}
      fieldState={fieldState}
    />
  );
};
