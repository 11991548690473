import { makeStyles } from '@mui/styles';
import { Colors } from '../../../../constants/Style';

export default makeStyles(() => ({
  selectWrapper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fullWidth: {
    width: '100%',
  },
  listItem: {
    width: '100%',
    margin: 0,
    padding: '2px 8px',
  },
  activeRow: {
    backgroundColor: Colors.LightPrimary,
  },
  checkbox: {
    padding: '11px',
  },
}));
