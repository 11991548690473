import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import moment from 'moment';
import {
  AdministratorsFilter,
  DateRangeFilter,
  EmployeesFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import { APP, ROUTES } from 'config';
import { Link, ListPageHeader, ListTotal, Table } from 'melp-design/components';
import { logActions, logsFiltersSchema, useLogs } from 'store/logs';
import { calculateFiltersTotal } from 'utils/Filters';
import { useSearchParams } from 'utils/navigation';
import { LogDescription } from '../atoms';

export const LogsList: FC = () => {
  const { t } = useTranslation();

  const { searchParams: filters, navigate } =
    useSearchParams(logsFiltersSchema);

  const { data: logs, isLoading } = useLogs(filters);

  return (
    <>
      <ListPageHeader
        title={t('sidebar.log')}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={logs?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={logsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                showClearButton={!!calculateFiltersTotal(filters)}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('entity_type.employee')}
                      value={value.employees}
                      initialValue={initialValues.employees}
                      onChange={(v) => setFilter('employees', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <EmployeesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('entity_type.admin')}
                      value={value.admins}
                      initialValue={initialValues.admins}
                      onChange={(v) => setFilter('admins', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdministratorsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.date')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'year']}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.action')}
                      value={value.actions}
                      initialValue={initialValues.actions}
                      onChange={(v) => setFilter('actions', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={logActions.map((action) => ({
                            key: action,
                            label: t(`logs.action.${action}`),
                          }))}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </Filters>
            </Stack>
          </Stack>
        }
      />

      <Table
        data={logs?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'date',
            header: t('common.date'),
            cell: ({ row: { original: log } }) =>
              moment(log.createdAt).format('l LT'),
          },
          {
            id: 'owner',
            header: t('common.done_by'),
            cell: ({ row: { original: log } }) =>
              log.owner?.role === 'employee' ? (
                <Link
                  label={log.owner.name}
                  to={ROUTES.employees.details.replace(':id', log.owner.id)}
                />
              ) : log.owner?.role === 'hrAdmin' ? (
                <Link
                  label={log.owner.name}
                  to={`${ROUTES.administrators.details.replace(
                    ':id',
                    log.owner.id,
                  )}?tab=administratorsInformation`}
                />
              ) : log.owner ? (
                log.owner.name
              ) : (
                '-'
              ),
          },
          {
            id: 'action',
            header: t('common.action'),
            cell: ({ row: { original: log } }) => <LogDescription log={log} />,
          },
        ]}
        pagination={
          logs && {
            count: logs.total,
            page: logs.page,
            pageSize: logs.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
