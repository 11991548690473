import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      paddingTop: '30px',
      paddingBottom: '100px',
    },
    maxWidthLg: {
      maxWidth: 1260,
    },
  }),
);
