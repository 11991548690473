import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { InternalFieldRenderer } from '../Types';

export const renderSwitchField: InternalFieldRenderer = (
  props,
  { label, value, ...field },
  { message, ...fieldState },
) => (
  <FormControl error={fieldState.invalid}>
    <FormControlLabel
      control={<Switch checked={value} {...field} {...props} />}
      label={label}
      sx={{ width: 'fit-content' }}
    />
    {message && <FormHelperText>{message}</FormHelperText>}
  </FormControl>
);
