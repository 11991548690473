import { Typography } from '@mui/material';
import { CellProps } from 'react-table';
import { getRawValue } from '@mp-react/table';
import { useMemo } from 'react';
import moment from 'moment';
import { useDateFormat } from '../../../utils/Common';

export default function TimeRemaining(props: CellProps<{}>) {
  const { data, row } = props;
  const id = useMemo(() => 'votingEndDate', []);
  const { localeLanguage } = useDateFormat();

  const rowIndex = useMemo(() => row.index, [row.index]);
  const deadlineValue = useMemo(
    () => getRawValue(data[rowIndex], id),
    [data, rowIndex, id],
  );

  const formatedDeadlineValue = useMemo(
    () => moment(new Date(deadlineValue), moment.ISO_8601).format(),
    [deadlineValue],
  );

  const timeRemainingValue = useMemo(
    () => moment(formatedDeadlineValue).locale(localeLanguage).fromNow(true),
    [formatedDeadlineValue, localeLanguage],
  );

  const isExpired = useMemo(
    () => moment(new Date(formatedDeadlineValue)).isBefore(),
    [formatedDeadlineValue],
  );

  return (
    <Typography variant="body2">
      {isExpired === false ? timeRemainingValue : 0}
    </Typography>
  );
}
