import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { Search, TabUnselected } from '@mui/icons-material';
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {
  MetaContext,
  MpFilterProps,
  useFilterItems,
  useFilterOptions,
  useStoredState,
} from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CleanIcon } from '../../../../assets/icons/cross.svg';
import useStyles from './FilterNameSearchSelectFilter.styles';

const empty: any[] = [];

export default function FilterNameSearchSelectFilter({
  column,
  value = empty,
  setValue,
  doFiltering,
}: MpFilterProps) {
  const { t } = useTranslation();
  const { mpColumn } = column;
  const { translations } = useContext(MetaContext);
  const [searchText, setSearchText] = useStoredState<string>(
    `custom-search-select-${column.mpColumn.key}`,
    '',
  );
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const {
    loading,
    items: asyncItems,
    getItems,
  } = useFilterItems(column, filter);
  const isMounted = React.useRef(true);
  const key = useMemo(() => mpColumn.key, [mpColumn]);

  const label = useCallback(
    (item: any) => {
      if (key === 'benefitPlanType' || key === 'type') {
        return t(
          `menu.${item?.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()}`,
        );
      } else {
        return item;
      }
    },
    [key, t],
  );

  const normalizeSearchValue = useCallback((value: string) => {
    return value
      .toLowerCase()
      .replaceAll(' ', '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }, []);

  const items = useMemo(() => {
    if (!!asyncItems) {
      if (!!searchText) {
        return asyncItems?.filter((item: any) =>
          normalizeSearchValue(label(item)).includes(
            normalizeSearchValue(searchText),
          ),
        );
      }
      return asyncItems;
    }

    if (!filter?.items) return [];

    return filter?.items;
  }, [asyncItems, filter?.items, label, searchText, normalizeSearchValue]);

  const itemValues = useMemo(() => {
    if (!!asyncItems) {
      return asyncItems;
    }
    return items?.map((i) => i?.value ?? []);
  }, [asyncItems, items]);

  const allSelected = useMemo(
    () =>
      JSON.stringify(
        value?.filter((val: string) => val !== 'all_selected')?.sort(),
      ) === JSON.stringify(itemValues?.sort()),
    [itemValues, value],
  );

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        if (event.target.name === 'all') {
          setValue([...itemValues, 'all_selected']);
        } else if (!value?.includes(event.target.name)) {
          const newValues = [...(value ?? []), event.target.name];
          const allSelected =
            JSON.stringify(newValues?.sort()) ===
            JSON.stringify(itemValues?.sort());
          if (allSelected) setValue([...newValues, 'all_selected']);
          else setValue(newValues);
        }
      } else {
        if (event.target.name === 'all') {
          setValue(null);
        } else {
          setValue(
            value?.filter(
              (val: any) => val !== event.target.name && val !== 'all_selected',
            ),
          );
        }
      }
    },
    [itemValues, setValue, value],
  );

  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText],
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering],
  );

  useEffect(() => {
    if (isMounted.current) {
      getItems();
      return () => {
        isMounted.current = false;
      };
    }
  }, [getItems]);

  useEffect(() => {
    setSearchText('');
  }, [setSearchText]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingY={1}
        paddingX={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height={50}
      >
        <InputBase
          startAdornment={
            <Search
              className={`${classes.searchIcon} ${
                searchText.length > 0 && classes.activeSearchIcon
              }`}
              color="action"
            />
          }
          value={searchText}
          onChange={handleSearchText}
          placeholder={translations?.search ?? 'Search'}
          autoFocus={true}
          className={classes.naked}
        />
        <Box>
          {searchText.length > 0 ? (
            <IconButton onClick={() => setSearchText('')} size="large">
              <CleanIcon />
            </IconButton>
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={handleClear}
              className={classes.emptyAnchor}
            >
              {translations?.clear ?? 'Clear'}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      {!loading && (items?.length ?? 0) > 0 && (
        <Box
          paddingY="10px"
          maxHeight={300}
          overflow="auto"
          className={classes.selectWrapper}
        >
          <FormControl
            component="fieldset"
            color="primary"
            className={classes.fullWidth}
          >
            <FormGroup className={classes.fullWidth}>
              {!searchText && (
                <FormControlLabel
                  key="all-items"
                  onChange={handleChange}
                  name="all"
                  className={`${classes.listItem} ${
                    allSelected && classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSelected}
                      className={classes.checkbox}
                    />
                  }
                  label={`${translations?.all ?? 'All'} (${
                    itemValues?.length
                  })`}
                />
              )}
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={`${item?.value ?? item}`}
                  className={`${classes.listItem} ${
                    !!value?.includes(`${item?.value ?? item}`) &&
                    classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={!!value?.includes(`${item?.value ?? item}`)}
                      className={classes.checkbox}
                    />
                  }
                  label={label(item)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {loading && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (items?.length ?? 0) < 1 && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      )}
    </Box>
  );
}
