import { FC, useState } from 'react';
import { useShopItems } from 'store/shop';
import { useTranslation } from 'react-i18next';
import { useCompanyLanguages } from 'state/Administrators';
import { resolveTranslation } from 'utils/general';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const ShopItemsFilter: FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const [search, setSearch] = useState('');

  const { data: shopItems, isLoading } = useShopItems({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={shopItems?.items.map((item) => ({
        key: item.id,
        label:
          resolveTranslation(i18n.language, defaultLanguage, item.translations)
            ?.title ?? item.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
