import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Button, Typography } from 'melp-design/components';

interface Props {
  title: string;
  backTo?: string;
  under?: ReactNode;
  side?: ReactNode;
}

export const DetailsPageHeader: FC<Props> = ({
  title,
  backTo,
  side,
  under,
}) => {
  const { push, goBack } = useHistory();

  return (
    <Stack
      direction="row"
      alignItems="start"
      gap={2}
      mb={under ? '20px' : '30px'}
    >
      <Button
        variant="neutral-outline"
        size="md"
        icon={ArrowBack}
        onClick={() => {
          backTo ? push(backTo) : goBack();
        }}
      />

      <Stack flexGrow={1} pt="5px">
        <Typography variant="h1">{title}</Typography>
        {under ? <Box pl="1px">{under}</Box> : null}
      </Stack>
      <Box>{side}</Box>
    </Stack>
  );
};
