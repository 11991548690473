import { FC } from 'react';
import { Modal, FileInput, SelectInput } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps, useModalContext } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { useImportVouchersXls } from 'store/admin-shop-vouchers';
import { Stack } from '@mui/material';
import { useAdminShopItem } from 'store/admin-shop';
import { getSinglePriceLabel } from 'components/marketplace/ItemCard/common/getPriceLabel';
import { VouchersImportErrorsModal, VouchersImportWarningsModal } from '..';

interface Values {
  files: File[];
  shopItemPriceId: string;
}

interface Props extends ModalProps {
  shopItemId: string;
  shopItemPriceId?: string;
}

export const ImportVoucherCodesModal: FC<Props> = ({
  shopItemId,
  shopItemPriceId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();

  const { control, handleSubmit } = useForm<Values>({
    values: { files: [], shopItemPriceId: shopItemPriceId ?? '' },
    mode: 'onChange',
  });

  const { data: shopItem } = useAdminShopItem(shopItemId);

  const { mutate: importVouchers, status } = useImportVouchersXls();

  const onSubmit: SubmitHandler<Values> = (values) => {
    importVouchers(
      {
        shopItemId,
        shopItemPriceId: values.shopItemPriceId,
        file: values.files[0],
      },
      {
        onSuccess: ({ data }) => {
          closeModal({ action: 'CLOSE' });

          if (!data.success) {
            openModal({
              component: VouchersImportErrorsModal,
              size: 'lg',
              props: {
                errors: data.errorMessages.map((error) => ({
                  row: error.row,
                  field: error.column,
                  message: error.message,
                })),
                shopItemId,
                shopItemPriceId,
              },
            });
          } else if (data.existingVouchers.length) {
            openModal({
              component: VouchersImportWarningsModal,
              props: {
                duplicates: data.existingVouchers,
              },
            });
          } else {
            predefinedToasts.success.added();
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('shop.vouchers.actions.upload_codes')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('actions.import'),
            disabled: status === 'loading',
            type: 'submit',
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="shopItemPriceId"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <SelectInput
                label={t('marketplace.ordersTablePrice')}
                name={name}
                value={value}
                onChange={onChange}
                options={
                  shopItem?.prices
                    .filter((price) => price.priceType === 'fixedPrice')
                    .map((price) => ({
                      label: getSinglePriceLabel(price, shopItem.currency),
                      value: price.id,
                    })) ?? []
                }
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="files"
            control={control}
            render={({ field: { name, onChange, ref } }) => (
              <FileInput
                name={name}
                onChange={onChange}
                accept={['xls', 'xlsx']}
                maxFiles={1}
                required
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
