import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    timeField: {
      '& .MuiIconButton-root': {
        display: 'none',
      },
    },
    tzButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '11px 15px',
    },
  }),
);
