import { FC, useState } from 'react';
import { Modal, Typography } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import { shopItemCategories } from 'types/MarketplaceItems';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { Stack } from '@mui/material';
import {
  RecognitionRewardsFilters,
  useRecognitionRewards,
  useRemoveRecognitionRewardsConfig,
  useUpdateRecognitionRewardsConfig,
} from 'store/recognition-rewards';
import { calculateFiltersTotal } from 'utils/Filters';
import {
  FilterButton,
  Filters,
  SuppliersFilter,
  SelectFilter,
} from 'components/filters';
import { isEqual } from 'lodash';

type Values = Pick<
  RecognitionRewardsFilters,
  'suppliers' | 'categories' | 'cities' | 'countries'
>;

const INITIAL_VALUES: Values = {
  categories: [],
  countries: [],
  cities: [],
  suppliers: [],
};

interface Props extends ModalProps {
  config: Values;
}

export const RewardsConfigModal: FC<Props> = ({ closeModal, config }) => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const [filters, setFilters] = useState<Values>(config);

  const { data: rewards, isLoading } = useRecognitionRewards(filters);

  const { mutate: updateConfig, isLoading: isUpdating } =
    useUpdateRecognitionRewardsConfig();
  const { mutate: removeConfig, isLoading: isRemoving } =
    useRemoveRecognitionRewardsConfig();

  return (
    <Modal.Content
      title={t('recognitions.rewards.actions.set_config')}
      actions={[
        {
          variant: 'danger-outline',
          onClick: () => {
            removeConfig(undefined, {
              onSuccess: () => {
                closeModal({ action: 'CLOSE' });
                predefinedToasts.success.updated();
              },
            });
          },
          label: t('recognitions.rewards.actions.remove_config'),
          disabled: isRemoving,
        },
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        ...(isEqual(config, filters)
          ? []
          : [
              {
                variant: 'primary',
                onClick: () => {
                  updateConfig(
                    {
                      config: {
                        suppliers: filters.suppliers,
                        categories: filters.categories,
                        countries: filters.countries,
                        cities: filters.cities,
                      },
                    },
                    {
                      onSuccess: () => {
                        closeModal({ action: 'CLOSE' });
                        predefinedToasts.success.updated();
                      },
                    },
                  );
                },
                label: t('marketplace.confirmSettings', {
                  count: rewards?.total,
                }),
                disabled: isLoading || isUpdating,
              } as const,
            ]),
      ]}
    >
      <Stack gap={2}>
        <Typography variant="p1" color="textSecondary">
          {t('recognitions.rewards.config.description')}
        </Typography>

        <Filters
          value={filters}
          initialValues={INITIAL_VALUES}
          onChange={setFilters}
          showClearButton={!!calculateFiltersTotal(filters)}
        >
          {({ value, initialValues, setFilter }) => (
            <>
              <FilterButton
                label={t('marketplaceItems.supplier')}
                value={value.suppliers}
                initialValue={initialValues.suppliers}
                onChange={(v) => setFilter('suppliers', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SuppliersFilter
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('benefits.benefit_type')}
                value={value.categories}
                initialValue={initialValues.categories}
                onChange={(v) => setFilter('categories', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="categories"
                    value={value}
                    multiple
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    options={shopItemCategories.map((category) => ({
                      key: category,
                      label: t(`marketplaceItems.itemCategory-${category}`),
                    }))}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('benefits.country')}
                value={value.countries}
                initialValue={initialValues.countries}
                onChange={(v) => setFilter('countries', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="countries"
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    multiple
                    options={APP.countries.alpha3.map((alpha3) => ({
                      key: alpha3,
                      label: formatCountry(i18n.language, alpha3),
                    }))}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('common.city.label')}
                value={value.cities}
                initialValue={initialValues.cities}
                onChange={(v) => setFilter('cities', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="cities"
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    multiple
                    options={APP.cities.map((city) => ({
                      key: city,
                      label: city,
                    }))}
                  />
                )}
              </FilterButton>
            </>
          )}
        </Filters>
      </Stack>
    </Modal.Content>
  );
};
