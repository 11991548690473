import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
import { AppLanguage } from 'types/general';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import { useAuthContext } from '../auth/react-bindings/AuthContext';
import { UseParentCompany } from '../types/ParentCompany';
import { useLoading } from '../utils/Loading';
import { useMe } from './Administrators';

const initialUseParentCompanyValues: UseParentCompany = {
  parentCompanyLoading: false,
  parentCompanyError: false,
  companyId: '',
  updateDefaultLanguage: () => {},
};

const parentCompanyContext = createContext(initialUseParentCompanyValues);

const useProvideParentCompany = (): UseParentCompany => {
  const { isMelpAdmin } = useMe();
  const { loggedIn } = useAuthContext();
  const canGetParentCompany = useMemo(
    () => loggedIn && !isMelpAdmin,
    [loggedIn, isMelpAdmin],
  );
  const url = useMemo(
    () => (canGetParentCompany ? Endpoints.getOwnCompany : null),
    [canGetParentCompany],
  );
  const { data: parentCompany, error } = useSWR(url);
  const { startLoading, stopLoading, loading } = useLoading();
  const { t } = useTranslation();

  const parentCompanyLoading = useMemo(
    () => (!parentCompany && !error) || loading,
    [error, loading, parentCompany],
  );

  const updateDefaultLanguage = useCallback(
    async (language: AppLanguage) => {
      startLoading();
      await makeRequest('patch', `${Endpoints.updateDefaultLanguage}`, {
        defaultLanguage: language.toUpperCase(),
      })
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(Endpoints.getOwnCompany);
          mutate(Endpoints.me);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t],
  );

  return {
    parentCompany,
    parentCompanyLoading,
    parentCompanyError: error,
    companyId: parentCompany?.id ?? '',
    updateDefaultLanguage,
  };
};

export const ParentCompanyProvider = ({ children }: PropsWithChildren<{}>) => {
  const parentCompany = useProvideParentCompany();
  return (
    <parentCompanyContext.Provider value={parentCompany}>
      {children}
    </parentCompanyContext.Provider>
  );
};

export const useParentCompany = (): UseParentCompany => {
  return useContext(parentCompanyContext);
};
