import { AnchorHTMLAttributes, FC } from 'react';
import { Link as MuiLink } from '@mui/material';

interface Props {
  label: string | number;
  href: string;
  title?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}

export const ExternalLink: FC<Props> = ({
  label,
  href,
  title,
  target = '_blank',
}) => {
  return (
    <MuiLink
      component="a"
      href={href}
      target={target}
      rel="noreferrer"
      sx={{ color: 'inherit' }}
      underline="always"
      title={title}
    >
      {label}
    </MuiLink>
  );
};
