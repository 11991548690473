import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IEmployeeGroupBenefit,
  IEmployeeGroupEmployee,
  IEmployeesGroup,
} from 'types/income';
import { CustomCategory } from 'store/custom-categories';
import {
  EmployeeGroupBenefitsFilters,
  EmployeeGroupEmployeesFilters,
  EmployeesGroupsFilters,
} from './employee-groups.types';

interface GetEmployeesGroups {
  filters: Partial<EmployeesGroupsFilters>;
}

interface GetEmployeeGroupBenefits {
  employeesGroupId: string;
  filters: Partial<EmployeeGroupBenefitsFilters>;
}

interface GetEmployeeGroupEmployees {
  employeesGroupId: string;
  filters: Partial<EmployeeGroupEmployeesFilters>;
}

interface AssignBenefitsToGroup {
  benefits: Array<{
    id?: string;
    benefitPlanId: string;
    employeeGroupId: string;
    fromDate: string;
    status: string;
    toDelete: boolean;
  }>;
}

interface AssignEmployeesToGroup {
  employeesGroupId: string;
  assign?: string[];
  remove?: string[];
}

interface RemoveAllEmployeesFromGroup {
  employeesGroupId: string;
}

export const loaders = {
  getEmployeesGroups: ({ filters }: GetEmployeesGroups) => {
    return api<TableDataResponse<IEmployeesGroup>>({
      url: Endpoints.clientAdmin.employeesGroups.root,
      params: {
        filter: {
          name: filters.name || undefined,
        },
      },
    });
  },
  getEmployeeGroupBenefits: ({
    employeesGroupId,
    filters,
  }: GetEmployeeGroupBenefits) => {
    return api<TableDataResponse<IEmployeeGroupBenefit>>({
      url: Endpoints.clientAdmin.employeesGroups.byId.benefits.root.replace(
        ':employeesGroupId',
        employeesGroupId,
      ),
      params: {
        filter: {
          benefitId: filters.benefits,
          benefitType: filters.categories,
          currentStatus: filters.assignmentStatus,
        },
        sort: filters.sort
          ? `${filters.sort.order === 'desc' ? '-' : ''}${
              filters.sort.columnKey
            }`
          : undefined,
      },
    });
  },
  getEmployeeGroupEmployees: ({
    employeesGroupId,
    filters,
  }: GetEmployeeGroupEmployees) => {
    return api<
      TableDataResponse<IEmployeeGroupEmployee> & {
        customCategories?: Pick<CustomCategory, 'id' | 'name'>[];
      }
    >({
      url: Endpoints.clientAdmin.employeesGroups.byId.employees.root.replace(
        ':employeesGroupId',
        employeesGroupId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          jobTitles: filters.position,
          employeeIds: filters.employees,
          included:
            filters.inGroupStatus === 'included'
              ? true
              : filters.inGroupStatus === 'excluded'
              ? false
              : undefined,
          companyIds: filters.companies,
          departmentIds: filters.departments,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValues: filters.customCategoryValue,
        },
      },
    });
  },
  assignBenefitsToGroup: ({ benefits }: AssignBenefitsToGroup) => {
    return api({
      url: Endpoints.clientAdmin.benefits.byId.employeesGroups.assignments
        .assign,
      data: benefits,
      method: 'post',
    });
  },
  assignEmployeesToGroup: ({
    employeesGroupId,
    assign,
    remove,
  }: AssignEmployeesToGroup) => {
    return api({
      url: Endpoints.clientAdmin.employeesGroups.byId.employees.assign.replace(
        ':employeesGroupId',
        employeesGroupId,
      ),
      data: {
        assignToGroup: assign,
        removeFromGroup: remove,
      },
      method: 'patch',
    });
  },
  removeAllEmployeesFromGroup: ({
    employeesGroupId,
  }: RemoveAllEmployeesFromGroup) => {
    return api({
      url: Endpoints.clientAdmin.employeesGroups.byId.employees.removeAll.replace(
        ':employeesGroupId',
        employeesGroupId,
      ),
      method: 'patch',
    });
  },
};
