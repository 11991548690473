import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientSettings } from 'containers/admin';

const AdminClientSettingsPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientSettings />
    </AdminClientLayout>
  );
};

export default AdminClientSettingsPage;
