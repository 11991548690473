import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { z } from 'zod';
import { logActions } from './logs.types';

export const logsFiltersSchema = z
  .object({
    actions: z.array(z.enum(logActions)).catch([]),
    employees: z.array(z.string()).catch([]),
    admins: z.array(z.string()).catch([]),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);
