import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './recognition-rewards.loaders';
import { recognitionRewardsKeys } from './recognition-rewards.queries';

export const useAddRecognitionReward = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.addReward, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.lists);
    },
  });
};

export const useRemoveRecognitionReward = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeReward, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.lists);
    },
  });
};

export const useAddRecognitionRewards = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.addRewards, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.lists);
    },
  });
};

export const useRemoveRecognitionRewards = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeRewards, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.lists);
    },
  });
};

export const useUpdateRecognitionRewardsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.all);
    },
  });
};

export const useRemoveRecognitionRewardsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionRewardsKeys.all);
    },
  });
};
