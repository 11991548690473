import { IAdministrator } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './administrators.loaders';
import { Administrator } from './administrators.types';

const convertAdministrator = (administrator: IAdministrator): Administrator => {
  return {
    id: administrator.id,
    status: administrator.status,
    email: administrator.email,
    phone: administrator.phone,
    fullName: administrator.fullName,
    parentCompanyId: administrator.parentCompanyId,
  };
};

const convertAdministrators = (
  administrators: IAdministrator[],
): Administrator[] => {
  return administrators.map(convertAdministrator);
};

export const converters = {
  getAdministrators: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAdministrators>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAdministrators(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
