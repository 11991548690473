import { ButtonOld, DropdownMenu, Typography } from 'melp-design/components';
import { Stack } from '@mui/material';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Kebab } from 'melp-design/icons';
import { useBoolean } from '../../../utils/hooks/useBoolean';

interface Props {
  selectedItems?: string[];
  total?: number;
  actions?: Array<{
    label: string;
    onClick?: () => void;
  }>;
  isLoading?: boolean;
}

export const ListTotal: FC<{ total?: number }> = ({ total = 0 }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {t('common.allRowsCount', { count: total })}
    </Typography>
  );
};

export const BulkActions: FC<Props> = ({
  selectedItems = [],
  total = 0,
  actions,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [isOpen, _open, close, toggle] = useBoolean(false);
  const anchorRef = useRef(null);

  if (!selectedItems.length) {
    return (
      <Typography variant="h3">
        {t('common.allRowsCount', { count: total })}
      </Typography>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      <Typography variant="h3" component="span">
        {t('common.selectedRowsCount', {
          count: selectedItems.length,
        })}
      </Typography>

      {actions?.length ? (
        <>
          <ButtonOld
            variant="contained"
            size="small"
            color="primary"
            onClick={toggle}
            loading={isLoading}
            startIcon={<Kebab width={3} />}
            ref={anchorRef}
          >
            {t('common.actions')}
          </ButtonOld>
          <DropdownMenu
            anchorEl={anchorRef.current}
            open={isOpen}
            onClose={close}
            menuItems={actions.map((action) => ({
              key: action.label,
              label: action.label,
              onClick: action.onClick,
            }))}
            onItemClick={({ onClick }) => {
              onClick?.();
              close();
            }}
          />
        </>
      ) : null}
    </Stack>
  );
};
