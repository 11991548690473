import { FC } from 'react';
import { useCustomCategories } from 'store/custom-categories';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, false>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const CustomCategoryFilter: FC<Props> = (props) => {
  const { data: categories, isLoading } = useCustomCategories();

  return (
    <SelectFilter
      {...props}
      options={categories?.map((category) => ({
        key: category.id,
        label: category.name,
      }))}
      loadingOptions={isLoading}
      multiple={false}
    />
  );
};
