import { Stack } from '@mui/material';
import { Modal, NumberInput } from 'melp-design/components';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { useUpdateLowQuantity } from 'store/internal-shop';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';

interface Values {
  lowQuantity: number;
}

interface Props extends ModalProps {
  variantId: string;
  initialLowQuantity: number;
}

export const LowQuantityEditModal: FC<Props> = ({
  variantId,
  initialLowQuantity,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      lowQuantity: initialLowQuantity,
    },
  });

  const { mutate: updateLowQuantity, isLoading } = useUpdateLowQuantity();

  return (
    <form
      onSubmit={handleSubmit(({ lowQuantity }) => {
        updateLowQuantity(
          {
            variantId,
            data: { lowQuantity },
          },
          {
            onSuccess: () => {
              predefinedToasts.success.updated();
              closeModal({ action: 'CLOSE' });
            },
          },
        );
      })}
    >
      <Modal.Content
        title={t('shop.actions.edit_low_quantity')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: isLoading,
          },
        ]}
      >
        <Stack spacing={2}>
          <Controller
            name="lowQuantity"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <NumberInput
                  label={t('shop.lowQuantity.title')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  key={value}
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
