import { MpFormType } from '@mp-react/form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { TooltipControl } from '../../types/Common';

export const useUploadClientCSVForm = (): MpFormType => {
  const { t } = useTranslation();
  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: 'attachment' as any,
        key: 'csvFile',
        required: true,
        label: t('form.csv_file'),
        format: '1',
        messages: {
          required: t('form.required'),
        },
      },
    ],
    [t],
  );

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
