import { createStyles, makeStyles } from '@mui/styles';
import { Colors, Effects } from 'melp-design/style';

export default makeStyles((theme) =>
  createStyles({
    button: {
      padding: '15px 10px',
      margin: '0 -10px',
      borderRadius: 6,
      color: Colors.black,
    },
    buttonInner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'pre',
    },
    buttonIcon: {
      color: Colors.primary,
      marginLeft: 5,
    },
    vAlignCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 10px !important', // To override another !important
      borderRadius: 6,
      transition: theme.transitions.create(['color', 'backGround-color']),
      '&:hover': {
        backGroundColor: Colors.screenBackgroundGrey,
      },
      '&::after': {
        display: 'none',
      },
    },
    paperRoot: {
      minWidth: 220,
      boxShadow: Effects.smallElementsShadow,
      padding: 5,
      marginTop: 10,
    },
  }),
);
