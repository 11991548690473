import { Box, MenuItem, Typography } from '@mui/material';
import { Timezone } from '../../../../types/Common';
import { useTimezones } from '../../../../constants/Timezones';

interface IProps {
  selectedZone: Timezone;
  handleTimezoneChange: (timezone: Timezone) => void;
}

export default function TimezoneMenu(props: IProps) {
  const { selectedZone, handleTimezoneChange } = props;
  const timezones = useTimezones();

  return (
    <Box>
      {timezones?.map((item, i) => (
        <MenuItem
          key={i}
          selected={item.value === selectedZone.value}
          onClick={() => handleTimezoneChange(item)}
        >
          <Typography variant="body2">{item.label}</Typography>
        </MenuItem>
      ))}
    </Box>
  );
}
