import { mutate } from 'swr';
import create from 'zustand';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import {
  Benefit,
  BenefitContent,
  BenefitContentUpdateRequest,
  BenefitCopyRequest,
  BenefitCreationRequest,
  BenefitDeactivationRequest,
  BenefitPublishRequest,
  BenefitResponse,
  BenefitUpdateRequest,
  UseBenefitStore,
} from '../types/Benefits';
import { Status } from '../types/Common';
import { useGet, useCustomSwrMutation } from '../utils/Api';
import { mergeDateTime } from '../utils/Common';

export const useBenefitStore = create<UseBenefitStore>((set) => ({
  status: { name: 'inactive' },
  setStatus: (obj: Status) => set(() => ({ status: obj })),
  formIsResetting: false,
  resetForm: () => {
    set(() => ({ formIsResetting: true }));
    setTimeout(function () {
      set(() => ({ formIsResetting: false }));
    }, 1000);
  },
  publishForm: false,
  setPublishForm: (publish: boolean) => set(() => ({ publishForm: publish })),
  activationDate: null,
  setActivationDate: (dateToSet: string | null, type?: 'date' | 'time') => {
    if (type === 'date' && !!dateToSet) {
      set((state: UseBenefitStore) => ({
        activationDate: mergeDateTime(dateToSet, state.activationDate ?? ''),
      }));
    } else if (type === 'time' && !!dateToSet) {
      set((state: UseBenefitStore) => ({
        activationDate: mergeDateTime(state.activationDate ?? '', dateToSet),
      }));
    } else {
      set(() => ({ activationDate: dateToSet }));
    }
  },
  deactivationDate: null,
  publishDate: null,
  votingStartDate: null,
  votingEndDate: null,
  setDeactivationDate: (
    deactivationDate: string | null,
    type?: 'date' | 'time',
  ) => {
    if (type === 'date' && !!deactivationDate) {
      set((state: UseBenefitStore) => ({
        deactivationDate: mergeDateTime(
          deactivationDate,
          state.deactivationDate ?? '',
        ),
      }));
    } else if (type === 'time' && !!deactivationDate) {
      set((state: UseBenefitStore) => ({
        deactivationDate: mergeDateTime(
          state.deactivationDate ?? '',
          deactivationDate,
        ),
      }));
    } else {
      set(() => ({ deactivationDate }));
    }
  },
  setPublishDate: (publishDate: string | null, type?: 'date' | 'time') => {
    if (type === 'date' && !!publishDate) {
      set((state: UseBenefitStore) => ({
        publishDate: mergeDateTime(publishDate, state.publishDate ?? ''),
      }));
    } else if (type === 'time' && !!publishDate) {
      set((state: UseBenefitStore) => ({
        publishDate: mergeDateTime(state.publishDate ?? '', publishDate),
      }));
    } else set(() => ({ publishDate }));
  },
  setVotingStartDate: (
    votingStartDate: string | null,
    type?: 'date' | 'time',
  ) => {
    if (type === 'date' && !!votingStartDate) {
      set((state: UseBenefitStore) => ({
        votingStartDate: mergeDateTime(
          votingStartDate,
          state.votingStartDate ?? '',
        ),
      }));
    } else if (type === 'time' && !!votingStartDate) {
      set((state: UseBenefitStore) => ({
        votingStartDate: mergeDateTime(
          state.votingStartDate ?? '',
          votingStartDate,
        ),
      }));
    } else set(() => ({ votingStartDate }));
  },
  setVotingEndDate: (votingEndDate: string | null, type?: 'date' | 'time') => {
    if (type === 'date' && !!votingEndDate) {
      set((state: UseBenefitStore) => ({
        votingEndDate: mergeDateTime(votingEndDate, state.votingEndDate ?? ''),
      }));
    } else if (type === 'time' && !!votingEndDate) {
      set((state: UseBenefitStore) => ({
        votingEndDate: mergeDateTime(state.votingEndDate ?? '', votingEndDate),
      }));
    } else set(() => ({ votingEndDate }));
  },
}));

export const useBenefitsMutations = () => {
  const create = useCustomSwrMutation<BenefitCreationRequest, Benefit>(
    Endpoints.clientAdmin.benefitPlans.root,
    'post',
    {
      onSuccess: () =>
        mutate((key) => {
          if (typeof key === 'string') {
            return key.startsWith(Endpoints.clientAdmin.benefitPlans.root);
          }
        }),
    },
  );

  return { create };
};

export const useBenefit = (id: string) => {
  return useGet<BenefitResponse>(
    Endpoints.clientAdmin.benefitPlans.byId(id).root,
  );
};

export const useBenefitMutations = (id: string) => {
  const benefitsUrls = Endpoints.clientAdmin.benefitPlans;
  const benefitUrls = benefitsUrls.byId(id);
  const benefitUrl = benefitUrls.root;

  const update = useCustomSwrMutation<BenefitUpdateRequest, Benefit>(
    benefitUrl,
    'put',
    {
      onSuccess: () => mutate(benefitUrl),
    },
  );

  const remove = useCustomSwrMutation<void, void>(benefitUrl, 'delete', {
    onSuccess: () => {
      mutate(benefitUrl, null, { revalidate: false });
    },
  });

  const patchBenefit = async (patchData: Partial<Benefit>) => {
    const latestBenefitResponse = await makeRequest<BenefitResponse>(
      'get',
      benefitUrl,
    );
    const { id, ...rest } = latestBenefitResponse.data;
    const benefitAfterUpdate = await makeRequest<Benefit, BenefitUpdateRequest>(
      'put',
      benefitUrl,
      {
        ...rest,
        ...patchData,
      },
    );
    return benefitAfterUpdate;
  };

  const publish = useCustomSwrMutation<BenefitPublishRequest, Benefit>(
    benefitUrl,
    'put',
    {
      onSuccess: () => {
        mutate(benefitUrl);
        mutate(benefitUrls.content);
        mutate(benefitUrls.employeeAssignments);
        mutate(benefitUrls.employeeGroupAssignments);
      },
      fetcher: async (_method, _url, data) => {
        return patchBenefit({
          isDraft: false,
          ...data,
        });
      },
    },
  );

  const deactivate = useCustomSwrMutation<BenefitDeactivationRequest, Benefit>(
    benefitUrl,
    'put',
    {
      onSuccess: () => mutate(benefitUrl),
      fetcher: async (_method, _url, data) => {
        return patchBenefit({
          isDraft: false,
          ...data,
        });
      },
    },
  );

  const copy = useCustomSwrMutation<BenefitCopyRequest, Benefit>(
    benefitUrls.copy,
    'post',
    {
      onSuccess: (response) =>
        mutate(benefitsUrls.byId(response.id).root, response),
    },
  );

  return { update, remove, publish, deactivate, copy };
};

export const useBenefitContent = (id: string) => {
  return useGet<BenefitContent>(
    Endpoints.clientAdmin.benefitPlans.byId(id).content,
  );
};

export const useBenefitContentMutations = (id: string) => {
  const contentUrl = Endpoints.clientAdmin.benefitPlans.byId(id).content;
  const update = useCustomSwrMutation<
    BenefitContentUpdateRequest,
    BenefitContent
  >(contentUrl, 'put', {
    onSuccess: (response) => mutate(contentUrl, response),
  });

  return { update };
};
