import { useState } from 'react';
import { APP } from 'config';

interface PaginationHookOptions {
  /**
   * Sets initial page size value.
   */
  pageSize?: number;
}

export const usePagination = (options: PaginationHookOptions = {}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    options.pageSize ?? APP.pagination.defaults.pageSize[0],
  );

  return { page, setPage, pageSize, setPageSize };
};
