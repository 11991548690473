import { BaseShopItem } from 'store/shop';
import { Translations } from 'types/Common';
import { z } from 'zod';
import {
  internalShopFiltersSchema,
  internalShopInventoryEventsFiltersSchema,
  internalShopInventoryFiltersSchema,
} from './internal-shop.schemas';

export type InternalShopItemTranslations = Translations<{
  title: string;
  description: string;
}>;

export const purchaseLimitVariants = [
  'none',
  'perItem',
  'perItemVariant',
] as const;
export type PurchaseLimitVariant = (typeof purchaseLimitVariants)[number];

export const purchaseLimitPeriods = ['monthly', 'quarterly', 'yearly'] as const;
export type PurchaseLimitPeriod = (typeof purchaseLimitPeriods)[number];

export interface InternalShopItem extends BaseShopItem {
  prices: Array<{
    id: string;
    price: number;
    inventory: number;
    translations: Translations<{ description: string }>;
  }>;
  trackInventory: boolean;
  purchaseLimitVariant: PurchaseLimitVariant;
  purchaseLimitPeriod: PurchaseLimitPeriod;
  purchaseLimit: number;
  notifyOnOrder: boolean;
}

export interface InternalShopInventoryItem {
  id: string;
  name: string;
  itemId: string;
  itemName: string;
  remainingInventory: number;
  lowQuantityAlert: number;
  trackInventory: boolean;
}

export const inventoryEventVariants = [
  'increase',
  'decrease',
  'monthlyCount',
] as const;
export type InventoryEventVariant = (typeof inventoryEventVariants)[number];

export interface InternalShopInventoryEvent {
  id: string;
  variant: InventoryEventVariant;
  inventoryChange: number;
  doneBy: {
    id: string;
    name: string;
    email: string;
  } | null;
  comment: string;
  createdAt: string;
}

export type InternalShopFilters = z.infer<typeof internalShopFiltersSchema>;

export type InternalShopInventoryFilters = z.infer<
  typeof internalShopInventoryFiltersSchema
>;

export type InternalShopInventoryEventsFilters = z.infer<
  typeof internalShopInventoryEventsFiltersSchema
>;
