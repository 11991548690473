import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from './../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    root: {
      '& .react-tel-input': {
        width: '100%',
        fontFamily: "'Inter', sans-serif",
        height: 44,
        '& .form-control': {
          paddingLeft: 14,
          paddingTop: 12,
          paddingBottom: 13,
          paddingRight: 40,
          height: '100%',
          width: '100%',
          borderRadius: 6,
          '&:active': {
            borderWidth: 1,
            borderColor: Colors.Primary,
          },
          '&:focus': {
            borderWidth: 1,
            borderColor: Colors.Primary,
          },
          '&:hover': {
            borderColor: '#ccd2d9',
          },
        },
        '& .flag-dropdown': {
          background: 'none',
          border: 'none',
          right: 0,
          pointerEvents: 'none',
          '& .selected-flag': {
            '&:hover': {
              background: 'none',
              border: 'none',
            },
          },
          '& .arrow': {
            display: 'none',
          },
        },
      },
    },
    error: {
      '& .react-tel-input .form-control': {
        borderColor: Colors.Error,
      },
    },
  }),
);
