import { Img } from 'melp-design/icons';
import { Colors, Effects } from 'melp-design/style';
import { Paper } from '@mui/material';
import { useFile } from 'store/files';

interface Props {
  imageId: string;
}

const AchievementImage = ({ imageId }: Props) => {
  const { data: file } = useFile(imageId);

  return (
    <Paper
      variant="outlined"
      sx={{
        width: 42,
        height: 42,
        boxShadow: Effects.smallElementsShadow,
        color: Colors.grey,
        display: 'grid',
        placeItems: 'center',

        img: {
          width: 40,
          height: 40,
          objectFit: 'cover',
          borderRadius: '7px',
        },
      }}
    >
      {file ? <img src={file.url} alt={file.name} /> : <Img />}
    </Paper>
  );
};

export default AchievementImage;
