import { z } from 'zod';
import { BenefitCategory } from 'store/benefits';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { ShopItem } from 'store/shop';
import { recognitionRewardsFiltersSchema } from './recognition-rewards.schemas';

export const recognitionRewardsVariants = [
  'all',
  'selected',
  'not-selected',
] as const;
export type RecognitionRewardsVariant =
  (typeof recognitionRewardsVariants)[number];

export interface RecognitionRewardsConfig {
  name: string;
  price: {
    from: number;
    to: number;
  } | null;
  suppliers: string[];
  items: string[];
  countries: string[];
  cities: string[];
  benefitCategories: BenefitCategory[];
  categories: ShopItemCategory[];
}

export interface RecognitionReward extends ShopItem {
  isWhitelisted: boolean;
  autoAssigned: boolean;
}

export type RecognitionRewardsFilters = z.infer<
  typeof recognitionRewardsFiltersSchema
>;
