import { FC } from 'react';
import {
  ListTotal,
  Menu,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useSearchParams } from 'utils/navigation';
import {
  adminDiscountCodesFiltersSchema,
  useAdminDiscountCodes,
  useExportXlsTemplate,
  useUpdateDiscountCodesStatus,
  COLOR_BY_STATUS,
} from 'store/admin-discount-codes';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import moment from 'moment';
import { usePredefinedToasts } from 'utils/Toast';
import { ImportDiscountCodesModal } from './atoms';

export const AdminDiscountCodesList: FC = () => {
  const { t } = useTranslation();
  const { id: discountId } = useRouteParams(
    ROUTES.admin.discounts.details.codes,
  );
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();
  const { openModal } = useModalContext();
  const predefinedToasts = usePredefinedToasts();
  const { searchParams: filters, navigate } = useSearchParams(
    adminDiscountCodesFiltersSchema,
  );

  const { data: codes, isLoading } = useAdminDiscountCodes(discountId, filters);

  const { mutate: exportXlsTemplate, isLoading: isExportingXlsTemplate } =
    useExportXlsTemplate();
  const { mutate: updateStatus, isLoading: isUpdatingStatus } =
    useUpdateDiscountCodesStatus();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <ListTotal total={codes?.total} />

          <Menu
            label={t('common.actions')}
            items={[
              {
                label: t('shop.vouchers.actions.upload_codes'),
                onClick: () => {
                  openModal({
                    component: ImportDiscountCodesModal,
                    props: {
                      discountId,
                    },
                  });
                },
              },
              {
                label: t('global.actions.export_template'),
                onClick: () => {
                  exportXlsTemplate();
                },
                disabled: isExportingXlsTemplate,
              },
            ]}
          />
        </Stack>
      </TableToolbar>

      <Table
        isLoading={isLoading}
        data={codes?.items ?? []}
        columns={[
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: code } }) => (
              <Typography color={COLOR_BY_STATUS[code.status]}>
                {t(`status.${code.status}`)}
              </Typography>
            ),
          },
          {
            id: 'createdAt',
            header: t('marketplaceOrders.orderDate'),
            cell: ({ row: { original: code } }) =>
              moment(code.createdAt).format('l LT'),
          },
          {
            id: 'expiresAt',
            header: t('alternatives.employeeExpirationDate'),
            cell: ({ row: { original: code } }) =>
              code.expiresAt ? moment(code.expiresAt).format('l') : '',
          },
          {
            id: 'code',
            header: t('shop.vouchers.redeem.code'),
            cell: ({ row: { original: code } }) => code.code,
          },
          {
            id: 'revealedAt',
            header: t('discounts.revealedAt.label'),
            cell: ({ row: { original: code } }) =>
              code.revealedAt ? moment(code.revealedAt).format('l LT') : '',
          },
          {
            id: 'employeeId',
            header: t('entity_type.employee'),
            cell: ({ row: { original: code } }) => code.employeeId,
          },
        ]}
        pagination={
          codes && {
            count: codes.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
        selection={{
          value: selectedRowIds,
          onChange: setSelectedRowIds,
          actions: [
            {
              label: t('global.actions.mark_as_archived'),
              onClick: () => {
                updateStatus(
                  { status: 'archived', codes: Object.keys(selectedRowIds) },
                  {
                    onSuccess: () => {
                      predefinedToasts.success.updated();
                      setSelectedRowIds({});
                    },
                  },
                );
              },
            },
            {
              label: t('global.actions.mark_as_expired'),
              onClick: () => {
                updateStatus(
                  { status: 'expired', codes: Object.keys(selectedRowIds) },
                  {
                    onSuccess: () => {
                      predefinedToasts.success.updated();
                      setSelectedRowIds({});
                    },
                  },
                );
              },
            },
          ],
          isLoading: isUpdatingStatus,
        }}
      />
    </TableWrapper>
  );
};
