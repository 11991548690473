import { IShopItem } from 'types/income';
import { BenefitCategory } from 'store/benefits';
import { PriceVariant, ShopItemStatus } from 'store/shop';
import { ContentLanguage } from 'types/general';
import { TableDataResponse } from './Table';
import { Currency } from './general/currencies';

export interface MarketplaceItem extends IShopItem {}

export const shopItemCategories = [
  'automotive_and_car_services',
  'books_and_media',
  'children_goods_and_toys',
  'donation',
  'education_and_learning',
  'electronics_and_computers',
  'entertainment',
  'fashion_and_apparel',
  'films_music_games',
  'food_and_groceries',
  'furniture_and_home_interior',
  'gyms_and_wellness',
  'health_and_beauty',
  'home_garden_and_diy',
  'insurance',
  'medical_services_and_goods',
  'pets',
  'sport_leisure_and_tourism',
  'supermarkets',
  'transportation_and_travel',
] as const;
export type ShopItemCategory = (typeof shopItemCategories)[number];

interface SingleMarketplaceItemResponse extends MarketplaceItem {
  autoAssigned?: boolean;
}

export type MarketplaceItemsResponse =
  TableDataResponse<SingleMarketplaceItemResponse>;

interface MarketplaceItemCreationRequestBody {
  name: string;
  imageId?: string;
  currency: Currency;
  vatRate: number;
  countries: string[];
  benefitPlanTypes: BenefitCategory[];
  categories: ShopItemCategory[];
  status: ShopItemStatus;
  price: number;
}

export interface MarketplaceItemUpdateRequestBody
  extends Partial<MarketplaceItemCreationRequestBody> {}

export interface MarketplaceItemTranslationCreationRequestBody {
  language: string;
  title: string;
  description: string;
}

export interface MarketplaceItemTranslationUpdateRequestBody
  extends MarketplaceItemTranslationCreationRequestBody {}

interface BenefitMarketplaceItem extends MarketplaceItem {
  assignment?: {
    autoAssigned?: boolean;
    assignmentDate?: string;
  };
}

export type BenefitMarketplaceItemsResponse =
  TableDataResponse<BenefitMarketplaceItem>;

export interface MarketplaceItemsFilterParams {
  name?: string;
  priceFrom?: number;
  priceTo?: number;
  countries?: string[];
  cities?: string[];
  benefitPlanTypes?: BenefitCategory[];
  categories?: ShopItemCategory[];
  itemIds?: string[];
  supplierIds?: string[];
}
export interface MarketplaceItemsParams extends MarketplaceItemsFilterParams {
  sort?: MarketplaceItemsSort;
  page?: number;
}

export type MarketplaceItemsSort = 'price' | '-price';

interface MarketplaceItemWithSelection extends MarketplaceItem {
  inSelection: boolean;
}

export interface MarketplaceItemsWithSelectionResponse {
  data: MarketplaceItemWithSelection[];
  count: number;
  page: number;
  pageSize: number;
}

export interface AutoAssignmentFilterResponse {
  assignmentFilter: MarketplaceItemsFilterParams;
  benefitPlanId: string;
  creationDate: string;
  id: string;
  lastModificationDate: string;
}

export interface MarketplaceItemPrice {
  creationDate: string;
  discountPercentage: number;
  id: string;
  lastModificationDate: string;
  marketplaceItemId: string;
  price: number | null;
  priceRangeFrom: number | null;
  priceRangeTo: number | null;
  priceType: PriceVariant;
}

export type MarketplaceItemPricesResponse = MarketplaceItemPrice[];

export interface MarketplaceItemPriceCreationRequest {
  priceType: PriceVariant;
  price: number | null;
  priceRangeFrom: number | null;
  priceRangeTo: number | null;
  discountPercentage: number | null;
}

export type MarketplaceItemPriceUpdateRequest =
  MarketplaceItemPriceCreationRequest;

export interface PriceTranslation {
  creationDate: string;
  description: string;
  id: string;
  language: Uppercase<ContentLanguage>;
  lastModificationDate: string;
  marketplaceItemPriceId: string;
}

export type PriceTranslationsResponse = PriceTranslation[];

export interface PriceTranslationCreationRequest {
  language: string;
  description: string;
}

export type PriceTranslationUpdateRequest = PriceTranslationCreationRequest;
