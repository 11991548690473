import { MetaContext, useFilterOptions } from '@mp-react/table';
import { MpFilterProps } from '@mp-react/table/build/types/Filter';
import {
  Box,
  Divider,
  InputBase,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { browserLanguage } from '../../../../utils/Common';
import useStyles from './DateRange.styles';

function InputBaseWithProps({
  InputProps,
  margin,
  onKeyDown,
  onKeyUp,
  helperText,
  ...props
}: TextFieldProps) {
  return <InputBase {...(InputProps as any)} {...props} />;
}

export default function DateRange({
  value,
  setValue,
  doFiltering,
  column,
}: MpFilterProps) {
  const classes = useStyles();
  const { mpColumn } = column;
  const filter = useFilterOptions(mpColumn);
  const { translations } = useContext(MetaContext);
  const [minPickerStatus, setMinPickerStatus] = useState<boolean>(false);
  const [maxPickerStatus, setMaxPickerStatus] = useState<boolean>(false);

  const handleChangeMin = useCallback(
    (date: moment.Moment | null) => {
      const evVal = moment(date).startOf('day');
      if (evVal != null) {
        setValue((val: any) => ({
          ...(val ?? {}),
          from: evVal.toISOString(),
        }));
      } else {
        setValue((val: any) =>
          val?.max != null && val?.max !== '' ? { to: val?.max } : null,
        );
      }
    },
    [setValue],
  );

  const handleChangeMax = useCallback(
    (date: moment.Moment | null) => {
      const evVal = moment(date).endOf('day');
      if (evVal != null) {
        setValue((val: any) => ({
          ...(val ?? {}),
          to: evVal.toISOString(),
        }));
      } else {
        setValue((val: any) =>
          val?.min != null && val?.min !== '' ? { from: val?.from } : null,
        );
      }
    },
    [setValue],
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering],
  );

  const valueFrom = useMemo(
    () => (!!value?.from ? moment(value?.from).locale(browserLanguage) : null),
    [value?.from],
  );

  const valueTo = useMemo(
    () => (!!value?.to ? moment(value?.to).locale(browserLanguage) : null),
    [value?.to],
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={browserLanguage}
    >
      <Box display="flex" flexDirection="column">
        <Box
          paddingLeft={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">{filter?.label ?? ''}</Typography>
          <Box paddingX={2} paddingY={1.5}>
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={handleClear}
              className={classes.emptyAnchor}
            >
              {translations?.clear ?? 'Clear'}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box paddingY={1} paddingX={2}>
          <DatePicker
            slots={{
              textField: InputBaseWithProps,
            }}
            slotProps={{
              textField: {
                InputProps: { readOnly: true },
                fullWidth: true,
                onClick: () => setMinPickerStatus(true),
                sx: {
                  '.MuiInputBase-input, .MuiInputBase-input::placeholder': {
                    textTransform: 'lowercase',
                  },
                },
              },
            }}
            value={valueFrom}
            onChange={handleChangeMin}
            maxDate={valueTo ?? undefined}
            onClose={() => setMinPickerStatus(false)}
            open={minPickerStatus}
          />
        </Box>
        <Divider />
        <Box paddingY={1} paddingX={2}>
          <DatePicker
            slots={{
              textField: InputBaseWithProps,
            }}
            slotProps={{
              textField: {
                InputProps: { readOnly: true },
                fullWidth: true,
                onClick: () => setMaxPickerStatus(true),
                sx: {
                  '.MuiInputBase-input, .MuiInputBase-input::placeholder': {
                    textTransform: 'lowercase',
                  },
                },
              },
            }}
            value={valueTo}
            onChange={handleChangeMax}
            minDate={valueFrom ?? undefined}
            onClose={() => setMaxPickerStatus(false)}
            open={maxPickerStatus}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
