import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-client-deposits.loaders';
import { converters } from './admin-client-deposits.converters';
import {
  AdminClientCompanyBalanceLogsFilters,
  AdminClientDepositsFilters,
  AdminDepositEventsFilters,
} from './admin-client-deposits.types';

export const adminClientDepositsKeys = queryKeyFactory('admin-client-deposits');

export const useAdminClientDeposits = (
  clientId: string,
  filters: Partial<AdminClientDepositsFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('deposits', clientId, filters),
    queryFn: () => loaders.getDeposits({ clientId, filters }),
    select: (data) => converters.getDeposits(data, filters.sort),
    enabled: !!clientId,
    keepPreviousData: true,
  });
};

export const useAdminClientCompanyBalanceLogs = (
  companyId: string,
  filters: Partial<AdminClientCompanyBalanceLogsFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('balance-logs', companyId, filters),
    queryFn: () => loaders.getCompanyBalanceLogs({ companyId, filters }),
    select: converters.getCompanyBalanceLogs,
    enabled: !!companyId,
    keepPreviousData: true,
  });
};

export const useAdminDepositEvents = (
  filters: Partial<AdminDepositEventsFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('deposit-events', filters),
    queryFn: () => loaders.getDepositEvents({ filters }),
    select: converters.getDepositEvents,
    keepPreviousData: true,
  });
};
