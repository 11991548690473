import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    input: {
      '& .MuiIconButton-root': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.Error,
          },
        },
      },
    },
    readonly: {
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
        {
          borderColor: Colors.BorderOutlinedInput,
          borderLeft: 'none',
        },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        background: 'unset',
        overflow: 'unset',
      },
    },
    tzButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '11px 15px',
    },
  }),
);
