import { ICustomCategory, IEmbeddedCustomCategory } from 'types/income';
import {
  CustomCategory,
  EmbeddedCustomCategory,
} from './custom-categories.types';
import { loaders } from './custom-categories.loaders';

const convertCustomCategory = (category: ICustomCategory): CustomCategory => {
  return {
    id: category.id,
    name: category.name,
    values:
      category.values?.map((value) => ({
        id: value.id,
        name: value.name,
      })) ?? [],
  };
};

export const convertCustomCategories = (
  categories: ICustomCategory[],
): CustomCategory[] => {
  return categories.map(convertCustomCategory);
};

const convertEmbeddedCustomCategory = (
  category: IEmbeddedCustomCategory,
): EmbeddedCustomCategory => {
  return {
    id: category.id,
    name: category.name,
    value: {
      id: category.valueId,
      name: category.value,
    },
  };
};

export const convertEmbeddedCustomCategories = (
  categories: IEmbeddedCustomCategory[],
): EmbeddedCustomCategory[] => {
  return categories.map(convertEmbeddedCustomCategory);
};

export const converters = {
  getCustomCategories: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCustomCategories>>) => {
    return convertCustomCategories(data.data);
  },
};
