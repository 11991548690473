import { useState, useCallback } from 'react';

/**
 * A reusable hook for managing boolean state.
 *
 * @param initialState - The initial state value (default: false).
 * @returns - A tuple containing the current state value
 *    and functions to manipulate the state.
 *   - state: The current boolean state value.
 *   - setTrue: A function to set the state to true.
 *   - setFalse: A function to set the state to false.
 *   - toggle: A function to toggle the state between true and false.
 */
export const useBoolean = (
  initialState = false,
): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const setTrue = useCallback(() => {
    setState(true);
  }, []);

  const setFalse = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return [state, setTrue, setFalse, toggle];
};
