import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { useLoading } from '../../utils/Loading';

type AuthActionHandler<D, R> = (requestData: D) => Promise<R>;
type AuthErrorHandler = (
  e: unknown,
  defaultHandler: (e: unknown) => void,
) => void;

export const useAuthAction = <D, R>(
  handleAction: AuthActionHandler<D, R>,
  handleError?: AuthErrorHandler,
) => {
  const { t } = useTranslation();
  const requestState = useLoading();

  const defaultErrorHandler = (e: unknown) => {
    toast.error(getErrorMessage(e) || t('login.loginFailed'));
  };

  return {
    loading: requestState.loading,
    execute: async (requestData: D) => {
      requestState.startLoading();
      try {
        const result = await handleAction(requestData);
        return result;
      } catch (e) {
        if (handleError) {
          handleError(e, defaultErrorHandler);
        } else {
          defaultErrorHandler(e);
        }
      } finally {
        requestState.stopLoading();
      }
    },
  };
};
