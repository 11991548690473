import { z } from 'zod';
import {
  numberRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { benefitCategories } from 'store/benefits';
import { shopItemCategories } from 'types/MarketplaceItems';
import { recognitionRewardsVariants } from './recognition-rewards.types';

export const recognitionRewardsFiltersSchema = z
  .object({
    name: z.string().catch(''),
    items: z.array(z.string()).catch([]),
    countries: z.array(z.string()).catch([]),
    cities: z.array(z.string()).catch([]),
    suppliers: z.array(z.string()).catch([]),
    price: numberRangeSchema,
    benefitCategories: z.array(z.enum(benefitCategories)).catch([]),
    categories: z.array(z.enum(shopItemCategories)).catch([]),
    variant: z.enum(recognitionRewardsVariants).catch('all'),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
