import { z } from 'zod';
import {
  dateRangeSchema,
  numberRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import {
  benefitCategories,
  benefitPublishStatuses,
  benefitStatuses,
} from './benefits.types';

export const benefitsFiltersSchema = z
  .object({
    name: z.array(z.string()).catch([]),
    benefits: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    category: z.array(z.enum(benefitCategories)).catch([]),
    status: z.array(z.enum(benefitStatuses)).catch([]),
    publishStatus: z.array(z.enum(benefitPublishStatuses)).catch([]),
    endsAt: dateRangeSchema,
    employeeCount: numberRangeSchema,
    investmentAmount: numberRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
