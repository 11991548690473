import { forwardRef } from 'react';
import clsx from 'clsx';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Colors } from '../../style/Colors';

interface StyleParams {
  textColor?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '11px 20px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5',
    borderRadius: 6,
    whiteSpace: 'nowrap',
    '&$iconOnly svg:not(.MuiCircularProgress-svg)': {
      height: 12,
      width: 'auto',
    },
    height: 'auto',
    color: Colors.black,
  },
  small: {
    padding: '6px 10px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.5',
  },
  large: {
    padding: '15px 30px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5',
    borderRadius: 10,
    '& svg:not(.MuiCircularProgress-svg)': {
      height: 14,
      width: 'auto',
    },
  },
  iconOnly: {
    padding: 8,
    '& > svg': {
      height: 14,
      width: 14,
    },
  },
  containedPrimary: {
    color: Colors.white,
    backgroundColor: Colors.primary,
  },
  containedDanger: {
    color: Colors.white,
    backgroundColor: Colors.red,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  customTextColor: (params: StyleParams) => ({
    color: params.textColor,
  }),
  outlined: {
    borderColor: Colors.elementsGrey,
    padding: '10px 20px',
    '&:hover': {
      borderColor: Colors.greyHover,
      backgroundColor: Colors.white,
    },
    '&$iconOnly': {
      padding: 7,
    },
  },
  outlinedSizeSmall: {
    padding: '5px 10px',
  },
  outlinedSizeLarge: {
    padding: '14px 30px',
  },
  outlinedPrimary: {
    borderColor: Colors.primary,
    color: Colors.primary,
    '&:hover': {
      borderColor: Colors.blueHover,
      color: Colors.blueHover,
      backgroundColor: Colors.primaryLight,
    },
  },
  outlinedDanger: {
    borderColor: Colors.red,
    color: Colors.red,
  },
  containedDefault: {},
  outlinedDefault: {},
}));

interface Props extends Omit<MuiButtonProps, 'color'> {
  /**
   * Use when button contains only an icon. It makes it square instead of
   * rectangle.
   */
  iconOnly?: boolean;
  /**
   * Button color
   */
  color?: MuiButtonProps['color'] | 'danger';
  /**
   * Button text color
   */
  textColor?: keyof typeof Colors;
  /**
   * Displays button in loading state
   */
  loading?: boolean;
  /**
   * Sets minimal width for the button
   */
  minWidth?: number | string;
}

const Button = forwardRef(
  (
    {
      variant = 'outlined',
      iconOnly = false,
      color = 'info',
      textColor,
      loading,
      disabled,
      children,
      minWidth = 0,
      ...props
    }: Props,
    ref: any,
  ) => {
    const classes = useStyles({ textColor: textColor && Colors[textColor] });
    const getSpinnerSize = () => {
      const isLarge = props.size === 'large';
      if (iconOnly) {
        return isLarge ? 14 : 12;
      }
      return isLarge ? 21 : 18;
    };
    return (
      <MuiButton
        {...props}
        color={color !== 'danger' ? color : undefined}
        disabled={disabled || loading}
        ref={ref}
        variant={variant}
        classes={{
          root: clsx(classes.root, {
            [classes.iconOnly]: iconOnly,
            [classes.containedDanger]:
              variant === 'contained' && color === 'danger',
            [classes.outlinedDanger]:
              variant === 'outlined' && color === 'danger',
            [classes.customTextColor]: !!textColor,
          }),
          sizeSmall: classes.small,
          sizeLarge: classes.large,
          containedPrimary: classes.containedPrimary,
          outlinedPrimary: classes.outlinedPrimary,
          outlined: classes.outlined,
          outlinedSizeSmall: classes.outlinedSizeSmall,
          outlinedSizeLarge: classes.outlinedSizeLarge,
        }}
        sx={{ minWidth, ...props.sx }}
      >
        {loading ? <CircularProgress size={getSpinnerSize()} /> : children}
      </MuiButton>
    );
  },
);
Button.displayName = 'Button';

export type ButtonProps = Props;
export default Button;
