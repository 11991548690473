import { Alert as MuiAlert } from '@mui/material';
import { ReactNode } from 'react';
import { Info } from '../../icons';

const iconMapping = {
  success: <Info />,
  info: <Info />,
  warning: <Info />,
  error: <Info />,
};

interface Props {
  severity?: 'success' | 'info' | 'warning' | 'error';
  /**
   * Content to display inside alert panel.
   */
  children: ReactNode;
  /**
   * Callback to execute when close button is clicked. When this callback is
   * not provided, close button is not displayed.
   */
  onClose?: () => void;
}

const Alert = ({ severity = 'info', ...props }: Props) => {
  return <MuiAlert {...props} severity={severity} iconMapping={iconMapping} />;
};

export default Alert;
