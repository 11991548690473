import { CardActionArea as MuiCardActionArea } from '@mui/material';
import { styled } from '@mui/system';

const CardActionArea = styled(MuiCardActionArea)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export default CardActionArea;
