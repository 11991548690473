import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
  Typography,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import {
  COLOR_BY_PUBLISH_STATUS,
  useAdminDiscount,
} from 'store/admin-discounts';
import { DiscountActions } from 'containers/admin';

export const AdminDiscountLayout: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { id } = useRouteParams(ROUTES.admin.discounts.details.settings);

  const { data: discount, isInitialLoading } = useAdminDiscount(id);

  return (
    <Stack>
      <DetailsPageHeader
        title={
          isInitialLoading
            ? ''
            : discount
            ? discount.name
            : t('discounts.actions.create')
        }
        under={
          discount ? (
            <Typography color={COLOR_BY_PUBLISH_STATUS[discount.publishStatus]}>
              {t(`status.${discount.publishStatus}`)}
            </Typography>
          ) : undefined
        }
        side={
          discount ? (
            <DiscountActions id={discount.id} name={discount.name} />
          ) : null
        }
        backTo={ROUTES.admin.discounts.list}
      />

      <DetailsPageContent>
        <SideMenu
          tabs={[
            {
              key: 'settings',
              label: t('discounts.details.title'),
              url: ROUTES.admin.discounts.details.settings.replace(':id', id),
              isActive:
                path === ROUTES.admin.discounts.details.settings ||
                path === ROUTES.admin.discounts.create,
              disabled: !discount,
            },
            ...(discount
              ? [
                  {
                    key: 'codes',
                    label: t('discounts.codes.title'),
                    url: ROUTES.admin.discounts.details.codes.replace(
                      ':id',
                      id,
                    ),
                    isActive: path === ROUTES.admin.discounts.details.codes,
                  },
                ]
              : []),
          ]}
        />
        {children}
      </DetailsPageContent>
    </Stack>
  );
};
