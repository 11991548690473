import { FC } from 'react';
import {
  Button,
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Tile,
} from 'melp-design/components';
import { Divider, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSearchParams } from 'utils/navigation';
import {
  adminClientCompanyBalanceLogsFiltersSchema,
  useAdminClientCompanyBalanceLogs,
  useAdminClientDeposits,
  useExportToXlsBalanceLogs,
} from 'store/admin-client-deposits';
import { formatCurrency, formatPercents } from 'utils/format';
import { APP, ROUTES } from 'config';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import TextFilter from 'components/filters/TextFilter';
import {
  AdministratorsFilter,
  DateRangeFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import { useRouteParams } from 'utils/useIdParam';
import { expenseTypes } from 'store/expenses';
import { useModalContext } from 'store/modal';
import { AdminClientDepositsActions, EditDepositModal } from '../atoms';

export const AdminClientCompanyBalanceLogsList: FC = () => {
  const { t, i18n } = useTranslation();
  const { id, companyId } = useRouteParams(
    ROUTES.admin.clients.details.balanceLogs.details,
  );
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    adminClientCompanyBalanceLogsFiltersSchema,
  );

  const { data: balanceLogs, isLoading } = useAdminClientCompanyBalanceLogs(
    companyId,
    filters,
  );
  const { data: deposits } = useAdminClientDeposits(id);

  const { mutate: exportToXls, isLoading: isExportingToXls } =
    useExportToXlsBalanceLogs();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack direction="row" alignItems="center" gap="15px">
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
              </>
            ) : balanceLogs ? (
              <>
                <Tile
                  title={t('clients.deposits.balance')}
                  items={[
                    formatCurrency(i18n.language, balanceLogs.meta.balance),
                  ]}
                />
                <Tile
                  title={t('clients.deposits.credit_used')}
                  items={[
                    formatCurrency(i18n.language, balanceLogs.meta.creditUsed),
                  ]}
                  label={formatPercents(
                    i18n.language,
                    balanceLogs.meta.creditUsed / balanceLogs.meta.credit,
                  )}
                />
                <Tile
                  title={t('clients.deposits.credit_remaining')}
                  items={[
                    formatCurrency(
                      i18n.language,
                      balanceLogs.meta.creditRemaining,
                    ),
                  ]}
                  label={formatPercents(
                    i18n.language,
                    balanceLogs.meta.creditRemaining / balanceLogs.meta.credit,
                  )}
                />
              </>
            ) : null}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={balanceLogs?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={adminClientCompanyBalanceLogsFiltersSchema.parse(
                  {},
                )}
                onChange={(v) => navigate({ params: v })}
                keysToExtractFromTotalCalculation={['search']}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('common.date')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'quarter', 'year']}
                        />
                      )}
                    </FilterButton>
                    <FilterButton
                      label={t('integrations.type')}
                      value={value.type}
                      initialValue={initialValues.type}
                      onChange={(v) => setFilter('type', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={expenseTypes.map((type) => ({
                            key: type,
                            label: t(`expenses.filters.type.options.${type}`),
                          }))}
                        />
                      )}
                    </FilterButton>
                    <FilterButton
                      label={t('entity_type.admin')}
                      value={value.admins}
                      initialValue={initialValues.admins}
                      onChange={(v) => setFilter('admins', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdministratorsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>

              <TextFilter
                value={filters.search}
                onApplyFilter={(v) => navigate({ params: { search: v } })}
                placeholder={t('common.search')}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={balanceLogs?.total ?? 0}
                exportToExcel={() =>
                  exportToXls({ clientId: id, companyId, filters })
                }
                loading={isExportingToXls}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <AdminClientDepositsActions
                clientId={id}
                companyId={companyId}
                credit={
                  deposits?.items.find((c) => c.id === companyId)
                    ?.creditLimit ?? NaN
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={balanceLogs?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'date',
            header: t('common.date'),
            cell: ({ row: { original: balanceLog } }) => {
              return moment(balanceLog.date).format('l');
            },
          },
          {
            id: 'type',
            header: t('integrations.type'),
            cell: ({ row: { original: balanceLog } }) => {
              return t(`expenses.filters.type.options.${balanceLog.type}`);
            },
          },
          {
            id: 'depositChange',
            header: t('clients.deposits.deposit_change.label'),
            cell: ({ row: { original: balanceLog } }) => {
              return formatCurrency(i18n.language, balanceLog.depositChange);
            },
            meta: { align: 'right' },
          },
          {
            id: 'remainingBalance',
            header: t('benefitExpenses.remaining'),
            cell: ({ row: { original: balanceLog } }) => {
              return formatCurrency(i18n.language, balanceLog.remainingBalance);
            },
            meta: { align: 'right' },
          },
          {
            id: 'owner',
            header: t('common.done_by'),
            cell: ({ row: { original: balanceLog } }) => {
              return balanceLog.owner ? (
                <Link
                  label={balanceLog.owner.name}
                  to={ROUTES.admin.clients.details.administrators.details
                    .replace(':clientId', id)
                    .replace(':adminId', balanceLog.owner.id)}
                />
              ) : null;
            },
          },
          {
            id: 'invoiceNumber',
            header: t('common.invoice_number.label'),
            cell: ({ row: { original: balanceLog } }) => {
              return balanceLog.invoiceNumber;
            },
          },
          {
            id: 'comment',
            header: t('clients.comment'),
            cell: ({ row: { original: balanceLog } }) => {
              return balanceLog.comment;
            },
          },
          {
            id: 'actions',
            cell: ({ row: { original: balanceLog } }) => {
              return balanceLog.type === 'deposit' ? (
                <Button
                  label={t('common.edit')}
                  variant="neutral-outline"
                  size="sm"
                  onClick={() => {
                    openModal({
                      component: EditDepositModal,
                      props: {
                        depositId: balanceLog.id,
                        initialValues: {
                          amount: balanceLog.depositChange,
                          comment: balanceLog.comment,
                          date: balanceLog.date,
                          invoice: balanceLog.invoiceNumber,
                        },
                      },
                    });
                  }}
                />
              ) : null;
            },
          },
        ]}
        pagination={
          balanceLogs && {
            count: balanceLogs.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
