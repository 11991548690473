import { QueryClient, QueryCache } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      // onError: (error) => {
      //   console.error(error);
      // },
    },
  },
  queryCache: new QueryCache({
    // onError: (error, { queryKey }) => {
    //   console.error(error, queryKey.join("|"));
    // },
  }),
});

export const queryKeyFactory = (rootKey: string) => ({
  all: [rootKey] as const,
  lists: [rootKey, 'list'] as const,
  list: (...args: any[]) => [rootKey, 'list', ...args] as const,
  details: [rootKey, 'detail'] as const,
  detail: (id: string | number, ...args: any[]) =>
    [rootKey, 'detail', id, ...args] as const,
});
