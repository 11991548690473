import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';

interface Props extends LinearProgressProps {
  value: number;
}

const ProgressBar = (props: Props) => {
  const progressValue = Math.min(props.value, 100);
  const progressLabel = `${Math.round(props.value)}%`;
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={35} mr={1} textAlign="right">
        <Typography variant="body2" color="textSecondary">
          {progressLabel}
        </Typography>
      </Box>
      <Box width="100%">
        <LinearProgress
          {...props}
          value={progressValue}
          variant="determinate"
        />
      </Box>
    </Box>
  );
};

export default ProgressBar;
