import { Stack } from '@mui/material';
import {
  ShopInternalItemDetails,
  ShopInternalDisabledFeatureAlert,
} from 'containers/shop';
import { FC } from 'react';

const ShopInternalItemDetailsPage: FC = () => {
  return (
    <Stack sx={{ maxWidth: 850, margin: 'auto' }} gap={3}>
      <ShopInternalDisabledFeatureAlert />

      <ShopInternalItemDetails />
    </Stack>
  );
};

export default ShopInternalItemDetailsPage;
