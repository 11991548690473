import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAdminShopVoucher, IVouchersImportXls } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { exportXlsxConfig, getFormData } from 'utils/general';
import {
  AdminShopVouchersFilters,
  RedeemMethods,
  VoucherStatus,
} from './admin-shop-vouchers.types';

interface GetShopVouchers {
  shopItemId: string;
  filters: Partial<AdminShopVouchersFilters>;
}

interface GetShopVoucher {
  voucherId: string;
}

interface EditShopVoucher {
  voucherId: string;
  data: Partial<
    {
      status: VoucherStatus;
      purchasePrice: number;
      purchaseReference: string;
      shopItemPriceId: string;
      shopOrderId: string;
      expiresAt: string | null;
    } & RedeemMethods
  >;
}

interface ExportVouchersXls {
  voucherIds: string[];
}

interface ImportVouchersXls {
  shopItemId: string;
  shopItemPriceId: string;
  file: File;
}

interface ImportVouchersFiles {
  shopItemId: string;
  shopItemPriceId: string;
  purchasePrice: number;
  purchaseReference: string;
  expiresAt: string | null;
  files: File[];
}

interface UpdateStatus {
  vouchers: string[];
  status: VoucherStatus;
}

export const loaders = {
  getShopVouchers: ({ shopItemId, filters }: GetShopVouchers) => {
    return api<TableDataResponse<IAdminShopVoucher>>({
      url: Endpoints.melpAdmin.shopVouchers.root.replace(
        ':shopItemId',
        shopItemId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        search: filters.search || undefined,
      },
    });
  },
  getShopVoucher: ({ voucherId }: GetShopVoucher) => {
    return api<IAdminShopVoucher>({
      url: Endpoints.melpAdmin.shopVouchers.byId.replace(
        ':voucherId',
        voucherId,
      ),
    });
  },
  updateShopVoucher: ({ voucherId, data }: EditShopVoucher) => {
    return api({
      url: Endpoints.melpAdmin.shopVouchers.byId.replace(
        ':voucherId',
        voucherId,
      ),
      method: 'patch',
      data: {
        status: data.status,
        qrCode: data.qrCode,
        barCode: data.barCode,
        serial: data.serial,
        url: data.url,
        code: data.code,
        pin: data.pin,
        purchasePrice: data.purchasePrice,
        purchaseReference: data.purchaseReference,
        marketplaceItemPriceId: data.shopItemPriceId,
        marketplaceOrderId: data.shopOrderId,
        expirationDate: data.expiresAt,
      },
    });
  },
  updateStatus: ({ vouchers, status }: UpdateStatus) => {
    return api({
      url: Endpoints.melpAdmin.shopVouchers.status,
      method: 'patch',
      data: {
        voucherIds: vouchers,
        status,
      },
    });
  },
  exportXlsTemplate: () => {
    return api({
      url: Endpoints.melpAdmin.shopVouchers.import.xls,
      ...exportXlsxConfig,
    });
  },
  exportVouchersXls: ({ voucherIds }: ExportVouchersXls) => {
    return api({
      url: Endpoints.melpAdmin.shopVouchers.export.xls,
      params: {
        voucherIds,
      },
      ...exportXlsxConfig,
    });
  },
  importVouchersXls: ({
    shopItemId,
    shopItemPriceId,
    file,
  }: ImportVouchersXls) => {
    return api<IVouchersImportXls>({
      url: Endpoints.melpAdmin.shopVouchers.import.xls,
      method: 'post',
      data: getFormData([
        { field: 'file', value: file },
        { field: 'marketplaceItemId', value: shopItemId },
        { field: 'marketplaceItemPriceId', value: shopItemPriceId },
      ]),
    });
  },
  importVouchersFiles: ({
    shopItemId,
    shopItemPriceId,
    purchasePrice,
    purchaseReference,
    expiresAt,
    files,
  }: ImportVouchersFiles) => {
    return api({
      url: Endpoints.melpAdmin.shopVouchers.import.files,
      method: 'post',
      data: getFormData([
        ...files.map((file) => ({ field: 'files', value: file })),
        { field: 'marketplaceItemId', value: shopItemId },
        { field: 'marketplaceItemPriceId', value: shopItemPriceId },
        { field: 'purchaseValue', value: String(purchasePrice) },
        { field: 'purchaseReference', value: purchaseReference },
        ...(expiresAt ? [{ field: 'expirationDate', value: expiresAt }] : []),
      ]),
    });
  },
};
