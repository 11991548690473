import { MpFormType, MpControlType } from '@mp-react/form';
import { useTranslation } from 'react-i18next';
import { TooltipControl } from '../../types/Common';

export const useIntegrationTypeForm = (): MpFormType => {
  const { t } = useTranslation();

  const controls: TooltipControl[] = [
    {
      type: MpControlType.text,
      key: 'name',
      label: t('integrations.name'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      type: MpControlType.multiline,
      key: 'comment',
      label: t('clients.comment'),
      validations: {
        maxLength: 100,
      },
    },
    {
      type: 'image' as MpControlType,
      key: 'logoId',
      label: t('integrations.logo'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      type: MpControlType.text,
      key: 'type',
      label: t('integrations.type'),
      messages: {
        required: t('form.required'),
      },
      required: true,
      validations: {
        pattern: '^([a-z]|\\d|-)+$',
      },
    },
  ];

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
