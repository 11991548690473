import { Quill, Range } from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import './quill-overrides.css';

Quill.register('modules/emoji', Emoji);
Quill.register('modules/imageResize', ImageResize);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['0.75em', false, '1.5em', '2.5em'];
Quill.register(Size, true);

// Setting default block element to div instead of p to comply with WYSIWYG
// approach. Specifically, the problem with the tag p is that it leads to
// unwanted vertical spaces in emails.
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

interface QuillInstanceHolder {
  quill: typeof Quill.prototype;
}

export const quillModules = {
  toolbar: {
    container: [
      [{ size: ['2.5em', '1.5em', false, '0.75em'] }],
      ['bold', 'italic'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'emoji', 'image'],
      ['clean'],
    ],
    handlers: {
      emoji() {},
    },
  },
  imageResize: {
    modules: ['Resize', 'DisplaySize'],
  },
  'emoji-toolbar': true,
  'emoji-shortname': true,
  clipboard: {
    matchVisual: false, // Do not add an empty line before list
  },
  // Initial code taken from https://github.com/quilljs/quill/issues/979#issuecomment-1076249000
  // It was adjusted to support more use cases
  keyboard: {
    bindings: {
      indent: {
        key: 9,
        format: ['blockquote', 'indent', 'list'],
        handler(this: QuillInstanceHolder, range: NonNullable<Range>) {
          // We want to disable the indentation if:
          // - (1) The current line is the first line and the indent level is 0 (not indented)
          // - (2) The current line is a list and the previous line is not a list
          // - (3) The current line is a list and the previous line too, but the previous lines indentation level is already one level lower
          // - (4) The current line is a list and the previous line is different type of list

          const currentLineFormats = this.quill.getFormat(range.index);
          const previousLineFormats = this.quill.getFormat(range.index - 1);
          const currentLineIsTheFirstLine = range.index === 0;
          const currentLineIsAList = currentLineFormats.list !== undefined;
          const previousLineIsAList = previousLineFormats.list !== undefined;
          const currentLineIndent = currentLineFormats.indent || 0;
          const previousLineIndent = previousLineFormats.indent || 0;

          if (
            (currentLineIsTheFirstLine && currentLineIndent === 0) ||
            (currentLineIsAList && !previousLineIsAList) ||
            (currentLineIsAList &&
              previousLineIsAList &&
              previousLineIndent === currentLineIndent - 1) ||
            (currentLineIsAList &&
              previousLineIsAList &&
              currentLineFormats.list !== previousLineFormats.list)
          ) {
            return;
          }

          this.quill.format('indent', '+1', 'user');
        },
      },
    },
  },
};
