import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import {
  InternalShopFilters,
  InternalShopInventoryEventsFilters,
  InternalShopInventoryFilters,
} from './internal-shop.types';
import { loaders } from './internal-shop.loaders';
import { converters } from './internal-shop.converters';

export const internalShopKeys = queryKeyFactory('internal-shop');

export const useInternalShopItems = (
  filters: Partial<InternalShopFilters> = {},
) => {
  return useQuery({
    queryKey: internalShopKeys.list(filters),
    queryFn: () => loaders.getInternalShopItems({ filters }),
    select: converters.getInternalShopItems,
  });
};

export const useInternalShopItem = (itemId: string) => {
  return useQuery({
    queryKey: internalShopKeys.detail(itemId),
    queryFn: () => loaders.getInternalShopItem({ itemId }),
    select: converters.getInternalShopItem,
    enabled: !!itemId,
  });
};

export const useInternalShopInventoryItems = (
  filters: Partial<InternalShopInventoryFilters> = {},
) => {
  return useQuery({
    queryKey: internalShopKeys.list('inventory', filters),
    queryFn: () => loaders.getInternalShopInventoryItems({ filters }),
    select: converters.getInternalShopInventoryItems,
  });
};

export const useInternalShopInventoryEvents = (
  variantId: string,
  filters: Partial<InternalShopInventoryEventsFilters> = {},
) => {
  return useQuery({
    queryKey: internalShopKeys.list('inventory', variantId, filters),
    queryFn: () =>
      loaders.getInternalShopInventoryEvents({ variantId, filters }),
    select: converters.getInternalShopInventoryEvents,
    enabled: !!variantId,
  });
};
