import React, { useMemo } from 'react';
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from '@mp-react/form';
import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assets/icons/arrow-right.svg';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import { CustomFormControl } from '../../../types/Common';
import useStyles from './Select.styles';

function Select({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  error,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: string | null;
  name: string;
  inputRef: React.MutableRefObject<any>;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const options = useMemo(() => control?.children ?? [], [control]);
  const placeholder = useMemo(() => control?.placeholder, [control]);
  const id = useMemo(() => control?.key, [control]);
  const selectedOption = options.find((option) => option.key === value);
  const disabled =
    (control as CustomFormControl).readonly ||
    (control as CustomFormControl).disabled;

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControl
        id={`select-${id}`}
        variant={variant ?? 'outlined'}
        fullWidth={true}
        required={control.required}
        className={classes.formControl}
        error={!!error}
        disabled={disabled}
      >
        {placeholder && (
          <InputLabel className={classes.placeholder} id={id}>
            {placeholder}
          </InputLabel>
        )}
        <MuiSelect
          value={selectedOption?.key ?? ''}
          onChange={onChange}
          labelId={placeholder && id}
          inputRef={inputRef}
          name={name}
          disabled={disabled}
        >
          {options.map((option: any) => (
            <MenuItem
              key={option.key}
              value={option.key ?? ''}
              disabled={option.disabled}
            >
              {option.type === 'link' ? (
                <Link to="/" className={classes.link}>
                  {option.label} <LinkIcon />{' '}
                </Link>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </MuiSelect>
        <FormHelperText>
          {error?.type
            ? control?.messages?.[error.type as ValidationKeys]
            : undefined}
        </FormHelperText>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function SelectControl({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value, name, ref }) => (
        <Select
          name={name}
          inputRef={ref}
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
