import { FC } from 'react';
import { AdminShopVouchersList } from 'containers/admin';
import { AdminShopItemLayout } from 'pages/atoms/admin-shop-item-layout';

const AdminShopItemVouchersPage: FC = () => {
  return (
    <AdminShopItemLayout>
      <AdminShopVouchersList />
    </AdminShopItemLayout>
  );
};

export default AdminShopItemVouchersPage;
