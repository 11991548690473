import { DeleteIconButton, EditIconButton } from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteConfirmationDialog from 'components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useRecognitionAllowanceRuleMutations } from 'state/Recognition';
import {
  RecognitionAllowanceRulesItem,
  UpdateRecognitionAllowanceRuleRequest,
} from 'types/Recognition';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';
import AllowanceRuleDialog from './AllowanceRuleDialog';

interface Props {
  ruleData: RecognitionAllowanceRulesItem;
}

const AllowanceRuleActions = (props: Props) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const allowanceRuleMutations = useRecognitionAllowanceRuleMutations(
    props.ruleData.id,
  );

  const updateDialog = useDialog();
  const handleUpdate = async (
    requestData: UpdateRecognitionAllowanceRuleRequest,
  ) => {
    await allowanceRuleMutations.update.execute(requestData);
    predefinedToasts.success.updated();
    updateDialog.closeDialog();
  };

  const removeConfirmation = useDialog();
  const handleRemove = async () => {
    await allowanceRuleMutations.remove.execute();
    predefinedToasts.success.deleted();
    removeConfirmation.closeDialog();
  };

  return (
    <Stack direction="row" gap="10px" justifyContent="end">
      <EditIconButton onClick={updateDialog.openDialog} />
      <AllowanceRuleDialog
        open={updateDialog.open}
        onClose={updateDialog.closeDialog}
        title={t('recognition.updateAllowanceRule')}
        initialValues={props.ruleData}
        submitButtonTitle={t('common.save')}
        onSubmit={handleUpdate}
        loading={allowanceRuleMutations.update.loading}
      />
      <DeleteIconButton onClick={removeConfirmation.openDialog} />
      <DeleteConfirmationDialog
        open={removeConfirmation.open}
        onCancel={removeConfirmation.closeDialog}
        onConfirm={handleRemove}
        loading={allowanceRuleMutations.remove.loading}
        content={t('recognition.removeAllowanceRuleWarning', {
          groups: props.ruleData.employeeGroups
            .map((employeeGroup) => employeeGroup.name)
            .join(', '),
        })}
      />
    </Stack>
  );
};

export default AllowanceRuleActions;
