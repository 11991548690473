import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { ICustomCategory } from 'types/income';
import { CustomCategory } from './custom-categories.types';

interface CreateCustomCategory {
  name: string;
}

interface CreateCustomCategoryValue {
  categoryId: string;
  value: string;
}

export const loaders = {
  getCustomCategories: () => {
    return api<{ data: ICustomCategory[] }>({
      url: Endpoints.clientAdmin.employees.customCategories.root,
    });
  },
  createCustomCategory: ({ name }: CreateCustomCategory) => {
    return api<CustomCategory>({
      url: Endpoints.clientAdmin.employees.customCategories.create,
      data: { name },
      method: 'post',
    });
  },
  createCustomCategoryValue: ({
    categoryId,
    value,
  }: CreateCustomCategoryValue) => {
    return api<{ id: string; name: string }>({
      url: `${Endpoints.clientAdmin.employees.customCategories.byId.values.replace(
        ':categoryId',
        categoryId,
      )}/create`,
      data: { name: value },
      method: 'post',
    });
  },
};
