import { createStyles, makeStyles } from '@mui/styles';
import { Shadows } from '../../../constants/Style';

export default makeStyles((theme) =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vw',
      height: '70px',
      boxShadow: Shadows.TableFooter,
      transition: theme.transitions.create('transform'),
      zIndex: 2,
    },
  }),
);
