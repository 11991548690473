export class FuzzySearch {
  private searchText: string;

  constructor(searchText: string) {
    this.searchText = this.normalizeValue(searchText.toLowerCase());
  }

  search = (item: string): boolean => {
    if (!this.searchText) {
      return true;
    }
    const normalizedItem = this.normalizeValue(item.toLowerCase());
    let searchPosition = 0;
    for (let i = 0; i < normalizedItem.length; i++) {
      if (normalizedItem[i] === this.searchText[searchPosition]) {
        searchPosition++;
        if (searchPosition === this.searchText.length) {
          return true;
        }
      }
    }
    return false;
  };

  private normalizeValue = (value: string) => {
    return value
      .toLowerCase()
      .replaceAll(' ', '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };
}
