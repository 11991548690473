import { FC } from 'react';
import {
  Button,
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP, ROUTES } from 'config';
import {
  COLOR_BY_STATUS,
  internalShopFiltersSchema,
  useInternalShopItems,
} from 'store/internal-shop';
import { useSearchParams } from 'utils/navigation';

export const ShopInternalItemsList: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { searchParams: filters, navigate } = useSearchParams(
    internalShopFiltersSchema,
  );

  const { data: shopItems, isLoading } = useInternalShopItems(filters);

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="15px"
          >
            <Typography variant="p1" color="textSecondary" maxWidth="75%">
              {t('shop.internal.description')}
            </Typography>
            <Button
              label={t('marketplaceItems.addMarketplaceItem')}
              onClick={() => history.push(ROUTES.shop.internal.items.create)}
            />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={shopItems?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              {/* Filters */}
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={shopItems?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('common.name'),
            cell: ({ row: { original: shopItem } }) => {
              return (
                <Link
                  label={shopItem.name}
                  to={ROUTES.shop.internal.items.details.replace(
                    ':id',
                    shopItem.id,
                  )}
                />
              );
            },
          },
          {
            id: 'variants',
            header: t('shop.variants'),
            cell: ({ row: { original: shopItem } }) => {
              return shopItem.prices.length;
            },
            meta: { align: 'right' },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: shopItem } }) => {
              return (
                <Typography color={COLOR_BY_STATUS[shopItem.status]}>
                  {t(`status.${shopItem.status}`)}
                </Typography>
              );
            },
            meta: { align: 'right' },
          },
        ]}
        pagination={
          shopItems && {
            count: shopItems.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
