import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './suppliers.loaders';
import { converters } from './suppliers.converters';
import { SuppliersFilters } from './suppliers.types';

export const suppliersKeys = queryKeyFactory('suppliers');

export const useSuppliers = (filters: Partial<SuppliersFilters> = {}) => {
  return useQuery({
    queryKey: suppliersKeys.list(filters),
    queryFn: () => loaders.getSuppliers({ filters }),
    select: converters.getSuppliers,
  });
};
