export const timeUnits = ['hours', 'days', 'months', 'years'] as const;
export type TimeUnit = (typeof timeUnits)[number];

export const frequencyUnits = [
  'hourly',
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'yearly',
  'lifetime',
] as const;
export type FrequencyUnit = (typeof frequencyUnits)[number];
