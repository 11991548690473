import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { exportXlsxConfig } from 'utils/general';
import { AdminReportsFilters } from './admin-reports.types';

interface ExportExpiredBenefits {
  filters: Partial<AdminReportsFilters>;
}

interface ExportSearchAttempts {
  filters: Partial<AdminReportsFilters>;
}

export const loaders = {
  exportExpiredBenefits: ({ filters }: ExportExpiredBenefits) => {
    return api({
      url: Endpoints.melpAdmin.reports.export.expiredBenefits,
      params: {
        filter: {
          deactivationDateFrom: filters.date?.from,
          deactivationDateTo: filters.date?.to,
        },
      },
      ...exportXlsxConfig,
    });
  },
  exportSearchAttempts: ({ filters }: ExportSearchAttempts) => {
    return api({
      url: Endpoints.melpAdmin.reports.export.searchAttempts,
      params: {
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
        },
      },
      ...exportXlsxConfig,
    });
  },
  exportShopItemsContent: () => {
    return api({
      url: Endpoints.melpAdmin.reports.export.shopItemsContent,
      ...exportXlsxConfig,
    });
  },
  exportShopItemsPriceSettings: () => {
    return api({
      url: Endpoints.melpAdmin.reports.export.shopItemsPriceSettings,
      ...exportXlsxConfig,
    });
  },
};
