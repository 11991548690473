import { FC } from 'react';
import { ShopInternalItemsList } from 'containers/shop';
import { ShopInternalLayout } from 'pages/atoms';

const ShopInternalItemsPage: FC = () => {
  return (
    <ShopInternalLayout>
      <ShopInternalItemsList />
    </ShopInternalLayout>
  );
};

export default ShopInternalItemsPage;
