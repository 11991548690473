import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders as benefitsLoaders } from 'store/benefits';
import { ExpensesFilters, ExpenseSourcesFilters } from './expenses.types';
import { loaders } from './expenses.loaders';
import { converters } from './expenses.converters';

export const expensesKeys = queryKeyFactory('expenses');

export const useExpenses = (filters: Partial<ExpensesFilters> = {}) => {
  const query = useQuery({
    queryKey: expensesKeys.list(filters),
    queryFn: () => loaders.getExpenses({ filters }),
    select: converters.getExpenses,
  });

  return query;
};

export const useExpenseSources = (
  filters: Partial<ExpenseSourcesFilters> = {},
) => {
  const query = useQuery({
    queryKey: expensesKeys.list('sources', filters),
    queryFn: () =>
      benefitsLoaders.getLiteBenefits({
        filters: { name: [filters.name ?? ''] },
      }),
    select: (data) => converters.getSources(data, filters.name ?? ''),
  });

  return query;
};
