import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAdministrator } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { AdministratorsFilters } from './administrators.types';

interface GetAdministrators {
  filters: Partial<AdministratorsFilters>;
}

export const loaders = {
  getAdministrators: ({ filters }: GetAdministrators) => {
    return api<TableDataResponse<IAdministrator>>({
      url: Endpoints.clientAdmin.administrators.root,
      params: {
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
        },
      },
    });
  },
};
