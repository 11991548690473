import { ComponentProps, FC } from 'react';
import { Link, Stack } from '@mui/material';
import { Button, Typography } from 'melp-design/components';
import { useFile } from 'store/files';

interface Props {
  id: string;
  actions?: Array<ComponentProps<typeof Button>>;
}

export const FileLink: FC<Props> = ({ id, actions }) => {
  const { data: file, isLoading } = useFile(id);

  if (isLoading || !file) {
    return <Typography component="span">{id}</Typography>;
  }

  const fileLink = (
    <Link
      component="a"
      href={file.url}
      underline="always"
      target="_blank"
      rel="noreferrer"
    >
      {file.name}
    </Link>
  );

  return actions?.length ? (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      {fileLink}
      {actions.map((action, i) => (
        <Button {...action} size="sm" key={i} />
      ))}
    </Stack>
  ) : (
    fileLink
  );
};
