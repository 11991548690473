import { CognitoUser } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { Auth } from 'aws-amplify';
import { APP } from 'config';
import { AuthService, PasswordLoginResult } from '../api/AuthService';
import { UserDataMissingException } from './UserDataMissingException';

export class CognitoAuthService implements AuthService {
  private msal: PublicClientApplication;
  private userForChallenge?: CognitoUser;
  private emailForReset?: string;

  constructor() {
    // Setup instance for Microsoft Authentication Library.
    this.msal = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
      },
    });

    // Setup AWS Amplify. Currently it is used only for authentication.
    Amplify.configure({
      Auth: {
        region: APP.cognito.region,
        userPoolId: APP.cognito.poolId,
        userPoolWebClientId: APP.cognito.webClientId,
      },
    });

    // Bind these methods to an instance to prevent "this" context loss.
    this.completeNewPassword = this.completeNewPassword.bind(this);
    this.verifySMSCode = this.verifySMSCode.bind(this);
    this.loginWithMicrosoft = this.loginWithMicrosoft.bind(this);
  }

  async loginWithPassword(
    email: string,
    password: string,
  ): Promise<PasswordLoginResult> {
    const response = await Auth.signIn(email.trim().toLowerCase(), password);
    this.userForChallenge = response;

    return {
      ...response,
      challengeDestination: response.challengeParam?.CODE_DELIVERY_DESTINATION,
    };
  }

  async signInWithMSEmail(email: string) {
    try {
      const user = await Auth.signIn(email);
      return user;
    } catch (e) {
      if (e instanceof Error && e.name === 'UserNotFoundException') {
        e.message += `\n${email}`;
      }
      throw e;
    }
  }

  async loginWithMicrosoft(): Promise<void> {
    /* MS sign in */
    const authResult = await this.msal.loginPopup({
      scopes: ['openid', 'profile', 'User.Read'],
      prompt: 'select_account',
    });

    /* Cognito sign in */
    const username = authResult.account?.username?.toLowerCase();
    if (!username) {
      throw Error('Username is missing');
    }

    // Remove previously created device key to prevent authorization error.
    // See MIN-2633 for more details.
    const cacheKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}.${username}.deviceKey`;
    const deviceKey = window.localStorage.getItem(cacheKey);
    if (deviceKey) {
      window.localStorage.removeItem(cacheKey);
    }

    const token = authResult.idToken;

    // 1st request to Cognito - we initiate sign in without a password, which starts the custom authentication flow
    const initialUser = await this.signInWithMSEmail(username);

    if (
      initialUser.challengeName === 'CUSTOM_CHALLENGE' &&
      initialUser.challengeParam.challengeType === 'SELECT_CHALLENGE'
    ) {
      // 2nd request to Cognito - we instruct Cognito that we would like to login via MICROSOFT_SSO challenge
      const userAfterChallengeSelection = await Auth.sendCustomChallengeAnswer(
        initialUser,
        'SELECT_CHALLENGE',
        { challengeType: 'MICROSOFT_SSO' },
      );

      // 3rd request to Cognito - we send the Microsoft token for verification
      if (
        userAfterChallengeSelection.challengeParam.challengeType ===
        'MICROSOFT_SSO'
      ) {
        const userAfterChallengeAnswer = await Auth.sendCustomChallengeAnswer(
          userAfterChallengeSelection,
          token,
          { challengeType: 'MICROSOFT_SSO' },
        );

        return userAfterChallengeAnswer;
      }
    }

    throw new Error('Unsupported flow in Microsoft login');
  }

  async logout(): Promise<void> {
    await Auth.signOut();

    // local logout (clears the cache)
    await this.msal.logoutRedirect({
      onRedirectNavigate: () => {
        return false;
      },
    });
  }

  async changePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<void> {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, currentPassword, newPassword);
  }

  async verifySMSCode(code: string): Promise<void> {
    if (!this.userForChallenge) {
      throw new UserDataMissingException();
    }

    const requiredCodeLength = 6;

    if (code.length !== requiredCodeLength) {
      throw new Error(
        `Code length is incorrect. It should be ${requiredCodeLength}, but a code with length ${code.length} was provided`,
      );
    }

    await Auth.confirmSignIn(this.userForChallenge, code);

    // Clear user as it should be used only once per authentication flow
    this.userForChallenge = undefined;
  }

  async completeNewPassword(password: string): Promise<PasswordLoginResult> {
    if (!this.userForChallenge) {
      throw new UserDataMissingException();
    }

    const response = await Auth.completeNewPassword(
      this.userForChallenge,
      password,
    );
    this.userForChallenge = response;

    return {
      ...response,
      challengeDestination: response.challengeParam?.CODE_DELIVERY_DESTINATION,
    };
  }

  async initiatePasswordReset(email: string): Promise<void> {
    const preprocessedEmail = email.trim().toLowerCase();
    await Auth.forgotPassword(preprocessedEmail);
    this.emailForReset = preprocessedEmail;
  }

  async resetPassword(code: string, newPassword: string): Promise<void> {
    if (!this.emailForReset) {
      throw new Error('Cannot reset password because user email is missing');
    }
    await Auth.forgotPasswordSubmit(this.emailForReset, code, newPassword);
    this.emailForReset = undefined;
  }

  async getToken(): Promise<string> {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return !!user;
    } catch {
      return false;
    }
  }
}
