import { Grid, Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Colors } from 'melp-design/style';
import { getBorder } from '../../../../constants/Style';

export default function InnerWithTabsLeft({ children }: PropsWithChildren<{}>) {
  return (
    <Grid
      xs={8}
      item
      component={Box}
      maxWidth="640px !important"
      height={605}
      border={getBorder(Colors.elementsGrey)}
      borderLeft="none"
      style={{
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      }}
    >
      <Box
        marginTop="25px"
        paddingLeft="30px"
        paddingRight="30px"
        marginBottom="11px"
      >
        {children}
      </Box>
    </Grid>
  );
}
