import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './integrations.loaders';
import { integrationsKeys } from './integrations.queries';

export const useCreateIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createIntegration, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.lists);
    },
  });
};

export const useUpdateIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateIntegration, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.all);
    },
  });
};

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteIntegration, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.lists);
    },
  });
};

export const useCreateIntegrationModel = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createIntegrationModel, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.lists);
    },
  });
};

export const useUpdateIntegrationModel = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateIntegrationModel, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.all);
    },
  });
};

export const useDeleteIntegrationModel = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteIntegrationModel, {
    onSuccess: () => {
      return queryClient.invalidateQueries(integrationsKeys.lists);
    },
  });
};
