import { FC, useState } from 'react';
import { useEmployees } from 'store/employees';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const EmployeesFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: employees, isLoading } = useEmployees({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={employees?.items.map((employee) => ({
        key: employee.id,
        label: employee.fullName,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
