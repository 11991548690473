import {
  Box,
  Icon,
  Switch as MUISwitch,
  SwitchProps,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { CellProps } from 'react-table';
import {
  getColumnAction,
  handleRowAction,
  MetaContext,
  MpRowActionMethods,
  RtMpColumnInstance,
} from '@mp-react/table';
import { useInvestmentCalculationsStore } from '../../../state/InvestmentCalculations';
import { useNewsRecipientsStore } from '../../../state/NewsRecipients';
import { Recipient } from '../../../types/Recipients';

export function Switch(props: CellProps<{}>) {
  const { column, row, value } = props;
  const { statuses, setStatus } = useInvestmentCalculationsStore();
  const recipientRequestData = useNewsRecipientsStore(
    (state) => state.recipientRequest,
  );
  const { rowMethods, size } = useContext(MetaContext);
  const action = useMemo(
    () => getColumnAction(column as unknown as RtMpColumnInstance),
    [column],
  );
  const { iconLeft, iconRight, labelLeft, labelRight, color } = action;
  const innerLeft = iconLeft ? <Icon>{iconLeft}</Icon> : labelLeft;
  const innerRight = iconRight ? <Icon>{iconRight}</Icon> : labelRight;

  const checked = useMemo(() => {
    if (column.id === 'status') {
      const reversed = [...recipientRequestData].reverse();
      const changedValue = reversed.find((recipient) => {
        const originalData = row.original as Recipient;
        const isSameEmployeeGroup = recipient.employeeGroupId === row.id;
        const isSameCompanyGroup = !!recipient.companyGroupId
          ? recipient.companyGroupId === originalData.companyGroupId
          : true;
        const isSameCompany = !!recipient.companyId
          ? recipient.companyId === originalData.companyId
          : true;
        return isSameEmployeeGroup && isSameCompany && isSameCompanyGroup;
      });
      if (!changedValue) return value === 'on';
      return changedValue.status === 'on';
    }
    return statuses?.[column.id]?.[row.id]?.value ?? value;
  }, [column.id, recipientRequestData, row, statuses, value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const value = event.target.checked;
      const statusValues = { [column.id]: { [row.id]: { value } } };
      setStatus(statusValues);
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        row,
        rowId: row.id,
        value,
      });
    },
    [column.id, row, setStatus, rowMethods, action.slug],
  );

  const handlePropagation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation(),
    [],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={handlePropagation}
    >
      <Typography component="span">{innerLeft}</Typography>
      <MUISwitch
        size={size}
        onChange={handleChange}
        color={color as SwitchProps['color']}
        checked={checked}
      />
      <Typography component="span">{innerRight}</Typography>
    </Box>
  );
}
