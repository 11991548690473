import {
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from 'melp-design/components';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as LinkIcon } from '../../../assets/icons/arrow-right.svg';
import { useMe } from '../../../state/Administrators';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';

interface Props extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  handleSubmit: () => void;
  handleChange: (value: string) => void;
  title: string;
  options: string[];
  value: any;
}

const CustomizeColumnDialog = ({
  actionLabel,
  onCancelClick,
  handleSubmit,
  handleChange,
  title,
  options,
  value,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { openTooltip, closeTooltip, anchorEl } = useTooltip();
  const { canEditCustomEmployeeCategory } = useMe();

  const handleSelectChange = (e: SelectChangeEvent) => {
    handleChange(e.target.value);
    closeTooltip();
  };

  const handleClose = () => {
    onCancelClick();
    closeTooltip();
  };

  const renderLinkOption = () => {
    if (canEditCustomEmployeeCategory) {
      return (
        <MenuItem
          component={Link}
          to="/more/company-settings?tab=companySettingsCustomEmployeeCategory"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {t('form.manage')}
          <LinkIcon />
        </MenuItem>
      );
    }
    return null;
  };

  const dropdownOptions = options?.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));

  return (
    <Dialog onClose={handleClose} width={450} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          <Select
            variant="outlined"
            value={value}
            onChange={handleSelectChange}
            fullWidth
          >
            {dropdownOptions}
            {renderLinkOption()}
          </Select>
          <FormTooltip
            tooltip="tooltip.choose_custom_column"
            anchorEl={anchorEl}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonOld onClick={onCancelClick}>{t('common.cancel')}</ButtonOld>
        <ButtonOld onClick={handleSubmit} variant="contained" color="primary">
          {actionLabel}
        </ButtonOld>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizeColumnDialog;
