import { FC, ReactNode } from 'react';
import { Modal, Typography } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';

interface Props extends ModalProps {
  title?: string;
  prompt?: ReactNode;
  closeModal: (param?: { action: 'CLOSE' } | { action: 'CONFIRM' }) => void;
}

export const ConfirmationModal: FC<Props> = ({ title, prompt, closeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal.Content
      title={title ?? t('common.confirmationModalTitle')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        {
          variant: 'primary',
          label: t('common.confirm'),
          onClick: () => closeModal({ action: 'CONFIRM' }),
          autoFocus: true,
        },
      ]}
    >
      {prompt ? (
        <Typography
          variant="p1"
          color="textSecondary"
          // to prevent runtime warnings about invalid DOM when content is a components node
          component="span"
        >
          {prompt}
        </Typography>
      ) : null}
    </Modal.Content>
  );
};
