import { z } from 'zod';
import { paginationFiltersSchema } from 'utils/schemas';
import { supplierStatuses } from 'store/suppliers';

export const adminSuppliersFiltersSchema = z
  .object({
    status: z.enum(supplierStatuses).nullable().catch(null),
    name: z.string().catch(''),
    systemName: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
