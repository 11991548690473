import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { orderStatuses } from 'store/orders';

export const adminOrdersFiltersSchema = z
  .object({
    status: z.array(z.enum(orderStatuses)).catch([]),
    orderNrs: z.array(z.string()).catch([]),
    orderIds: z.array(z.string()).catch([]),
    parentCompanies: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    sku: z.array(z.string()).catch([]),
    items: z.array(z.string()).catch([]),
    createdAt: dateRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
