import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './automations.loaders';
import { converters } from './automations.converters';
import { AutomationsFilters } from './automations.types';

export const automationsKeys = queryKeyFactory('automations');

export const useAutomations = (filters: Partial<AutomationsFilters> = {}) => {
  const query = useQuery({
    queryKey: automationsKeys.list(filters),
    queryFn: () => loaders.getAutomations({ filters }),
    select: converters.getAutomations,
  });

  return query;
};

export const useAutomation = (automationId: string) => {
  const query = useQuery({
    queryKey: automationsKeys.detail(automationId),
    queryFn: () => loaders.getAutomation({ automationId }),
    select: converters.getAutomation,
    enabled: !!automationId,
  });

  return query;
};
