import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { ICurrentUser } from 'types/income';

export const loaders = {
  getCurrentUser: () => {
    return api<ICurrentUser>({
      url: Endpoints.me,
    });
  },
};
