import { FC, useState } from 'react';
import { useExpenseSources } from 'store/expenses';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const SourcesFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: sources, isLoading } = useExpenseSources({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={sources?.map((source) => ({
        key: source.id,
        label: source.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
