import { Box, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import {
  useErrorMessages,
  MpControlProps,
  controlRegisterOptions,
  NumberInput,
} from '@mp-react/form';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import { CustomFormControl } from '../../../types/Common';
import useStyles from '../../../styles/Form.styles';

const inputLabelProps = { shrink: true };

function NumberWithCommaView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const classes = useStyles();
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { endAdornment, startAdornment } = control;
  const InputProps = useMemo(
    () => ({
      inputComponent: NumberInput,
      endAdornment,
      startAdornment,
    }),
    [endAdornment, startAdornment],
  );
  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control],
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control],
  );
  const [inputValue, setInputValue] = React.useState<string | null>();
  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 200);

  useEffect(() => {
    if (value === '0') {
      setInputValue(null);
    } else if (value !== null && value !== undefined && value !== inputValue) {
      setInputValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <TextField
        error={!!error}
        helperText={firstMessage}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === 'separated' ? '' : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        value={inputValue?.toString().replace(',', '.') ?? ''}
        onChange={(e) => {
          if (e.target.value === '' || e.target.value === '-') {
            setInputValue(null);
          } else {
            setInputValue(e.target.value);
            debounced(e.target.value);
          }
        }}
        InputProps={InputProps as any}
        variant={variant}
        className={clsx({
          [classes.readonly]: readonly,
        })}
        disabled={disabled}
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function NumberWithComma(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <NumberWithCommaView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
