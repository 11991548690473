import { ComponentProps } from 'react';
import { Box } from '@mui/material';
import { Info } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import { Dialog, DialogContent } from '../Dialog/Dialog';

interface Props extends ComponentProps<typeof Dialog> {}

const WarningDialog = ({ children, ...props }: Props) => {
  return (
    <Dialog width={450} {...props}>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Info
            style={{
              width: 'auto',
              height: 66,
              marginTop: -8,
              marginBottom: 12,
              color: Colors.yellow,
            }}
          />
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default WarningDialog;
