import {
  DateInput,
  Modal,
  NumberInput,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { Stack } from '@mui/material';
import { useAdminShopItem } from 'store/admin-shop';
import { getSinglePriceLabel } from 'components/marketplace/ItemCard/common/getPriceLabel';
import {
  RedeemMethods,
  useAdminShopVoucher,
  useUpdateShopVoucher,
} from 'store/admin-shop-vouchers';
import { fromCents } from 'utils/general';

interface Values extends RedeemMethods {
  shopItemPriceId: string;
  purchasePrice: number;
  purchaseReference: string;
  expiresAt: string | null;
}

const INITIAL_VALUES: Values = {
  qrCode: '',
  barCode: '',
  serial: '',
  url: '',
  code: '',
  pin: '',
  shopItemPriceId: '',
  purchasePrice: NaN,
  purchaseReference: '',
  expiresAt: null,
};

interface Props extends ModalProps {
  shopItemId: string;
  voucherId: string;
}

export const EditVoucherModal: FC<Props> = ({
  shopItemId,
  voucherId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit, reset } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const { data: shopItem, isLoading: isShopItemLoading } =
    useAdminShopItem(shopItemId);
  const { data: voucher, isLoading: isVoucherLoading } =
    useAdminShopVoucher(voucherId);

  useEffect(() => {
    if (voucher) {
      reset({
        qrCode: voucher.redeem.qrCode,
        barCode: voucher.redeem.barCode,
        serial: voucher.redeem.serial,
        url: voucher.redeem.url,
        code: voucher.redeem.code,
        pin: voucher.redeem.pin,
        shopItemPriceId: voucher.shopItem.priceId,
        purchasePrice: fromCents(voucher.purchasePrice),
        purchaseReference: voucher.purchaseReference,
        expiresAt: voucher.expiresAt,
      });
    }
  }, [reset, voucher]);

  const { mutate: updateShopVoucher, isLoading } = useUpdateShopVoucher();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateShopVoucher(
      {
        voucherId,
        data: {
          qrCode: values.qrCode,
          barCode: values.barCode,
          serial: values.serial,
          url: values.url,
          code: values.code,
          pin: values.pin,
          shopItemPriceId: values.shopItemPriceId,
          purchaseReference: values.purchaseReference,
          purchasePrice: values.purchasePrice,
          expiresAt: values.expiresAt,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
          closeModal({ action: 'CLOSE' });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={`${t('common.edit')}: ${voucher?.sequence ?? ''}`}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isLoading || isShopItemLoading || isVoucherLoading,
          },
        ]}
      >
        <Stack gap="20px">
          <Controller
            name="qrCode"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.qrCode')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="barCode"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.barCode')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="serial"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.serial')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="url"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.url')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="code"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.code')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="pin"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.redeem.pin')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="shopItemPriceId"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <SelectInput
                label={t('marketplace.ordersTablePrice')}
                name={name}
                value={value}
                onChange={onChange}
                options={
                  shopItem?.prices
                    .filter((price) => price.priceType === 'fixedPrice')
                    .map((price) => ({
                      label: getSinglePriceLabel(price, shopItem.currency),
                      value: price.id,
                    })) ?? []
                }
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="purchasePrice"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('shop.vouchers.purchasePrice.label')}
                name={name}
                value={value}
                onChange={onChange}
                required
                key={value}
                InputProps={{
                  endAdornment: voucher?.currency,
                }}
                ref={ref}
              />
            )}
          />

          <Controller
            name="purchaseReference"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.purchaseReference.label')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="expiresAt"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <DateInput
                label={t('alternatives.employeeExpirationDate')}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
