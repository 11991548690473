import { Box, Chip } from '@mui/material';
import { useContext, useMemo } from 'react';
import { CellProps } from 'react-table';
import { MetaContext, useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Style';

export default function DateEndPill(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { row } = props;
  const { value } = useRendererOptions(props);
  const { size } = useContext(MetaContext);
  const ms = useMemo(() => new Date(value).getTime(), [value]);

  const chipContent = useMemo(() => {
    const now = Date.now();
    if (value === 'active') {
      return {
        style: {
          backgroundColor: Colors.StatusGreen,
          color: Colors.White,
        },
        text: t('status.active'),
      };
    } else if (value === 'inactive') {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.inactive'),
      };
    } else if (value === 'draft') {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.draft'),
      };
    }

    if (value === 'endsSoon') {
      return {
        style: {
          backgroundColor: Colors.StatusYellow,
          color: Colors.White,
        },
        text: `${t('status.ends_soon')}`,
      };
    } else if (value === 'comingSoon') {
      return {
        style: {
          backgroundColor: Colors.StatusYellow,
          color: Colors.White,
        },
        text: `${t('status.coming_soon')}`,
      };
    } else if (value === 'recentlyEnded') {
      return {
        style: {
          backgroundColor: Colors.Warning,
          color: Colors.White,
        },
        text: t('status.recently_ended'),
      };
    } else if (value === 'ended') {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.ended'),
      };
    } else if (value === 'expired') {
      return {
        style: {
          backgroundColor: Colors.Warning,
          color: Colors.White,
        },
        text: t('status.expired'),
      };
    } else if (value === 'published') {
      return {
        style: {
          backgroundColor: Colors.StatusGreen,
          color: Colors.White,
        },
        text: t('status.published'),
      };
    } else if (value === 'inactive') {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.inactive'),
      };
    }

    if (now > ms + 1000 * 60 * 60 * 24 * 30) {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.inactive'),
      };
    } else if (now > ms) {
      return {
        style: {
          backgroundColor: Colors.Warning,
          color: Colors.White,
        },
        text: t('status.expired'),
      };
    } else if (now > ms - 1000 * 60 * 60 * 24 * 30) {
      return {
        style: {
          backgroundColor: Colors.StatusYellow,
          color: Colors.White,
        },
        text: t('status.valid_till')?.replace(
          '{days}',
          Math.ceil((ms - now) / (1000 * 60 * 60 * 24)).toString(),
        ),
      };
    } else if (value === 'expiresSoon') {
      const statusPillLabel =
        (row?.original as any)?.statusLabel ?? `${t('status.ends_soon')}`;
      return {
        style: {
          backgroundColor: Colors.StatusYellow,
          color: Colors.White,
        },
        text: statusPillLabel,
      };
    } else {
      return {
        style: {
          backgroundColor: Colors.StatusGreen,
          color: Colors.White,
        },
        text: t('status.published'),
      };
    }
  }, [ms, row?.original, t, value]);

  return (
    <Box textAlign="center">
      <Chip size={size} style={chipContent?.style} label={chipContent?.text} />
    </Box>
  );
}
