import { TypographyColor } from 'melp-design/components';
import { OrderStatus } from './orders.types';

export const COLOR_BY_STATUS: Record<OrderStatus, TypographyColor> = {
  pending: 'warning',
  in_progress: 'warning',
  done: 'success',
  rejected: 'error',
  invoiced: 'primary',
  paid: 'primary',
  failed: 'error',
};
