import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { benefitCategories } from 'store/benefits';
import SingleFieldDialog from '../SingleFieldDialog/SingleFieldDialog';

interface IProps {
  slug: string;
  open: boolean;
  onCancelClick: () => void;
  onActionClick: (value: string) => void;
}

export function ActionDialog({
  slug,
  open,
  onCancelClick,
  onActionClick,
}: IProps) {
  const { t } = useTranslation();
  const dialogs: { [key: string]: JSX.Element } = useMemo(
    () => ({
      changeBenefitType: (
        <SingleFieldDialog
          onCancelClick={onCancelClick}
          onActionClick={onActionClick}
          id="change-benefit-type-dialog"
          open={open}
          title={t('benefits.benefit_type')}
          actionLabel={t('common.confirm')}
          placeholder={`${t('benefits.benefit_name')}`}
          type="select"
          defaultValue={benefitCategories[0]}
          options={benefitCategories.map((category) => ({
            value: category,
            name: t(`menu.${category}`),
          }))}
          tooltip="tooltip.change_benefit_type"
        />
      ),
    }),
    [onActionClick, onCancelClick, open, t],
  );
  return dialogs[slug];
}
