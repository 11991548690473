import { z } from 'zod';
import { APP } from 'config';

export const paginationFiltersSchema = z.object({
  page: z.coerce.number().finite().int().min(1).catch(1),
  pageSize: z.coerce
    .number()
    .finite()
    .int()
    .min(1)
    .catch(APP.pagination.defaults.pageSize[0]),
});

export const sortFiltersSchema = z.object({
  sort: z
    .object({
      columnKey: z.string(),
      order: z.enum(['asc', 'desc']),
    })
    .nullable()
    .catch(null),
});

export const dateRangeSchema = z
  .object({
    from: z.string().optional(),
    to: z.string().optional(),
  })
  .nullable()
  .catch(null);

export const numberRangeSchema = z
  .object({
    from: z.coerce.number().optional(),
    to: z.coerce.number().optional(),
  })
  .nullable()
  .catch(null);
