import { FC } from 'react';
import { CardMedia } from 'melp-design/components';
import { Colors, Effects } from 'melp-design/style';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const ImagesContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  backgroundColor: theme.palette.common.white,
}));

const SupplierLogoContainer = styled('div')(({ theme }) => ({
  width: 34,
  height: 34,
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: `1px solid ${Colors.elementsGrey}`,
  borderRadius: 6,
  padding: 1,
  background: theme.palette.common.white,
  boxShadow: Effects.smallElementsShadow,
}));

const SupplierLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

interface Props {
  image?: string;
  logo?: string;
  logoTooltip?: string;
}

const ItemImages: FC<Props> = ({ image, logo, logoTooltip }) => {
  return (
    <ImagesContainer>
      {image ? (
        <CardMedia image={image} />
      ) : (
        <CardMedia
          image="/no-item-image-placeholder.svg"
          style={{ opacity: 0.3 }}
        />
      )}
      {logo ? (
        <Tooltip title={logoTooltip} placement="top">
          <SupplierLogoContainer>
            <SupplierLogo src={logo} alt={logoTooltip} />
          </SupplierLogoContainer>
        </Tooltip>
      ) : null}
    </ImagesContainer>
  );
};

export default ItemImages;
