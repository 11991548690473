import {
  Calendar,
  CheckboxChecked,
  CheckboxIndeterminate,
  CheckboxUnchecked,
  Close,
  Dropdown,
  RadioChecked,
  RadioUnchecked,
  Time,
} from 'melp-design/icons';
import { Effects, Colors as MelpColors, SystemColors } from 'melp-design/style';
import type {} from '@mui/lab/themeAugmentation';
import { DeprecatedThemeOptions } from '@mui/material';
import { Theme, adaptV4Theme, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import {
  Colors,
  ShadowOpacities,
  Shadows,
  getBorder,
} from '../../constants/Style';
import CustomDatePickerLayout from './components/CustomDatePickerLayout';

// Workaround for the issue described in https://github.com/mui/material-ui/issues/35287
declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: Colors.Primary,
    borderRadius: '20px',
  },
};

export default createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        light: MelpColors.primaryLight,
        main: MelpColors.primary,
        dark: MelpColors.primaryDark,
        contrastText: MelpColors.white,
      },
      secondary: {
        main: Colors.Secondary,
      },
      error: {
        main: MelpColors.red,
        contrastText: MelpColors.white,
      },
      warning: {
        main: Colors.Warning,
        contrastText: Colors.White,
      },
      success: {
        main: MelpColors.green,
        contrastText: Colors.White,
      },
      info: {
        main: Colors.Primary,
        contrastText: Colors.White,
      },
      mode: 'light',
      background: {
        default: MelpColors.lightBackgroundGrey,
      },
      text: {
        primary: Colors.TextPrimary,
        secondary: Colors.TextSecondary,
      },
      divider: MelpColors.elementsGrey,
      action: {
        active: MelpColors.grey,
      },
    },
    typography: {
      fontFamily: "'Inter', sans-serif",
      h1: {
        fontWeight: 700,
        fontSize: 62,
        lineHeight: '70.4px',
      },
      h2: {
        fontWeight: 700,
        fontSize: 46,
        lineHeight: '52.8px',
      },
      h3: {
        fontWeight: 700,
        fontSize: 34,
        lineHeight: '39.6px',
      },
      h4: {
        fontWeight: 700,
        fontSize: 26,
        lineHeight: '35px',
      },
      h5: {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '33.6px',
      },
      h6: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '28px',
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '25.2px',
      },
      subtitle2: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '22.4px',
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '25.2px',
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '22.4px',
      },
      button: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 'none',
      },
      caption: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '18.2px',
        letterSpacing: '1px',
      },
      overline: {
        fontWeight: 700,
        fontSize: 10,
        lineHeight: '16.8px',
      },
    },
    shape: {
      borderRadius: 6,
    },
    shadows: [
      'none',
      'none',
      `0px  1px  5px  0px rgba(0,0,0,${ShadowOpacities.Dark}),0px  2px  2px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px  1px -2px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  1px  8px  0px rgba(0,0,0,${ShadowOpacities.Dark}),0px  3px  4px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px  3px -2px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  2px  4px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  4px  5px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 10px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  3px  5px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  5px  8px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 14px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  3px  5px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  6px 10px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 18px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  4px  5px -2px rgba(0,0,0,${ShadowOpacities.Dark}),0px  7px 10px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 2px 16px  1px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  5px  5px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px  8px 10px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px 14px  2px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  5px  6px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px  9px 12px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px 16px  2px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  6px  6px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px 10px 14px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 4px 18px  3px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  6px  7px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 11px 15px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 4px 20px  3px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  7px  8px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 12px 17px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 22px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  7px  8px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 13px 19px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 24px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  7px  9px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 14px 21px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 26px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  8px  9px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 15px 22px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 28px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  8px 10px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 16px 24px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 30px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  8px 11px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 17px 26px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 32px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  9px 11px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 18px 28px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 7px 34px  6px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px  9px 12px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 19px 29px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 7px 36px  6px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px 10px 13px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 20px 31px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 38px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px 10px 13px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 21px 33px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 40px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px 10px 14px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 22px 35px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 42px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px 11px 14px -7px rgba(0,0,0,${ShadowOpacities.Dark}),0px 23px 36px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 9px 44px  8px rgba(0,0,0,${ShadowOpacities.Light})`,
      `0px 11px 15px -7px rgba(0,0,0,${ShadowOpacities.Dark}),0px 24px 38px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 9px 46px  8px rgba(0,0,0,${ShadowOpacities.Light})`,
    ],
    overrides: {
      MuiCssBaseline: {
        body: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '22.4px',
        },
      },
      MuiMenu: {
        root: {
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
        },
        list: {
          padding: '5px',
        },
      },
      MuiMenuItem: {
        root: {
          padding: '8px 10px !important', // To override another !important
          borderRadius: 6,
          color: MelpColors.black,
          transition: 'color, background-color 100ms ease-in-out',
          '&:hover': {
            backgroundColor: MelpColors.screenBackgroundGrey,
          },
          // To override existing styles without impacting other elements
          '&::after': {
            display: 'none',
          },

          '&.Mui-selected': {
            color: MelpColors.primary,
          },
        },
      },
      MuiCardContent: {
        root: {
          padding: 30,
          '&:first-of-type': {
            paddingTop: 33,
          },
          '&:last-child': {
            paddingBottom: 40,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '1.4',
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: MelpColors.white80,
        },
      },
      MuiDivider: {
        inset: {
          marginLeft: 20,
        },
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: 0,

          // '& > *:not(:first-child) > .MuiTableRow-root:last-child > .MuiTableCell-root':
          //   {
          //     borderBottom: getBorder(MelpColors.elementsGrey),
          //   },

          '& > *:last-child': {
            '& > .MuiTableRow-root:last-child': {
              // '& > .MuiTableCell-root': {
              //   '&:first-child': {
              //     borderBottomLeftRadius: 20,
              //   },
              //   '&:last-child': {
              //     borderBottomRightRadius: 20,
              //   },
              // },
            },
          },
        },
      },
      MuiTableBody: {
        root: {
          background: MelpColors.white,
        },
      },
      MuiTableRow: {
        root: {
          // position: 'relative',

          '&:first-of-type': {
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          },

          // '&:last-child': {
          //   '&::after': {
          //     opacity: 0,
          //     bottom: 0, // to prevent the table from overflowing (Y axis)
          //   },
          // },

          // '&::before': {
          //   content: '""',
          //   display: 'table-cell',
          //   position: 'absolute',
          //   left: 5,
          //   right: 5,
          //   top: 6,
          //   bottom: 5,
          //   background: MelpColors.lightBackgroundGrey,
          //   borderRadius: 10,
          //   opacity: 0,
          //   transition: 'opacity 300ms ease-in-out',
          // },

          // '&::after': {
          //   content: '""',
          //   display: 'table-cell',
          //   position: 'absolute',
          //   left: 20,
          //   right: 20,
          //   bottom: -1,
          //   height: 1,
          //   background: MelpColors.elementsGrey,
          // },

          // '&.MuiTableRow-hover:hover': {
          //   backgroundColor: 'transparent',
          //   '&::before': {
          //     opacity: 1,
          //   },
          // },

          '&.MuiTableRow-hover:hover': {
            background: MelpColors.lightBackgroundGrey,
          },
        },
      },
      MuiTableCell: {
        root: {
          margin: 5,
          padding: 15,
          fontSize: 14,
          lineHeight: '1.4',
          color: SystemColors.black,
          // position: 'relative',

          // borderBottom: 'none',
          // borderTop: 'none',
          borderColor: SystemColors.grey[90],

          '&:first-child': {
            paddingLeft: 20,
          },

          '&:last-child': {
            paddingRight: 20,
          },
        },

        body: {
          color: MelpColors.black,

          '&:first-child': {
            borderLeft: getBorder(SystemColors.grey[90]),
          },

          '&:last-child': {
            borderRight: getBorder(SystemColors.grey[90]),
          },

          '.MuiTableRow-root:first-child > &': {
            borderTop: getBorder(SystemColors.grey[90]),

            '&:first-child': {
              borderTopLeftRadius: 20,
            },
            '&:last-child': {
              borderTopRightRadius: 20,
            },
          },
        },

        head: {
          paddingTop: 20,
          paddingBottom: 15,
          fontSize: 12,
          color: SystemColors.grey[55],
          // background: 'none',
          border: 'none',
        },

        footer: {
          fontWeight: 700,
          fontSize: 12,
          color: SystemColors.grey[55],

          '&:first-child': {
            borderLeft: getBorder(SystemColors.grey[90]),
          },

          '&:last-child': {
            borderRight: getBorder(SystemColors.grey[90]),
          },
        },
      },
      MuiTableFooter: {
        root: {
          backgroundColor: Colors.White,
        },
      },
      MuiTablePagination: {
        toolbar: {
          background: 'none',
        },
      },
      MuiChip: {
        root: {
          height: '30px!important',
          borderRadius: 18,
        },
        label: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '20px',
          paddingTop: 5,
          paddingBottom: 6,
        },
        colorPrimary: {
          color: Colors.White,
          backgroundColor: Colors.Dark,
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
        },
        elevation8: {
          boxShadow: Shadows.BulkAction,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          height: 44,
          '&$disabled': {
            color: Colors.Default,
          },
        },
        contained: {
          padding: '11px 20px',
          boxShadow: 'none',
          '&$disabled': {
            color: Colors.TextElement,
            backgroundColor: Colors.Default,
            '& .MuiButton-iconSizeMedium svg path': {
              stroke: Colors.TextElement,
            },
          },
          '&$containedSecondary': {
            color: Colors.White,
          },
        },
        text: {
          padding: '11px 20px',
        },
        textSizeSmall: {
          padding: '4px 5px',
          fontSize: '0.8125rem',
        },
        textInfo: {
          color: Colors.TextElement,
          '&:hover': {
            backgroundColor: Colors.Hover,
          },
        },
        outlined: {
          padding: '10px 19px',
          backgroundColor: Colors.White,
          border: getBorder(Colors.BorderDefault),
          '&$disabled': {
            border: getBorder(Colors.BorderDisabledLight),
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: '10px',
          borderRadius: '10px',
          '&$disabled': {
            color: Colors.Disable,
          },
        },
        sizeSmall: {
          padding: '7px',
          borderRadius: '6px',
          '& > svg': {
            height: 16,
            width: 16,
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: MelpColors.elementsGrey,
          transition: 'color 100ms ease-in-out',

          '&:hover': {
            color: MelpColors.primary,
            backgroundColor: 'transparent',
          },

          '&.Mui-disabled': {
            color: MelpColors.elementsGrey,
            '& rect': {
              fill: MelpColors.elementsGrey,
            },
          },
        },
      },
      MuiRadio: {
        root: {
          color: MelpColors.elementsGrey,
          transition: 'color 100ms ease-in-out',
          paddingRight: '10px',
          marginTop: '-9px',
          marginBottom: '-9px',

          '&:hover, &.Mui-checked': {
            color: MelpColors.primary,
          },
        },
      },
      MuiSwitch: {
        root: {
          width: 35,
          height: 21,
          padding: 0,
          margin: '8px 9px 8px 12px',
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: MelpColors.white,

            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: Colors.Disable,
            },

            '& + .MuiSwitch-track': {
              backgroundColor: MelpColors.primary,
              opacity: 1,
            },
          },
        },
        thumb: {
          width: 17,
          height: 17,
          boxShadow: Shadows.ThumbShadow,
        },
        track: {
          borderRadius: 32.55,
          opacity: 1,
          backgroundColor: Colors.Default,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: Colors.BorderDefaultLight,
          '&:hover': {
            backgroundColor: Colors.BorderDefault,
          },
          '&.Mui-focused': {
            backgroundColor: Colors.BorderDefaultLight,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&$disabled': {
            background: Colors.Inactive,
            overflow: 'hidden',
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: MelpColors.greyHover,
            },
          },
        },
        notchedOutline: {
          borderColor: MelpColors.elementsGrey,
          borderWidth: '1px !important',
        },
        adornedEnd: {
          color: Colors.LightGray,
          '& .MuiIconButton-root:last-child': {
            marginRight: '-10px',
          },
        },
        multiline: {
          paddingTop: 12,
          paddingBottom: 18,
        },
        input: {
          color: Colors.TextPrimary,
          paddingTop: 13,
          paddingBottom: 14,
          '&::placeholder': {
            color: Colors.TextElement,
            opacity: 1,
          },
        },
        inputMultiline: {
          padding: 0,
        },
      },
      MuiInputBase: {
        root: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '22.4px',
        },
        input: {
          height: '1.1876em',
        },
      },
      MuiInputAdornment: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: Colors.TextElement,
          },
        },
        positionEnd: {
          marginLeft: 0,
        },
      },
      MuiInputLabel: {
        root: {
          color: Colors.TextPrimary,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '22.4px',
          whiteSpace: 'normal',
        },
        filled: {
          color: Colors.TextSecondary,
          transform: 'translate(12px, 17px) scale(1)',
          '&$shrink': {
            transform: 'translate(12px, 6px) scale(0.75)',
          },
        },
        formControl: {
          color: Colors.TextElement,
          transform: 'translate(0px, 20px) scale(1)',
        },
        outlined: {
          transform: 'translate(14px, 17px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
        asterisk: {
          color: Colors.Error,
        },
      },
      MuiSelect: {
        select: {
          minHeight: '1.4em',
          '&:focus': {
            backgroundColor: 'unset',
            borderRadius: 6,
          },
        },
        iconOutlined: {
          color: MelpColors.black,
          right: 15,
          top: 'calc(50% - 6px)',
        },
      },
      MuiToggleButton: {
        root: {
          border: 'none',
          height: 28,
          width: 50,
          color: Colors.LightGray,
          fontSize: 14,
          lineHeight: '22px',
        },
      },
      MuiToggleButtonGroup: {
        groupedHorizontal: {
          marginRight: 5,
          borderRadius: 30,
          '&:not(:last-child)': {
            borderTopRightRadius: 30,
            borderBottomRightRadius: 30,
          },
          '&:not(:first-child)': {
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
      MuiPopover: {
        paper: scrollBarStyle,
      },
      MuiAutocomplete: {
        root: {
          '.MuiTextField-root .MuiOutlinedInput-input.MuiOutlinedInput-input': {
            padding: '0',
          },

          '.MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 15px)',
          },

          '.MuiInputLabel-root + .MuiAutocomplete-inputRoot': {
            padding: '24px 15px 6px',
          },
        },
        inputRoot: {
          padding: '15px',
          gap: '5px',
        },
        popupIndicator: {
          right: 10,
          padding: '10px',
          color: MelpColors.black,
        },
        clearIndicator: {
          right: 16,
          padding: '5px',
        },
        paper: {
          boxShadow: Effects.smallElementsShadow,
        },
        tag: {
          borderRadius: '4px',
          margin: 0,
          height: '20px!important',
          '& > .MuiChip-label': {
            padding: '0 10px 0 5px',
            fontWeight: 'normal',
            fontSize: '12px',
          },
        },

        listbox: {
          padding: '5px',
        },
        option: {
          padding: '8px 10px',
          borderRadius: 6,
          color: MelpColors.black,
          transitionProperty: 'color, backGround-color',

          '&[aria-selected="true"]': {
            color: MelpColors.primary,
            backgroundColor: 'transparent',
          },

          '&:hover, &[aria-selected="true"]:hover': {
            backgroundColor: MelpColors.screenBackgroundGrey,
          },
        },
      },
      MuiTabs: {
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          height: '1px',

          '& > .MuiTabs-indicatorSpan': {
            width: 'calc(100% - 18px)',
            backgroundColor: MelpColors.primary,
          },
        },
      },
      MuiTab: {
        root: {
          minWidth: 'auto',
          minHeight: 'auto',
          padding: '20px 10px',
          fontSize: 14,
          fontWeight: 400,
          textTransform: 'none',
          color: MelpColors.grey,

          '&:hover': {
            color: MelpColors.primary,
          },

          '&.Mui-selected': {
            fontWeight: 500,
            color: MelpColors.primary,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          padding: '20px',
          backgroundColor: MelpColors.white,
          borderRadius: '10px',
          filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.1))',
          color: MelpColors.black,
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '1.4',
          textAlign: 'center',
        },
        arrow: {
          '&::before': { backgroundColor: MelpColors.white },
        },
      },
      MuiLinearProgress: {
        root: {
          height: 10,
          borderRadius: '5px',
          backgroundColor: MelpColors.elementsGrey,
        },
        bar: {
          borderRadius: '5px',
        },
      },
      MuiLink: {
        root: {
          '&:disabled': {
            color: MelpColors.grey,
            cursor: 'default',
          },
        },
        button: {
          lineHeight: 1,
        },
      },
      MuiDateCalendar: {
        viewTransitionContainer: {
          '& .PrivatePickersSlideTransition-root': {
            minHeight: '240px',
          },
        },
      },
      MuiPickersPopper: {
        paper: {
          boxShadow: Effects.smallElementsShadow,
        },
      },
      MuiFormHelperText: {
        root: {
          margin: '5px 2px 0',
          fontWeight: 400,
          fontSize: 10,
          lineHeight: 1.4,
        },
      },
      MuiButtonBase: {
        root: {
          fontFamily: 'Inter, sans-serif',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.5',
        },
      },
      MuiDigitalClock: {
        list: {
          minWidth: '100px',
        },
        item: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: MelpColors.primary,
            '&:hover': {
              backgroundColor: MelpColors.screenBackgroundGrey,
            },
          },
          '&:hover': {
            backgroundColor: MelpColors.screenBackgroundGrey,
          },
        },
      },
      MuiAlert: {
        root: {
          backgroundColor: MelpColors.white,
          border: `1px solid ${MelpColors.elementsGrey}`,
          borderRadius: 6,
          padding: 10,
        },
        icon: {
          padding: 0,
          marginRight: 12,
          '& > svg': {
            height: 20,
            width: 'auto',
          },
        },
        message: {
          color: MelpColors.grey,
          padding: 0,
          fontSize: 14,
          lineHeight: 1.4,
        },
        action: {
          padding: '0 0 0 10px',
          margin: '-5px -5px -5px auto',
        },
      },
    },
    props: {
      MuiLink: {
        fontSize: '14px',
        lineHeight: '1.4',
        underline: 'none',
      },
      MuiSelect: {
        IconComponent: Dropdown,
      },
      MuiAutocomplete: {
        popupIcon: <Dropdown />,
        clearIcon: <Close />,
        componentsProps: {
          paper: {
            variant: 'outlined',
          },
        },
        ChipProps: {
          deleteIcon: <Close width={15} height={15} />,
        },
      },
      MuiRadio: {
        icon: <RadioUnchecked />,
        checkedIcon: <RadioChecked />,
      },
      MuiDatePicker: {
        slots: {
          openPickerIcon: Calendar,
          layout: CustomDatePickerLayout,
        },
        slotProps: {
          actionBar: {
            actions: ['today'],
          },
        },
      },
      MuiDateTimePicker: {
        slots: {
          openPickerIcon: Calendar,
          layout: CustomDatePickerLayout,
        },
        slotProps: {
          actionBar: {
            actions: ['today'],
          },
        },
      },
      MuiTimePicker: {
        slots: {
          openPickerIcon: Time,
        },
      },
      MuiTabs: {
        TabIndicatorProps: {
          children: <span className="MuiTabs-indicatorSpan" />,
        },
      },
      MuiTooltip: {
        arrow: true,
      },
      MuiCheckbox: {
        icon: <CheckboxUnchecked />,
        checkedIcon: <CheckboxChecked />,
        indeterminateIcon: <CheckboxIndeterminate />,
      },
    },
  }),
) as DeprecatedThemeOptions;
