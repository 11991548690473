import { Box, Button, CircularProgress } from '@mui/material';
import { MpForm } from '@mp-react/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { Loader } from 'melp-design/components';
import StatusButton from '../../../../components/common/StatusButton/StatusButton';
import FormPaper from '../../../../components/layouts/FormPaper/FormPaper';
import FormFooter from '../../../../components/layouts/FormFooter/FormFooter';
import Title from '../../../../components/sidebar/Title/Title';
import { useAdministratorForm } from '../../../../configs/Forms/AdministratorForm';
import { Administrator } from '../../../../types/Administrators';
import { Status } from '../../../../types/Common';
import { StatusActionKey } from '../../../../types/Status';
import { useFormUtils } from '../../../../utils/Form';
import useStatus from '../../../../utils/Status';
import { useClientAdministrator } from '../../../../state/Clients';

const Information = () => {
  const { t } = useTranslation();
  // const history = useHistory();
  // const location = useLocation();
  const params: { adminId: string; clientId: string } = useParams();
  const administratorId = useMemo(() => params?.adminId, [params?.adminId]);
  const clientId = useMemo(() => params?.clientId, [params?.clientId]);
  const {
    updateAdministrator,
    clientAdministratorData: administrator,
    loading,
    apiLoading,
  } = useClientAdministrator(clientId, administratorId);
  const { overridables } = useFormUtils();
  const formMethods = useForm({
    defaultValues: administrator as FieldValues,
  });
  const administratorsForm = useAdministratorForm(true);
  const { handleSubmit, formState, reset } = formMethods;

  const { getStatus } = useStatus();
  const [statusValue, setStatusValue] = useState<Status>({
    name: administrator?.status ?? 'active',
  });

  useEffect(() => {
    if (!!administrator) {
      reset(administrator);
      setStatusValue({
        name: administrator.status,
      });
    }
  }, [administrator, reset]);

  const statusChanged = useMemo(
    () => statusValue.name !== administrator?.status,
    [administrator?.status, statusValue.name],
  );

  const hasChanges: boolean = useMemo(
    () => formState.isDirty || statusChanged,
    [formState.isDirty, statusChanged],
  );

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? '0' : '70px'})`,
    [hasChanges],
  );

  const removeFooter = useCallback(() => {
    reset(administrator, { dirtyFields: false });
  }, [administrator, reset]);

  const submitData = useCallback(
    () =>
      handleSubmit(async (data: Partial<Administrator>) => {
        await updateAdministrator({
          ...data,
          status: statusValue.name as 'active' | 'inactive',
        });
        reset(administrator, { dirtyFields: false });
      })(),
    [administrator, handleSubmit, reset, statusValue.name, updateAdministrator],
  );

  const handleStatusChange = useCallback(
    (action: StatusActionKey, date?: string) => {
      const status = getStatus(action);
      if (status) {
        setStatusValue({
          name: status,
          date,
        });
      }
    },
    [getStatus],
  );

  if (apiLoading) return <Loader />;

  return (
    <>
      <Prompt
        when={hasChanges}
        message={t('errors.user_leaving_edited_page')}
      />
      <Box display="flex">
        <FormPaper marginTop={0} marginRight="30px">
          <Title
            marginBottom="35px"
            title={t('common.contact_details')}
            padding="0px !important"
          />
          <MpForm
            overridables={overridables}
            useFormMethods={formMethods}
            {...administratorsForm}
          />
        </FormPaper>
        <StatusButton
          status={statusValue}
          onActionChange={handleStatusChange}
          id="administrators"
        />
      </Box>

      <FormFooter
        justifyContent="flex-end"
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
        maxWidth="600px"
        marginLeft={0}
        paddingRight="20px"
      >
        <Box marginRight="10px">
          <Button
            onClick={removeFooter}
            variant="text"
            disabled={loading}
            color="info"
          >
            {t('common.cancel')}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={submitData}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            t('common.confirm_changes')
          )}
        </Button>
      </FormFooter>
    </>
  );
};

export default Information;
