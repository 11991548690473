import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './custom-categories.loaders';
import { converters } from './custom-categories.converters';

export const customCategoriesKeys = queryKeyFactory('custom-categories');

export const useCustomCategories = () => {
  return useQuery({
    queryKey: customCategoriesKeys.list(),
    queryFn: () => loaders.getCustomCategories(),
    select: converters.getCustomCategories,
    staleTime: 1000 * 30,
  });
};
