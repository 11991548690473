import { converters as tableConverters } from 'store/table';
import { IAdminShopVoucher } from 'types/income';
import { loaders } from './admin-shop-vouchers.loaders';
import { AdminShopVoucher } from './admin-shop-vouchers.types';

const convertShopVoucher = (voucher: IAdminShopVoucher): AdminShopVoucher => {
  return {
    id: voucher.id,
    shopItem: {
      id: voucher.marketplaceItemId,
      priceId: voucher.marketplaceItemPriceId,
    },
    order: voucher.marketplaceOrderId
      ? {
          id: voucher.marketplaceOrderId,
          name: voucher.marketplaceOrderNr ?? '',
        }
      : null,
    status: voucher.status,
    price: voucher.price,
    purchasePrice: voucher.purchasePrice,
    purchaseReference: voucher.purchaseReference,
    currency: voucher.currency,
    sequence: voucher.sequence,
    redeem: {
      qrCode: voucher.qrCode ?? '',
      barCode: voucher.barCode ?? '',
      serial: voucher.serial ?? '',
      url: voucher.url ?? '',
      code: voucher.code ?? '',
      pin: voucher.pin ?? '',
    },
    fileLink: voucher.fileLink ?? '',
    createdAt: voucher.creationDate,
    expiresAt: voucher.expirationDate,
    lastAccess: voucher.lastAccess ?? null,
  };
};

const convertShopVouchers = (
  suppliers: IAdminShopVoucher[],
): AdminShopVoucher[] => {
  return suppliers.map(convertShopVoucher);
};

export const converters = {
  getShopVouchers: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getShopVouchers>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertShopVouchers(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getShopVoucher: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getShopVoucher>>) => {
    return convertShopVoucher(data);
  },
};
