import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'melp-design/components';
import { useRouteParams } from 'utils/useIdParam';
import { ROUTES } from 'config';
import { useMarketplaceItemTranslations } from '../../../../../state/MarketplaceItems';
import Title from '../../../../../components/sidebar/Title/Title';
import FormDialog from '../../../../../components/dialogs/FormDialog/FormDialog';
import { useDialog } from '../../../../../utils/Dialog';
import { MarketplaceItemTranslationFormData } from '../../../common/Types';
import { useMarketplaceItemTranslationForm } from './MarketplaceItemTranslationForm';
import MarketplaceItemTranslationCard from './MarketplaceItemTranslationCard';

const useStyles = makeStyles(() => ({
  creationDialog: {
    maxWidth: 800,
    padding: 30,
  },
}));

const MarketplaceItemContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useRouteParams(ROUTES.admin.shopItems.details.content);

  const {
    translations = [],
    create,
    creating,
  } = useMarketplaceItemTranslations(id);

  const form = useMarketplaceItemTranslationForm();
  const { open, openDialog, closeDialog } = useDialog();
  const handleCreation = async (
    formData: MarketplaceItemTranslationFormData,
  ) => {
    await create(formData);
    closeDialog();
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" marginBottom={4}>
        <Title
          title={t('benefitTemplates.templateContent')}
          padding="0px !important"
        />
        <Button
          label={t('marketplaceItems.addTranslation')}
          onClick={openDialog}
        />

        <FormDialog
          actionLabel={t('common.confirm')}
          onCancelClick={closeDialog}
          onSubmitForm={handleCreation}
          id="connect-form-dialog"
          title={t('marketplaceItems.addTranslation')}
          form={form}
          open={open}
          loading={creating}
          classes={{
            paperWidthSm: classes.creationDialog,
          }}
        />
      </Box>
      <Grid container spacing={2}>
        {translations.map((translation) => {
          return (
            <Grid item key={translation.id} xs={6}>
              <MarketplaceItemTranslationCard
                marketplaceItemId={id}
                translation={translation}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default MarketplaceItemContent;
