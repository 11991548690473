import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IAdminShopItem,
  IAdminShopItemsInfo,
  IShopItemsImport,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { Currency } from 'types/general';
import { BenefitCategory } from 'store/benefits';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { ShopItemStatus } from 'store/shop';
import { exportXlsxConfig, getFormData } from 'utils/general';
import { AdminShopItemsFilters } from './admin-shop.types';

const getFilters = (filters: Partial<AdminShopItemsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    supplierIds: filters.suppliers,
    countries: filters.countries,
    cities: filters.cities,
    categories: filters.categories,
    status: filters.status,
    creationDateFrom: filters.createdAt?.from,
    creationDateTo: filters.createdAt?.to,
  },
  search: filters.search || undefined,
  sort: filters.sort
    ? `${filters.sort.order === 'desc' ? '-' : ''}${filters.sort.columnKey}`
    : undefined,
});

interface GetShopItems {
  filters: Partial<AdminShopItemsFilters>;
}

interface GetShopItem {
  shopItemId: string;
}

interface RemoveShopItem {
  shopItemId: string;
}

interface CreateShopItem {
  supplierId: string;
  item: {
    name: string;
    status: ShopItemStatus;
    price: number;
    currency: Currency;
    vatRate: number;
    countries: string[];
    cities: string[];
    categories: ShopItemCategory[];
    benefitCategories: BenefitCategory[];
    imageId?: string;
    priority: number;
    searchTags: string;
  };
}

interface UpdateShopItem {
  shopItemId: string;
  item: Partial<CreateShopItem['item']>;
}

interface ExportXls {
  filters: Partial<AdminShopItemsFilters>;
}

interface ImportShopItems {
  file: File;
}

export const loaders = {
  getShopItems: ({ filters }: GetShopItems) => {
    return api<TableDataResponse<IAdminShopItem>>({
      url: Endpoints.melpAdmin.shopItems.root,
      params: getFilters(filters),
    });
  },
  getShopItem: ({ shopItemId }: GetShopItem) => {
    return api<IAdminShopItem>({
      url: Endpoints.melpAdmin.shopItems.byId.replace(
        ':shopItemId',
        shopItemId,
      ),
    });
  },
  createShopItem: ({ supplierId, item }: CreateShopItem) => {
    return api<{ id: string }>({
      url: Endpoints.melpAdmin.shopItems.create.replace(
        ':supplierId',
        supplierId,
      ),
      data: {
        name: item.name,
        imageId: item.imageId,
        currency: item.currency,
        vatRate: item.vatRate,
        countries: item.countries,
        cities: item.cities,
        benefitPlanTypes: item.benefitCategories,
        categories: item.categories,
        status: item.status,
        price: item.price,
        priorityIndex: item.priority,
        searchTags: item.searchTags,
      },
      method: 'post',
    });
  },
  updateShopItem: ({ shopItemId, item }: UpdateShopItem) => {
    return api<{ id: string }>({
      url: Endpoints.melpAdmin.shopItems.byId.replace(
        ':shopItemId',
        shopItemId,
      ),
      data: {
        name: item.name,
        imageId: item.imageId,
        currency: item.currency,
        vatRate: item.vatRate,
        countries: item.countries,
        cities: item.cities,
        benefitPlanTypes: item.benefitCategories,
        categories: item.categories,
        status: item.status,
        price: item.price,
        priorityIndex: item.priority,
        searchTags: item.searchTags,
      },
      method: 'patch',
    });
  },
  removeShopItem: ({ shopItemId }: RemoveShopItem) => {
    return api<IAdminShopItem>({
      url: Endpoints.melpAdmin.shopItems.byId.replace(
        ':shopItemId',
        shopItemId,
      ),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, ...filtersWithoutPagination } = getFilters(filters);
    return api({
      url: Endpoints.melpAdmin.shopItems.export.xls,
      params: filtersWithoutPagination,
      ...exportXlsxConfig,
    });
  },
  getShopItemsInfo: () => {
    return api<IAdminShopItemsInfo>({
      url: Endpoints.melpAdmin.shopItems.info,
    });
  },
  importShopItems: ({ file }: ImportShopItems) => {
    return api<IShopItemsImport>({
      url: Endpoints.melpAdmin.shopItems.import.updatesFile,
      method: 'post',
      data: getFormData([{ field: 'file', value: file }]),
    });
  },
  exportShopItemsTemplate: () => {
    return api({
      url: Endpoints.melpAdmin.shopItems.import.updatesFile,
    });
  },
};
