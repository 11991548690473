import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  text: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  filterBtn: {
    background: '#EDF9FF',
    color: '#0098CB',
    textTransform: 'initial',
    height: 50,
    borderRadius: 0,
  },
  arrowIcon: {
    margin: '0 5px',
  },
  textHighlighted: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '& svg>path': {
      fill: theme.palette.primary.main,
    },
  },
}));
