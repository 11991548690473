import { TypographyColor } from 'melp-design/components';
import { NewsStatus } from './news.types';

export const COLOR_BY_STATUS: Record<NewsStatus, TypographyColor> = {
  inactive: 'textSecondary',
  published: 'success',
  expiresSoon: 'warning',
  expired: 'textSecondary',
  comingSoon: 'warning',
  draft: 'textSecondary',
};

export const NONE_CATEGORY_ID = 'none';
