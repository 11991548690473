import { ReactNode } from 'react';
import PageTitleBar from './PageTitleBar';
import PageToolbar from './PageToolbar';
import PageTop from './PageTop';

interface Props {
  /**
   * Top-level information (e.g., breadcrumbs, global filters)
   */
  top?: ReactNode;
  /**
   * Page title
   */
  title: string;
  /**
   * Content placed on the side of the title. Can be used for action buttons.
   */
  sideContent?: ReactNode;
  /**
   * Toolbar content (e.g., filter buttons, tabs). It stick at the top of a
   * page when scrolling.
   */
  toolbarContent?: ReactNode;
  backTo?: string;
}

/**
 * Provides a full width bar to use as a page header. It is meant to be used
 * for pages with lists (tables, cards, etc.).
 */
const ListPageHeader = ({
  toolbarContent,
  top,
  title,
  sideContent,
  backTo,
}: Props) => {
  const topEl = !!top ? <PageTop>{top}</PageTop> : null;
  const toolbarEl = toolbarContent ? (
    <PageToolbar>{toolbarContent}</PageToolbar>
  ) : null;
  // Keep structure as an array in order for position sticky to work
  return (
    <>
      {topEl}
      <PageTitleBar
        first={!topEl}
        last={!toolbarEl}
        title={title}
        sideContent={sideContent}
        backTo={backTo}
      />
      {toolbarEl}
    </>
  );
};

export default ListPageHeader;
