import { FC } from 'react';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsGiversList } from 'containers/recognitions';

const RecognitionsGiversPage: FC = () => {
  return (
    <RecognitionsLayout>
      <RecognitionsGiversList />
    </RecognitionsLayout>
  );
};

export default RecognitionsGiversPage;
