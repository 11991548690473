import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { z } from 'zod';
import { shopItemCategories } from 'types/MarketplaceItems';
import { expenseStatuses, expenseTypes } from './expenses.types';

export const expensesFiltersSchema = z
  .object({
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    employees: z.array(z.string()).catch([]),
    source: z.array(z.string()).catch([]),
    categories: z.array(z.enum(shopItemCategories)).catch([]),
    status: z.array(z.enum(expenseStatuses)).catch([]),
    type: z.array(z.enum(expenseTypes)).catch([]),
    date: dateRangeSchema,
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
