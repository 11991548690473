import { z } from 'zod';
import { suppliersFiltersSchema } from './suppliers.schemas';

export const supplierStatuses = ['active', 'inactive'] as const;
export type SupplierStatus = (typeof supplierStatuses)[number];

export interface Supplier {
  id: string;
  name: string;
  status: SupplierStatus;
  systemName: string;
  logo: string;
  description: string;
  additionalInfo: string;
  termsAndConditionsLink: string;
  items: number;
  orders: number;
  isParentCompany: boolean;
  createdAt: string;
}

export type SuppliersFilters = z.infer<typeof suppliersFiltersSchema>;
