import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    formControl: {
      '& .MuiInputBase-root': {
        height: 44,
      },
    },
    placeholder: {
      top: -6,
      '&.MuiInputLabel-shrink': {
        display: 'none',
      },
    },
    link: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);
