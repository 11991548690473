import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';

const SuccessText = withStyles((theme) => ({
  root: {
    color: theme.palette.success.light,
  },
}))(Typography);

const WarningText = withStyles((theme) => ({
  root: {
    color: theme.palette.warning.light,
  },
}))(Typography);

const BenefitAssignmentStatusCell = <T extends { inactive?: boolean }>({
  value,
  row,
}: CellProps<T, string>) => {
  const { t } = useTranslation();
  const { inactive = false } = row.original;
  const commonProps = {
    style: { fontWeight: 700, color: inactive ? 'inherit' : undefined },
  };
  switch (value) {
    case 'on':
      return <SuccessText {...commonProps}>{t('status.on')}</SuccessText>;
    case 'flex':
      return <WarningText {...commonProps}>{t('status.flex')}</WarningText>;
    case 'off':
      return <Typography {...commonProps}>{t('status.off')}</Typography>;
    default:
      return null;
  }
};

export default BenefitAssignmentStatusCell;
