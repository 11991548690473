import { z } from 'zod';
import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { benefitCategories } from 'store/benefits';
import {
  alternativeEmployeesAssignmentStatuses,
  alternativeSelectionStatuses,
} from './alternatives.types';

export const alternativesFiltersSchema = z.object({
  company: z.array(z.string()).catch([]),
  department: z.array(z.string()).catch([]),
  customCategory: z.string().catch(''),
  customCategoryValue: z.array(z.string()).catch([]),
});

export const alternativeEmployeesFiltersSchema = z
  .object({
    search: z.string().catch(''),
    status: z.array(z.enum(alternativeSelectionStatuses)).catch([]),
    benefits: z.array(z.string()).catch([]),
    benefitCategories: z.array(z.enum(benefitCategories)).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    customCategory: z.string().catch(''),
    customCategoryValues: z.array(z.string()).catch([]),
    assignmentStatus: z
      .array(z.enum(alternativeEmployeesAssignmentStatuses))
      .catch([]),
    expiresAt: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);
