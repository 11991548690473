import { MpControlType, MpFormType } from '@mp-react/form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Matchers } from '../../constants/form/form';
import { CustomFormControl } from '../../types/Common';

export const useAdministratorForm = (disabledEmail?: boolean): MpFormType => {
  const { t } = useTranslation();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: MpControlType.text,
        key: 'firstName',
        validations: {
          minLength: 1,
        },
        messages: {
          required: t('form.required'),
          minLength: t('form.min_length'),
        },
        label: t('form.first_name'),
        required: true,
        tooltip: 'tooltip.administrator_first_name',
      },
      {
        type: MpControlType.text,
        key: 'lastName',
        validations: {
          minLength: 1,
        },
        messages: {
          required: t('form.required'),
          minLength: t('form.min_length'),
        },
        label: t('form.last_name'),
        required: true,
        tooltip: 'tooltip.administrator_last_name',
      },
      {
        type: 'phone' as MpControlType,
        key: 'phone',
        messages: {
          required: t('form.required'),
          minLength: t('form.min_length'),
        },
        label: t('form.mobile_number'),
        required: true,
        tooltip: 'tooltip.administrator_phone',
      },
      {
        type: MpControlType.text,
        key: 'email',
        validations: {
          minLength: 2,
          pattern: String(Matchers.EMAIL).slice(1, -1),
        },
        messages: {
          required: t('form.required'),
          pattern: t('form.pattern'),
          minLength: t('form.min_length'),
        },
        label: t('common.email'),
        required: true,
        tooltip: 'tooltip.administrator_email',
        disabled: !!disabledEmail,
      },
    ],
    [disabledEmail, t],
  );

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
