import { DeleteIconButton, EditIconButton } from 'melp-design/components';
import { Dropdown } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import {
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types/general';
import { getBorder } from '../../../../../constants/Style';
import { useItemPrice } from '../../../../../state/MarketplaceItems';
import { MarketplaceItemPrice } from '../../../../../types/MarketplaceItems';
import { currencyFormatter } from '../../../../../utils/Currency';
import { useDialog } from '../../../../../utils/Dialog';
import PriceDialog, { PriceFormData } from './PriceDialog';
import PriceTranslations from './PriceTranslations';
import { toRequestData, usePriceTypes } from './Utils';

const useStyles = makeStyles((theme) => ({
  pricesRow: {
    '&.MuiTableRow-root td:first-child': {
      paddingLeft: 10,
      borderRight: 'none',
    },
  },
  lastRowCollapsed: {
    '&:nth-last-child(2)': {
      '&::after': {
        opacity: 0,
      },

      '& td': {
        borderBottom: getBorder(Colors.elementsGrey),
      },

      '& td:first-child': {
        borderBottomLeftRadius: 20,
      },

      '& td:last-child': {
        borderBottomRightRadius: 20,
      },
    },

    '&:nth-last-child(1) > td': {
      borderBottom: 'none !important',
    },
  },
  rowExpanderCell: {
    width: 35,
  },
  rowExpander: {
    transition: theme.transitions.create('transform'),
  },
  rowExpanderExpanded: {
    transform: 'rotate(180deg)',
  },
}));

interface Props {
  itemId: string;
  price: MarketplaceItemPrice;
  currency: Currency;
}

const PriceRow = ({ price, currency, ...props }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [rowExpanded, setRowExpanded] = useState(false);
  const priceDialog = useDialog();

  const { update, remove } = useItemPrice(props.itemId, price.id);

  const priceTypes = usePriceTypes();
  const priceTypeLabel = priceTypes.find(
    (type) => type.key === price.priceType,
  )?.label;

  const amount =
    price.priceType === 'priceRange'
      ? `${currencyFormatter.formatFractionalUnitAmount(
          price.priceRangeFrom,
          currency,
        )} - ${currencyFormatter.formatFractionalUnitAmount(
          price.priceRangeTo,
          currency,
        )}`
      : currencyFormatter.formatFractionalUnitAmount(price.price, currency);

  const handleSubmit = async (data: PriceFormData) => {
    const requestData = toRequestData(data);
    await update.execute(requestData);
    priceDialog.closeDialog();
  };

  const lastRowClass = !rowExpanded ? classes.lastRowCollapsed : undefined;

  return (
    <>
      <TableRow className={clsx(classes.pricesRow, lastRowClass)} hover>
        <TableCell className={classes.rowExpanderCell}>
          <IconButton onClick={() => setRowExpanded(!rowExpanded)} size="large">
            <Dropdown
              className={clsx(classes.rowExpander, {
                [classes.rowExpanderExpanded]: rowExpanded,
              })}
            />
          </IconButton>
        </TableCell>
        <TableCell>{priceTypeLabel}</TableCell>
        <TableCell align="right">{amount}</TableCell>
        <TableCell align="right" style={{ borderRight: 'none' }}>
          {!price.discountPercentage ? '-' : price.discountPercentage}
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" gap="10px">
            <EditIconButton onClick={priceDialog.openDialog} />
            <DeleteIconButton
              onClick={() => remove.execute()}
              loading={remove.loading}
            />
          </Stack>
          <PriceDialog
            title={t('marketplaceItems.updatePrice')}
            initialData={price}
            currency={currency}
            open={priceDialog.open}
            onClose={priceDialog.closeDialog}
            onSubmit={handleSubmit}
            loading={update.loading}
          />
        </TableCell>
      </TableRow>
      <TableRow className={lastRowClass}>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
            <PriceTranslations itemId={props.itemId} priceId={price.id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PriceRow;
