import { Stack } from '@mui/material';
import { Modal, NumberInput, TextField } from 'melp-design/components';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { useUpdateVariantInventory } from 'store/internal-shop';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';

interface Values {
  inventoryChange: number;
  comment: string;
}

const INITIAL_VALUES: Values = {
  inventoryChange: NaN,
  comment: '',
};

interface Props extends ModalProps {
  variantId: string;
  action: 'ADD' | 'REMOVE';
}

export const InventoryUpdateModal: FC<Props> = ({
  variantId,
  action,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const { mutate: updateInventory, isLoading } = useUpdateVariantInventory();

  return (
    <form
      onSubmit={handleSubmit(({ inventoryChange, comment }) => {
        const multiplier = action === 'ADD' ? 1 : action === 'REMOVE' ? -1 : 0;
        updateInventory(
          {
            variantId,
            data: { inventoryChange: multiplier * inventoryChange, comment },
          },
          {
            onSuccess: () => {
              predefinedToasts.success.updated();
              closeModal({ action: 'CLOSE' });
            },
          },
        );
      })}
    >
      <Modal.Content
        title={
          action === 'ADD'
            ? 'Add inventory'
            : action === 'REMOVE'
            ? t('shop.actions.remove_inventory')
            : ''
        }
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: isLoading,
          },
        ]}
      >
        <Stack spacing={2}>
          <Controller
            name="inventoryChange"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <NumberInput
                  label={t('shop.variant.inventory')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  key={value}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('clients.comment')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  multiline
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
