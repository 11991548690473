import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './administrators.loaders';
import { converters } from './administrators.converters';
import { AdministratorsFilters } from './administrators.types';

export const administratorsKeys = queryKeyFactory('administrators');

export const useAdministrators = (
  filters: Partial<AdministratorsFilters> = {},
) => {
  const query = useQuery({
    queryKey: administratorsKeys.list(filters),
    queryFn: () => loaders.getAdministrators({ filters }),
    select: converters.getAdministrators,
  });

  return query;
};
