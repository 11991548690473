export enum SupplierStatus {
  active = 'active',
  inactive = 'inactive',
}

export interface Supplier {
  id: string;
  name: string;
  logoId: string;
  description: string;
  additionalInfo: string;
  termsAndConditionsLink: string;
  status: SupplierStatus;
  creationDate: Date;
}

export interface SupplierCreationRequestBody {
  name: string;
  logoId?: string;
  description?: string;
  additionalInfo?: string;
  termsAndConditionsLink: string;
  status: SupplierStatus;
}

export interface SupplierUpdateRequestBody
  extends SupplierCreationRequestBody {}
