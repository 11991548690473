import { useMemo, useCallback, useState } from 'react';
import { MpControlProps, controlRegisterOptions } from '@mp-react/form';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import CompanyDropdownView from './CompanyDropdownView/CompanyDropdownView';
import DepartmentDropdownView from './DepartmentDropdownView/DepartmentDropdownView';

export default function CompanyAndDepartmentDropdown({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  const [companyId, setCompanyId] = useState<string>('');

  const companyValue = useCallback(
    (companyId: string) => {
      setCompanyId(companyId);
    },
    [setCompanyId],
  );

  return (
    <Box style={{ marginTop: -16 }}>
      <Controller
        name={control.key ?? ''}
        rules={rules}
        control={hookFormControl}
        defaultValue={null}
        render={({ onChange, value, name, ref }) => (
          <CompanyDropdownView
            name={name}
            inputRef={ref}
            onChange={onChange}
            value={value}
            control={control}
            locale={locale}
            size={size}
            layout={layout}
            variant={variant}
            error={error}
            defaultMessages={defaultMessages}
            handleCompanyId={companyValue}
          />
        )}
      />
      <Controller
        name="departmentId"
        rules={rules}
        control={hookFormControl}
        defaultValue={null}
        render={({ onChange, value, name, ref }) => (
          <DepartmentDropdownView
            name={name}
            inputRef={ref}
            onChange={onChange}
            value={value}
            control={control}
            locale={locale}
            size={size}
            layout={layout}
            variant={variant}
            error={error}
            defaultMessages={defaultMessages}
            companyId={companyId ?? ''}
          />
        )}
      />
    </Box>
  );
}
