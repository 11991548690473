import { FC } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  when: boolean;
}

export const DataLossPrompt: FC<Props> = ({ when = false }) => {
  const { t } = useTranslation();

  return <Prompt when={when} message={t('errors.user_leaving_edited_page')} />;
};
