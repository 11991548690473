import { TypographyColor } from 'melp-design/components';
import { DiscountStatus } from 'store/discounts';
import { DiscountPublishStatus } from './admin-discounts.types';

export const COLOR_BY_STATUS: Record<DiscountStatus, TypographyColor> = {
  draft: 'textPrimary',
  active: 'textPrimary',
  inactive: 'textPrimary',
  expired: 'error',
};

export const COLOR_BY_PUBLISH_STATUS: Record<
  DiscountPublishStatus,
  TypographyColor
> = {
  published: 'success',
  unpublished: 'textPrimary',
};
