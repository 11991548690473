import { BenefitCategory } from 'store/benefits';
import { UploadedFile } from 'store/files';
import {
  NumberRange,
  PaginationFilters,
  SortFilters,
  Translations,
} from 'types/Common';
import { Currency } from 'types/general';
import { ShopItemCategory } from 'types/MarketplaceItems';

export const shopItemStatuses = ['active', 'inactive', 'archived'] as const;
export type ShopItemStatus = (typeof shopItemStatuses)[number];

export const priceVariants = ['fixedPrice', 'priceRange'] as const;
export type PriceVariant = (typeof priceVariants)[number];

export interface BaseShopItem {
  id: string;
  name: string;
  image: UploadedFile | null;
  sku: string;
  vatRate: number;
  currency: Currency;
  countries: string[];
  countriesAlpha2: string[];
  cities: string[];
  benefitPlanTypes: BenefitCategory[];
  categories: ShopItemCategory[];
  status: ShopItemStatus;
  supplier: {
    id: string;
    name: string;
    logo: UploadedFile | null;
    termsAndConditionsLink?: string;
    additionalInfo: string;
    isParentCompany: boolean;
  };
  priority: number;
  searchTags: string;
  translations: Translations<{
    id: string;
    title: string;
    description: string;
  }>;
  createdAt: string;
}

export interface ShopItem extends BaseShopItem {
  prices: Array<{
    id: string;
    price: number | null;
    priceRangeFrom: number | null;
    priceRangeTo: number | null;
    priceType: PriceVariant;
    discountPercentage: number;
    translations: Translations<{ description: string }>;
    index: number;
    inventory: number;
  }>;
}

export interface ShopItemsFilters extends PaginationFilters, SortFilters {
  name: string;
  price: NumberRange | undefined;
  countries: string[];
  benefitCategories: BenefitCategory[];
  categories: ShopItemCategory[];
}
