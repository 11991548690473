import { FC, useState } from 'react';
import { useLiteBenefits } from 'store/benefits';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const BenefitsFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: benefits, isLoading } = useLiteBenefits({
    name: [search],
  });

  return (
    <SelectFilter
      {...props}
      options={benefits?.map((benefit) => ({
        key: benefit.id,
        label: benefit.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
