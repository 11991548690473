import { Hub, HubCallback } from '@aws-amplify/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from 'config';
import { useLoading } from '../../utils/Loading';
import { usePredefinedToasts } from '../../utils/Toast';
import { auth } from '../service/index';
import { useAuthAction } from './useAuthAction';
import { useRedirectTo } from './useRedirectTo';

/**
 * Auth hook to provide state and actions.
 */
export const useAuth = () => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const history = useHistory();

  const { redirectTo, navigateWithRedirectState } = useRedirectTo();

  const [loggedIn, setLoggedIn] = useState(false);
  const { startLoading, stopLoading, loading } = useLoading(true);
  const [challengeDestination, setChallengeDestination] = useState('');

  const checkIfUserLoggedIn = useCallback(async () => {
    startLoading();

    try {
      const result = await auth.isLoggedIn();
      setLoggedIn(result);
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading]);

  const handleAuth: HubCallback = useCallback(
    ({ payload }) => {
      switch (payload.event) {
        // success events
        case 'signIn':
        case 'tokenRefresh': {
          setLoggedIn(true);
          stopLoading();
          break;
        }
        case 'signOut': {
          // Navigating this way to clear the memory. It prevents from bleeding
          // a current session data into a new one, when switching an account
          // without closing the tab.
          window.location.href = `${window.location.origin}${ROUTES.auth.login}`;
          break;
        }

        // failure events
        case 'tokenRefresh_failure':
        case 'signIn_failure': {
          setLoggedIn(false);
          stopLoading();
          break;
        }

        default: {
          // we do not handle other events like "configured", "autoSignIn", etc.
          break;
        }
      }
    },
    [stopLoading],
  );

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', handleAuth, 'useAuth');
    checkIfUserLoggedIn(); // on init, see if user is already logged in

    return unsubscribe;
  }, [handleAuth, checkIfUserLoggedIn]);

  const navigateToSmsMfa = (destinationToSet?: string) => {
    setChallengeDestination(destinationToSet ?? '');
    navigateWithRedirectState(ROUTES.auth.twoFactor);
  };

  const passwordLoginActionHandler = async (data: {
    email: string;
    password: string;
  }) => {
    const result = await auth.loginWithPassword(data.email, data.password);

    switch (result.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        navigateWithRedirectState(ROUTES.auth.newPassword);
        break;
      case 'SMS_MFA':
        navigateToSmsMfa(result.challengeDestination);
        break;
    }

    return result;
  };

  const passwordLogin = useAuthAction(passwordLoginActionHandler);

  const firstPasswordLogin = useAuthAction(
    passwordLoginActionHandler,
    (e, defaultHandler) => {
      if (
        typeof e === 'object' &&
        e !== null &&
        'code' in e &&
        e.code === 'NotAuthorizedException'
      ) {
        toast.error(t('errors.invite_expired'));
        return;
      }

      defaultHandler(e);
    },
  );

  const verifySMSCode = useAuthAction(auth.verifySMSCode);

  const resendCode = () => {
    navigateWithRedirectState(ROUTES.auth.login);
  };

  const completeNewPassword = useAuthAction(async (password: string) => {
    const result = await auth.completeNewPassword(password);

    if (result.challengeName === 'SMS_MFA') {
      navigateToSmsMfa();
    }

    toast.success(t('login.new_password_set'));
  });

  const changePassword = useAuthAction(
    async (data: { currentPassword: string; newPassword: string }) => {
      await auth.changePassword(data.currentPassword, data.newPassword);
      predefinedToasts.success.updated();
      history.push(ROUTES.root.index);
    },
  );

  const initiatePasswordReset = useAuthAction(async (email: string) => {
    await auth.initiatePasswordReset(email);
    toast.success(t('success.reset_code_sent'));
    history.push({ pathname: ROUTES.auth.resetPassword, state: { email } });
  });

  const resetPassword = useAuthAction(
    async (data: { code: string; newPassword: string }) => {
      await auth.resetPassword(data.code, data.newPassword);
      toast.success(t('success.password_changed_succesfully'));
      history.push(ROUTES.auth.login);
    },
  );

  const microsoftLogin = useAuthAction<void, void>(auth.loginWithMicrosoft);

  return {
    loading,
    loggedIn,
    redirectTo,
    passwordLogin,
    firstPasswordLogin,
    challengeDestination,
    verifySMSCode,
    resendCode,
    completeNewPassword,
    changePassword,
    initiatePasswordReset,
    resetPassword,
    microsoftLogin,
  };
};
