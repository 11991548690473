import {
  Button,
  TableOld,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { ROUTES } from 'config';
import { useRecognitionAchievements } from 'state/Recognition';
import { AchievementAssignmentLimitFrequency } from 'types/Recognition';
import { useLimitFrequencyLabels } from 'containers/Recognition/common/useLimitFrequencyLabels';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import AchievementImage from './AchievementImage';
import AchievementsItemActions from './AchievementsItemActions';

export const RecognitionsAchievementsLists = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const pointsLabel = usePointsLabel();
  const limitFrequencyLabels = useLimitFrequencyLabels();

  const achievements = useRecognitionAchievements();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack direction="row" alignItems="center" gap="15px" p="10px 5px">
          <Button
            icon={Add}
            variant="tertiary"
            onClick={() =>
              history.push(ROUTES.recognitions.achievements.create)
            }
          />
          <Typography variant="h2">
            {t('recognition.achievementsListTitle')}
          </Typography>
        </Stack>
      </TableToolbar>
      <TableOld
        data={achievements.data}
        loading={achievements.loading}
        rowKey="id"
        columns={[
          {
            key: 'imageId',
            render: (value: string) => <AchievementImage imageId={value} />,
            width: '1%',
          },
          {
            key: 'name',
            title: t('recognition.achievementsListName'),
          },
          {
            key: 'points',
            title: pointsLabel,
            align: 'right',
          },
          {
            key: 'limit',
            title: t('recognition.achievementAssignmentLimit'),
            align: 'right',
            render: (_, record) => (record.limit ? record.limit : ''),
          },
          {
            key: 'limitPeriod',
            title: t('recognition.achievementAssignmentLimitFrequency'),
            render: (
              value: AchievementAssignmentLimitFrequency | undefined,
              record,
            ) => {
              if (!value || !record.limit) {
                return '';
              }
              return limitFrequencyLabels[value] ?? value;
            },
          },
          {
            key: 'actions',
            render: (_, record) => <AchievementsItemActions data={record} />,
          },
        ]}
      />
    </TableWrapper>
  );
};
