import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    breadcrumbs: {
      '& .MuiBreadcrumbs-ol': {
        flexWrap: 'nowrap',
        '& > .MuiBreadcrumbs-li': {
          textAlign: 'center',
          lineHeight: '20px',
          '& > a': {
            lineHeight: '20px',
          },
        },
      },
    },
  }),
);
