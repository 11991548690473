import {
  Box,
  ButtonBase,
  Collapse,
  Paper,
  Stack,
  SxProps,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { Dropdown } from '../../icons';
import Typography from '../Typography/Typography';

interface Props {
  /**
   * Panel title
   */
  title?: ReactNode;
  /**
   * Panel subtitle
   */
  subtitle?: string;
  /**
   * Panel content
   */
  children: ReactNode;
  /**
   * Styling props
   */
  sx?: SxProps;
  /**
   * A content to display on the side
   */
  collapsible?: boolean;
  /**
   * Sets the iniitial state of collapsible section.
   */
  expanded?: boolean;
  /**
   * Content displayed on the side.
   */
  side?: ReactNode;
}

/**
 * Layout box to outline certain sections of a content.
 */
const Panel = ({ title, subtitle, collapsible = false, ...props }: Props) => {
  const [expanded, setExpanded] = useState(props.expanded ?? true);

  const handleVisibilityToggle = () => {
    setExpanded((currentValue) => !currentValue);
  };

  let header = (
    <Stack
      direction="row"
      gap="5px"
      alignItems="start"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Stack gap="10px" sx={{ textAlign: 'left' }}>
        {!!title && <Typography variant="h2">{title}</Typography>}
        {!!subtitle && (
          <Typography variant="p1" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Stack>
      <div>
        {props.side}
        {collapsible && (
          <Dropdown
            style={{
              marginTop: 7,
              transition: 'transform 0.2s',
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        )}
      </div>
    </Stack>
  );

  if (collapsible) {
    header = (
      <ButtonBase
        sx={{
          width: '100%',
          justifyContent: 'start',
        }}
        disableRipple
        onClick={handleVisibilityToggle}
      >
        {header}
      </ButtonBase>
    );
  }

  return (
    <Paper variant="outlined" sx={{ padding: '40px', ...props.sx }}>
      {header}
      <Collapse in={expanded}>
        <Box mt={!!title || !!subtitle ? '30px' : 0}>{props.children}</Box>
      </Collapse>
    </Paper>
  );
};

export default Panel;
