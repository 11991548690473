import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authKeys } from 'store/auth';
import { loaders } from './companies.loaders';

export const useUpdateCompanySettings = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.updateCompanySettings, {
    onSuccess: () => {
      return queryClient.invalidateQueries(authKeys.all);
    },
  });

  return mutation;
};
