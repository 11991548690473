import { isPlainObject } from 'lodash';

type SupportedParamValues = string | number | boolean;

const convertToParamsTuples = <T extends {}>(
  params: T | undefined,
  keyTransformation: (key: string, isArrayItem: boolean) => string,
) => {
  if (!params || !Object.keys(params).length) {
    return [];
  }
  const paramsKeys = Object.keys(params);
  return paramsKeys.reduce((acc, key) => {
    const paramValue = (params as Record<string, any>)[key];
    if (paramValue !== null && paramValue !== undefined) {
      if (Array.isArray(paramValue)) {
        if (paramValue.length) {
          paramValue.forEach((item) => {
            if (item !== null && item !== undefined) {
              acc.push([keyTransformation(key, true), item]);
            }
          });
        }
      } else {
        acc.push([keyTransformation(key, false), paramValue]);
      }
    }
    return acc;
  }, [] as Array<[string, SupportedParamValues]>);
};

const convertToQueryString = (
  paramsTuples: [string, SupportedParamValues][],
) => {
  if (!paramsTuples.length) {
    return '';
  }
  const queryValue = paramsTuples
    .map(
      (paramsTuples) =>
        `${paramsTuples[0]}=${encodeURIComponent(paramsTuples[1])}`,
    )
    .join('&');
  return '?' + queryValue;
};

const convertToFilterQueryValue = (key: string, isArrayItem: boolean) =>
  `filter[${key}]${isArrayItem ? '[]' : ''}`;

interface ParamsConverterOptions {
  /**
   * Specified keys won't be converted to filter-like params.
   */
  exclude?: string[];
}

/**
 * Does the same thing as @link convertToFilterQueryParams but does not apply
 * the filter pattern for "sort", "page" and "pageSize" parameters.
 *
 * @param params Parameters object
 * @returns query string to attach to URL. It already contains "?" sign.
 */
export const convertToQueryParams = <T extends {}>(
  params?: T,
  options?: ParamsConverterOptions,
) => {
  const paramsTuples = convertToParamsTuples(params, (key, isArrayItem) => {
    if (
      ['sort', 'page', 'pageSize', ...(options?.exclude ?? [])].includes(key)
    ) {
      return key;
    }
    return convertToFilterQueryValue(key, isArrayItem);
  });
  return convertToQueryString(paramsTuples);
};

/**
 * Converts given parameters object to filter query parameters. It converts all
 * parameters according to the following pattern:
 *
 *             filter[<parameter key>]=<parameter value>
 *
 * If parameter value is an array, then each array item will be presented as a
 * filter parameter (e.g., object { foo: ["bar", "baz"]} will be converted to
 * string "?filter[foo]=bar&filter[foo]=baz").
 *
 * @param params Parameters object
 * @returns query string to attach to URL. It already contains "?" sign.
 */
export const convertToFilterQueryParams = <T extends {}>(params?: T) => {
  const paramsTuples = convertToParamsTuples(params, convertToFilterQueryValue);
  return convertToQueryString(paramsTuples);
};

export const calculateFiltersTotal = <F extends Record<string, any>>(
  filters: F,
  keysToExtractFromTotalCalculation: (keyof F)[] = [],
): number => {
  const total = Object.entries(filters).reduce((acc, [key, value]) => {
    if (
      ['page', 'pageSize', ...keysToExtractFromTotalCalculation].includes(key)
    ) {
      return acc;
    }

    if (Array.isArray(value)) {
      return acc + value.length;
    }

    if (isPlainObject(value)) {
      if (('from' in value && !!value.from) || ('to' in value && !!value.to)) {
        return acc + 1;
      }
    }

    if (typeof value === 'string' && value.length) {
      return acc + 1;
    }

    if (typeof value === 'number' && !Number.isNaN(value)) {
      return acc + 1;
    }

    if (typeof value === 'boolean' && value) {
      return acc + 1;
    }

    return acc;
  }, 0);

  return total;
};
