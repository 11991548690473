import { makeStyles } from '@mui/styles';
import { richTextAsPlainText } from '../../../utils/richTextAsPlainText';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const useStyles = makeStyles(() => ({
  plainTextContainer: {
    whiteSpace: 'pre-line',
  },
}));

interface Props {
  /**
   * Text to display. Should be HTML string.
   */
  value?: string;
  /**
   * Removes text styling except new lines.
   */
  plainText?: boolean;
}

/**
 * Component for displaying a rich text created with RichTextEditor component.
 */
const RichText = (props: Props) => {
  const classes = useStyles();
  if (!props.value) {
    return null;
  }
  if (props.plainText) {
    const plainText = richTextAsPlainText(props.value);
    return <span className={classes.plainTextContainer}>{plainText}</span>;
  }
  return <RichTextEditor value={props.value} displayMode />;
};

export default RichText;
