import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './custom-categories.loaders';
import { customCategoriesKeys } from './custom-categories.queries';

export const useCreateCustomCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.createCustomCategory, {
    onSuccess: () => {
      return queryClient.invalidateQueries(customCategoriesKeys.lists);
    },
  });

  return mutation;
};

export const useCreateCustomCategoryValue = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.createCustomCategoryValue, {
    onSuccess: () => {
      return queryClient.invalidateQueries(customCategoriesKeys.lists);
    },
  });

  return mutation;
};
