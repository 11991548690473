import { FC } from 'react';
import {
  Button,
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Tile,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APP, ROUTES } from 'config';
import {
  internalShopInventoryEventsFiltersSchema,
  useExportToXls,
  useInternalShopInventoryEvents,
} from 'store/internal-shop';
import { useIdParam } from 'utils/useIdParam';
import moment from 'moment';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useModalContext } from 'store/modal';
import { useCompanyLanguages } from 'state/Administrators';
import {
  InventoryUpdateModal,
  ShopInternalDisabledFeatureAlert,
} from 'containers/shop';
import { useSearchParams } from 'utils/navigation';

export const ShopInternalInventoryDetails: FC = () => {
  const { t } = useTranslation();
  const variantId = useIdParam();
  const { defaultLanguage } = useCompanyLanguages();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    internalShopInventoryEventsFiltersSchema,
  );

  const { data: events, isLoading } = useInternalShopInventoryEvents(
    variantId,
    filters,
  );

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <>
      <ListPageHeader
        top={<ShopInternalDisabledFeatureAlert />}
        title={[
          events?.meta.itemName,
          events?.meta.translations.find(
            (trans) => trans.language === defaultLanguage,
          )?.title,
        ]
          .filter(Boolean)
          .join(' > ')}
        backTo={ROUTES.shop.internal.inventory.list}
        sideContent={
          <Stack flexDirection="row" gap={2}>
            <Button
              label={t('shop.actions.add_inventory')}
              onClick={() => {
                openModal({
                  component: InventoryUpdateModal,
                  props: { action: 'ADD', variantId },
                });
              }}
            />
            <Button
              label={t('shop.actions.remove_inventory')}
              onClick={() => {
                openModal({
                  component: InventoryUpdateModal,
                  props: { action: 'REMOVE', variantId },
                });
              }}
            />
          </Stack>
        }
        toolbarContent={
          <Stack gap={3}>
            <Stack direction="row" alignItems="center" gap="15px">
              {isLoading ? (
                <Skeleton variant="rounded" width={200} height={81} />
              ) : events?.meta.inventory !== undefined ? (
                <Tile
                  title={t('shop.inventory.title')}
                  items={[String(events.meta.inventory)]}
                />
              ) : null}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <ListTotal total={events?.total} />

              <Stack direction="row" alignItems="center" gap="10px">
                {/* Filters */}

                {/* Divider */}

                <DownloadExcelButton
                  listLength={events?.total ?? 0}
                  exportToExcel={() => exportToXls({ variantId })}
                  loading={isExportingToXls}
                />
              </Stack>
            </Stack>
          </Stack>
        }
      />
      <Table
        data={events?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'createdAt',
            header: t('common.date'),
            cell: ({ row: { original: event } }) => {
              return moment(event.createdAt.split('T')[0]).format('l');
            },
          },
          {
            id: 'inventoryChange',
            header: t('shop.inventoryChange.title'),
            cell: ({ row: { original: event } }) => {
              return event.inventoryChange;
            },
            meta: { align: 'right' },
          },
          {
            id: 'doneBy',
            header: t('common.done_by'),
            cell: ({ row: { original: event } }) => {
              return event.variant === 'monthlyCount' ? (
                t('clients.feature-marketplace')
              ) : event.doneBy ? (
                <Link
                  label={event.doneBy.name}
                  to={ROUTES.administrators.details.replace(
                    ':id',
                    event.doneBy.id,
                  )}
                />
              ) : null;
            },
          },
          {
            id: 'comment',
            header: t('clients.comment'),
            cell: ({ row: { original: event } }) => {
              return event.variant === 'monthlyCount'
                ? t('orders.for_period', {
                    date: moment(event.createdAt.split('T')[0]).format(
                      'MMMM YYYY',
                    ),
                  })
                : event.comment;
            },
          },
        ]}
        rows={{
          color: ({ original: event }) => {
            if (event.variant === 'monthlyCount') {
              return SystemColors.grey[90];
            }
          },
        }}
        pagination={
          events && {
            count: events.total,
            page: events.page,
            pageSize: events.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
