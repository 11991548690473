import { converters as tableConverters } from 'store/table';
import {
  IBaseParentCompany,
  ICompany,
  IParentCompany,
  IParentCompanyWithStats,
} from 'types/income';
import { loaders } from './companies.loaders';
import {
  BaseParentCompany,
  Company,
  Department,
  ParentCompany,
  ParentCompanyWithStats,
} from './companies.types';

const convertCompany = (company: ICompany): Company => {
  return {
    id: company.id,
    name: company.name,
    status: company.status,
    parentCompanyId: company.parentCompanyId,
    companyGroups: company.companyGroups,
    employeeCount: Number(company.employeeCount),
    departmentCount: Number(company.departmentCount),
  };
};

const convertCompanies = (companies: ICompany[]): Company[] => {
  return companies.map(convertCompany);
};

export const convertBaseParentCompany = (
  client: IBaseParentCompany,
): BaseParentCompany => {
  return {
    id: client.id,
    name: client.name,
    country: client.country,
    internalComment: client.internalComment ?? '',
    status: client.status,
    activeEmployees: client.activeEmployees ?? 0,
    activeMobileUsers: client.activeMobileUsers ?? 0,
    inactiveEmployees: client.inactiveEmployees ?? 0,
  };
};

export const convertParentCompany = (client: IParentCompany): ParentCompany => {
  return {
    ...convertBaseParentCompany(client),
    code: client.code,
    defaultCurrency: client.defaultCurrency,
    creationDate: client.creationDate,
    deactivationDate: client.deactivationDate,
    supportedLanguages: client.supportedLanguages,

    features: client.features,
    settings: {
      defaultLanguage: client.settings.defaultLanguage,
      isPersonalCodeRequiredForLogin:
        client.settings.isPersonalCodeRequiredForLogin,
      isEmailVisibleInternally: client.settings.isEmailVisibleInternally,
      discountPlan: client.settings.discountPlan,
    },
  };
};

const convertParentCompaniesWithStats = (
  companies: IParentCompanyWithStats[],
): ParentCompanyWithStats[] => {
  return companies.map(convertBaseParentCompany);
};

const convertDepartments = (
  hierarchy: Awaited<ReturnType<typeof loaders.getDepartments>>['data'],
): Department[] => {
  return hierarchy
    .map(({ companies }) =>
      companies.map((company) =>
        company.departments.map((department) => ({
          id: department.id,
          name: department.name,
        })),
      ),
    )
    .flat(2);
};

export const converters = {
  getCompanies: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCompanies>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCompanies(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getParentCompanies: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getParentCompanies>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertParentCompaniesWithStats(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepartments: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepartments>>) => {
    return convertDepartments(data);
  },
};
