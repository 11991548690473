import { FC, useState } from 'react';
import { useAdminOrders } from 'store/admin-orders';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const AdminOrdersFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: orders, isLoading } = useAdminOrders({
    orderNrs: [search],
  });

  return (
    <SelectFilter
      {...props}
      options={orders?.items.map((order) => ({
        key: order.id,
        label: order.orderNr,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
