import { Box, Chip } from '@mui/material';
import { useContext, useMemo } from 'react';
import { CellProps } from 'react-table';
import { MetaContext, useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Style';
import { BasicActivityStatus } from '../../../types/Common';

const BasicStatusPill = (props: CellProps<{}>) => {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);
  const { size } = useContext(MetaContext);

  const chipContent = useMemo(() => {
    switch (value) {
      case BasicActivityStatus.active:
        return {
          style: {
            backgroundColor: Colors.StatusGreen,
            color: Colors.White,
          },
          text: t('status.active'),
        };
      case BasicActivityStatus.inactive:
        return {
          style: {
            backgroundColor: Colors.Default,
            color: Colors.TextSecondary,
          },
          text: t('status.inactive'),
        };
      default:
        return null;
    }
  }, [t, value]);

  if (!chipContent) {
    return <span>-</span>;
  }

  return (
    <Box textAlign="center">
      <Chip size={size} style={chipContent.style} label={chipContent.text} />
    </Box>
  );
};

export default BasicStatusPill;
