import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-orders.loaders';
import { converters } from './admin-orders.converters';
import { AdminOrdersFilters, SearchFilterName } from './admin-orders.types';

export const adminOrdersKeys = queryKeyFactory('admin-orders');

export const useAdminOrders = (
  filters: Partial<AdminOrdersFilters> = {},
  options: { enabled?: boolean } = {},
) => {
  const query = useQuery({
    queryKey: adminOrdersKeys.list(filters),
    queryFn: () => loaders.getAdminOrders({ filters }),
    select: converters.getAdminOrders,
    enabled: options.enabled,
  });

  return query;
};

export const useAdminOrder = (orderId: string) => {
  const query = useQuery({
    queryKey: adminOrdersKeys.detail(orderId),
    queryFn: () => loaders.getAdminOrder({ orderId }),
    select: converters.getAdminOrder,
    enabled: !!orderId,
  });

  return query;
};

export const useFilterValues = (
  filterName: SearchFilterName,
  search: string,
) => {
  const query = useQuery({
    queryKey: adminOrdersKeys.list(filterName, search),
    queryFn: () => loaders.getFilterValues({ filterName, search }),
    select: converters.getFilterValues,
    enabled: !!search,
  });

  return query;
};
