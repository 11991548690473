import { Button, Typography } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'store/confirm';
import { useModalContext } from 'store/modal';
import {
  COLOR_BY_STATUS,
  Recognition,
  useApproveRecognition,
  useDeleteRecognition,
  useEditRecognition,
} from 'store/recognitions';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { Stack } from '@mui/material';
import { RecognitionEditModal } from '..';

interface Props {
  recognition: Recognition;
}

export const RecognitionActions: FC<Props> = ({ recognition }) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const { confirmation } = useConfirm();
  const pointsLabel = usePointsLabel();

  const { mutate: approveRecognition, isLoading: isApproving } =
    useApproveRecognition();
  const { mutate: editRecognition, isLoading: isEditing } =
    useEditRecognition();
  const { mutate: deleteRecognition, isLoading: isDeleting } =
    useDeleteRecognition();

  const editAction = (
    <Button
      label={t('common.edit')}
      variant="neutral-outline"
      size="sm"
      onClick={async () => {
        const response = await openModal({
          component: RecognitionEditModal,
          props: {
            editable: recognition.type === 'companyToEmployee',
            comment: recognition.comment,
            points: recognition.pointsReceived,
          },
        });

        if (response.action === 'SAVE') {
          editRecognition({
            recognitionId: recognition.id,
            points: response.points,
            comment: response.comment,
          });
        }

        if (response.action === 'DELETE') {
          const { confirmed } = await confirmation(
            t('recognitions.delete.message', { pointsLabel }),
            t('recognitions.delete.title'),
          );

          if (confirmed) {
            deleteRecognition({ recognitionId: recognition.id });
          }
        }
      }}
      disabled={isEditing || isDeleting}
    />
  );

  switch (recognition.status) {
    case 'not_required':
      return editAction;
    case 'pending':
      return (
        <Stack direction="row" justifyContent="center" gap="10px">
          <Button
            label={t('recognition.recognitionApprovalAction-reject')}
            size="sm"
            variant="danger-outline"
            onClick={() =>
              approveRecognition({
                recognitionId: recognition.id,
                approval: false,
              })
            }
            disabled={isApproving}
          />
          <Button
            label={t('recognition.recognitionApprovalAction-approve')}
            size="sm"
            variant="secondary"
            onClick={() =>
              approveRecognition({
                recognitionId: recognition.id,
                approval: true,
              })
            }
            disabled={isApproving}
          />
        </Stack>
      );
    case 'approved':
      return (
        <Stack gap={1} alignItems="center">
          <Typography color={COLOR_BY_STATUS[recognition.status]}>
            {t('recognition.recognitionApprovalStatus-approved')}
          </Typography>
          {editAction}
        </Stack>
      );
    case 'rejected':
      return (
        <Stack gap={1} alignItems="center">
          <Typography color={COLOR_BY_STATUS[recognition.status]}>
            {t('recognition.recognitionApprovalStatus-rejected')}
          </Typography>
          {editAction}
        </Stack>
      );
    default:
      return recognition.status satisfies never;
  }
};
