import { FC } from 'react';
import { Alert, ExternalLink } from 'melp-design/components';
import { useMe } from 'state/Administrators';
import { Trans } from 'react-i18next';
import { APP } from 'config';

export const ShopDisabledFeatureAlert: FC = () => {
  const { isFeatureEnabled } = useMe();

  return isFeatureEnabled('marketplace') ? null : (
    <Alert severity="warning">
      <Trans
        i18nKey="marketplace.featureWarning"
        values={{ email: APP.email.info }}
        components={{
          mailTo: (
            <ExternalLink
              label={APP.email.info}
              href={`mailto:${APP.email.info}`}
            />
          ),
        }}
      />
    </Alert>
  );
};
