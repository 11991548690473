import { Box, BoxProps, Popper, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Style';
import { TooltipConfig } from '../../../types/Common';

interface IProps extends BoxProps {
  tooltip: string | TooltipConfig;
  anchorEl?: HTMLElement | null;
}

export default function FormTooltip(props: IProps) {
  const { tooltip, anchorEl, ...rest } = props;
  const { t } = useTranslation();
  const isTextOnly = typeof tooltip === 'string';
  const tooltipTextKey = isTextOnly ? tooltip : tooltip.text;
  const translated = useMemo(() => t(tooltipTextKey), [t, tooltipTextKey]);
  const noTranslation = useMemo(
    () => !translated || translated.includes('tooltip.'),
    [translated],
  );

  const open = useMemo(() => !!anchorEl, [anchorEl]);

  if (!tooltip || noTranslation || !anchorEl) return null;

  const config = {
    width: (!isTextOnly && tooltip.width) || 140,
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      style={{ zIndex: 1500 }}
    >
      <Box marginLeft="68px" width={config.width} {...rest}>
        <Box
          bgcolor={Colors.White}
          padding="12px 16px"
          borderRadius="8px"
          whiteSpace="pre-line"
        >
          <Typography variant="body2" color="textSecondary">
            {t(tooltipTextKey)}
          </Typography>
        </Box>
      </Box>
    </Popper>
  );
}
