import { Alert } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Component } from 'react';
import { Translation } from 'react-i18next';

/**
 * Sets a value for given key in local storage with expiration.
 * @param key Local storage property key
 * @param value Value to set
 * @param ttl Time to live in milliseconds
 */
const setWithExpiry = (key: string, value: any, ttl: number) => {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get a property value with expiration from local storage.
 * @param key Local storage property key
 * @returns A value for a given key, or null if not set yet or expired.
 */
const getWithExpiry = (key: string) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

interface Props {}

interface State {
  hasError: boolean;
}

/**
 * Error boundary that is used for errors that appeared within routes.
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // Try to reload the page when chunk loading fails
    const chunkFailedMessageExpression = /Loading chunk [\d]+ failed/;
    const unexpectedTokenMessageExpression = /Unexpected token '<'/;
    if (
      error?.message &&
      (chunkFailedMessageExpression.test(error.message) ||
        unexpectedTokenMessageExpression.test(error.message))
    ) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    } else {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Translation>
          {(t) => <Alert severity="error">{t('errors.globalError')}</Alert>}
        </Translation>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
