import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-discounts.loaders';
import { adminDiscountsKeys } from './admin-discounts.queries';

export const useCreateDiscount = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createDiscount, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminDiscountsKeys.lists);
    },
  });
};

export const useUpdateDiscount = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateDiscount, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminDiscountsKeys.all);
    },
  });
};

export const useDeleteDiscount = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteDiscount, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminDiscountsKeys.lists);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
