import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import { Button } from 'melp-design/components';
import FormDialog from '../../../components/dialogs/FormDialog/FormDialog';
import { useDialog } from '../../../utils/Dialog';
import { useCreateSupplier } from '../../../state/Suppliers';
import {
  SupplierCreationRequestBody,
  SupplierStatus,
} from '../../../types/Suppliers';
import { useSupplierForm } from '../common/SupplierForm';

export const AddSupplier = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { open, openDialog, closeDialog } = useDialog();
  const { create } = useCreateSupplier();

  const handleSubmit = async (data: SupplierCreationRequestBody) => {
    const result = await create.execute(data);
    closeDialog();
    history.push(ROUTES.admin.suppliers.details.replace(':id', result.id));
  };

  const submitButtonLabel = create.loading ? (
    <CircularProgress size={20} />
  ) : (
    t('marketplaceSuppliers.create')
  );

  const addForm = useSupplierForm();
  const initialFormData = { status: SupplierStatus.inactive };

  return (
    <div>
      <Button
        label={t('marketplaceSuppliers.addSupplier')}
        onClick={openDialog}
      />

      <FormDialog
        onCancelClick={closeDialog}
        onSubmitForm={handleSubmit}
        id="creation-dialog"
        open={open}
        title={t('marketplaceSuppliers.addSupplier')}
        actionLabel={submitButtonLabel}
        form={addForm}
        loading={create.loading}
        defaultValues={initialFormData}
      />
    </div>
  );
};
