import { MpTable } from '@mp-react/table';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, UseRowSelectRowProps } from 'react-table';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'config';
import { Button } from 'melp-design/components';
import {
  useClientAdministrators,
  useClientAdministratorsAsyncMethods,
} from '../../../../state/Clients';
import { useAdministratorsTableType } from '../../../../configs/Tables/AdministratorsTableType';
import { useAdministratorForm } from '../../../../configs/Forms/AdministratorForm';
import useTableUtils from '../../../../utils/Table';
import FormDialog from '../../../../components/dialogs/FormDialog/FormDialog';
import { Administrator } from '../../../../types/Administrators';
import { bulkMethods, rowMethods } from '../../../../utils/TableMethods';
import Toolbar from '../../../../components/layouts/Toolbar/Toolbar';
import GeneralSettingsTitle from '../../../../components/general_settings/GeneralSettingsTitle/GeneralSettingsTitle';

export const AdminClientAdministrators = () => {
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
  } = useTableUtils('clientAdministrators');
  const { t } = useTranslation();
  const history = useHistory();
  const administratorsTableType = useAdministratorsTableType();
  const administratorForm = useAdministratorForm();
  const { id } = useParams<{ id: string }>();
  const {
    createClientsAdministrator,
    clientAdministratorsData,
    loading,
    apiLoading,
    footerData,
    count,
    pageSize,
  } = useClientAdministrators(id, tableQueryParam);
  const asyncGetMethods = useClientAdministratorsAsyncMethods(id);

  const [open, setOpen] = useState<boolean>(false);

  const handleRowClick = useCallback(
    (row: (Row<{}> & UseRowSelectRowProps<{}>) | undefined) =>
      history.push(
        ROUTES.admin.clients.details.administrators.details
          .replace(':clientId', id)
          .replace(':adminId', row?.id ?? ''),
      ),
    [history, id],
  );

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleCreateAdministrator = useCallback(
    async (data: Partial<Administrator>) => {
      await createClientsAdministrator(data);
      handleClose();
    },
    [createClientsAdministrator, handleClose],
  );

  return (
    <Box>
      <Toolbar>
        <GeneralSettingsTitle
          count={count}
          title={t('sidebar.administrators')}
        />
        <Button
          label={t('administrators.new_administrator')}
          onClick={handleOpen}
        />
      </Toolbar>
      <MpTable
        {...administratorsTableType}
        data={clientAdministratorsData}
        pageSize={pageSize}
        totalsData={footerData}
        totals={!!clientAdministratorsData?.length}
        onGetData={handleGetData}
        loading={apiLoading}
        overridables={overridables}
        bulkMethods={bulkMethods}
        rowMethods={rowMethods}
        stickyTotals={true}
        disablePagination={true}
        classes={overrideClasses}
        translations={translations}
        onRowClick={handleRowClick}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        asyncGetMethods={asyncGetMethods}
      />
      <FormDialog
        actionLabel={t('common.confirm')}
        onCancelClick={handleClose}
        id="new-administrator-dialog"
        title={t('administrators.add_administrator')}
        form={administratorForm}
        open={open}
        onSubmitForm={handleCreateAdministrator}
        loading={loading}
      />
    </Box>
  );
};
