import { FC } from 'react';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsRecipientsList } from 'containers/recognitions';

const RecognitionsRecipientsPage: FC = () => {
  return (
    <RecognitionsLayout>
      <RecognitionsRecipientsList />
    </RecognitionsLayout>
  );
};

export default RecognitionsRecipientsPage;
