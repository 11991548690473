import { FC, MouseEvent, useRef, useState } from 'react';
import { Props } from './image-zoom.types';

const openInNewTab = (href: string) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noreferrer',
    href,
  }).click();
};

export const ImageZoom: FC<Props> = ({ alt = '', src, ...imgAttributes }) => {
  const sourceRef = useRef<HTMLImageElement>(null);
  const targetRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [offset, setOffset] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!targetRef.current || !sourceRef.current || !containerRef.current)
      return;

    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    if (targetRect.width <= containerRect.width) return;

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio =
      (targetRect.height - containerRect.height) / sourceRect.height;

    const left = Math.max(
      Math.min(event.pageX - sourceRect.left, sourceRect.width),
      0,
    );
    const top = Math.max(
      Math.min(event.pageY - sourceRect.top, sourceRect.height),
      0,
    );

    setOffset({
      top: top * -yRatio,
      left: left * -xRatio,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img
          {...imgAttributes}
          style={{
            display: 'block',
            maxWidth: '100%',
          }}
          alt={alt}
          src={src}
          ref={sourceRef}
        />
        <img
          {...imgAttributes}
          alt={alt}
          src={src}
          style={{
            display: 'block',
            position: 'absolute',
            left: `${offset.left}px`,
            top: `${offset.top}px`,
            opacity: isHovered ? 1 : 0,
            cursor: offset.top && offset.left ? 'zoom-in' : undefined,
          }}
          onClick={() => {
            if (offset.top && offset.left) {
              openInNewTab(src);
            }
          }}
          ref={targetRef}
        />
      </div>
    </div>
  );
};
