import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './companies.loaders';
import { converters } from './companies.converters';
import { ParentCompaniesFilters } from './companies.types';

export const companiesKeys = queryKeyFactory('companies');

export const useCompanies = () => {
  const query = useQuery({
    queryKey: companiesKeys.list(),
    queryFn: () => loaders.getCompanies(),
    select: converters.getCompanies,
  });

  return query;
};

export const useParentCompanies = (
  filters: Partial<ParentCompaniesFilters> = {},
) => {
  const query = useQuery({
    queryKey: companiesKeys.list('parents', filters),
    queryFn: () => loaders.getParentCompanies({ filters }),
    select: converters.getParentCompanies,
  });

  return query;
};

export const useDepartments = () => {
  const query = useQuery({
    queryKey: companiesKeys.list('departments'),
    queryFn: () => loaders.getDepartments(),
    select: converters.getDepartments,
  });

  return query;
};
