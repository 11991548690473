import {
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'melp-design/components';
import { MpControlType, MpForm } from '@mp-react/form';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contentLanguages } from 'types/general';
import { formatLanguage } from 'utils/format';
import { CustomFormControl } from '../../../../../types/Common';
import { CustomControlType } from '../../../../../types/Form';
import { useFormUtils } from '../../../../../utils/Form';
export interface PriceTranslationFormData {
  language: string;
  description: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: PriceTranslationFormData) => void;
  loading: boolean;
  title: string;
  initialData: Partial<PriceTranslationFormData>;
}

const PriceTranslationDialog = ({ initialData, ...props }: Props) => {
  const { t, i18n } = useTranslation();

  const { overridables } = useFormUtils();
  const methods = useForm();
  const { clearErrors, reset } = methods;

  useEffect(() => {
    reset(initialData);
  }, [initialData, reset]);

  const controls: CustomFormControl[] = [
    {
      key: 'language',
      type: CustomControlType.select,
      label: t('marketplaceItems.priceTranslationLanguage'),
      children: contentLanguages.map((language) => ({
        type: '' as MpControlType,
        key: language.toUpperCase(),
        label: formatLanguage(i18n.language, language),
      })),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      key: 'description',
      type: CustomControlType.text,
      label: t('marketplaceItems.priceTranslationDescription'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
  ];

  const handleClose = () => {
    if (methods.formState.isDirty) {
      if (window.confirm(t('errors.user_leaving_edited_page'))) {
        props.onClose();
      }
    } else {
      props.onClose();
    }
    reset(initialData, { isDirty: false });
    clearErrors();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <MpForm
          overridables={overridables}
          controls={controls}
          size="small"
          layout="separated"
          variant="outlined"
          useFormMethods={methods}
        />
      </DialogContent>
      <DialogActions>
        <ButtonOld variant="outlined" onClick={handleClose}>
          {t('common.cancel')}
        </ButtonOld>
        <ButtonOld
          variant="contained"
          color="primary"
          type="submit"
          loading={props.loading}
          onClick={methods.handleSubmit(props.onSubmit)}
        >
          {t('common.confirm')}
        </ButtonOld>
      </DialogActions>
    </Dialog>
  );
};

export default PriceTranslationDialog;
