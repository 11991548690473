import { TextField } from 'melp-design/components';
import { Autocomplete } from '@mui/material';
import { InternalFieldRenderer } from '../Types';

export const renderAutocompleteField: InternalFieldRenderer = (
  props,
  { required, label, ...field },
  fieldState,
) => (
  <Autocomplete
    fullWidth
    renderInput={(params) => (
      <TextField
        {...params}
        error={fieldState.invalid}
        helperText={fieldState.message}
        required={props.multiple ? undefined : required}
        inputProps={{
          ...params.inputProps,
          required: props.multiple
            ? required && field.value?.length === 0
            : required,
        }}
        label={label}
      />
    )}
    {...field}
    onChange={(_, newValue) => {
      field.onChange(newValue);
    }}
    {...props}
  />
);
