import { APP } from 'config';
import { AppLanguage, appLanguages } from 'types/general';

export const formatList = (
  language: string,
  list: string[],
  options?: Intl.ListFormatOptions,
): string => {
  const locale = appLanguages.includes(language as any)
    ? (language as AppLanguage)
    : APP.locales.default;

  return new Intl.ListFormat(locale, options).format(list);
};
