import ReactDOM from 'react-dom';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { APP } from 'config';
import { App } from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: APP.sentry.dsn,
    environment: APP.env,
  });
}
Hotjar.init(APP.hotjar.siteId, APP.hotjar.version);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
