import { forwardRef, ReactNode } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { SystemColors } from 'melp-design/style';

interface Props {
  items: Array<{ label: ReactNode; value: string; disabled?: boolean }>;
  label?: string;
  name?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  info?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CheckboxGroupInput = forwardRef<HTMLInputElement, Props>(
  (
    { items, label, name, value, onChange, info, error, required, disabled },
    forwardedRef,
  ) => {
    return (
      <FormControl
        error={!!error}
        required={required}
        disabled={disabled}
        name={name}
        component="fieldset"
      >
        <FormLabel
          component="legend"
          sx={{
            fontSize: 14,
            color: SystemColors.grey[45],
            '&.Mui-focused': { color: SystemColors.grey[45] },
          }}
        >
          {label}
        </FormLabel>

        <FormGroup>
          {items.map((item) => (
            <FormControlLabel
              key={item.value}
              control={<Checkbox inputRef={forwardedRef} />}
              label={item.label}
              name={item.value}
              checked={value?.includes(item.value)}
              onChange={
                !onChange || !value
                  ? undefined
                  : (_e, checked) => {
                      onChange(
                        checked
                          ? [...value, item.value]
                          : value.filter((v) => v !== item.value),
                      );
                    }
              }
              disabled={item.disabled}
            />
          ))}
        </FormGroup>

        {info && <FormHelperText error={false}>{info}</FormHelperText>}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  },
);

CheckboxGroupInput.displayName = 'CheckboxGroupInput';
