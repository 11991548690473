import { FC } from 'react';
import { Stack } from '@mui/material';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsRewardsList } from 'containers/recognitions';
import { ShopDisabledFeatureAlert } from 'containers/shop';

const RecognitionsRewardsPage: FC = () => {
  return (
    <RecognitionsLayout>
      <Stack gap={2}>
        <ShopDisabledFeatureAlert />
        <RecognitionsRewardsList />
      </Stack>
    </RecognitionsLayout>
  );
};

export default RecognitionsRewardsPage;
