import { z } from 'zod';
import {
  dateRangeSchema,
  numberRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { shopItemCategories } from 'types/MarketplaceItems';
import { discountStatuses } from 'store/discounts';
import {
  discountPackages,
  discountPublishStatuses,
} from './admin-discounts.types';

export const adminDiscountsFiltersSchema = z
  .object({
    status: z.array(z.enum(discountStatuses)).catch([]),
    categories: z.array(z.enum(shopItemCategories)).catch([]),
    publishStatus: z.array(z.enum(discountPublishStatuses)).catch([]),
    package: z.array(z.enum(discountPackages)).catch([]),
    countries: z.array(z.string()).catch([]),
    suppliers: z.array(z.string()).catch([]),
    viewsCount: numberRangeSchema,
    validAt: dateRangeSchema,
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
