import { Box, BoxProps, Paper, PaperProps } from '@mui/material';

const SidebarInner = ({
  children,
  width = 285,
  position,
  ...rest
}: PaperProps & BoxProps) => {
  return (
    <Paper
      variant="outlined"
      component={Box}
      sx={{
        width,
        position: position ?? 'sticky',
        top: position ? undefined : 20,
      }}
      {...rest}
    >
      {children}
    </Paper>
  );
};

export default SidebarInner;
