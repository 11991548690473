import { Colors, Effects } from 'melp-design/style';
import { SentimentSatisfiedAlt as EmojiEmotions } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Popper,
  Tooltip,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StaticEmojiPicker from './StaticEmojiPicker';

interface Props {
  /**
   * Callback to execute when emoji is selected (clicked).
   */
  onSelect: (emoji: string) => void;
  /**
   * Disables the picker.
   */
  disabled?: boolean;
}

/**
 * Allows picking emoji to insert into a text.
 */
const EmojiPicker = (props: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleEmojiSelect = (emoji: string) => {
    props.onSelect(emoji);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('common.emoji')}>
        <IconButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          disabled={props.disabled}
          sx={{
            color: Colors.grey,
            opacity: open ? 1 : 0.25,
            transition: 'background-color, opacity 150ms ease-in-out',
            '&:hover': { opacity: 1 },
          }}
        >
          <EmojiEmotions />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Box
              sx={{
                boxShadow: Effects.smallElementsShadow,
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <StaticEmojiPicker onSelect={handleEmojiSelect} />
                </div>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default EmojiPicker;
