import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Button, TextField } from 'melp-design/components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberRange } from 'types/Common';
import { isDefined } from 'utils/isDefined';

interface Props {
  value?: NumberRange;
  onApplyFilter: (value?: NumberRange) => void;
  onClearFilter: () => void;
  onCancel: () => void;
  nonNegativeIntegers?: boolean;
}

const NumberRangeFilter = ({
  value,
  onApplyFilter,
  onClearFilter,
  onCancel,
  nonNegativeIntegers = false,
}: Props) => {
  const { t } = useTranslation();

  const [localFrom, setLocalFrom] = useState(value?.from);
  const [localTo, setLocalTo] = useState(value?.to);

  const valuesUndefined = !isDefined(localFrom) && !isDefined(localTo);

  const handleApplyFilter = () => {
    if (valuesUndefined) {
      onApplyFilter(undefined);
    } else {
      onApplyFilter({ from: localFrom, to: localTo });
    }
  };

  const handleChange =
    (set: typeof setLocalFrom) =>
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = e.target.value;
      if (newValue.length === 0) {
        set(undefined);
        return;
      }
      set(Number(newValue));
    };

  const handleKeys = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLocaleLowerCase() === 'e') {
      e.preventDefault();
    }
    if (nonNegativeIntegers && ['-', '.', ','].includes(e.key)) {
      e.preventDefault();
    }
  };

  const commonProps = {
    fullWidth: true,
  };

  return (
    <Box display="flex" flexDirection="column" p={1} style={{ gap: '8px' }}>
      <TextField
        type="number"
        value={localFrom}
        onChange={handleChange(setLocalFrom)}
        placeholder={t('table.from')}
        onKeyDown={handleKeys}
        {...commonProps}
      />
      <TextField
        type="number"
        value={localTo}
        onChange={handleChange(setLocalTo)}
        placeholder={t('table.to')}
        {...commonProps}
      />
      <Box display="flex" justifyContent="flex-end" style={{ gap: '8px' }}>
        {value && (
          <Button
            label={t('table.clear')}
            variant="neutral-outline"
            onClick={onClearFilter}
          />
        )}
        <Button
          label={t('common.cancel')}
          variant="neutral-outline"
          onClick={onCancel}
        />
        <Button
          label={t('common.applyFilter')}
          variant="primary"
          disabled={valuesUndefined}
          onClick={handleApplyFilter}
        />
      </Box>
    </Box>
  );
};

export default NumberRangeFilter;
