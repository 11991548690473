import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IntegrationStatus,
  integrationStatuses,
  useDeleteIntegrationModel,
  useIntegrationModel,
  useUpdateIntegrationModel,
} from 'store/integrations';
import { usePredefinedToasts } from 'utils/Toast';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import {
  Button,
  DataLossPrompt,
  DetailsPageHeader,
  FileInput,
  Footer,
  ImageList,
  Loader,
  Menu,
  NothingFoundAlert,
  Panel,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { Delete } from '@mui/icons-material';
import { useUploadFile } from 'store/files';
import { useConfirm } from 'store/confirm';
import { useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useIdParam } from 'utils/useIdParam';

interface Values {
  name: string;
  comment: string;
  logoId: string;
  type: string;
  status: IntegrationStatus;
}

const INITIAL_VALUES: Values = {
  name: '',
  comment: '',
  logoId: '',
  type: '',
  status: 'inactive',
};

export const AdminIntegrationDetails: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const predefinedToasts = usePredefinedToasts();
  const { confirmation } = useConfirm();
  const integrationModelId = useIdParam();

  const { data: integration, isLoading } =
    useIntegrationModel(integrationModelId);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (integration) {
      reset({
        name: integration.name,
        comment: integration.comment,
        status: integration.status,
        logoId: integration.logo?.id ?? '',
        type: integration.variant,
      });
    }
  }, [integration, reset]);

  const { mutate: updateIntegrationModel, isLoading: isUpdating } =
    useUpdateIntegrationModel();
  const { mutate: deleteIntegrationModel, isLoading: isDeleting } =
    useDeleteIntegrationModel();
  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateIntegrationModel(
      {
        integrationModelId,
        data: values,
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  const [logoId] = watch(['logoId']);

  if (isLoading) return <Loader />;
  if (!integration) return <NothingFoundAlert />;

  return (
    <>
      <Stack>
        <DetailsPageHeader
          title={integration?.name}
          side={
            <Menu
              label={t('common.actions')}
              items={[
                {
                  label: t('actions.delete'),
                  onClick: async () => {
                    const { confirmed } = await confirmation(
                      t('common.areYouSureToDelete', {
                        title: integration.name,
                        interpolation: { escapeValue: false },
                      }),
                    );

                    if (confirmed) {
                      deleteIntegrationModel(
                        { integrationModelId },
                        {
                          onSuccess: () => {
                            predefinedToasts.success.deleted();
                            history.push(ROUTES.admin.integrations.list);
                          },
                        },
                      );
                    }
                  },
                  disabled: isDeleting,
                },
              ]}
            />
          }
          backTo={ROUTES.admin.integrations.list}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel title={t('integrations.integrationSettings')}>
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('integrations.name')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={integrationStatuses.map((status) => ({
                        label: t(`status.${status}`),
                        value: status,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="comment"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('clients.comment')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="logoId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return logoId ? (
                    <ImageList
                      items={[
                        {
                          imageId: logoId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      label={t('integrations.logo')}
                      name={name}
                      onChange={([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="type"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('integrations.type')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />
            </Stack>
          </Panel>

          <Footer visible={isDirty}>
            <Stack direction="row" justifyContent="end" gap="10px">
              <Button
                label={t('common.cancel')}
                variant="neutral-outline"
                onClick={() => {
                  reset(undefined, { keepDirty: false });
                }}
              />
              <Button
                label={t('common.save')}
                variant="primary"
                type="submit"
                disabled={isUpdating}
              />
            </Stack>
          </Footer>
        </form>
      </Stack>

      <DataLossPrompt when={isDirty && !isUpdating && !isDeleting} />
    </>
  );
};
