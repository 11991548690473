import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Colors } from '../../style/Colors';

interface StyleParams {
  color: string;
  textColor: string;
}

const useStyles = makeStyles((theme) => ({
  root: (params: StyleParams) => ({
    padding: '5px 10px',
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 8,
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    textAlign: 'center',
    color: params.textColor,
    backgroundColor: params.color,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
  }),
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.lightBackgroundGrey,
    },
  },
  fullWidth: {
    display: 'block',
    width: '100%',
  },
}));

interface Props {
  /**
   * Text to display
   */
  children: ReactNode;
  /**
   * Background color
   */
  color?: keyof typeof Colors;
  /**
   * Text color
   */
  textColor?: keyof typeof Colors;
  /**
   * Callback to execute when element is clicked
   */
  onClick?: () => void;
  /**
   * CSS class name
   */
  className?: string;
  /**
   * Indicates whether element should expand to full width of its' parent
   */
  fullWidth?: boolean;
}

const Label = ({ onClick, ...props }: Props) => {
  const color = props.color ? Colors[props.color] : Colors.white;
  const textColor = props.textColor ? Colors[props.textColor] : Colors.black;
  const classes = useStyles({ color, textColor });
  return (
    <span
      className={clsx(
        classes.root,
        onClick && classes.clickable,
        props.className,
        props.fullWidth && classes.fullWidth,
      )}
      onClick={onClick}
      role={onClick && 'button'}
    >
      {props.children}
    </span>
  );
};

export default Label;
