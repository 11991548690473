const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value',
)?.set;

const nativeTextareaValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLTextAreaElement.prototype,
  'value',
)?.set;

/**
 * Inserts a given value into an input based on the latest cursor position.
 * @param input an input element to insert a value into
 * @param text a value to insert
 */
export const insertIntoInput = (
  inputEl: HTMLInputElement | HTMLTextAreaElement,
  text: string,
) => {
  // Insert a text into existing input value
  const { selectionStart, value: currentValue } = inputEl;
  let newValue = '';
  if (selectionStart !== null) {
    const before = currentValue.substring(0, selectionStart);
    const after = currentValue.substring(selectionStart);
    newValue = before + text + after;
  } else {
    newValue = currentValue + text;
  }

  const isTextarea = inputEl instanceof HTMLTextAreaElement;

  // Change value of an input and trigger a change event in react
  // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
  if (inputEl instanceof HTMLInputElement && nativeInputValueSetter) {
    nativeInputValueSetter.call(inputEl, newValue);
  } else if (isTextarea && nativeTextareaValueSetter) {
    nativeTextareaValueSetter.call(inputEl, newValue);
  } else {
    throw new Error('Unsupported element');
  }
  const event = new Event('input', { bubbles: true });
  inputEl.dispatchEvent(event);

  // Set cursor right after added text
  inputEl.focus({ preventScroll: isTextarea });
  const newCursorPosition = selectionStart
    ? selectionStart + text.length
    : newValue.length;
  inputEl.setSelectionRange(newCursorPosition, newCursorPosition);
};
