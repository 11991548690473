import { useTranslation } from 'react-i18next';
import { MpColumn, MpTableType } from '@mp-react/table';
import { useMemo } from 'react';
import { CustomRendererType } from '../../types/Table';
import { useTableFilterTypes } from '../../utils/Table';
import { useLocalStorage } from '../../utils/LocalStorage';
import { StorageKeys } from '../../types/LocalStorage';
import { useDefaultHighlights } from './../../constants/Table';

export const useAdministratorsTableType = (): MpTableType => {
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const { getSelectFilter, getFilterNameSearchSelectFilter } =
    useTableFilterTypes();

  const fullNameFilter = useMemo(
    () => getFilterNameSearchSelectFilter('getFullNames'),
    [getFilterNameSearchSelectFilter],
  );

  const emailFilter = useMemo(
    () => getFilterNameSearchSelectFilter('getEmail'),
    [getFilterNameSearchSelectFilter],
  );

  const phoneFilter = useMemo(
    () => getFilterNameSearchSelectFilter('getPhone'),
    [getFilterNameSearchSelectFilter],
  );

  const statusFilter = useMemo(
    () =>
      getSelectFilter('status', [
        {
          label: t('status.active'),
          value: 'active',
        },
        {
          label: t('status.inactive'),
          value: 'inactive',
        },
      ]),
    [getSelectFilter, t],
  );

  const columns: MpColumn[] = useMemo(
    () => [
      {
        label: t('common.full_name'),
        key: 'fullName',
        filter: fullNameFilter,
        width: 218,
        fixedHeadWidth: true,
      },
      {
        label: t('common.email'),
        key: 'email',
        filter: emailFilter,
        width: 335,
        fixedHeadWidth: true,
      },
      {
        label: t('form.mobile_number'),
        key: 'phone',
        filter: phoneFilter,
        width: 222,
        fixedHeadWidth: true,
      },
      {
        label: t('common.status'),
        key: 'status',
        render: CustomRendererType.dateEndPill as any,
        sort: false,
        filter: statusFilter,
      },
    ],
    [t, statusFilter, phoneFilter, emailFilter, fullNameFilter],
  );

  return {
    columns,
    rowIdKey: 'id',
    filterVariant: 'head',
    stickyTotals: true,
    totals: true,
    highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.adminTable),
    cacheFilters: false,
    cacheSorting: false,
    clearColumnFilters: true,
  };
};
