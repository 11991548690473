import { Typography } from 'melp-design/components';
import { Search } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const NoDataIcon = styled(Search)({
  height: 40,
  width: 'auto',
  color: Colors.grey,
  marginBottom: '10px',
});

interface Props {
  /**
   * Main message
   */
  title?: string;
  /**
   * Secondary message
   */
  subtitle?: string;
}

/**
 * Component should be used in list-like UI elements, when there is no data to
 * display.
 */
const NoData = (props: Props) => {
  const { t } = useTranslation();
  const noDataMessage = props.title ?? t('common.no_data_to_show');
  return (
    <Stack
      p="30px"
      alignItems="center"
      minHeight="200px"
      className="NoData-root"
    >
      <NoDataIcon />
      <Typography
        variant={props.subtitle ? 'h3' : 'p1'}
        color="textSecondary"
        align="center"
        mb="5px"
      >
        {noDataMessage}
      </Typography>
      {!!props.subtitle && (
        <Typography variant="p1" color="textSecondary" align="center">
          {props.subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export default NoData;
