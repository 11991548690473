export const cities = [
  'Akmenė',
  'Alytus',
  'Anykščiai',
  'Ariogala',
  'Baltoji Vokė',
  'Birštonas',
  'Biržai',
  'Daugai',
  'Druskininkai',
  'Dūkštas',
  'Dusetos',
  'Eišiškės',
  'Elektrėnai',
  'Ežerėlis',
  'Gargždai',
  'Garliava',
  'Gelgaudiškis',
  'Grigiškės',
  'Ignalina',
  'Jieznas',
  'Jonava',
  'Joniškėlis',
  'Joniškis',
  'Jurbarkas',
  'Kaišiadorys',
  'Kalvarija',
  'Kaunas',
  'Kavarskas',
  'Kazlų Rūda',
  'Kėdainiai',
  'Kelmė',
  'Kybartai',
  'Klaipėda',
  'Kretinga',
  'Kudirkos Naumiestis',
  'Kupiškis',
  'Kuršėnai',
  'Lazdijai',
  'Lentvaris',
  'Linkuva',
  'Marijampolė',
  'Mažeikiai',
  'Molėtai',
  'Naujoji Akmenė',
  'Nemenčinė',
  'Neringa',
  'Obeliai',
  'Pabradė',
  'Pagėgiai',
  'Pakruojis',
  'Palanga',
  'Pandėlys',
  'Panemunė',
  'Panevėžys',
  'Pasvalys',
  'Plungė',
  'Priekulė',
  'Prienai',
  'Radviliškis',
  'Ramygala',
  'Raseiniai',
  'Rietavas',
  'Rokiškis',
  'Rūdiškės',
  'Salantai',
  'Seda',
  'Simnas',
  'Skaudvilė',
  'Skuodas',
  'Smalininkai',
  'Subačius',
  'Šakiai',
  'Šalčininkai',
  'Šeduva',
  'Šiauliai',
  'Šilalė',
  'Šilutė',
  'Širvintos',
  'Švenčionėliai',
  'Švenčionys',
  'Tauragė',
  'Telšiai',
  'Tytuvėnai',
  'Trakai',
  'Troškūnai',
  'Ukmergė',
  'Utena',
  'Užventis',
  'Vabalninkas',
  'Varėna',
  'Varniai',
  'Veisiejai',
  'Venta',
  'Viekšniai',
  'Vievis',
  'Vilkaviškis',
  'Vilkija',
  'Vilnius',
  'Virbalis',
  'Visaginas',
  'Zarasai',
  'Žagarė',
  'Žiežmariai',
] as const;
export type City = (typeof cities)[number];
