import { useTranslation } from 'react-i18next';
import { currencyConverters } from '../../../../../utils/Currency';
import { PriceFormData } from './PriceDialog';

export const usePriceTypes = () => {
  const { t } = useTranslation();
  return [
    {
      key: 'fixedPrice',
      label: t('marketplaceItems.priceTypeFixed'),
    },
    {
      key: 'priceRange',
      label: t('marketplaceItems.priceTypeCustom'),
    },
  ];
};

export const toRequestData = (data: PriceFormData) => ({
  ...data,
  price: data.price
    ? currencyConverters.toFractionalUnitAmount(data.price)
    : null,
  priceRangeFrom: data.priceRangeFrom
    ? currencyConverters.toFractionalUnitAmount(data.priceRangeFrom)
    : null,
  priceRangeTo: data.priceRangeTo
    ? currencyConverters.toFractionalUnitAmount(data.priceRangeTo)
    : null,
  discountPercentage: Number(data.discountPercentage ?? 0),
});
