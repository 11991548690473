import { makeStyles } from '@mui/styles';
import { Colors } from 'melp-design/style';

export default makeStyles((theme) => ({
  implementationToggle: {
    marginLeft: 0,
  },
  statusToggleButton: {
    zIndex: 1,
    transition: theme.transitions.create(['color']),
    '&:first-child::before': {
      content: "''",
      width: 50,
      height: 28,
      position: 'absolute',
      borderRadius: 30,
      transition: theme.transitions.create(['background-color', 'transform']),
      transform: 'translateX(0px)',
    },
  },
  onTransform: {
    '&:first-child::before': {
      transform: 'translateX(55px)',
      backgroundColor: Colors.green,
    },
  },
  offTransform: {
    '&:first-child::before': {
      transform: 'translateX(0px)',
    },
  },
}));
