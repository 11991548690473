import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { z } from 'zod';
import { binaries } from 'types/Common';
import { newsSendMethods, newsStatuses } from './news.types';

export const newsFiltersSchema = z
  .object({
    status: z.array(z.enum(newsStatuses)).catch([]),
    sendMethods: z.array(z.enum(newsSendMethods)).catch([]),
    publishedAt: dateRangeSchema,
    categories: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    pinned: z.enum(binaries).nullable().catch(null),
  })
  .merge(paginationFiltersSchema);

export const newsCategoriesFiltersSchema = z
  .object({})
  .merge(paginationFiltersSchema);
