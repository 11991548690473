import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState, forwardRef } from 'react';
import { TextField, TextFieldProps } from '../TextField/TextField';

interface Props extends Omit<TextFieldProps, 'type'> {}

const PasswordInput = forwardRef((props: Props, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <TextField
      ref={ref}
      type={isVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setIsVisible((current) => !current)}
              size="large"
            >
              {isVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
