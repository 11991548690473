import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './internal-shop.loaders';
import { internalShopKeys } from './internal-shop.queries';

export const useCreateInternalShopItem = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createInternalShopItem, {
    onSuccess: () => {
      return queryClient.invalidateQueries(internalShopKeys.lists);
    },
  });
};

export const useUpdateInternalShopItem = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateInternalShopItem, {
    onSuccess: () => {
      return queryClient.invalidateQueries(internalShopKeys.all);
    },
  });
};

export const useUpdateVariantInventory = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateVariantInventory, {
    onSuccess: () => {
      return queryClient.invalidateQueries(internalShopKeys.all);
    },
  });
};

export const useUpdateLowQuantity = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateLowQuantity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(internalShopKeys.all);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
