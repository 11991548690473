import { Box, MenuItem, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { formatLanguage } from 'utils/format';
import { appLanguages } from 'types/general';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg';
import useAdministrators, { useMe } from '../../../state/Administrators';
import { useClientAdministrator } from '../../../state/Clients';
import { StorageKeys } from '../../../types/LocalStorage';
import useStyles from './LanguageSwitcher.styles';

interface IProps {
  handleBack: () => void;
}

export default function LanguageSwitcher(props: IProps) {
  const { me, isMelpAdmin, isHrAdmin, updateMe } = useMe();
  const { updateAdministrator } = useAdministrators(me?.id, undefined, true);
  const { updateAdministrator: updateClientAdministrator } =
    useClientAdministrator(me?.parentCompany.id, me?.id, true);
  const { handleBack } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem(StorageKeys.lang, language);
    const languageParam = language.toUpperCase();
    if (isHrAdmin) {
      updateAdministrator({ language: languageParam }, false);
      updateMe({ language: languageParam });
    }
    if (isMelpAdmin) {
      updateClientAdministrator({ language: languageParam }, false);
    }
    handleBack();
  };

  const languageMap = [...appLanguages].sort().map((language) => (
    <MenuItem
      classes={{ root: clsx(classes.vAlignCenter, classes.item) }}
      onClick={() => changeLanguage(language)}
      data-test={language}
      key={language}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        className={clsx({
          [classes.bold]: i18n.language === language,
        })}
      >
        {formatLanguage(language, language)}
      </Typography>
      {i18n.language === language && <Checkmark className={classes.icon} />}
    </MenuItem>
  ));

  return (
    <>
      <MenuItem
        onClick={handleBack}
        className={clsx(classes.backItem, classes.item)}
      >
        <Box marginRight="11px">
          <ChevronLeft />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {t('common.back')}
        </Typography>
      </MenuItem>
      {languageMap}
    </>
  );
}
