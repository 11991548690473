import i18n from 'i18n';
import { ArrayElement } from 'types/Common';
import { MarketplaceItem } from 'types/MarketplaceItems';
import { currencyFormatter } from 'utils/Currency';
import { isDefined } from 'utils/isDefined';
import { formatCurrency } from 'utils/format';
import { Currency } from 'types/general';

export default function getPriceLabel<
  T extends Pick<MarketplaceItem, 'prices' | 'currency'>,
>(item: T) {
  const { prices } = item;
  if (!prices?.length) {
    return '';
  }

  const amounts = prices
    .reduce((acc, price) => {
      if (price.priceType === 'fixedPrice') {
        acc.push(price.price);
      }
      if (price.priceType === 'priceRange') {
        acc.push(price.priceRangeFrom);
        acc.push(price.priceRangeTo);
      }
      return acc;
    }, [] as (number | null)[])
    .filter((amount): amount is number => isDefined(amount));

  const formatPrice = (fractionUnitAmount?: number) => {
    return currencyFormatter.formatFractionalUnitAmount(
      fractionUnitAmount,
      item.currency,
    );
  };

  if (amounts.length === 1) {
    return formatPrice(amounts[0]);
  } else {
    const min = formatPrice(Math.min(...amounts));
    const max = formatPrice(Math.max(...amounts));
    return `${min} - ${max}`;
  }
}

export const getSinglePriceLabel = (
  {
    priceType,
    price,
    priceRangeFrom,
    priceRangeTo,
    translations,
  }: ArrayElement<NonNullable<MarketplaceItem['prices']>>,
  currency: Currency,
) => {
  switch (priceType) {
    case 'fixedPrice':
      return isDefined(price)
        ? formatCurrency(i18n.language, price, currency)
        : '';
    case 'priceRange':
      if (!isDefined(priceRangeFrom) || !isDefined(priceRangeTo)) {
        return '';
      }
      const min = formatCurrency(i18n.language, priceRangeFrom, currency);
      const max = formatCurrency(i18n.language, priceRangeTo, currency);
      const key = translations?.length
        ? 'compactPriceRangeTag'
        : 'priceRangeTag';
      return i18n.t(`marketplace.${key}`, { min, max });
    default:
      return '';
  }
};
