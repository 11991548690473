import { Typography } from 'melp-design/components';
import { Close, Info } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  Tooltip,
} from '@mui/material';
import { ChangeEvent, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFile, useUploadFile } from 'store/files';
import { ReactComponent as AddPhotoIcon } from '../../../../assets/icons/add-photo.svg';
import { InternalFieldRenderer } from '../Types';

type ImageUploadValue = string | null;

interface Props {
  /**
   * Current value
   */
  value?: ImageUploadValue;
  /**
   * Callback to execute when image is uploaded or removed.
   */
  onChange?: (value: ImageUploadValue) => void;
  /**
   * Input label
   */
  label?: ReactNode;
  /**
   * Puts component in a disabled state.
   */
  disabled?: boolean;
  /**
   * Puts component into error state.
   */
  error?: boolean;
  /**
   * Helper message to display at the bottom (e.g., validation error message).
   */
  message?: string;
  /**
   * Extended information to describe purpose, suggestion, etc.
   */
  hint?: string;
}

export const ImageUpload = ({ disabled, ...props }: Props) => {
  const { t } = useTranslation();

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const { data: file } = useFile(props.value ?? '');
  const { mutate: uploadFile, isLoading } = useUploadFile();
  const preview = file?.url ?? null;

  const handleBrowse = () => {
    if (!preview && hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      uploadFile(
        { file },
        {
          onSuccess: ({ data }) => {
            const uploadedFileId = data.id;
            props.onChange?.(uploadedFileId);
          },
        },
      );
    }
  };

  const clearImageInput = () => {
    props.onChange?.(null);
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
  };

  return (
    <Box position="relative">
      {!!props.label && (
        <Typography
          variant="p1"
          color={props.error ? 'error' : 'textSecondary'}
          component="label"
        >
          {props.label}
          {props.hint && (
            <Tooltip title={props.hint}>
              <Info style={{ verticalAlign: 'middle', marginLeft: '5px' }} />
            </Tooltip>
          )}
        </Typography>
      )}
      <Box
        width={160}
        height={120}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        mt={props.label ? '10px' : 0}
        onClick={handleBrowse}
        sx={
          !preview
            ? {
                border: `1px solid ${
                  props.error ? Colors.red : Colors.elementsGrey
                }`,
                borderRadius: '6px',
                cursor: disabled ? 'default' : 'pointer',
                transition: 'border-color 100ms ease-in-out',

                '&:hover': {
                  borderColor: disabled
                    ? Colors.elementsGrey
                    : Colors.greyHover,
                },
              }
            : undefined
        }
      >
        {!preview && !isLoading && <AddPhotoIcon />}
        {isLoading && <CircularProgress size={20} />}
        {preview && (
          <Box position="relative" display="flex" width="100%" height="100%">
            <IconButton
              onClick={clearImageInput}
              disabled={disabled}
              size="large"
              sx={{
                position: 'absolute',
                top: '-12px',
                right: '-38px',
              }}
            >
              <Close />
            </IconButton>
            <img
              src={preview}
              alt={file?.name ?? ''}
              style={{
                flex: 1,
                maxWidth: 160,
                maxHeight: 120,
                borderRadius: 8,
                objectFit: 'contain',
              }}
              // Required to avoid issues while getting images programmatically
              // For more info see https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
              crossOrigin="anonymous"
            />
          </Box>
        )}
      </Box>
      <input
        accept="image/png, image/jpeg, image/svg+xml"
        type="file"
        ref={hiddenFileInputRef}
        onChange={handleUploadFile}
        disabled={disabled}
        style={{ display: 'none' }}
      />
      {!preview && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          paddingTop={1}
        >
          <Typography
            variant="p2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t('common.max_upload_size')} 25Mb
          </Typography>
          <Typography
            variant="p2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t('common.accepted_formats')} jpeg, png, svg
          </Typography>
        </Box>
      )}
      {props.message && (
        <FormHelperText error={props.error}>{props.message}</FormHelperText>
      )}
    </Box>
  );
};

export const renderImageUploadField: InternalFieldRenderer = (
  props,
  field,
  fieldState,
) => {
  return (
    <ImageUpload
      {...props}
      value={field.value}
      onChange={field.onChange}
      label={field.label}
      disabled={field.disabled}
      error={fieldState.invalid}
      message={fieldState.message}
    />
  );
};
