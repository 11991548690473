import { ComponentProps, FC } from 'react';
import {
  ImageList as MuiImageList,
  ImageListItem,
  Skeleton,
  ImageListItemBar,
  Stack,
} from '@mui/material';
import { useFiles } from 'store/files';
import { SystemColors } from 'melp-design/style';
import { Button } from 'melp-design/components';

interface Props {
  items: Array<{
    imageId: string;
    actions?: Array<ComponentProps<typeof Button>>;
  }>;
  imageFit?: 'cover' | 'contain';
}

export const ImageList: FC<Props> = ({ items, imageFit = 'contain' }) => {
  const { data: images = [], isLoading } = useFiles(
    items.map((item) => item.imageId),
  );

  return (
    <MuiImageList cols={2} gap={10}>
      {isLoading
        ? Array.from({ length: items.length }).map((_, i) => (
            <ImageListItem key={i}>
              <Skeleton
                sx={{ aspectRatio: '16 / 9' }}
                height="100%"
                variant="rectangular"
              />
            </ImageListItem>
          ))
        : items.map((item) => {
            const image = images.find((image) => image.id === item.imageId);

            if (!image) return null;

            return (
              <ImageListItem key={item.imageId}>
                {item.actions ? (
                  <ImageListItemBar
                    position="top"
                    actionIcon={
                      <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                        {item.actions.map((action, i) => (
                          <Button {...action} size="sm" key={i} />
                        ))}
                      </Stack>
                    }
                    sx={{
                      p: 1,
                      m: '1px',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      background: 'rgba(0, 0, 0, 0.25)',
                    }}
                  />
                ) : null}
                <img
                  src={image.url}
                  alt={image.name}
                  style={{
                    aspectRatio: '16 / 9',
                    objectFit: imageFit,
                    border: `1px dashed ${SystemColors.grey[55]}`,
                    borderRadius: 6,
                  }}
                />
              </ImageListItem>
            );
          })}
    </MuiImageList>
  );
};
