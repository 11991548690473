import { Box, CircularProgress } from '@mui/material';
import { MpTableBreadcrumb } from '@mp-react/table';
import { useMemo } from 'react';
import { MpTableGlobalFilterProps } from '@mp-react/table/build/types/Overridables';
import { MpBreadcrumbFilterData } from '@mp-react/table/build/types/Filter';
import { objectLength } from '../../../utils/Common';
import useStyles from './TableBreadcrumbs.styles';

interface IProps extends MpTableGlobalFilterProps {
  loading: boolean;
  data: MpBreadcrumbFilterData;
}
export default function TableBreadcrumbs(props: IProps) {
  const { loading, onGlobalFilter, globalFilters, translations, data } = props;
  const classes = useStyles();
  const parsedData = useMemo(
    () => (!!objectLength(data) ? data : { no_items: [] }),
    [data],
  );

  if (loading) return <CircularProgress size={20} />;

  return (
    <Box className={classes.breadcrumbs}>
      <MpTableBreadcrumb
        onGlobalFilter={onGlobalFilter}
        translations={translations}
        globalFilters={globalFilters}
        data={parsedData}
      />
    </Box>
  );
}
