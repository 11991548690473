import React, { useMemo, useState } from 'react';
import { MpControlProps, controlRegisterOptions } from '@mp-react/form';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Box,
} from '@mui/material';
import { Link, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config';
import { ReactComponent as LinkIcon } from '../../../assets/icons/arrow-right.svg';
import { HierarchyRequest } from '../../../types/ParentCompany';
import { useHierarchy } from '../../../state/Hierarchy';
import { HierarchyLevels } from '../../../constants/ParentCompany';
import useEmployeeGroups from '../../../state/EmployeeGroups';
import { EmployeeGroupListResponse } from '../../../types/EmployeeGroups';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import {
  LocalPermissionModules,
  PermissionOptions,
} from '../../../constants/Administrators';
import { useMe } from '../../../state/Administrators';
import useStyles from './CustomDropdown.styles';

function CustomDropdownView({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  inputRef: React.MutableRefObject<any>;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const { t } = useTranslation();
  const key = useMemo(() => control.key, [control]);
  const { canEditEmployees, canEditCompanies } = useMe();
  const [leaving, setLeaving] = useState<boolean>(false);

  const hierarchyRequest = useMemo<HierarchyRequest>(
    () => ({
      level: HierarchyLevels.COMPANY_AND_DEPARTMENT,
      module: LocalPermissionModules.Employees,
      permissions: canEditEmployees
        ? PermissionOptions.Edit
        : PermissionOptions.View,
    }),
    [canEditEmployees],
  );

  const { hierarchy, loading } = useHierarchy(hierarchyRequest);
  const { employeeGroupsList } = useEmployeeGroups('', true);

  const companies = useMemo(() => {
    return hierarchy?.companies?.map((data) => ({
      name: data.name,
      id: data.id,
    }));
  }, [hierarchy]);

  const SelectContent = useMemo(() => {
    if (key === 'companyId') {
      return companies?.map((company: { name?: string; id?: string }) => (
        <MenuItem key={company.id} value={company.id ?? ''}>
          {company.name}
        </MenuItem>
      ));
    } else if (key === 'employeeGroupsId') {
      return employeeGroupsList?.map((group: EmployeeGroupListResponse) => (
        <MenuItem key={group.id} value={group.id ?? ''}>
          {group.name}
        </MenuItem>
      ));
    } else {
      return null;
    }
  }, [companies, employeeGroupsList, key]);

  const LinkContent = useMemo(() => {
    if (key === 'companyId' && canEditCompanies) {
      return (
        <MenuItem
          component={Link}
          to="/more/company-settings?tab=companySettingsCompanies"
          className={classes.link}
        >
          {t('form.manage')}
          <LinkIcon />
        </MenuItem>
      );
    } else if (key === 'employeeGroupsId') {
      return (
        <MenuItem
          component={Link}
          to={ROUTES.employeeGroups.list}
          className={classes.link}
        >
          {t('form.manage')}
          <LinkIcon />
        </MenuItem>
      );
    } else {
      return null;
    }
  }, [canEditCompanies, classes.link, key, t]);

  if (loading) return <CircularProgress size={20} />;

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <Prompt when={leaving} message={t('errors.user_leaving_edited_page')} />
      <FormControl
        id="company-dropdown"
        variant={variant ?? 'outlined'}
        fullWidth={true}
        required={control.required}
        className={classes.formControl}
      >
        <Select
          value={value ?? ''}
          onChange={onChange}
          inputRef={inputRef}
          name={name}
          onOpen={() => setLeaving(true)}
          onClose={() => setLeaving(false)}
        >
          {SelectContent}
          {LinkContent}
        </Select>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function CustomDropdown({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value, name, ref }) => (
        <CustomDropdownView
          name={name}
          inputRef={ref}
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
