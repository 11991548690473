import { MenuItem, TextField, Select, Box } from '@mui/material';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  ButtonOld,
} from 'melp-design/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types/Common';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import useStyles from './SingleFieldDIalog.styles';

interface IProps extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  onActionClick: (value: string) => void;
  id: string;
  title: string;
  placeholder?: string;
  defaultValue?: string;
  type?: 'select' | 'input';
  options?: SelectOption[];
  additionalActions?: JSX.Element[];
  tooltip?: string;
}

const SingleFieldDialog = ({
  actionLabel,
  onCancelClick,
  onActionClick,
  id,
  title,
  placeholder,
  defaultValue,
  type,
  options,
  additionalActions,
  tooltip,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState<string>('');
  const { openTooltip, closeTooltip, anchorEl } = useTooltip();

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  const handleTextFieldChange = useCallback(
    (e: any) => setValue(e.target.value),
    [],
  );

  const handleCancel = useCallback(() => {
    onCancelClick();
    setValue('');
  }, [onCancelClick]);

  const handleSubmit = useCallback(() => {
    onActionClick(value);
    setValue('');
  }, [onActionClick, value]);

  const Field = useMemo(
    () =>
      type === 'select' ? (
        <Select
          variant="outlined"
          value={value}
          onChange={handleTextFieldChange}
          fullWidth
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {t(option.name)}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          variant="outlined"
          value={value}
          onChange={handleTextFieldChange}
          placeholder={placeholder}
          fullWidth
        />
      ),
    [handleTextFieldChange, options, placeholder, t, type, value],
  );

  return (
    <Dialog
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onCancelClick}
      {...rest}
    >
      <DialogTitle id={id}>{title}</DialogTitle>
      <DialogContent>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          {Field}
          <FormTooltip tooltip={tooltip ?? ''} anchorEl={anchorEl} />
        </Box>
      </DialogContent>
      <DialogActions>
        {additionalActions}
        <ButtonOld onClick={handleCancel}>{t('common.cancel')}</ButtonOld>
        <ButtonOld
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!value}
        >
          {actionLabel}
        </ButtonOld>
      </DialogActions>
    </Dialog>
  );
};

export default SingleFieldDialog;
