import { Box, Button, Typography } from '@mui/material';
import { MpForm } from '@mp-react/form';
import { MpBreadcrumbFilterData } from '@mp-react/table';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { Loader } from 'melp-design/components';
import PermissionList from '../../../../../../../components/administrators/PermissionList/PermissionList';
import PermissionsActionHeader from '../../../../../../../components/administrators/PermissionsActionHeader/PermissionsActionHeader';
import FormFooter from '../../../../../../../components/layouts/FormFooter/FormFooter';
import InnerWithTabsContainer from '../../../../../../../components/layouts/InnerWithTabs/InnerWithTabsContainer/InnerWithTabsContainer';
import InnerWithTabsLeft from '../../../../../../../components/layouts/InnerWithTabs/InnerWithTabsLeft/InnerWithTabsLeft';
import InnerWithTabsRight from '../../../../../../../components/layouts/InnerWithTabs/InnerWithTabsRight/InnerWithTabsRight';
import InnerWithTabsTitle from '../../../../../../../components/layouts/InnerWithTabs/InnerWithTabsTitle/InnerWithTabsTitle';
import {
  GlobalPermissionModules,
  PermissionLevels,
  PermissionOptions,
} from '../../../../../../../constants/Administrators';
import { HierarchyLevels } from '../../../../../../../constants/ParentCompany';
import { useMe } from '../../../../../../../state/Administrators';
import { useHierarchy } from '../../../../../../../state/Hierarchy';
import { usePermissions } from '../../../../../../../state/Permissions';
import { HierarchyRequest } from '../../../../../../../types/ParentCompany';
import { useFormUtils } from '../../../../../../../utils/Form';
import { usePermissionUtils } from '../../../../../../../utils/Permissions';
import useStyles from '../../../../../../../styles/Form.styles';

const Permissions = () => {
  const classes = useStyles();
  const params: { id: string } = useParams();
  const administratorId = useMemo(() => params?.id, [params?.id]);
  const { canEditAdministrators } = useMe();

  const hierachyRequest = useMemo<HierarchyRequest>(
    () => ({
      level: HierarchyLevels.COMPANY_AND_COMPANY_GROUPS,
      module: GlobalPermissionModules.Administrators,
      permissions: canEditAdministrators
        ? PermissionOptions.Edit
        : PermissionOptions.View,
    }),
    [canEditAdministrators],
  );

  const { hierarchy, loading: hierarchyLoading } =
    useHierarchy(hierachyRequest);

  const { t } = useTranslation();
  const {
    form,
    formTitle,
    handlePermissionClick,
    selectedId,
    formMethods,
    handleSubmit,
    removeChanges,
    hasChanges,
    permissionData,
    handleSetAllPermissions,
    formChanges,
    setDefaultFormValues,
    getActualFormValues,
  } = usePermissionUtils();
  const {
    updatePermissions,
    loading,
    parsedCompanyGroupPermissions,
    parsedCompanyPermissions,
    parsedGlobalPermissions,
  } = usePermissions({
    adminId: administratorId,
    selectedId,
    removeChanges,
  });
  const { reset } = formMethods;

  const defaultValues = useMemo(() => {
    if (loading || !selectedId) return null;
    const isCompany =
      selectedId.includes('company-') || selectedId === 'allLocalPermissions';
    const isCompanyGroup = selectedId.includes('companyGroup-');
    if (isCompany && !!parsedCompanyPermissions) {
      return parsedCompanyPermissions;
    }

    if (isCompanyGroup && !!parsedCompanyGroupPermissions) {
      return parsedCompanyGroupPermissions;
    }

    if (!!parsedGlobalPermissions && !isCompany && !isCompanyGroup) {
      return parsedGlobalPermissions;
    }
  }, [
    loading,
    parsedCompanyGroupPermissions,
    parsedCompanyPermissions,
    parsedGlobalPermissions,
    selectedId,
  ]);

  const formValues = useMemo(() => {
    if (!defaultValues) return null;
    return getActualFormValues({ ...defaultValues }, formChanges, selectedId);
  }, [defaultValues, formChanges, getActualFormValues, selectedId]);

  useEffect(() => {
    if (loading || !selectedId || !defaultValues || !formValues) return;
    reset(formValues, { isDirty: false });
    setDefaultFormValues(defaultValues);
    //eslint-disable-next-line
  }, [defaultValues, loading, selectedId, formValues]);

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? '0' : '70px'})`,
    [hasChanges],
  );

  const { overridables } = useFormUtils();

  const submitData = useCallback(
    () =>
      handleSubmit(() => {
        reset(defaultValues, { isDirty: false });
        updatePermissions(permissionData as FormData);
      })(),
    [handleSubmit, reset, defaultValues, updatePermissions, permissionData],
  );

  const LocalPermissions = useMemo(
    () =>
      !hierarchyLoading ? (
        <PermissionList
          tree={hierarchy as MpBreadcrumbFilterData}
          allSelectionId="allLocalPermissions"
          level={PermissionLevels.Local}
          onClick={handlePermissionClick}
          selectedId={selectedId}
          disabled={loading}
        />
      ) : (
        <Loader minHeight={20} />
      ),
    [handlePermissionClick, hierarchy, hierarchyLoading, loading, selectedId],
  );

  const Form = useMemo(() => {
    if (loading) return <Loader />;

    if (!selectedId) return null;

    return (
      <MpForm
        overridables={overridables}
        {...form}
        useFormMethods={formMethods}
      />
    );
  }, [form, formMethods, loading, overridables, selectedId]);

  return (
    <Box className={canEditAdministrators ? '' : classes.disableFormFields}>
      <Prompt
        when={hasChanges}
        message={t('errors.user_leaving_edited_page')}
      />
      <PermissionsActionHeader
        onChangeAll={handleSetAllPermissions}
        isSelected={!!selectedId && canEditAdministrators}
        backTo="/more/company-settings?tab=companySettingsAdministrators"
      />
      <InnerWithTabsContainer>
        <InnerWithTabsRight>
          <InnerWithTabsTitle>
            {t('administrators.global_permissions')}
          </InnerWithTabsTitle>
          <PermissionList
            allSelectionId="allGlobalPermissions"
            level={PermissionLevels.Global}
            onClick={handlePermissionClick}
            selectedId={selectedId}
            disabled={loading || !canEditAdministrators}
          />
          <InnerWithTabsTitle marginTop="25px">
            {t('administrators.local_permissions')}
          </InnerWithTabsTitle>
          {LocalPermissions}
        </InnerWithTabsRight>
        <InnerWithTabsLeft>
          <Box marginBottom="15px">
            <Typography variant="subtitle1">{formTitle}</Typography>
          </Box>
          {Form}
        </InnerWithTabsLeft>
      </InnerWithTabsContainer>
      <FormFooter
        justifyContent="flex-end"
        spacing={0}
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
      >
        <Box marginRight="20px">
          <Button onClick={removeChanges} variant="text" color="info">
            {t('common.cancel')}
          </Button>
        </Box>
        <Button variant="contained" color="primary" onClick={submitData}>
          {t('common.confirm_changes')}
        </Button>
      </FormFooter>
    </Box>
  );
};

export default Permissions;
