import { converters as tableConverters } from 'store/table';
import { IBenefit, IEmployeeBenefit, ILiteBenefit } from 'types/income';
import { loaders } from './benefits.loaders';
import { BaseBenefit, Benefit, LiteBenefit } from './benefits.types';

const convertBenefit = (benefit: IBenefit): Benefit => {
  return {
    id: benefit.id,
    name: benefit.name,
    value: benefit.value === null ? NaN : Number(benefit.value) * 100,
    type: benefit.type,
    status: benefit.status,
    publishStatus: benefit.publishStatus,
    investment: benefit.investment,
    investmentAmount:
      benefit.investmentAmount === null
        ? NaN
        : Number(benefit.investmentAmount) * 100,
    employeeCount: benefit.employeeCount,
    activationDate: benefit.activationDate,
    deactivationDate: benefit.deactivationDate,
    allowClaimReimbursement: benefit.allowClaimReimbursement,
    trackBalance: benefit.trackBalance,
  };
};

const convertBenefits = (benefits: IBenefit[]): Benefit[] => {
  return benefits.map(convertBenefit);
};

const convertLiteBenefit = (benefit: ILiteBenefit): LiteBenefit => {
  return {
    id: benefit.id,
    name: benefit.name,
    type: benefit.type,
    status: benefit.status,
    publishStatus: benefit.publishStatus,
    activationDate: benefit.activationDate,
    deactivationDate: benefit.deactivationDate,
    value: benefit.value,
    trackBalance: benefit.trackBalance,
    allowClaimReimbursement: benefit.allowClaimReimbursement,
  };
};

const convertLiteBenefits = (benefits: ILiteBenefit[]): LiteBenefit[] => {
  return benefits.map(convertLiteBenefit);
};

const convertEmployeeBenefits = (
  benefits: IEmployeeBenefit[],
): BaseBenefit[] => {
  return benefits.map((benefit) => ({
    id: benefit.benefitId,
    name: benefit.benefitName,
  }));
};

export const converters = {
  getBenefits: ({ data }: Awaited<ReturnType<typeof loaders.getBenefits>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBenefits(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getLiteBenefits: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getLiteBenefits>>) => {
    return convertLiteBenefits(data.data);
  },
  getEmployeeBenefits: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeBenefits>>) => {
    return convertEmployeeBenefits(data.data);
  },
};
