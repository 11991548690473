import { FC } from 'react';
import { Modal, Typography } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { useExportVouchersXls } from 'store/admin-shop-vouchers';

interface Props extends ModalProps {
  duplicates: string[];
}

export const VouchersImportWarningsModal: FC<Props> = ({
  closeModal,
  duplicates,
}) => {
  const { t } = useTranslation();

  const { mutate: exportVouchersXls } = useExportVouchersXls();

  return (
    <Modal.Content
      title={t('shop.vouchers.actions.upload_codes')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        {
          variant: 'primary',
          onClick: () => {
            exportVouchersXls({ voucherIds: duplicates });
          },
          label: t('dashboard.download_xls'),
        },
      ]}
    >
      <Stack gap={1}>
        <Typography>{t('shop.vouchers.warning.duplicates')}</Typography>
      </Stack>
    </Modal.Content>
  );
};
