import { useTranslation } from 'react-i18next';
import { AchievementAssignmentLimitFrequency } from '../../../types/Recognition';

export const useLimitFrequencyLabels = (): Record<
  AchievementAssignmentLimitFrequency,
  string
> => {
  const { t } = useTranslation();

  return {
    weekly: t('recognition.achievementAssignmentLimitFrequency-weekly'),
    monthly: t('recognition.achievementAssignmentLimitFrequency-monthly'),
    yearly: t('recognition.achievementAssignmentLimitFrequency-yearly'),
  };
};
