import { z } from 'zod';
import { numberRangeSchema, paginationFiltersSchema } from 'utils/schemas';

export const internalShopInventoryEventsFiltersSchema = z
  .object({})
  .merge(paginationFiltersSchema);

export const internalShopInventoryFiltersSchema = z
  .object({
    items: z.array(z.string()).catch([]),
    inventory: numberRangeSchema,
  })
  .merge(paginationFiltersSchema);

export const internalShopFiltersSchema = z
  .object({
    name: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
