import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { LogsFilters, converters as logsConverters } from 'store/logs';
import { loaders } from './admin-logs.loaders';

export const adminLogsKeys = queryKeyFactory('admin-logs');

export const useAdminLogs = (filters: Partial<LogsFilters> = {}) => {
  return useQuery({
    queryKey: adminLogsKeys.list(filters),
    queryFn: () => loaders.getLogs({ filters }),
    select: logsConverters.getLogs,
  });
};
