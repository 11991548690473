import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { ShopItemsFilters } from './shop.types';
import { loaders } from './shop.loaders';
import { converters } from './shop.converters';

export const shopKeys = queryKeyFactory('shop');

export const useShopItems = (filters: Partial<ShopItemsFilters> = {}) => {
  return useQuery({
    queryKey: shopKeys.list(filters),
    queryFn: () => loaders.getShopItems({ filters }),
    select: converters.getShopItems,
  });
};
