import { FC } from 'react';
import { ShopItemSettings } from 'containers/admin';
import { AdminShopItemLayout } from 'pages/atoms/admin-shop-item-layout';

const AdminShopItemSettingsPage: FC = () => {
  return (
    <AdminShopItemLayout>
      <ShopItemSettings />
    </AdminShopItemLayout>
  );
};

export default AdminShopItemSettingsPage;
