import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { shopItemCategories } from 'types/MarketplaceItems';
import { availabilityStatuses } from './discounts.types';

export const discountsFiltersSchema = z
  .object({
    name: z.string().catch(''),
    discounts: z.array(z.string()).catch([]),
    countries: z.array(z.string()).catch([]),
    suppliers: z.array(z.string()).catch([]),
    status: z.array(z.enum(availabilityStatuses)).catch([]),
    categories: z.array(z.enum(shopItemCategories)).catch([]),
    publishDate: dateRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
