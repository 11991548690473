import { Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  AutocompleteInput,
  Button,
  CheckboxGroupInput,
  DataLossPrompt,
  DateTimeInput,
  FileInput,
  FileLink,
  Footer,
  ImageList,
  Loader,
  MultiLanguageInputContainer,
  NothingFoundAlert,
  NumberInput,
  Panel,
  RichTextInput,
  SelectInput,
  SwitchInput,
  TextField,
  Typography,
} from 'melp-design/components';
import { FC, useEffect, useRef } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DiscountPackage,
  DiscountPublishStatus,
  discountPackages,
  useAdminDiscount,
  useCreateDiscount,
  useUpdateDiscount,
} from 'store/admin-discounts';
import {
  ContentLanguage,
  contentLanguages,
  FrequencyUnit,
  frequencyUnits,
} from 'types/general';
import { Translations } from 'types/Common';
import { upper } from 'utils/general';
import { ShopItemCategory, shopItemCategories } from 'types/MarketplaceItems';
import { useModalContext } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import { useIdParam } from 'utils/useIdParam';
import { Delete } from '@mui/icons-material';
import { formatCountry, formatLanguage } from 'utils/format';
import { useUploadFile } from 'store/files';
import { useOptions } from './discount-details.utils';
import { DateSelectModal } from './atoms';

interface Values {
  languages: Array<Uppercase<ContentLanguage>>;
  name: string;
  supplier: string;
  countries: string[];
  categories: ShopItemCategory[];
  priority: number;
  packages: DiscountPackage[];
  companies: string[];
  comment: string;
  validFrom: string | null;
  validTo: string | null;
  publishedAt: string | null;
  supplierContact: string;
  isSupplierContactPublic: boolean;
  code: string;
  link: string;
  codeImageId: string;
  isUniqueCode: boolean;
  isLimitedReveal: boolean;
  revealFrequencyLimit: FrequencyUnit | 'none';
  translations: Translations<{
    title: string;
    teaser: string;
    promo: string;
    description: string;
    attachments: string[];
  }>;
  imageId: string;
  searchTags: string;
  publishStatus: DiscountPublishStatus;
}

const MAX_CHAR_TEASER = 60;
const MAX_CHAR_PROMO = 20;

export const AdminDiscountDetails: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const discountId = useIdParam();
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();
  const formRef = useRef<HTMLFormElement>(null);

  const options = useOptions();

  const { data: discount, isLoading } = useAdminDiscount(discountId);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<Values>({
    defaultValues: {
      languages: [upper(APP.locales.default)],
      name: '',
      supplier: '',
      countries: ['LTU'],
      categories: [],
      priority: 100,
      packages: [],
      companies: [],
      comment: '',
      validFrom: null,
      validTo: null,
      publishedAt: null,
      supplierContact: '',
      isSupplierContactPublic: false,
      code: '',
      link: '',
      codeImageId: '',
      isUniqueCode: false,
      isLimitedReveal: false,
      revealFrequencyLimit: 'none',
      translations: [
        {
          language: upper(APP.locales.default),
          title: '',
          teaser: '',
          promo: '',
          description: '',
          attachments: [],
        },
      ],
      imageId: '',
      searchTags: '',
      publishStatus: 'unpublished',
    },
  });
  const { fields: translationsFields, replace: replaceTranslations } =
    useFieldArray({ name: 'translations', control });

  useEffect(() => {
    if (discount) {
      reset({
        languages: discount.translations.map((trans) => trans.language),
        name: discount.name,
        supplier: discount.supplier.id,
        countries: discount.countries,
        categories: discount.categories,
        priority: discount.priority,
        packages: discount.packages,
        companies: discount.companies,
        comment: discount.comment,
        validFrom: discount.validFrom,
        validTo: discount.validTo,
        publishedAt: discount.publishedAt,
        supplierContact: discount.supplierContact,
        isSupplierContactPublic: discount.isSupplierContactPublic,
        code: discount.code,
        link: discount.link,
        codeImageId: discount.codeImage?.id,
        isUniqueCode: discount.isUniqueCode,
        isLimitedReveal: discount.revealFrequencyLimit !== 'none',
        revealFrequencyLimit: discount.revealFrequencyLimit,
        translations: discount.translations.map((trans) => ({
          ...trans,
          attachments: trans.attachments.map((attachment) => attachment.id),
        })),
        imageId: discount.image?.id,
        searchTags: discount.searchTags,
        publishStatus: discount.publishStatus,
      });
    }
  }, [discount, reset]);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: createDiscount, isLoading: isCreating } = useCreateDiscount();
  const { mutate: updateDiscount, isLoading: isUpdating } = useUpdateDiscount();

  const onSubmit: SubmitHandler<Values> = (values) => {
    const valuesToData = {
      name: values.name,
      supplier: values.supplier,
      countries: values.countries,
      categories: values.categories,
      priority: values.priority,
      packages: values.packages,
      companies: values.companies,
      comment: values.comment,
      validFrom: values.validFrom,
      validTo: values.validTo,
      supplierContact: values.supplierContact,
      isSupplierContactPublic: values.isSupplierContactPublic,
      code: values.code,
      link: values.link,
      codeImageId: values.codeImageId,
      translations: values.translations,
      isUniqueCode: values.isUniqueCode,
      revealFrequencyLimit: values.isLimitedReveal
        ? values.revealFrequencyLimit
        : 'none',
      imageId: values.imageId,
      publishedAt: values.publishedAt,
      isDraft: values.publishStatus === 'unpublished',
      searchTags: values.searchTags,
    } as const;

    if (discount) {
      updateDiscount(
        { discountId, data: valuesToData },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
          },
        },
      );
    } else {
      createDiscount(valuesToData, {
        onSuccess: ({ data: { id } }) => {
          predefinedToasts.success.added();
          history.push(
            ROUTES.admin.discounts.details.settings.replace(':id', id),
          );
        },
      });
    }
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  const [
    languages,
    packages,
    isSupplierContactPublic,
    isUniqueCode,
    isLimitedReveal,
    codeImageId,
    translations,
    imageId,
    publishedAt,
    publishStatus,
  ] = watch([
    'languages',
    'packages',
    'isSupplierContactPublic',
    'isUniqueCode',
    'isLimitedReveal',
    'codeImageId',
    'translations',
    'imageId',
    'publishedAt',
    'publishStatus',
  ]);

  if (discountId && isLoading) return <Loader />;
  if (discountId && !discount) return <NothingFoundAlert />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Stack spacing={2}>
          <Panel title={t('benefits.benefitGeneralSettingsSection')}>
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('common.systemName')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Controller
                  name="supplier"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <AutocompleteInput
                      label={t('marketplaceItems.supplier')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={options.suppliers.items}
                      disabled={options.suppliers.isLoading}
                      required
                      ref={ref}
                    />
                  )}
                />
                <Button
                  label={t('marketplaceSuppliers.addSupplier')}
                  variant="tertiary"
                  onClick={() =>
                    window.open(ROUTES.admin.suppliers.list, '_blank')
                  }
                />
              </Stack>

              <Controller
                name="countries"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('marketplaceItems.countries')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={APP.countries.alpha3.map((alpha3) => ({
                      value: alpha3,
                      label: formatCountry(i18n.language, alpha3),
                    }))}
                    required
                    multiple
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="categories"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('benefits.benefit_type')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={shopItemCategories.map((category) => ({
                      label: t(`marketplaceItems.itemCategory-${category}`),
                      value: category,
                    }))}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="priority"
                control={control}
                rules={{
                  min: {
                    value: 1,
                    message: t('common.minValueValidationError', { min: 1 }),
                  },
                }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('common.priority.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={errors.priority?.message}
                    infoMessage={t('discounts.priority.info')}
                    type="integer"
                    key={value}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="packages"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <CheckboxGroupInput
                    label={t('discounts.package.label')}
                    items={discountPackages.map((pack) => ({
                      label: t(`discounts.package.${pack}`),
                      value: pack,
                    }))}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              {packages.includes('internal') ? (
                <Controller
                  name="companies"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <AutocompleteInput
                      label={t('menu.clients')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={options.companies.items}
                      disabled={options.companies.isLoading}
                      multiple
                      required
                      ref={ref}
                    />
                  )}
                />
              ) : null}

              <Controller
                name="comment"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('marketplaceOrders.commentForAdmin')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    multiline
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="searchTags"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('shop.search_tags.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    multiline
                    ref={ref}
                  />
                )}
              />

              <Controller
                control={control}
                name="publishStatus"
                render={({ field: { value } }) => (
                  <input type="hidden" value={value} />
                )}
              />
            </Stack>
          </Panel>

          <Panel title={t('discounts.validity.title')}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Controller
                  name="validFrom"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <DateTimeInput
                      label={t(
                        'recognition.allowanceEmployeeOverviewListValidFrom',
                      )}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  name="validTo"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <DateTimeInput
                      label={t(
                        'recognition.allowanceEmployeeOverviewListValidTo',
                      )}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </Stack>

              <Controller
                name="publishedAt"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <DateTimeInput
                    label={t('news.published_from')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="supplierContact"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('discounts.supplierContact.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    multiline
                    required={isSupplierContactPublic}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="isSupplierContactPublic"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SwitchInput
                    label={t('discounts.isSupplierContactPublic.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />
            </Stack>
          </Panel>

          <Panel title={t('discounts.details.title')}>
            <Stack spacing={2}>
              <Controller
                name="isUniqueCode"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SwitchInput
                    label={t('discounts.isUniqueCode.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="code"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('discounts.discountCode.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isUniqueCode}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="link"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('discounts.discountLink.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isUniqueCode}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="codeImageId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return codeImageId ? (
                    <ImageList
                      items={[
                        {
                          imageId: codeImageId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      label={t('discounts.codeImage.label')}
                      name={name}
                      onChange={([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUniqueCode || isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>

          <Panel title={t('benefitTemplates.templateContent')}>
            <Stack spacing={2}>
              <Controller
                name="languages"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SelectInput
                    label={t('companies.supported_languages')}
                    name={name}
                    value={value}
                    onChange={(value) => {
                      const selectedLanguages = value as Array<
                        Uppercase<ContentLanguage>
                      >;
                      replaceTranslations(
                        selectedLanguages.map(
                          (language) =>
                            translations.find(
                              (t) => t.language === language,
                            ) ?? {
                              language,
                              title: '',
                              teaser: '',
                              promo: '',
                              description: '',
                              attachments: [],
                            },
                        ),
                      );
                      onChange(selectedLanguages);
                    }}
                    options={contentLanguages.map((l) => {
                      const language = l.toUpperCase();
                      return {
                        value: language,
                        label: formatLanguage(i18n.language, language),
                      };
                    })}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              {languages.length ? (
                <>
                  <MultiLanguageInputContainer
                    label={t('common.name')}
                    required
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.title`}
                          control={control}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              required
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('discounts.teaser.label')}
                    info={t('common.max_char', { count: MAX_CHAR_TEASER })}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.teaser`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: MAX_CHAR_TEASER,
                              message: t('common.maxValueValidationError', {
                                max: MAX_CHAR_TEASER,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              errorMessage={
                                errors.translations?.[i]?.teaser?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('discounts.discountPromo.label')}
                    info={t('common.max_char', { count: MAX_CHAR_PROMO })}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.promo`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: MAX_CHAR_PROMO,
                              message: t('common.maxValueValidationError', {
                                max: MAX_CHAR_PROMO,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              errorMessage={
                                errors.translations?.[i]?.promo?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('employees.details')}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.description`}
                          control={control}
                          render={({ field: { value, onChange, ref } }) => (
                            <RichTextInput
                              value={value}
                              onChange={onChange}
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />
                </>
              ) : null}
            </Stack>
          </Panel>

          <Panel title={t('shop.internal.media.title')}>
            <Stack spacing={2}>
              <Controller
                name="imageId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return imageId ? (
                    <ImageList
                      items={[
                        {
                          imageId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      name={name}
                      onChange={([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      required
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>

          <Panel title={t('form.attachments')}>
            <Stack spacing={2}>
              <MultiLanguageInputContainer
                fields={translationsFields.map((field, i) => ({
                  id: field.id,
                  language: field.language,
                  component: (
                    <Controller
                      name={`translations.${i}.attachments`}
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => (
                        <Stack gap={2}>
                          <FileInput
                            name={name}
                            onChange={([file]) => {
                              if (file) {
                                uploadFile(
                                  { file },
                                  {
                                    onSuccess: ({ data }) => {
                                      onChange([...value, data.id]);
                                    },
                                  },
                                );
                              } else {
                                onChange(value);
                              }
                            }}
                            disabled={isUploading}
                            maxFiles={1}
                            previewSelectedFiles={false}
                            accept={[
                              'jpg',
                              'jpeg',
                              'png',
                              'gif',
                              'webp',
                              'txt',
                              'pdf',
                              'doc',
                              'docx',
                              'xls',
                              'xlsx',
                            ]}
                            ref={ref}
                          />
                          {value.length ? (
                            <Stack mb={3} gap={0.5}>
                              {value.map((attachmentId) => (
                                <FileLink
                                  id={attachmentId}
                                  actions={[
                                    {
                                      icon: Delete,
                                      onClick: () =>
                                        onChange(
                                          value.filter(
                                            (v) => v !== attachmentId,
                                          ),
                                        ),
                                      title: t('common.delete'),
                                      variant: 'danger-outline',
                                    },
                                  ]}
                                  key={attachmentId}
                                />
                              ))}
                            </Stack>
                          ) : null}
                        </Stack>
                      )}
                    />
                  ),
                }))}
              />
            </Stack>
          </Panel>

          <Panel title={t('common.advanced_settings')}>
            <Stack spacing={2}>
              <Controller
                name="isLimitedReveal"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SwitchInput
                    label={t('discounts.isLimitedReveal.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />

              {isLimitedReveal ? (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Typography sx={{ whiteSpace: 'nowrap', mr: 2 }}>
                    {t('discounts.revealFrequencyLimit.info')}
                  </Typography>

                  <Controller
                    name="revealFrequencyLimit"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <SelectInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={t('recognition.allowanceRulesListFrequency')}
                          options={frequencyUnits
                            .filter(
                              (period) =>
                                !['none', 'quarterly'].includes(period),
                            )
                            .map((period) => ({
                              label: t(
                                `recognition.allowanceRuleFrequency-${period}`,
                              ),
                              value: period,
                            }))}
                          ref={ref}
                        />
                      );
                    }}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty || discount?.status === 'draft'}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            {publishStatus === 'unpublished' ? (
              <>
                <Button
                  label={t('buttons.save_as_draft')}
                  variant="secondary"
                  type="submit"
                  disabled={isCreating || isUpdating}
                />
                <Button
                  label={t('buttons.publish')}
                  variant="primary"
                  onClick={async () => {
                    if (!publishedAt) {
                      const response = await openModal({
                        component: DateSelectModal,
                      });

                      if (response.action === 'CLOSE') return;

                      if (response.action === 'CONFIRM') {
                        setValue('publishedAt', response.date);
                      }
                    }
                    setValue('publishStatus', 'published');
                    formRef.current?.requestSubmit();
                  }}
                  disabled={isCreating || isUpdating}
                />
              </>
            ) : publishStatus === 'published' ? (
              <Button
                label={t('common.save')}
                variant="secondary"
                type="submit"
                disabled={isCreating || isUpdating}
              />
            ) : null}
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isCreating && !isUpdating} />
    </>
  );
};
