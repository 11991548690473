import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

const DIVIDER_WIDTH = 'calc(100% - 30px)';

export default makeStyles(() =>
  createStyles({
    listRoot: {
      width: '100%',
      position: 'relative',
      '&::before': {
        content: "''",
        background: Colors.Divider,
        height: 1,
        position: 'absolute',
        top: 0,
        width: DIVIDER_WIDTH,
        right: 0,
      },
    },
  }),
);
