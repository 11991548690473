import {
  ComponentProps,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import {
  Alert,
  DetailsPageContent,
  DetailsPageHeader,
  Menu,
  SideMenu,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'config';
import { useClient } from 'state/Clients';
import { useIdParam } from 'utils/useIdParam';
import { useDialog } from 'utils/Dialog';
import FormDialog from 'components/dialogs/FormDialog/FormDialog';
import { UnpackNestedValue } from 'react-hook-form';
import { ImportClientError } from 'types/Clients';
import { useUploadClientCSVForm } from 'configs/Forms/UploadClientCSVForm';
import { usePredefinedToasts } from 'utils/Toast';
import { useConfirm } from 'store/confirm';
import { useAdminClient } from 'store/admin-clients';

export const AdminClientLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const clientId = useIdParam();
  const predefinedToasts = usePredefinedToasts();
  const history = useHistory();
  const { confirmation } = useConfirm();

  const { closeDialog, openDialog, open } = useDialog();
  const uploadFormDialogForm = useUploadClientCSVForm();

  const [importErrors, setImportErrors] = useState<ImportClientError[]>([]);

  const { data: client } = useAdminClient(clientId);
  const { loading, importClients, remove } = useClient(clientId);

  const submitForm = useCallback(
    async (data: UnpackNestedValue<{}>) => {
      const dataType = data as { csvFile: File };
      const file = dataType.csvFile;
      const resultData = await importClients(file);
      if (resultData?.errors?.length > 0) {
        setImportErrors(resultData?.errors);
      } else {
        setImportErrors([]);
      }
      closeDialog();
    },
    [importClients, closeDialog],
  );

  const criticalErrors = importErrors.filter(
    (error) => error.severity === 'critical',
  );
  const warningErrors = importErrors.filter(
    (error) => error.severity === 'warning',
  );
  const clearImportErrors = () => setImportErrors([]);

  const tabs: ComponentProps<typeof SideMenu>['tabs'] = [
    {
      key: 'settings',
      label: t('sidebar.companySettingsGeneralSettings'),
      url: ROUTES.admin.clients.details.settings.replace(':id', clientId),
      isActive: path === ROUTES.admin.clients.details.settings,
    },
    {
      key: 'administrators',
      label: t('sidebar.administrators'),
      url: ROUTES.admin.clients.details.administrators.list.replace(
        ':id',
        clientId,
      ),
      isActive: path === ROUTES.admin.clients.details.administrators.list,
    },
    {
      key: 'features',
      label: t('sidebar.features'),
      url: ROUTES.admin.clients.details.features.replace(':id', clientId),
      isActive: path === ROUTES.admin.clients.details.features,
    },
    {
      key: 'deposits',
      label: t('clients.deposit_management.title'),
      url: ROUTES.admin.clients.details.deposits.list.replace(':id', clientId),
      isActive: path === ROUTES.admin.clients.details.deposits.list,
    },
  ];

  return (
    <>
      <Stack gap={3}>
        <Stack>
          <DetailsPageHeader
            title={client?.name ?? ''}
            backTo={
              tabs.map((tab) => tab.isActive).includes(true)
                ? ROUTES.admin.clients.list
                : undefined
            }
            side={
              <Menu
                label={t('common.actions')}
                items={[
                  {
                    label: t('actions.import'),
                    onClick: () => {
                      openDialog();
                    },
                  },
                  {
                    label: t('actions.delete'),
                    onClick: async () => {
                      const { confirmed } = await confirmation(
                        t('clients.deleteConfirmationContent'),
                        t('clients.deleteConfirmationTitle'),
                      );
                      if (confirmed) {
                        await remove.execute();
                        predefinedToasts.success.deleted();
                        history.push(ROUTES.admin.clients.list);
                      }
                    },
                  },
                ]}
              />
            }
          />

          <DetailsPageContent>
            <SideMenu tabs={tabs} />
            <Stack gap={2}>
              {children}

              {!!criticalErrors.length && (
                <Alert severity="error" onClose={clearImportErrors}>
                  <Stack gap="10px">
                    {criticalErrors.map((error, i) => (
                      <Box key={i}>
                        <Typography variant="h3" color="error" component="span">
                          {`Error in row "${error.rowNumber}" field "${error.fieldName}": `}
                        </Typography>
                        <Typography variant="p1" color="error" component="span">
                          {error.message}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Alert>
              )}
              {!!warningErrors.length && (
                <Box mt="20px">
                  <Alert severity="warning" onClose={clearImportErrors}>
                    <Stack gap="10px">
                      {warningErrors.map((error, i) => (
                        <Box key={i}>
                          {!!error.rowNumber && !!error.fieldName && (
                            <Typography
                              variant="h3"
                              color="warning"
                              component="span"
                            >
                              {`Warning in row "${error.rowNumber}" field "${error.fieldName}": `}
                            </Typography>
                          )}
                          <Typography
                            variant="p1"
                            color="warning"
                            component="span"
                          >
                            {error.message}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Alert>
                </Box>
              )}
            </Stack>
          </DetailsPageContent>
        </Stack>
      </Stack>

      <FormDialog
        onCancelClick={closeDialog}
        onSubmitForm={submitForm}
        id="create-employee-dialog"
        open={open}
        title={t('clients.import_employees')}
        actionLabel={t('actions.import')}
        form={uploadFormDialogForm}
        loading={loading}
      />
    </>
  );
};
