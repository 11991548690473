import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IShopItem } from 'types/income';
import { ShopItemsFilters } from './shop.types';

interface GetShopItems {
  filters: Partial<ShopItemsFilters>;
}

export const loaders = {
  getShopItems: ({ filters }: GetShopItems) => {
    return api<TableDataResponse<IShopItem>>({
      url: Endpoints.clientAdmin.shop.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
          priceFrom: filters.price?.from,
          priceTo: filters.price?.from,
          countries: filters.countries,
          benefitPlanTypes: filters.benefitCategories,
          categories: filters.categories,
          sort: filters.sort
            ? `${filters.sort.order === 'desc' ? '-' : ''}${
                filters.sort.columnKey
              }`
            : undefined,
        },
      },
    });
  },
};
