import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import {
  EmployeeCategoryCreateResponse,
  UseCustomEmployeeCategories,
  UseCustomEmployeeCategoriesValues,
  UseEmployeeInnerCategories,
} from '../types/CustomEmployeeCategories';
import { Endpoints } from '../api/constants';
import { useLocalStorage } from '../utils/LocalStorage';
import useTableUtils from '../utils/Table';
import { StorageKeys } from '../types/LocalStorage';
import { useLoading } from './../utils/Loading';
import { makeRequest } from './../api/api';
import { useTableStore } from './Table';
import { CustomEmployeeCategory } from './../types/CustomEmployeeCategories';

export const useCustomEmployeeCategories = (): UseCustomEmployeeCategories => {
  const { t } = useTranslation();

  const { data: employeeCategories, error: employeeCategoriesError } = useSWR(
    Endpoints.employeeCategories,
  );
  const { startLoading, stopLoading, loading: requestLoading } = useLoading();

  const loading = useMemo(
    () => !employeeCategories && !employeeCategoriesError,
    [employeeCategories, employeeCategoriesError],
  );

  const createEmployeeCategory = useCallback(
    async (name: string) => {
      startLoading();
      return await makeRequest('post', Endpoints.createEmployeeCategories, {
        name,
      })
        .then((res) => {
          if (res?.status === 201) {
            toast(t('common.added_succesfully'), { type: 'success' });
            mutate(Endpoints.employeeCategories);
            return res.data as EmployeeCategoryCreateResponse;
          }
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t],
  );

  return {
    employeeCategoriesError,
    employeeCategories,
    loading: requestLoading || loading,
    createEmployeeCategory,
  };
};

export const useCustomEmployeeCategoriesValues = (
  categoryId: string,
): UseCustomEmployeeCategoriesValues => {
  const valuesUrl = useMemo(
    () => `${Endpoints.employeeCategories}/${categoryId}/values`,
    [categoryId],
  );
  const categoryUrl = useMemo(
    () => `${Endpoints.employeeCategories}/${categoryId}`,
    [categoryId],
  );
  const { t } = useTranslation();
  const {
    data: employeeCategoriesValues,
    error: employeeCategoriesValuesError,
  } = useSWR(!!categoryId ? valuesUrl : null);
  const { startLoading, stopLoading, loading } = useLoading();

  const apiLoading = useMemo(
    () => !employeeCategoriesValues && !employeeCategoriesValuesError,
    [employeeCategoriesValues, employeeCategoriesValuesError],
  );

  const parsedEmployeeCategoriesValues = useMemo(
    () => employeeCategoriesValues?.data ?? [],
    [employeeCategoriesValues?.data],
  );

  const createEmployeeCategoryValue = useCallback(
    (name: string) => {
      startLoading();
      makeRequest('post', `${valuesUrl}/create`, { name })
        .then(() => {
          toast(t('common.added_succesfully'), { type: 'success' });
          mutate(valuesUrl);
        })
        .finally(() => stopLoading());
    },
    [valuesUrl, startLoading, stopLoading, t],
  );

  const deleteEmployeeCategory = useCallback(() => {
    startLoading();
    makeRequest('delete', categoryUrl)
      .then(() => {
        toast(t('common.deleted_succesfully'), { type: 'success' });
        mutate(categoryUrl);
        mutate(Endpoints.employeeCategories);
      })
      .finally(() => stopLoading());
  }, [categoryUrl, startLoading, stopLoading, t]);

  const updateCategoryTitle = useCallback(
    async (name: string): Promise<CustomEmployeeCategory> => {
      startLoading();
      const updatedCategory = await makeRequest('patch', categoryUrl, { name })
        .then((res) => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(categoryUrl);
          mutate(Endpoints.employeeCategories);
          return res;
        })
        .finally(() => stopLoading());

      return updatedCategory.data;
    },
    [categoryUrl, startLoading, stopLoading, t],
  );

  const deleteCategoryItem = useCallback(
    (valueId: string) => {
      startLoading();
      makeRequest('delete', `${categoryUrl}/values/${valueId}`)
        .then(() => {
          toast(t('common.deleted_succesfully'), { type: 'success' });
          mutate(valuesUrl);
        })
        .finally(() => stopLoading());
    },
    [categoryUrl, startLoading, stopLoading, t, valuesUrl],
  );

  const updateCategoryItem = useCallback(
    (valueId: string, name: string) => {
      startLoading();
      makeRequest('patch', `${valuesUrl}/${valueId}`, { name })
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(valuesUrl);
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t, valuesUrl],
  );

  return {
    loading: apiLoading || loading,
    employeeCategoriesValuesError,
    employeeCategoriesValues,
    parsedEmployeeCategoriesValues,
    createEmployeeCategoryValue,
    deleteEmployeeCategory,
    updateCategoryTitle,
    deleteCategoryItem,
    updateCategoryItem,
  };
};

export const useEmployeeInnerCategories = (): UseEmployeeInnerCategories => {
  const { t } = useTranslation();
  const { updateCustomColumn } = useTableUtils();
  const { getData } = useLocalStorage();

  const { setCustomColumn, customColumn } = useTableStore(
    ({ setCustomColumn, customColumn }) => {
      return { setCustomColumn, customColumn };
    },
  );

  const { data: employeeInnerCategories, error: employeeInnerCategoriesError } =
    useSWR(Endpoints.employeeInnerCategories);
  const { startLoading, stopLoading, loading: requestLoading } = useLoading();

  const loading = useMemo(
    () => !employeeInnerCategories && !employeeInnerCategoriesError,
    [employeeInnerCategories, employeeInnerCategoriesError],
  );

  const createEmployeeCategory = useCallback(
    async (name: string) => {
      startLoading();
      return await makeRequest('post', Endpoints.createEmployeeCategories, {
        name,
      })
        .then((res) => {
          if (res?.status === 201) {
            toast(t('common.added_succesfully'), { type: 'success' });
            mutate(Endpoints.employeeCategories);
            return res.data as EmployeeCategoryCreateResponse;
          }
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t],
  );

  useEffect(() => {
    if (!!employeeInnerCategories && employeeInnerCategories?.length > 0) {
      const localStorageCustomColumn = getData(StorageKeys.customColumn);
      const storedCustomCustomColumn = localStorageCustomColumn ?? null;
      const currentCustomColumn = customColumn ?? storedCustomCustomColumn;

      const currentCustomColumnExists = employeeInnerCategories.some(
        (cat: CustomEmployeeCategory) => cat.name === currentCustomColumn,
      );

      if (currentCustomColumnExists) updateCustomColumn(currentCustomColumn);
      else if (!customColumn || !currentCustomColumnExists)
        updateCustomColumn(employeeInnerCategories[0]?.name);
    } else setCustomColumn(null);
    //eslint-disable-next-line
  }, [employeeInnerCategories]);

  return {
    employeeInnerCategoriesError,
    employeeInnerCategories,
    loading: requestLoading || loading,
    createEmployeeCategory,
  };
};
