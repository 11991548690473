import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    badge: {
      backgroundColor: Colors.Primary,
      color: Colors.White,
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 5px',
      minWidth: 23,
    },
    typography: {
      marginRight: 15,
    },
  }),
);
