import { DeleteIconButton, EditIconButton } from 'melp-design/components';
import { Stack, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePriceTranslation } from '../../../../../state/MarketplaceItems';
import { PriceTranslation } from '../../../../../types/MarketplaceItems';
import { useDialog } from '../../../../../utils/Dialog';
import PriceTranslationDialog, {
  PriceTranslationFormData,
} from './PriceTranslationDialog';

interface Props {
  itemId: string;
  priceId: string;
  translation: PriceTranslation;
}

const PriceTranslationsRow = ({ translation, ...props }: Props) => {
  const { t } = useTranslation();

  const translationDialog = useDialog();

  const { update, remove } = usePriceTranslation(
    props.itemId,
    props.priceId,
    translation.id,
  );

  const handleSubmit = async (data: PriceTranslationFormData) => {
    await update.execute(data);
    translationDialog.closeDialog();
  };

  return (
    <TableRow hover>
      <TableCell>{t(`language.${translation.language}`)}</TableCell>
      <TableCell style={{ borderRight: 'none', whiteSpace: 'break-spaces' }}>
        {translation.description}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" gap="10px">
          <EditIconButton onClick={translationDialog.openDialog} />
          <DeleteIconButton
            onClick={() => remove.execute()}
            loading={remove.loading}
          />
        </Stack>
        <PriceTranslationDialog
          title={t('marketplaceItems.updatePriceTranslation')}
          initialData={translation}
          open={translationDialog.open}
          onClose={translationDialog.closeDialog}
          onSubmit={handleSubmit}
          loading={update.loading}
        />
      </TableCell>
    </TableRow>
  );
};

export default PriceTranslationsRow;
