import { useBoolean } from './useBoolean';

/**
 * Convenience hook when dealing with a dialog state.
 */
export const useDialog = (initialValue?: boolean) => {
  const [open, openDialog, closeDialog] = useBoolean(initialValue);
  return {
    open,
    closeDialog,
    openDialog,
  };
};
