import { FC } from 'react';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsList } from 'containers/recognitions';

const RecognitionsListPage: FC = () => {
  return (
    <RecognitionsLayout>
      <RecognitionsList />
    </RecognitionsLayout>
  );
};

export default RecognitionsListPage;
