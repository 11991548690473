import { useCallback, useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MpAsyncGetMethod } from '@mp-react/table';
import { AxiosResponse } from 'axios';
import { ROUTES } from 'config';
import { Currency } from 'types/general';
import { ContentLanguage } from 'types/general';
import { DiscountPackage } from 'store/admin-discounts';
import { ClientFeature, adminClientsKeys } from 'store/admin-clients';
import { makeRequest } from '../api/api';
import { useLoading } from '../utils/Loading';
import {
  Administrator,
  LocalPermissionsRequest,
  UsePermissionsParams,
} from '../types/Administrators';
import { usePermissionUtils } from '../utils/Permissions';
import { AdministratorFilterNames } from '../constants/Administrators';
import { useAdminUtils } from '../utils/Administrators';
import { useCustomSwrMutation } from '../utils/Api';
import { Endpoints } from './../api/constants';
import {
  ClientAdministratorsFooter,
  ClientAdministratorsResponse,
  ImportClientsResponse,
} from './../types/Clients';

export const useClient = (clientId?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const url = !!clientId ? `${Endpoints.parentCompany}/${clientId}` : null;

  const { loading, startLoading, stopLoading } = useLoading();

  const updateClient = useCallback(
    async (
      data: Partial<{
        name: string;
        code: string;
        defaultCurrency: Currency;
        defaultLanguage: Uppercase<ContentLanguage>;
        country: string;
        internalComment: string;
        deactivationDate: string | null;
        features: ClientFeature[];
        discountPlan: DiscountPackage;
      }>,
    ) => {
      if (!clientId && !url) {
        toast(t('errors.nothing_found'), { type: 'error' });
        return;
      }
      startLoading();
      await makeRequest('patch', url as string, data)
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          queryClient.invalidateQueries(adminClientsKeys.all);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [clientId, queryClient, startLoading, stopLoading, t, url],
  );

  const importClients = useCallback(
    async (file: File) => {
      startLoading();
      const formData = new FormData();
      formData.append('file', file);
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const data = await makeRequest(
        'post',
        `${url}/importEmployees`,
        formData,
        headers,
      )
        .then((res) => {
          const response = res.data as ImportClientsResponse;
          const noCriticalErrors = response.errors.every(
            (error) => error.severity !== 'critical',
          );
          if (noCriticalErrors) {
            toast(t('common.added_succesfully'), { type: 'success' });
            queryClient.invalidateQueries(adminClientsKeys.all);
          }

          return response;
        })
        .finally(() => {
          stopLoading();
        });
      return data;
    },
    [queryClient, startLoading, stopLoading, t, url],
  );

  const remove = useCustomSwrMutation(
    Endpoints.melpAdmin.parentCompanies.byId(clientId ?? '').root,
    'delete',
  );

  return {
    loading,
    updateClient,
    importClients,
    remove,
  };
};

export const useClientAdministrators = (clientId: string, query?: string) => {
  const { t } = useTranslation();
  const { parseAdministratorRequest } = useAdminUtils();
  const { loading, startLoading, stopLoading } = useLoading();

  const url = !!clientId
    ? `${Endpoints.parentCompany}/${clientId}/admins${
        !!query ? `?${query}` : ''
      }`
    : null;
  const {
    data: clientAdministratorsResponse,
    error: clientAdministratorError,
  } = useSWR<ClientAdministratorsResponse, any>(url);

  const apiLoading = useMemo(
    () => !clientAdministratorsResponse && !clientAdministratorError,
    [clientAdministratorError, clientAdministratorsResponse],
  );

  const clientAdministratorsData = useMemo(
    () =>
      clientAdministratorsResponse?.data?.map((item) => ({
        ...item,
        inactive: item.status === 'inactive',
      })),
    [clientAdministratorsResponse?.data],
  );

  const count = useMemo(
    () => clientAdministratorsResponse?.count ?? 0,
    [clientAdministratorsResponse?.count],
  );

  const pageSize = useMemo(
    () => clientAdministratorsResponse?.pageSize ?? 0,
    [clientAdministratorsResponse?.pageSize],
  );

  const footerData = useMemo((): ClientAdministratorsFooter => {
    if (!clientAdministratorsResponse?.footer)
      return {
        email: '',
        fullName: '',
        phone: '',
        status: '',
      };

    const footerResponse = clientAdministratorsResponse.footer;
    return {
      email: `${footerResponse.email} ${t('common.emails')}`,
      fullName: `${footerResponse.fullName} ${t('sidebar.administrators')}`,
      phone: `${footerResponse.phone} ${t('administrators.phone_numbers')}`,
      status: '',
    };
  }, [clientAdministratorsResponse?.footer, t]);

  const createClientsAdministrator = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const parsedData = parseAdministratorRequest(data);
      await makeRequest('post', `${url}/create`, parsedData)
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(url);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [parseAdministratorRequest, startLoading, stopLoading, t, url],
  );

  return {
    loading,
    apiLoading,
    clientAdministratorsData,
    count,
    pageSize,
    footerData,
    createClientsAdministrator,
  };
};

export const useClientAdministratorsAsyncMethods = (
  clientId: string,
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!clientId
        ? `${Endpoints.parentCompany}/${clientId}/admins/filterValues`
        : '',
    [clientId],
  );

  const getFilterItems = useCallback(
    (filterName: AdministratorFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return makeRequest('get', apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data,
      );
    },
    [baseUrl],
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.FULL_NAMES),
    [getFilterItems],
  );

  const getEmail = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.EMAIL),
    [getFilterItems],
  );

  const getPhone = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.PHONE),
    [getFilterItems],
  );

  return {
    getFullNames,
    getEmail,
    getPhone,
  };
};

export const useClientAdministrator = (
  clientId?: string,
  administratorId?: string,
  disableAPI?: boolean,
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { parseAdministratorRequest } = useAdminUtils();
  const { loading, startLoading, stopLoading } = useLoading();
  const url = useMemo(
    () => `${Endpoints.parentCompany}/${clientId}/admins/${administratorId}`,
    [administratorId, clientId],
  );
  const { data: clientAdministratorData, error: clientAdministratorError } =
    useSWR(!!clientId && !disableAPI ? url : null);

  const apiLoading = useMemo(
    () => !clientAdministratorData && !clientAdministratorError,
    [clientAdministratorData, clientAdministratorError],
  );

  const updateAdministrator = useCallback(
    async (data: Partial<Administrator>, redirect?: boolean) => {
      const parsedData = parseAdministratorRequest(data);
      startLoading();
      await makeRequest('patch', url, parsedData)
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(url);
          if (redirect !== false && clientId) {
            history.push(
              ROUTES.admin.clients.details.administrators.list.replace(
                ':id',
                clientId,
              ),
            );
          }
        })
        .finally(() => {
          stopLoading();
        });
    },
    [
      clientId,
      history,
      parseAdministratorRequest,
      startLoading,
      stopLoading,
      t,
      url,
    ],
  );

  const resendInvite = useCallback(() => {
    startLoading();
    makeRequest(
      'post',
      `${Endpoints.parentCompany}/${clientId}/admins/${administratorId}/resendInvite`,
    )
      .then(() => {
        toast(t('common.invitation_sent'), { type: 'success' });
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, stopLoading, t, clientId, administratorId]);

  const deleteAdmin = useCallback(() => {
    startLoading();
    makeRequest(
      'delete',
      `${Endpoints.deleteMelpAdmin}/${administratorId}?parentCompanyId=${clientId}`,
    )
      .then(() => {
        toast(t('common.deleted_succesfully'), { type: 'success' });
        if (clientId) {
          history.push(
            ROUTES.admin.clients.details.administrators.list.replace(
              ':id',
              clientId,
            ),
          );
        }
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, administratorId, t, history, clientId, stopLoading]);

  return {
    resendInvite,
    apiLoading,
    loading,
    updateAdministrator,
    clientAdministratorData,
    deleteAdmin,
  };
};

export const useClientPermissions = ({
  clientId,
  adminId,
  selectedId,
  removeChanges,
}: UsePermissionsParams) => {
  const { startLoading, stopLoading, loading: apiLoading } = useLoading();
  const { t } = useTranslation();
  const { clientAdministratorData } = useClientAdministrator(clientId, adminId);

  const {
    wasChanged,
    parsePermissionEntry,
    getAllLocalPermissions,
    getCompanyGroupPermissions,
    getCompanyPermissions,
    getParsedPermissions,
  } = usePermissionUtils();

  const clientAdminIsRoot = !!clientAdministratorData?.root;

  const companyId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes('company-'))
      return selectedId.replace('company-', '');
    return false;
  }, [selectedId, wasChanged]);

  const companyGroupId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes('companyGroup-'))
      return selectedId.replace('companyGroup-', '');
    return false;
  }, [selectedId, wasChanged]);

  const basePermissionUrl = useMemo(
    () =>
      `${Endpoints.parentCompany}/${clientId}/admins/${adminId}/permissions`,
    [adminId, clientId],
  );

  const companyGroupUrl = useMemo(
    () =>
      companyGroupId
        ? `${basePermissionUrl}/companyGroup/${companyGroupId}`
        : null,
    [basePermissionUrl, companyGroupId],
  );

  const companyUrl = useMemo(() => {
    if (selectedId === 'allLocalPermissions')
      return `${basePermissionUrl}/company/`;
    return companyId ? `${basePermissionUrl}/company/${companyId}` : null;
  }, [basePermissionUrl, companyId, selectedId]);

  const globalPermissionUrl = useMemo(
    () =>
      adminId && !companyGroupId && !companyId && selectedId
        ? `${basePermissionUrl}/global`
        : null,
    [adminId, basePermissionUrl, companyGroupId, companyId, selectedId],
  );

  const { data: globalPermissions, error: globalPermissionError } =
    useSWR(globalPermissionUrl);

  const { data: companyPermissions, error: companyError } = useSWR(companyUrl);

  const { data: companyGroupPermissions, error: companyGroupError } =
    useSWR(companyGroupUrl);

  const globalPermissionsLoading = useMemo(
    () => !globalPermissions && !globalPermissionError,
    [globalPermissionError, globalPermissions],
  );

  const companyPermissionsLoading = useMemo(
    () => !companyPermissions && !companyError,
    [companyError, companyPermissions],
  );

  const companyGroupPermissionsLoading = useMemo(
    () => !companyGroupPermissions && !companyGroupError,
    [companyGroupError, companyGroupPermissions],
  );

  const loading = useMemo(() => {
    if (!selectedId) return false;
    if (!!companyGroupId) return companyGroupPermissionsLoading;
    if (!!companyId) return companyPermissionsLoading;

    return globalPermissionsLoading;
  }, [
    companyGroupId,
    companyGroupPermissionsLoading,
    companyId,
    companyPermissionsLoading,
    globalPermissionsLoading,
    selectedId,
  ]);

  const parsedGlobalPermissions = useMemo(
    () => getParsedPermissions(globalPermissions, clientAdminIsRoot),
    [getParsedPermissions, globalPermissions, clientAdminIsRoot],
  );

  const parsedCompanyPermissions = useMemo(
    () => getParsedPermissions(companyPermissions, clientAdminIsRoot),
    [companyPermissions, getParsedPermissions, clientAdminIsRoot],
  );

  const parsedCompanyGroupPermissions = useMemo(
    () => getParsedPermissions(companyGroupPermissions, clientAdminIsRoot),
    [companyGroupPermissions, getParsedPermissions, clientAdminIsRoot],
  );

  const updateGlobalPermissions = useCallback(
    async (globalEntries: [string, any] | undefined) => {
      if (!globalEntries) return;
      const requestData = parsePermissionEntry([globalEntries]);
      if (requestData.length > 0) {
        await makeRequest('patch', `${basePermissionUrl}/global`, {
          updatedModules: requestData,
        });
        mutate(`${basePermissionUrl}/global`);
      }
    },
    [basePermissionUrl, parsePermissionEntry],
  );

  const updateLocalPermissions = useCallback(
    async (requestData: LocalPermissionsRequest) => {
      if (requestData.updatedModules.length > 0) {
        await makeRequest('patch', `${basePermissionUrl}/local`, requestData);
        mutate(`${basePermissionUrl}/local`);
      }
    },
    [basePermissionUrl],
  );

  const updatePermissions = useCallback(
    async (data: FormData) => {
      startLoading();
      const entries = Object.entries(data);
      const globalEntries = entries.find(
        ([key]) => key === 'allGlobalPermissions',
      );
      await updateGlobalPermissions(globalEntries);

      const localEntries = entries.find(
        ([key]) => key === 'allLocalPermissions',
      );
      const companyGroupEntries = entries
        .filter(([key]) => key.includes('companyGroup-'))
        .map(([key, value]) => [key.replace('companyGroup-', ''), value]);
      const companyEntries = entries
        .filter(([key]) => key.includes('company-'))
        .map(([key, value]) => [key.replace('company-', ''), value]);
      const localPermissions = getAllLocalPermissions(localEntries);
      const companyGroupPermissions =
        getCompanyGroupPermissions(companyGroupEntries);
      const companyPermissions = getCompanyPermissions(companyEntries);
      const requestData: LocalPermissionsRequest = {
        updatedModules: [
          ...companyPermissions,
          ...companyGroupPermissions,
          ...localPermissions,
        ],
      };
      await updateLocalPermissions(requestData);
      removeChanges();
      toast(t('common.updated_succesfully'), { type: 'success' });
      mutate(Endpoints.me);
      stopLoading();
    },
    [
      startLoading,
      updateGlobalPermissions,
      getAllLocalPermissions,
      getCompanyGroupPermissions,
      getCompanyPermissions,
      updateLocalPermissions,
      removeChanges,
      t,
      stopLoading,
    ],
  );

  return {
    updatePermissions,
    globalPermissions,
    companyPermissions,
    companyGroupPermissions,
    loading: loading || apiLoading,
    companyGroupId,
    companyId,
    parsedGlobalPermissions,
    parsedCompanyPermissions,
    parsedCompanyGroupPermissions,
  };
};
