import { Box, BoxProps, Typography } from '@mui/material';
import useStyles from './GeneralSettingsTitle.styles';

interface IProps extends BoxProps {
  count?: number;
  title: string;
}

const GeneralSettingsTitle = (props: IProps) => {
  const { count, title, ...rest } = props;
  const classes = useStyles();
  return (
    <Box className={classes.wrapper} {...rest}>
      <Typography classes={{ root: classes.typography }} variant="h5">
        {title}
      </Typography>
      {!!count && (
        <Box className={classes.badge}>
          <Typography variant="body2">{count}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default GeneralSettingsTitle;
