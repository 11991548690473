import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from 'melp-design/icons';
import { Loader } from 'melp-design/components';
import { useGlobalSearch } from '../../../state/GlobalSearch';
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as Cross } from '../../../assets/icons/cross.svg';
import { useMe } from '../../../state/Administrators';
import { EntityTypes } from '../../../types/GlobalSearch';
import useStyles from './GlobalSearch.styles';

export default function GlobalSearch() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    hasBenefitPermissions,
    hasEmployeePermissions,
    hasAdministratorPermissions,
    hasNewsPermissions,
    hasGroupPermissions,
  } = useMe();
  const {
    search,
    results,
    loading,
    searchQuery,
    searchInputValue,
    clearSearch,
    handleSearchItemClick,
  } = useGlobalSearch();
  const textFieldRef = useRef<HTMLInputElement>();
  const [open, setOpen] = useState<boolean>(false);

  const anchorEl = useMemo(
    () => (!!results ? textFieldRef.current : null),
    [results],
  );

  const popperOpen = useMemo(() => !!anchorEl, [anchorEl]);
  const popperId = useMemo(
    () => (popperOpen ? 'global-search-popover' : undefined),
    [popperOpen],
  );

  const textFieldWidth = useMemo(() => (open ? '200px' : 0), [open]);

  const toggleTextField = useCallback(() => {
    setOpen((prevState) => !prevState);
    clearSearch();
  }, [clearSearch]);

  const clearTextField = useCallback(() => {
    clearSearch();
    textFieldRef?.current?.focus();
  }, [clearSearch]);

  useLayoutEffect(() => {
    open && textFieldRef?.current?.focus();
  }, [open]);

  const EndAdornment = useMemo(() => {
    if (!searchQuery) return null;

    return (
      <IconButton onClick={clearTextField} size="large">
        <Cross />
      </IconButton>
    );
  }, [clearTextField, searchQuery]);

  const checkIfDisabled = useCallback(
    (entityType: EntityTypes) => {
      switch (entityType) {
        case EntityTypes.admin:
          return !hasAdministratorPermissions;
        case EntityTypes.benefitPlan:
          return !hasBenefitPermissions;
        case EntityTypes.employee:
          return !hasEmployeePermissions;
        case EntityTypes.employeeGroup:
          return !hasGroupPermissions;
        case EntityTypes.news:
          return !hasNewsPermissions;
        default:
          return false;
      }
    },
    [
      hasAdministratorPermissions,
      hasBenefitPermissions,
      hasEmployeePermissions,
      hasGroupPermissions,
      hasNewsPermissions,
    ],
  );

  const PopperContent = useMemo(() => {
    if (loading) return <Loader minHeight={100} />;

    if (!!results && results.length === 0)
      return (
        <Box
          padding="15px 25px"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1">{t('errors.nothing_found')}</Typography>
        </Box>
      );

    return (
      <MenuList style={{ padding: 5 }}>
        {results?.map(
          ({ entityId, description, benefitPlanType, entityType }) => (
            <MenuItem
              key={entityId}
              className={classes.result}
              onClick={() => {
                handleSearchItemClick(entityType, entityId);
                toggleTextField();
              }}
              disabled={checkIfDisabled(entityType)}
            >
              <Box marginRight="20px">
                <Typography component="span" variant="body2">
                  {description}
                </Typography>
                <Typography
                  className={classes.resultTextSpacing}
                  component="span"
                  color="textSecondary"
                  variant="body2"
                >
                  {!!benefitPlanType
                    ? `- ${t(`menu.${benefitPlanType}`)} `
                    : ''}
                  - {t(`entity_type.${entityType}`)}
                </Typography>
              </Box>
              <ChevronRight />
            </MenuItem>
          ),
        )}
      </MenuList>
    );
  }, [
    checkIfDisabled,
    classes.result,
    classes.resultTextSpacing,
    handleSearchItemClick,
    loading,
    results,
    t,
    toggleTextField,
  ]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      marginRight="20px"
    >
      <IconButton onClick={toggleTextField} size="large">
        <Search />
      </IconButton>
      <TextField
        variant="outlined"
        margin="dense"
        placeholder={`${t('common.search')}...`}
        classes={{ root: classes.textfield }}
        style={{ width: textFieldWidth, opacity: !open ? '0' : '1' }}
        onChange={search}
        aria-describedby={popperId}
        inputRef={textFieldRef}
        InputProps={{
          endAdornment: EndAdornment,
        }}
        type="text"
        inputProps={{
          autoComplete: 'off',
          'aria-autocomplete': 'none',
        }}
        value={searchInputValue}
      />
      <Popper
        id={popperId}
        anchorEl={anchorEl}
        open={popperOpen}
        placement="bottom-start"
        style={{ zIndex: 1 }}
      >
        <Box marginTop="10px">
          <Paper elevation={6}>{PopperContent}</Paper>
        </Box>
      </Popper>
    </Box>
  );
}
