import { z } from 'zod';
import { DateRange } from 'types/Common';
import {
  alternativeEmployeesFiltersSchema,
  alternativesFiltersSchema,
} from './alternatives.schemas';

export const alternativeSelectionStatuses = [
  'selected',
  'notSelected',
  'expired',
  'notAssigned',
] as const;
export type AlternativeSelectionStatus =
  (typeof alternativeSelectionStatuses)[number];

export const alternativeEmployeesAssignmentStatuses = [
  'assignedIndividually',
  'assignedPerGroup',
  'notAssigned',
] as const;
export type AlternativeEmployeesAssignmentStatus =
  (typeof alternativeEmployeesAssignmentStatuses)[number];

export const alternativeAssignmentTypes = ['group', 'individual'] as const;
export type AlternativeAssignmentTypes =
  (typeof alternativeAssignmentTypes)[number];

export const selectionPeriods = ['range', 'inDays'] as const;
export type SelectionPeriod = (typeof selectionPeriods)[number];

export interface BaseAlternative {
  id: string;
  name: string;
  isActive: boolean;
  assignments: number;
  benefits: number;
  selections: number;
  missingSelections: number;
}

export interface Alternative extends BaseAlternative {
  assignedGroupsCount: number;
  maxSelections: number;
  deadlineType: SelectionPeriod;
  deadlineRange?: Required<DateRange>;
  deadlineInDays?: number;
}

export interface ExportAlternativesFilters {
  alternatives: string[];
}

export interface AlternativeEmployeesGroup {
  id: string;
  name: string;
  isAssigned: boolean;
  employeesCount: number;
}

export type AlternativesFilters = z.infer<typeof alternativesFiltersSchema>;

export type AlternativeEmployeesFilters = z.infer<
  typeof alternativeEmployeesFiltersSchema
>;
