import { useCallback, useMemo } from 'react';
import { Currency } from 'types/general';
import { useParentCompany } from '../state/ParentCompany';

export const useCurrency = () => {
  const { parentCompany } = useParentCompany();
  const currency = useMemo(
    () => (parentCompany?.defaultCurrency ?? '') as Currency,
    [parentCompany?.defaultCurrency],
  );
  const currencyDelimiter = useMemo(() => ' ', []);

  const getDefaultCurrencyFormat = useCallback(
    (value?: string | number) => {
      if (value === 0) return `0 ${currency}`;
      if (!value) return null;
      return `${value
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, currencyDelimiter)} ${currency}`;
    },
    [currency, currencyDelimiter],
  );

  const getDefaultCurrencyNumber = useCallback(
    (value?: string | number) => {
      if (!value) return '';

      return value
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, currencyDelimiter);
    },
    [currencyDelimiter],
  );

  return {
    currency,
    currencyDelimiter,
    getDefaultCurrencyFormat,
    getDefaultCurrencyNumber,
  };
};
