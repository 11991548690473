import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { getRawValue, useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../utils/Common';

export default function BenefitBalanceDetails(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { data, row } = props;
  const { t } = useTranslation();

  const rowIndex = useMemo(() => row.index, [row.index]);
  const type = useMemo(() => (data[rowIndex] as any)?.type, [data, rowIndex]);
  const benefit = useMemo(
    () => (data[rowIndex] as any)?.benefitPlanName,
    [data, rowIndex],
  );
  const allocationFrequency = useMemo(
    () => getRawValue(data[rowIndex], 'allocationFrequency'),
    [data, rowIndex],
  );
  const { shortMonth, quarter, year } = useDateFormat(
    (data[rowIndex] as any)?.date,
  );

  const commentValue = useMemo(() => {
    if (!!value) return value;
    return '';
  }, [value]);

  const detail = useMemo(() => {
    if (type === 'manualAdjustment') {
      return commentValue;
    } else if (type === 'allocation') {
      if (allocationFrequency === 'monthly') {
        return `${t(
          'employees.flex_benefit_allocation_for',
        )} ${shortMonth} ${commentValue}`;
      } else if (allocationFrequency === 'quarterly') {
        return `${t(
          'employees.flex_benefit_allocation_for',
        )} Q${quarter} ${commentValue}`;
      } else if (allocationFrequency === 'annually') {
        return `${t(
          'employees.flex_benefit_allocation_for',
        )} ${year} ${commentValue}`;
      }
    } else if (type === 'allocationReset') {
      if (allocationFrequency === 'monthly') {
        return `${t(
          'employees.flex_benefit_allocation_reset',
        )} ${shortMonth} ${commentValue}`;
      } else if (allocationFrequency === 'quarterly') {
        return `${t(
          'employees.flex_benefit_allocation_reset',
        )} Q${quarter} ${commentValue}`;
      } else if (allocationFrequency === 'annually') {
        return `${t(
          'employees.flex_benefit_allocation_reset',
        )} ${year} ${commentValue}`;
      }
    } else if (type === 'benefitPlanChoice') {
      return `${benefit} ${commentValue}`;
    } else if (type === 'benefitPlanMonthlyCharge') {
      return `${t('benefit_balance.benefit_balance_for')
        ?.replace('{benefit}', benefit)
        ?.replace('{period}', shortMonth ?? '')} ${commentValue}`;
    }
    return null;
  }, [
    type,
    benefit,
    shortMonth,
    t,
    allocationFrequency,
    quarter,
    year,
    commentValue,
  ]);

  return <Typography variant="body2">{detail}</Typography>;
}
