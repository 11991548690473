import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IEmployee,
  IEmployeeExpense,
  IEmployeeWalletBenefit,
  IEmployeeWalletRecognitions,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { RECOGNITION_SHOP_ID } from 'store/recognitions';
import { ExpenseStatus } from 'store/expenses';
import { toCents } from 'utils/general';
import {
  EmployeeExpensesFilters,
  EmployeeWalletBenefitsFilters,
  EmployeesFilters,
} from './employees.types';

interface GetEmployees {
  filters: Partial<EmployeesFilters>;
}

interface GetEmployeeExpenses {
  employeeId: string;
  filters: Partial<EmployeeExpensesFilters>;
}

interface CreateExpense {
  employeeId: string;
  expense: {
    date: string;
    status: ExpenseStatus;
    benefits: string[];
    recognition: boolean;
    shopCategories: ShopItemCategory[];
    amount: number;
    description: string;
  };
}

interface GetWalletBenefits {
  employeeId: string;
  filters: Partial<EmployeeWalletBenefitsFilters>;
}

interface GetWalletRecognitions {
  employeeId: string;
}

export const loaders = {
  getEmployees: ({ filters }: GetEmployees) => {
    return api<TableDataResponse<IEmployee>>({
      url: Endpoints.clientAdmin.employees.root,
      params: {
        filter: {
          name: filters.name || undefined,
        },
      },
    });
  },
  getExpenses: ({ employeeId, filters }: GetEmployeeExpenses) => {
    return api<TableDataResponse<IEmployeeExpense>>({
      url: Endpoints.clientAdmin.employees.byId.expenses.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
          itemCategory: filters.categories,
          expenseStatus: filters.status,
          benefitId:
            filters.source?.filter(
              (source) => source !== RECOGNITION_SHOP_ID,
            ) ?? [],
          recognition:
            filters.source?.includes(RECOGNITION_SHOP_ID) || undefined,
        },
      },
    });
  },
  createExpense: ({ employeeId, expense }: CreateExpense) => {
    return api({
      url: Endpoints.clientAdmin.employees.byId.expenses.root.replace(
        ':employeeId',
        employeeId,
      ),
      method: 'post',
      data: {
        date: expense.date,
        status: expense.status,
        sourceBenefitIds: expense.benefits,
        sourceRecognition: expense.recognition,
        marketplaceItemCategories: expense.shopCategories,
        expense: toCents(expense.amount),
        description: expense.description,
      },
    });
  },
  getWalletBenefits: ({ employeeId, filters }: GetWalletBenefits) => {
    return api<TableDataResponse<IEmployeeWalletBenefit>>({
      url: Endpoints.clientAdmin.employees.byId.walletBenefits.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.benefits?.filter(Boolean),
          status: filters.status,
        },
      },
    });
  },
  getWalletRecognitions: ({ employeeId }: GetWalletRecognitions) => {
    return api<IEmployeeWalletRecognitions>({
      url: Endpoints.clientAdmin.employees.byId.walletRecognitions.root.replace(
        ':employeeId',
        employeeId,
      ),
    });
  },
};
