import { createContext, ReactNode, useContext } from 'react';
import { Loader } from 'melp-design/components';
import { useAuth } from './useAuth';

type AuthContextValue = Omit<ReturnType<typeof useAuth>, 'loading'>;

const AuthContext = createContext<AuthContextValue | null>(null);

interface Props {
  children: ReactNode;
}

export const AuthProvider = (props: Props) => {
  const { loading, ...authContext } = useAuth();

  if (loading) return <Loader />;

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Auth context is missing');
  }

  return authContext;
};
