import { z } from 'zod';
import { paginationFiltersSchema } from 'utils/schemas';
import { automationSubjects } from './automations.types';
import { AUTOMATION_OPERATOR_BY_CONDITION } from './automations.constants';

export const automationsFiltersSchema = z
  .object({})
  .merge(paginationFiltersSchema);

export const ruleSchema = z.object({
  subject: z.object({ type: z.enum(automationSubjects) }),
  conditions: z.array(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('employeeBirthDate'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeBirthDate']),
      }),
      z.object({
        type: z.literal('employeeGroup'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeGroup']),
        value: z.array(z.string()),
      }),
      z.object({
        type: z.literal('employeeCompany'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeCompany']),
        value: z.array(z.string()),
      }),
      z.object({
        type: z.literal('employeeDepartment'),
        operator: z.enum(
          AUTOMATION_OPERATOR_BY_CONDITION['employeeDepartment'],
        ),
        value: z.array(z.string()),
      }),
      z.object({
        type: z.literal('employeeStartDate'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeStartDate']),
      }),
      z.object({
        type: z.literal('employeeCustomCategory'),
        operator: z.enum(
          AUTOMATION_OPERATOR_BY_CONDITION['employeeCustomCategory'],
        ),
        customCategoryId: z.string(),
        customCategoryValueIds: z.array(z.string()),
      }),
      z.object({
        type: z.literal('employeeAge'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeAge']),
        value: z.array(z.number()),
      }),
      z.object({
        type: z.literal('employeeWorkDuration'),
        operator: z.enum(
          AUTOMATION_OPERATOR_BY_CONDITION['employeeWorkDuration'],
        ),
        unit: z.string(),
        value: z.number(),
      }),
      z.object({
        type: z.literal('employeeEndDate'),
        operator: z.enum(AUTOMATION_OPERATOR_BY_CONDITION['employeeEndDate']),
        unit: z.string(),
        value: z.number(),
      }),
    ]),
  ),
  action: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('assignBenefit'),
      benefitId: z.string(),
      duration: z.number().optional(),
      unit: z.string().optional(),
    }),
    z.object({
      type: z.literal('assignAlternative'),
      alternativeId: z.string(),
    }),
    z.object({
      type: z.literal('assignToGroup'),
      groupId: z.string(),
    }),
    z.object({
      type: z.literal('removeFromGroup'),
      groupId: z.string(),
    }),
  ]),
});
