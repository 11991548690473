import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    emptyImageBox: {
      border: `1px solid ${Colors.BorderDefault}`,
      borderRadius: 8,
      cursor: 'pointer',
    },
    img: {
      flex: 1,
      maxWidth: 160,
      maxHeight: 120,
      borderRadius: 8,
      objectFit: 'contain',
    },
    clearIcon: {
      position: 'absolute',
      top: '-12px',
      right: '-38px',
    },
  }),
);
