import { ComponentPropsWithoutRef } from 'react';
import { useDepartments } from 'store/companies';
import { SelectFilter } from 'components/filters';

interface Props<Multiple extends boolean>
  extends Pick<
    ComponentPropsWithoutRef<typeof SelectFilter>,
    'onClearFilter' | 'onCancel'
  > {
  value?: Multiple extends true ? string[] : string;
  multiple: Multiple;
  onApplyFilter: (value: Multiple extends true ? string[] : string) => void;
}

export const DepartmentsFilter = <Multiple extends boolean>({
  multiple,
  value,
  onApplyFilter,
  onClearFilter,
  onCancel,
}: Props<Multiple>) => {
  const { data: departments, isLoading } = useDepartments();

  return (
    <SelectFilter
      options={departments?.map((department) => ({
        key: department.id,
        label: department.name,
      }))}
      value={value}
      onApplyFilter={onApplyFilter}
      onClearFilter={onClearFilter}
      onCancel={onCancel}
      loadingOptions={isLoading}
      multiple={multiple}
    />
  );
};
