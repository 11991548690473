import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
import { ROUTES } from 'config';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import { OrgStructureFilterParams } from '../types/Common';
import {
  CreateEmployeesGroupRequest,
  EmployeeGroupsResponse,
  EmployeesGroup,
  EmployeesGroupSearchResponse,
} from '../types/EmployeeGroups';
import {
  useFilterValues,
  useGet,
  useCustomSwrMutation,
  useXLSExport,
} from '../utils/Api';
import { convertToQueryParams } from '../utils/Filters';
import { useLoading } from '../utils/Loading';
import { UseEmployeeGroups } from './../types/EmployeeGroups';
import { useMe } from './Administrators';

const useEmployeeGroups = (
  groupId?: string,
  list?: boolean,
  query?: string,
): UseEmployeeGroups => {
  const { hasGroupPermissions } = useMe();
  const history = useHistory();
  const { t } = useTranslation();
  const queryParam = !!query ? `?${query}` : '';
  const { data: employeeGroupsList, error: employeeGroupsListError } = useSWR(
    !groupId && list && hasGroupPermissions
      ? Endpoints.employeeGroupsList + queryParam
      : null,
  );
  const { stopLoading, startLoading, loading } = useLoading();

  const addEmployeeGroups = useCallback(
    async (employeeGroupId: string, employeeIds: string[]) => {
      startLoading();
      try {
        try {
          await makeRequest(
            'patch',
            `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
            {
              assignToGroup: employeeIds,
            },
          );
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(Endpoints.employee);
          return true;
        } catch {
          return false;
        }
      } finally {
        stopLoading();
      }
    },
    [startLoading, stopLoading, t],
  );

  const deleteRequestState = useLoading();
  const deleteEmployeeGroup = (employeeGroupId: string) => {
    deleteRequestState.startLoading();
    makeRequest('delete', `${Endpoints.employeeGroup}/${employeeGroupId}`)
      .then(() => {
        toast(t('common.deleted_succesfully'), { type: 'success' });
        mutate(Endpoints.employeeGroup);
        history.push(ROUTES.employeeGroups.list);
      })
      .finally(() => {
        deleteRequestState.stopLoading();
      });
  };

  const updateEmployeeGroup = useCallback(
    async (employeeGroupId: string, name: string) => {
      startLoading();
      const isSuccess = await makeRequest(
        'patch',
        `${Endpoints.employeeGroup}/${employeeGroupId}`,
        {
          name,
        },
      )
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(`${Endpoints.employeeGroup}/${employeeGroupId}`);
          mutate(Endpoints.employeeGroup);
          return true;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          stopLoading();
        });
      return isSuccess;
    },
    [startLoading, stopLoading, t],
  );

  const removeEmployeeFromGroups = useCallback(
    async (employeeGroupId: string, employeeIds: string[]) => {
      return await makeRequest(
        'patch',
        `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
        {
          removeFromGroup: employeeIds,
        },
      )
        .then(() => true)
        .catch(() => false);
    },
    [],
  );

  const copyEmployeeGroup = useCallback(
    (id: string, name: string) => {
      startLoading();
      makeRequest('post', Endpoints.employeeGroupsCopy.replace('{id}', id), {
        name,
      })
        .then((res) => {
          toast(t('common.added_succesfully'), { type: 'success' });
          mutate(Endpoints.employeeGroup);
          history.push(
            ROUTES.employeeGroups.details.replace(':id', res.data.id),
          );
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, startLoading, stopLoading, t],
  );

  return {
    loading,
    employeeGroupsList,
    employeeGroupsListError,
    addEmployeeGroups,
    removeEmployeeFromGroups,
    deleteEmployeeGroup,
    deleting: deleteRequestState.loading,
    updateEmployeeGroup,
    copyEmployeeGroup,
  };
};

export default useEmployeeGroups;

interface EmployeesGroupsParams extends OrgStructureFilterParams {
  name?: string[];
  employeeCountFrom?: number;
  employeeCountTo?: number;
  benefitPlansCountFrom?: number;
  benefitPlansCountTo?: number;
  benefitPlans?: string[];
  sort?: string;
}

export const useEmployeesGroups = (params: EmployeesGroupsParams = {}) => {
  const { employeeGroups } = Endpoints.clientAdmin;
  const query = convertToQueryParams(params, {
    exclude: ['companyGroupId', 'companyId', 'departmentId'],
  });
  const response = useGet<EmployeeGroupsResponse>(employeeGroups.root + query);
  const xlsExport = useXLSExport(employeeGroups.exportToExcel + query);

  return { ...response, ...xlsExport };
};

interface EmployeesGroupsListParams {
  name?: string[];
}

export const useEmployeesGroupSearch = (
  params: EmployeesGroupsListParams = {},
) => {
  const query = convertToQueryParams(params);
  return useGet<EmployeesGroupSearchResponse>(
    Endpoints.clientAdmin.employeeGroups.list + query,
  );
};

type FilterNames = 'name' | 'benefitPlans';

export const useEmployeesGroupsFilterValues = (filterName: FilterNames) => {
  return useFilterValues(
    Endpoints.clientAdmin.employeeGroups.filterValues,
    filterName,
  );
};

export const useEmployeesGroupsMutations = () => {
  const url = Endpoints.clientAdmin.employeeGroups.root;
  const create = useCustomSwrMutation<
    CreateEmployeesGroupRequest,
    EmployeesGroup
  >(url, 'post', { onSuccess: () => mutate(url) });
  return { create };
};
