import { makeStyles } from '@mui/styles';
import { Colors } from '../constants/Style';

export default makeStyles(() => ({
  disableFormFields: {
    '& .MuiBox-root': {
      '& .MuiSwitch-switchBase, .MuiFormControl-root .MuiInputBase-root, .MuiTextField-root, .MuiSwitch-input, .MuiFormControlLabel-root, .MuiIconButton-root, $button, .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8, .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2, textarea, .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12, .MuiFormControlLabel-root, .MuiGridListTile-root, .MuiGridListTile-tile':
        {
          pointerEvents: 'none',
        },
    },
  },
  textInput: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'rgba(105, 113, 125, 1)',
    },
  },
  readonly: {
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.BorderOutlinedInput,
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      background: 'unset',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: Colors.TextPrimary,
    },
  },
}));
