import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './files.loaders';
import { converters } from './files.converters';

export const filesKeys = queryKeyFactory('files');

export const useFile = (fileId: string) => {
  const query = useQuery({
    queryKey: filesKeys.detail(fileId),
    queryFn: () => loaders.getFile({ fileId }),
    select: converters.getFile,
    enabled: !!fileId,
  });

  return query;
};

export const useFiles = (fileIds: string[]) => {
  const query = useQuery({
    queryKey: filesKeys.list(fileIds),
    queryFn: () => loaders.getFiles({ fileIds }),
    select: converters.getFiles,
    enabled: !!fileIds.length,
  });

  return query;
};
