import { FC } from 'react';
import { Link } from '@mui/material';
import { useMe } from 'state/Administrators';
import { Trans } from 'react-i18next';
import { Alert } from 'melp-design/components';
import { APP } from 'config';

export const RecognitionsDisabledFeatureAlert: FC = () => {
  const { isFeatureEnabled } = useMe();

  return isFeatureEnabled('recognition') ? null : (
    <Alert severity="warning">
      <Trans
        i18nKey="recognition.featureWarning"
        values={{ email: APP.email.info }}
        components={[
          <Link href={`mailto:${APP.email.info}`} target="_blank" key="0" />,
        ]}
      />
    </Alert>
  );
};
