import { FC } from 'react';
import {
  Button,
  ExternalLink,
  Link,
  ListTotal,
  Menu,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useSearchParams } from 'utils/navigation';
import {
  adminShopVouchersFiltersSchema,
  useAdminShopVouchers,
  useExportXlsTemplate,
  useUpdateStatus,
  COLOR_BY_STATUS,
} from 'store/admin-shop-vouchers';
import { Filters } from 'components/filters';
import { calculateFiltersTotal } from 'utils/Filters';
import TextFilter from 'components/filters/TextFilter';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import moment from 'moment';
import { formatCurrency } from 'utils/format';
import { usePredefinedToasts } from 'utils/Toast';
import {
  EditFileVoucherModal,
  EditVoucherModal,
  ImportVoucherCodesModal,
  UploadVoucherFilesModal,
} from './atoms';

export const AdminShopVouchersList: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: shopItemId } = useRouteParams(
    ROUTES.admin.shopItems.details.vouchers,
  );
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();
  const { openModal } = useModalContext();
  const predefinedToasts = usePredefinedToasts();
  const { searchParams: filters, navigate } = useSearchParams(
    adminShopVouchersFiltersSchema,
  );

  const { data: vouchers, isLoading } = useAdminShopVouchers(
    shopItemId,
    filters,
  );

  const { mutate: exportXlsTemplate, isLoading: isExportingXlsTemplate } =
    useExportXlsTemplate();
  const { mutate: updateStatus, isLoading: isUpdatingStatus } =
    useUpdateStatus();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <ListTotal total={vouchers?.total} />

          <Stack direction="row" alignItems="center" gap="10px">
            <Filters
              value={filters}
              initialValues={adminShopVouchersFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
              showClearButton={!!calculateFiltersTotal(filters)}
            >
              {({ value, setFilter }) => (
                <TextFilter
                  value={value.search}
                  onApplyFilter={(v) => setFilter('search', v)}
                  placeholder={t('common.search')}
                  hint={t('shop.vouchers.search.hint')}
                />
              )}
            </Filters>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Menu
              label={t('common.actions')}
              items={[
                {
                  label: t('global.actions.upload_filetypes', {
                    fileTypes: ['.pdf', '.png'].join(', '),
                  }),
                  onClick: () => {
                    openModal({
                      component: UploadVoucherFilesModal,
                      props: { shopItemId },
                    });
                  },
                },
                {
                  label: t('shop.vouchers.actions.upload_codes'),
                  onClick: () => {
                    openModal({
                      component: ImportVoucherCodesModal,
                      props: {
                        shopItemId,
                      },
                    });
                  },
                },
                {
                  label: t('global.actions.export_template'),
                  onClick: () => {
                    exportXlsTemplate();
                  },
                  disabled: isExportingXlsTemplate,
                },
              ]}
            />
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        isLoading={isLoading}
        data={vouchers?.items ?? []}
        columns={[
          {
            id: 'id',
            header: t('integrations.hiBobServiceUserId'),
            cell: ({ row: { original: voucher } }) => voucher.sequence,
          },
          {
            id: 'createdAt',
            header: t('marketplaceOrders.orderDate'),
            cell: ({ row: { original: voucher } }) =>
              moment(voucher.createdAt).format('l LT'),
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: voucher } }) => (
              <Typography color={COLOR_BY_STATUS[voucher.status]}>
                {t(`status.${voucher.status}`)}
              </Typography>
            ),
          },
          {
            id: 'price',
            header: t('marketplace.ordersTablePrice'),
            cell: ({ row: { original: voucher } }) =>
              formatCurrency(i18n.language, voucher.price, voucher.currency),
            meta: { align: 'right' },
          },
          {
            id: 'purchasePrice',
            header: t('shop.vouchers.purchasePrice.label'),
            cell: ({ row: { original: voucher } }) =>
              formatCurrency(
                i18n.language,
                voucher.purchasePrice,
                voucher.currency,
              ),
            meta: { align: 'right' },
          },
          {
            id: 'reference',
            header: t('shop.vouchers.purchaseReference.label'),
            cell: ({ row: { original: voucher } }) => voucher.purchaseReference,
          },
          {
            id: 'expiresAt',
            header: t('alternatives.employeeExpirationDate'),
            cell: ({ row: { original: voucher } }) =>
              voucher.expiresAt ? moment(voucher.expiresAt).format('l') : '',
          },
          {
            id: 'redeem',
            header: t('shop.vouchers.redeem.label'),
            cell: ({ row: { original: voucher } }) => (
              <Stack>
                {Object.entries(voucher.redeem).map(([type, value], i) => {
                  if (!value) return null;

                  return (
                    <Stack flexDirection="row" gap={0.5} key={i}>
                      <Typography sx={{ whiteSpace: 'nowrap' }}>
                        {t(`shop.vouchers.redeem.${type}`)}:
                      </Typography>
                      {type === 'url' ? (
                        <ExternalLink
                          label={value.substring(0, 14) + '...'}
                          title={value}
                          href={value}
                        />
                      ) : (
                        <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
                          {value}
                        </Typography>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            ),
          },
          {
            id: 'fileLink',
            header: t('shop.vouchers.fileLink.label'),
            cell: ({ row: { original: voucher } }) =>
              voucher.fileLink ? (
                <ExternalLink
                  label={voucher.fileLink.substring(0, 14) + '...'}
                  href={voucher.fileLink}
                />
              ) : null,
          },
          {
            id: 'orderId',
            header: t('marketplaceOrders.orderId'),
            cell: ({ row: { original: voucher } }) =>
              voucher.order ? (
                <Link
                  label={voucher.order.name}
                  to={ROUTES.admin.orders.details.replace(
                    ':id',
                    voucher.order.id,
                  )}
                />
              ) : null,
          },
          {
            id: 'lastAccess',
            header: t('order.last_access.label'),
            cell: ({ row: { original: voucher } }) =>
              voucher.lastAccess
                ? moment(voucher.lastAccess).format('l LT')
                : '',
          },
          {
            id: 'actions',
            cell: ({ row: { original: voucher } }) => {
              return (
                <Button
                  label={t('common.edit')}
                  variant="neutral-outline"
                  size="sm"
                  onClick={() => {
                    if (voucher.fileLink) {
                      openModal({
                        component: EditFileVoucherModal,
                        props: { shopItemId, voucherId: voucher.id },
                      });
                    } else {
                      openModal({
                        component: EditVoucherModal,
                        props: { shopItemId, voucherId: voucher.id },
                      });
                    }
                  }}
                />
              );
            },
          },
        ]}
        pagination={
          vouchers && {
            count: vouchers.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
        selection={{
          value: selectedRowIds,
          onChange: setSelectedRowIds,
          actions: [
            {
              label: t('global.actions.mark_as_archived'),
              onClick: () => {
                updateStatus(
                  { status: 'archived', vouchers: Object.keys(selectedRowIds) },
                  {
                    onSuccess: () => {
                      predefinedToasts.success.updated();
                      setSelectedRowIds({});
                    },
                  },
                );
              },
            },
            {
              label: t('global.actions.mark_as_expired'),
              onClick: () => {
                updateStatus(
                  { status: 'expired', vouchers: Object.keys(selectedRowIds) },
                  {
                    onSuccess: () => {
                      predefinedToasts.success.updated();
                      setSelectedRowIds({});
                    },
                  },
                );
              },
            },
          ],
          isLoading: isUpdatingStatus,
        }}
      />
    </TableWrapper>
  );
};
