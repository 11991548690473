export namespace ErrorCodes {
  export enum Parent {
    GENERAL = 101,
    DUPLICATE = 102,
    NOT_FOUND = 103,
    AUTHENTICATION = 104,
    PERMISSION = 105,
    CONFLICT = 106,
    VALIDATION = 107,
  }

  export namespace Sub {
    export enum Auth {
      INVALID_JWT = 1001,
      EXPIRED_REFRESH_TOKEN = 1002,
      INVALID_EMAIL_OR_PASSWORD = 1003,
      EMAIL_NOT_VERIFIED = 1004,
      EXPIRED_SESSION = 1005,
      MISSING_AUTH_TOKEN = 1006,
      UNAUTHORIZED = 1007,
      MISSING_PERMISSIONS = 1008,
      INVALID_ROLE = 1009,
      SESSION_NOT_FOUND = 1010,
      USED_REFRESH_TOKEN = 1011,
      ADMIN_RECORD_NOT_FOUND = 1012,
      USER_RECORD_NOT_FOUND = 1013,
      ACTIVE_EMPLOYEE_NOT_FOUND = 1014,
      API_KEY_NOT_VALID = 1015,
      API_KEY_RECORD_NOT_FOUND = 1016,
      API_KEY_NO_LONGER_VALID = 1017,
      MISSING_PERSONAL_CODE = 1018,
      UNSUPPORTED_LOGIN_FLOW = 1019,
    }

    export enum User {
      NOT_FOUND = 2001,
      CONNECTED_TO_COMPANY = 2002,
      SAME_PHONE_NUMBER = 2003,
    }

    export enum Admin {
      DUPLICATE_EMAIL = 3001,
      NOT_FOUND = 3002,
      LOOKUP_VALUE_IS_TOO_SHORT = 3003,
      NOT_ACTIVE = 3004,
    }

    export enum ParentCompany {
      DUPLICATE_NAME = 4001,
      NOT_FOUND = 4002,
      COMPANY_GROUPS_NOT_FOUND = 4003,
      COMPANY_GROUP_MISSING_COMPANIES = 4004,
      MISSING_PERMISSIONS = 4005,
      LOOKUP_VALUE_IS_TOO_SHORT = 4006,
      NOT_ACtIVE = 4407,
      LANGUAGE_NOT_SUPPORTED = 4008,
    }

    export enum Template {
      TEMPLATE_NOT_FOUND = 5001,
    }

    export enum File {
      NOT_FOUND = 6001,
      NOT_UPLOADED = 6002,
    }

    export enum BenefitPlan {
      NOT_FOUND = 15001,
      CATEGORY_NOT_FOUND = 15002,
      MISSING_DEFAULT_TRANSLATION = 15003,
      MISSING_MANDATORY_FIELDS = 15004,
      NOT_IMAGE = 15005,
      BENEFIT_PLAN_FORBIDDEN_FOR_EMPLOYEE = 15006,
      NOT_PDF = 15007,
      LOOKUP_VALUE_IS_TOO_SHORT = 15008,
      BENEFIT_CONTAINS_EMPLOYEE = 15009,
      TEMPLATE_NOT_FOUND = 15010,
    }

    export enum BenefitPlanAssignment {
      INVALID_TARGET = 16001,
      NOT_FOUND = 16002,
      LOOKUP_VALUE_IS_TOO_SHORT = 16003,
    }

    export enum TermsAndConditions {
      NOT_FOUND = 17001,
      NOT_REQUIRED_TO_BE_ACCEPTED = 17002,
      LOOKUP_VALUE_IS_TOO_SHORT = 17003,
      BENEFIT_NOT_FOUND = 17004,
      EMPLOYEES_NOT_FOUND = 17005,
      BENEFITS_NOT_FOUND = 17006,
      EMPLOYEE_NOT_FOUND = 17007,
    }

    export enum EmployeeGroup {
      NOT_FOUND = 7001,
      GROUP_CONTAINS_EMPLOYEE = 7002,
      DUPLICATE_NAME = 7003,
      LOOKUP_VALUE_IS_TOO_SHORT = 7004,
      EMPLOYEE_GROUP_CONTAINS_BENEFIT_ASSIGNMENTS = 7005,
      ALL_EMPLOYEES = 7006,
    }

    export enum Notification {
      USERS_WITHOUT_TOKENS = 8001,
      FIREBASE_NOT_CONNECTED = 8002,
      CHOICE_REMINDER_ALREADY_SENT = 8003,
      NO_EMPLOYEES_FOUND = 8004,
      CHOICES_NOT_FOUND = 8005,
      TERMS_ACCEPTANCES_NOT_FOUND = 8006,
      BENEFITS_NOT_FOUND = 8007,
      PARENT_COMPANY_NOT_FOUND = 8008,
    }

    export enum Company {
      DUPLICATE_NAME = 9001,
      COMPANY_GROUPS_NOT_FOUND = 9002,
      NOT_EVERY_EXISTING_DEPARTMENT_PROVIDED = 9003,
      NOT_FOUND = 9004,
      LOGO_NOT_IMAGE = 9005,
      LOOKUP_VALUE_IS_TOO_SHORT = 9006,
    }

    export enum Department {
      DUPLICATE_NAME = 10001,
      NOT_FOUND = 10002,
    }

    export enum CompanyGroup {
      DUPLICATE_NAME = 11001,
      NOT_FOUND = 11002,
      LOOKUP_VALUE_IS_TOO_SHORT = 11003,
    }

    export enum Employee {
      EMPLOYEE_NOT_FOUND = 12002,
      PHONE_NUMBER_DUPLICATE = 12003,
      PHONE_ALREADY_EXIST = 12004,
      EMPLOYEE_WITH_USER_NOT_SAVED = 12005,
      TRANSACTION_IS_NOT_SUCCESSFUL = 12006,
      LANGUAGE_NOT_SUPPORTED_BY_COMPANY = 12007,
      PERSONAL_CODE_NOT_EXIST = 12008,
      LOOKUP_VALUE_IS_TOO_SHORT = 12009,
      PARENT_COMPANY_NOT_FOUND = 12010,
      EMAIL_ALREADY_EXISTS = 12011,
      PHONE_OR_EMAIL_IS_MISSING = 12012,
      EMAIL_DUPLICATE = 12013,
      PHONE_WITH_PERSONAL_CODE = 12014,
      NOT_ALLOWED = 12015,
    }

    export enum FlexBenefitAllocation {
      FLEX_BENEFIT_ALLOCATION_NOT_FOUND = 13001,
    }

    export enum EmployeeCategory {
      DUPLICATE_CATEGORY = 19001,
      DUPLICATE_VALUE = 19002,
      NOT_EDITABLE = 19003,
      NOT_DELETABLE = 19004,
      CATEGORY_NOT_FOUND = 19005,
      VALUE_NOT_FOUND = 19006,
    }

    export enum Permission {
      COMPANY_GROUPS_NOT_FOUND = 14001,
      COMPANIES_NOT_FOUND = 14002,
      DEPARTMENT_NOT_FROM_SELECTED_COMPANY = 14003,
      PERMISSION_NOT_FOUND = 14004,
      NOT_FOUND = 14005,
      MISSING_LOCAL_PERMISSIONS = 14006,
      MISSING_GLOBAL_PERMISSIONS = 14007,
      MISSING_EDIT_PERMISSION_FOR_COMPANY = 14008,
      MISSING_EDIT_PERMISSION_FOR_DEPARTMENT = 14009,
      PERMISSIONS_MISSING_COMPANY_ID = 14010,
      COMPANY_GROUP_MISSING_COMPANIES = 14011,
      COMPANY_MISSING_DEPARTMENTS = 14012,
    }

    export enum BenefitPlanChoice {
      NOT_FOUND = 23001,
      NOT_SELECTED = 23002,
      NOT_FLEX = 23003,
      NOT_VOTING_PERIOD = 23004,
      LIMIT_QUANTITY_REACHED = 23005,
      CHOICE_ALREADY_MADE = 23006,
      NOT_ENOUGH_BALANCE = 23007,
      CANNOT_DECLINE_SINGLE_INVESTMENT_BENEFIT = 23008,
      LOOKUP_VALUE_IS_TOO_SHORT = 23009,
    }

    export enum News {
      NEWS_NOT_FOUND = 13001,
      LANGUAGE_NOT_SUPPORTED = 13002,
      EMPLOYEE_GROUP_NOT_FOUND = 13003,
      MISSING_SEND_METHODS = 13004,
      NOT_FOUND_OR_NOT_ALLOWED = 13005,
      LOOKUP_VALUE_IS_TOO_SHORT = 13006,
      NOT_FOUND_OR_PUBLISHED = 13007,
      EMPLOYEE_MISSING_EMAIL = 13008,
    }

    export enum FlexBenefitBalance {
      NOT_FOUND = 24001,
    }

    export enum Export {
      NO_DATA = 25001,
    }

    export enum Filter {
      INVALID_DATE = 26001,
      INVALID_NUMERIC = 26002,
      EMPTY_LIST = 26003,
      INVALID_FILTER_FIELD = 26004, //When passing an incorrect field during value lookup
    }

    export enum ActionLog {
      LOOKUP_VALUE_IS_TOO_SHORT = 27001,
    }

    export enum CompareMode {
      ROW_VALUES_NOT_AVAILABLE = 28001,
      COLUMN_VALUES_NOT_AVAILABLE = 28002,
      MISSING_COLUMN_CUSTOM_CATEGORY_ID = 28003,
      MISSING_ROW_CUSTOM_CATEGORY_ID = 28004,
    }

    export enum IntegrationType {
      NOT_FOUND = 29001,
      INACTIVE = 29002,
    }

    export enum ClientIntegration {
      NOT_FOUND = 29001,
    }

    export enum BenefitPlanTemplate {
      NOT_FOUND = 30001,
      NOT_IMAGE = 30002,
      MISSING_DEFAULT_TRANSLATION = 30003,
      INACTIVE = 30004,
    }

    export enum ClientDataImporterService {
      TEST = 31001,
      CREATE_CLIENT_INTEGRATION = 31002,
      UPDATE_CLIENT_INTEGRATION = 31003,
      DELETE_CLIENT_INTEGRATION = 31004,
      CREATE_INTEGRATION_TYPE = 31005,
      UPDATE_INTEGRATION_TYPE = 31006,
      DELETE_INTEGRATION_TYPE = 31007,
      GET_CLIENT_INTEGRATION_LOGS = 31008,
      GET_CLIENT_INTEGRATION_LOG_ITEMS = 31009,
    }

    export enum BenefitPlanExpense {
      BENEFIT_NOT_ASSIGNED = 32001,
      NOT_FOUND = 32002,
    }

    export enum PhoneChange {
      INVALID_CODE = 33001,
    }
  }
}
