import { MpControlType, MpFormType } from '@mp-react/form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { currencies } from 'types/general';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { CustomFormControl } from '../../types/Common';
import { CustomControlType } from '../../types/Form';

export const useClientForm = (): MpFormType => {
  const { t, i18n } = useTranslation();

  const coutryOptions = useMemo(() => {
    return APP.countries.alpha3.map((alpha3) => ({
      type: 'select' as any,
      key: alpha3,
      label: formatCountry(i18n.language, alpha3),
    }));
  }, [i18n.language]);

  const options = useMemo(() => {
    return currencies.map((currency) => ({
      type: 'select' as any,
      key: currency,
      label: currency,
    }));
  }, []);

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: CustomControlType.text,
        key: 'creationDate',
        label: t('clients.creationDate'),
        disabled: true,
      },
      {
        type: 'dropdown' as any,
        key: 'country',
        label: t('benefits.country'),
        required: true,
        children: coutryOptions,
        tooltip: 'tooltip.clients_default_country',
      },
      {
        type: MpControlType.text,
        key: 'name',
        messages: {
          required: t('form.required'),
        },
        label: t('companies.company_name'),
        required: true,
        tooltip: 'tooltip.clients_company_name',
        placeholder: t('companies.company_name'),
      },
      {
        type: MpControlType.text,
        key: 'code',
        messages: {
          required: t('form.required'),
        },
        label: t('companies.company_code'),
        required: true,
        tooltip: 'tooltip.clients_company_code',
        placeholder: t('companies.company_code'),
      },
      {
        type: 'dropdown' as any,
        key: 'defaultCurrency',
        label: t('clients.default_currency'),
        children: options,
        required: true,
        tooltip: 'tooltip.clients_default_currency',
      },
      {
        type: MpControlType.multiline,
        key: 'internalComment',
        label: t('common.internal_comment'),
        placeholder: t('common.comments'),
        tooltip: 'tooltip.clients_internal_comment',
      },
    ],
    [coutryOptions, options, t],
  );

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
