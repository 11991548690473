import { TextField, TextFieldProps } from '@mui/material';
import useStyles from './EditableTitle.styles';

const EditableTitle = (props: TextFieldProps) => {
  const classes = useStyles();
  return (
    <TextField
      classes={{ root: classes.root }}
      InputProps={{
        classes: {
          input: classes.input,
          focused: classes.focused,
        },
      }}
      size="small"
      variant="outlined"
      margin="none"
      fullWidth
      {...props}
    />
  );
};

export default EditableTitle;
