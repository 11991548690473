import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IInternalShopInventoryEvent,
  IInternalShopInventoryEventsAdditionalInfo,
  IInternalShopInventoryItem,
  IInternalShopItem,
} from 'types/income';
import { Translations } from 'types/Common';
import { exportXlsxConfig, toCents } from 'utils/general';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { ShopItemStatus } from 'store/shop';
import {
  InternalShopFilters,
  InternalShopInventoryEventsFilters,
  InternalShopInventoryFilters,
  InternalShopItemTranslations,
  PurchaseLimitPeriod,
  PurchaseLimitVariant,
} from './internal-shop.types';

interface GetInternalShopItems {
  filters: Partial<InternalShopFilters>;
}

interface GetInternalShopItem {
  itemId: string;
}

interface CreateInternalShopItem {
  name: string;
  categories: ShopItemCategory[];
  status: ShopItemStatus;
  translations: InternalShopItemTranslations;
  imageId: string;
  prices: Array<{
    id: string;
    price: number;
    initialInventory: number;
    index: number;
    translations: Translations<{ title: string }>;
  }>;
  countries: string[];
  trackInventory: boolean;
  purchaseLimit: number;
  purchaseLimitType: PurchaseLimitVariant;
  purchaseLimitPeriod: PurchaseLimitPeriod;
  notifyOnOrder: boolean;
}

interface UpdateInternalShopItem {
  itemId: string;
  data: Partial<CreateInternalShopItem>;
}

interface GetInternalShopInventoryItems {
  filters: Partial<InternalShopInventoryFilters>;
}

interface UpdateVariantInventory {
  variantId: string;
  data: {
    comment: string;
    inventoryChange: number;
  };
}

interface UpdateLowQuantity {
  variantId: string;
  data: {
    lowQuantity: number;
  };
}

interface ExportXls {
  variantId: string;
}

interface GetInternalShopInventoryEvents {
  variantId: string;
  filters: Partial<InternalShopInventoryEventsFilters>;
}

export const loaders = {
  getInternalShopItems: ({ filters }: GetInternalShopItems) => {
    return api<TableDataResponse<IInternalShopItem>>({
      url: Endpoints.clientAdmin.shop.internal.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          itemName: filters.name || undefined,
        },
      },
    });
  },
  getInternalShopItem: ({ itemId }: GetInternalShopItem) => {
    return api<IInternalShopItem>({
      url: Endpoints.clientAdmin.shop.internal.byId.replace(':itemId', itemId),
    });
  },
  createInternalShopItem: (data: CreateInternalShopItem) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.shop.internal.root,
      method: 'post',
      data: {
        name: data.name,
        categories: data.categories,
        status: data.status,
        translations: data.translations,
        imageId: data.imageId,
        prices: data.prices.map((price) => ({
          price: toCents(price.price),
          index: price.index,
          initialInventory: price.initialInventory,
          translations: price.translations,
        })),
        countries: data.countries,
        trackInventory: data.trackInventory,
        purchaseLimitType: data.purchaseLimitType,
        purchaseLimit: data.purchaseLimit,
        purchaseLimitPeriod: data.purchaseLimitPeriod,
        notifyOnMerchandisingOrder: data.notifyOnOrder,
      },
    });
  },
  updateInternalShopItem: ({ itemId, data }: UpdateInternalShopItem) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.shop.internal.byId.replace(':itemId', itemId),
      method: 'patch',
      data: {
        name: data.name,
        categories: data.categories,
        status: data.status,
        translations: data.translations,
        imageId: data.imageId,
        prices: data.prices?.map((price) => ({
          id: price.id || undefined,
          price: toCents(price.price),
          index: price.index,
          initialInventory: price.id ? undefined : price.initialInventory,
          translations: price.translations,
        })),
        countries: data.countries,
        trackInventory: data.trackInventory,
        purchaseLimitType: data.purchaseLimitType,
        purchaseLimit: data.purchaseLimit,
        purchaseLimitPeriod: data.purchaseLimitPeriod,
        notifyOnMerchandisingOrder: data.notifyOnOrder,
      },
    });
  },
  getInternalShopInventoryItems: ({
    filters,
  }: GetInternalShopInventoryItems) => {
    return api<TableDataResponse<IInternalShopInventoryItem>>({
      url: Endpoints.clientAdmin.shop.internal.inventory.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          itemIds: filters.items,
          inventoryMin: filters.inventory?.from,
          inventoryMax: filters.inventory?.to,
        },
      },
    });
  },
  updateVariantInventory: ({ variantId, data }: UpdateVariantInventory) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.shop.internal.inventory.byId.replace(
        ':variantId',
        variantId,
      ),
      data: {
        inventoryChange: data.inventoryChange,
        comment: data.comment,
      },
      method: 'post',
    });
  },
  updateLowQuantity: ({ variantId, data }: UpdateLowQuantity) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.shop.internal.inventory.updateLowQuantity.replace(
        ':variantId',
        variantId,
      ),
      data: {
        lowInventoryAlert: data.lowQuantity,
      },
      method: 'patch',
    });
  },
  getInternalShopInventoryEvents: ({
    variantId,
    filters,
  }: GetInternalShopInventoryEvents) => {
    return api<
      TableDataResponse<IInternalShopInventoryEvent> &
        IInternalShopInventoryEventsAdditionalInfo
    >({
      url: Endpoints.clientAdmin.shop.internal.inventory.byId.replace(
        ':variantId',
        variantId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
      },
    });
  },
  exportXls: ({ variantId }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.shop.internal.inventory.export.xls.replace(
        ':variantId',
        variantId,
      ),
      ...exportXlsxConfig,
    });
  },
};
