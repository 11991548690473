import { z } from 'zod';
import { ExpenseType, expenseTypes } from 'store/expenses';
import {
  adminClientCompanyBalanceLogsFiltersSchema,
  adminClientDepositsFiltersSchema,
  adminDepositEventsFiltersSchema,
} from './admin-client-deposits.schemas';

export const clientCompanyBalanceTypes = [...expenseTypes, 'deposit'] as const;
export type ClientCompanyBalanceType =
  (typeof clientCompanyBalanceTypes)[number];

export interface AdminClientDeposit {
  id: string;
  name: string;
  averageMonthlyTurnover: number;
  totalDeposits: number;
  totalExpenses: number;
  remainingCredit: number;
  creditLimit: number;
  lowDepositAlertThreshold: number;
  lastDepositDate: string | null;
  creditModificationDate: string | null;
  lowDepositAlertModificationDate: string | null;
}

export interface AdminClientBalanceLog {
  id: string;
  date: string;
  type: ClientCompanyBalanceType;
  depositChange: number;
  remainingBalance: number;
  owner: {
    id: string;
    name: string;
    email: string;
  } | null;
  invoiceNumber: string;
  comment: string;
}

export interface DepositsMeta {
  balance: number;
  credit: number;
  creditUsed: number;
  creditRemaining: number;
}

export interface AdminDepositEvent {
  id: string;
  type: ExpenseType;
  startsAt: {
    date: string;
    admin: {
      id: string;
      name: string;
      email: string;
    };
  };
  endsAt: {
    date: string;
    admin: {
      id: string;
      name: string;
      email: string;
    };
  } | null;
  parentCompany: {
    id: string;
    name: string;
  };
}

export type AdminClientDepositsFilters = z.infer<
  typeof adminClientDepositsFiltersSchema
>;

export type AdminClientCompanyBalanceLogsFilters = z.infer<
  typeof adminClientCompanyBalanceLogsFiltersSchema
>;

export type AdminDepositEventsFilters = z.infer<
  typeof adminDepositEventsFiltersSchema
>;
