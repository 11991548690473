import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from 'melp-design/style';

export default makeStyles((theme) =>
  createStyles({
    textfield: {
      margin: 0,
      transition: theme.transitions.create('width'),
      '& .MuiOutlinedInput-root': {
        background: Colors.white,
      },
      '& MuiOutlinedInput-notchedOutline': {
        borderRadius: 6,
      },
      '& input': {
        padding: '10px 15px',
      },
    },
    result: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 10px !important', // To override another !important
      borderRadius: 6,
      transition: theme.transitions.create(['color', 'backGround-color']),
      '&:hover': {
        backGroundColor: Colors.screenBackgroundGrey,
      },
      '&::after': {
        display: 'none',
      },
    },
    resultTextSpacing: {
      paddingLeft: 5,
    },
  }),
);
