import axios from 'axios';
import qs from 'qs';
import { APP } from 'config';
import { auth } from '../auth/index';
import { handleResponseError } from './response-error-handler';

const instance = axios.create({
  baseURL: APP.host.api,
});

export const api = instance.request;

export const fetcher = async <R = any>(url: string) => {
  const res = await api<R>({ url });
  return res.data;
};

instance.interceptors.request.use(async (config) => {
  const authToken = await auth.getToken();
  config.headers.common['Authorization'] = `Bearer ${authToken}`;
  config.paramsSerializer = (params) =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
      skipNulls: true,
    });
  return config;
});

instance.interceptors.response.use((response) => response, handleResponseError);

export const makeRequest = <T = any, D = any>(
  method: 'patch' | 'delete' | 'post' | 'put' | 'get',
  url: string,
  data?: D,
  headers?: {
    'Content-Type'?: string;
    'Access-Control-Allow-Origin'?: string;
  },
  responseType?: any,
) => {
  return instance.request<T>({
    method,
    url,
    data,
    headers,
    responseType,
  });
};
