import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IAdminClientBalanceLog,
  IAdminClientDeposit,
  IAdminDepositEvent,
  IDepositsMeta,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { Currency } from 'types/general';
import { exportXlsxConfig, toCents } from 'utils/general';
import { ExpenseType, revertExpenseType } from 'store/expenses';
import {
  AdminClientCompanyBalanceLogsFilters,
  AdminClientDepositsFilters,
  AdminDepositEventsFilters,
} from './admin-client-deposits.types';

const getDepositsFilters = (filters: Partial<AdminClientDepositsFilters>) => ({
  sort: filters.sort
    ? `${filters.sort.order === 'desc' ? '-' : ''}${filters.sort.columnKey}`
    : undefined,
});

const getBalanceLogsFilters = (
  filters: Partial<AdminClientCompanyBalanceLogsFilters>,
) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    dateFrom: filters.date?.from,
    dateTo: filters.date?.to,
    adminIds: filters.admins,
    depositTypes: filters.type?.map(revertExpenseType),
  },
  search: filters.search || undefined,
});

const getDepositEventsFilters = (
  filters: Partial<AdminDepositEventsFilters>,
) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    parentCompanyIds: filters.client,
    depositTypes: filters.type?.map(revertExpenseType),
  },
});

interface GetDeposits {
  clientId: string;
  filters: Partial<AdminClientDepositsFilters>;
}

interface CreateDeposit {
  companyId: string;
  data: {
    date: string;
    amount: number;
    currency: Currency;
    comment: string;
    invoice: string;
  };
}

interface UpdateDeposit {
  depositId: string;
  data: Partial<CreateDeposit['data']>;
}

interface GetCompanyBalanceLogs {
  companyId: string;
  filters: Partial<AdminClientCompanyBalanceLogsFilters>;
}

interface UpdateSettings {
  companyId: string;
  settings: Partial<{
    credit: number;
    lowDepositAlert: number;
  }>;
}

interface ExportXlsDeposits {
  clientId: string;
  filters: Partial<AdminClientDepositsFilters>;
}

interface ExportXlsBalanceLogs {
  clientId: string;
  companyId: string;
  filters: Partial<AdminClientCompanyBalanceLogsFilters>;
}

interface GetDepositEvents {
  filters: Partial<AdminDepositEventsFilters>;
}

interface CreateDepositEvent {
  clientId: string;
  expenseType: ExpenseType;
  from: string;
  to?: string;
}

interface EditDepositEvent {
  eventId: string;
  from: string;
  to?: string;
}

interface DeleteDepositEvent {
  eventId: string;
}

interface EnableDeposits {
  clientId: string;
  type: ExpenseType;
  enabled: boolean;
}

export const loaders = {
  getDeposits: ({ clientId, filters }: GetDeposits) => {
    return api<IAdminClientDeposit>({
      url: Endpoints.melpAdmin.clients.deposits.root.replace(
        ':clientId',
        clientId,
      ),
      params: getDepositsFilters(filters),
    });
  },
  createDeposit: ({ companyId, data }: CreateDeposit) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.createDeposit.replace(
        ':companyId',
        companyId,
      ),
      data: {
        amountInCents: toCents(data.amount),
        currency: data.currency,
        invoice: data.invoice,
        comment: data.comment,
        date: data.date,
      },
      method: 'post',
    });
  },
  updateDeposit: ({ depositId, data }: UpdateDeposit) => {
    return api<IAdminClientDeposit>({
      url: Endpoints.melpAdmin.clients.deposits.byId.replace(
        ':depositId',
        depositId,
      ),
      data: {
        comment: data.comment,
        invoice: data.invoice,
        amountInCents: data.amount,
        currency: data.currency,
        date: data.date,
      },
      method: 'patch',
    });
  },
  getCompanyBalanceLogs: ({ companyId, filters }: GetCompanyBalanceLogs) => {
    return api<TableDataResponse<IAdminClientBalanceLog> & IDepositsMeta>({
      url: Endpoints.melpAdmin.clients.balance.root.replace(
        ':companyId',
        companyId,
      ),
      params: getBalanceLogsFilters(filters),
    });
  },
  updateSettings: ({ companyId, settings }: UpdateSettings) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.updateSettings.replace(
        ':companyId',
        companyId,
      ),
      data: {
        credit:
          typeof settings.credit === 'number'
            ? toCents(settings.credit)
            : undefined,
        lowDepositAlertThreshold:
          typeof settings.lowDepositAlert === 'number'
            ? toCents(settings.lowDepositAlert)
            : undefined,
      },
      method: 'patch',
    });
  },
  exportXlsDeposits: ({ clientId, filters }: ExportXlsDeposits) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.export.xls.replace(
        ':clientId',
        clientId,
      ),
      params: getDepositsFilters(filters),
      ...exportXlsxConfig,
    });
  },
  exportXlsBalanceLogs: ({
    clientId,
    companyId,
    filters,
  }: ExportXlsBalanceLogs) => {
    return api({
      url: Endpoints.melpAdmin.clients.balance.export.xls
        .replace(':clientId', clientId)
        .replace(':companyId', companyId),
      params: getBalanceLogsFilters(filters),
      ...exportXlsxConfig,
    });
  },
  getDepositEvents: ({ filters }: GetDepositEvents) => {
    return api<TableDataResponse<IAdminDepositEvent>>({
      url: Endpoints.melpAdmin.clients.deposits.events.root,
      params: getDepositEventsFilters(filters),
    });
  },
  createDepositEvent: ({
    clientId,
    expenseType,
    from,
    to,
  }: CreateDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.root,
      data: {
        parentCompanyId: clientId,
        type: revertExpenseType(expenseType),
        from,
        to,
      },
      method: 'post',
    });
  },
  editDepositEvent: ({ eventId, from, to }: EditDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.byId.replace(
        ':eventId',
        eventId,
      ),
      data: { from, to },
      method: 'patch',
    });
  },
  deleteDepositEvent: ({ eventId }: DeleteDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.byId.replace(
        ':eventId',
        eventId,
      ),
      method: 'delete',
    });
  },
  enableDeposits: ({ clientId, type, enabled }: EnableDeposits) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.enableDeposits.replace(
        ':clientId',
        clientId,
      ),
      data: {
        type: revertExpenseType(type),
        status: enabled ? 'active' : 'inactive',
      },
      method: 'patch',
    });
  },
};
