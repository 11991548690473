import { makeStyles } from '@mui/styles';
import { Colors as MelpColors } from 'melp-design/style';
import { Colors } from '../constants/Style';

const common = {
  '& .MuiTableCell-head': {
    verticalAlign: 'top',
    '& > div': {
      whiteSpace: 'normal',
      lineHeight: 1.4,
    },
  },
  '& .MuiTableCell-root': {
    '& .MuiToggleButtonGroup-root': {
      '& .MuiToggleButton-root': {
        fontSize: 14,
        border: 'none',
        width: 50,
        height: 28,
        color: Colors.LightGray,
      },
    },
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)': {
      borderRadius: 30,
    },
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      borderRadius: 30,
    },
  },
  '& .MuiTableCell-footer': {
    textAlign: 'right',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '& .MuiTableRow-root > .MuiTableCell-root:first-of-type .MuiCheckbox-root': {
    margin: '-12px -9px -9px',
  },
};

export default makeStyles((theme) => ({
  inactiveRow: {
    color: `${MelpColors.greyHover} !important`,
  },
  table: {
    ...common,
    '& .MuiTableFooter-root': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiTableRow-footer': {
      clipPath: 'inset(0px 0px 0px round 0px 0px 8px 8px)',
      background: Colors.White,
    },
  },
  tableWithStatus: {
    ...common,
    '& .MuiTableHead-root': {
      background: 'transparent',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: 'transparent !important',
      position: 'relative',
      zIndex: 200,
    },
    '& .MuiTableRow-footer': {
      clipPath: 'inset(0px 0px 0px round 0px 0px 8px 8px)',
      background: Colors.White,
    },
    '& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)': {
      width: 209,
      padding: 0,
    },
  },
  tableWithImplementation: {
    ...common,
    '& .MuiTableHead-root': {
      background: 'transparent',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiTableRow-footer': {
      clipPath: 'inset(0px 0px 0px round 0px 0px 8px 8px)',
      background: Colors.White,
    },
    '& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)': {
      width: 166,
      padding: 0,
    },
  },
  tableWrapper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    borderRadius: '8px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  yScrollableTableWrapper: {
    overflowY: 'auto',
    maxHeight: 490,
  },
  assignEmployeesRow: {
    '& .MuiTableCell-root:last-child': {
      width: 115,
    },
  },
  disableTable: {
    '& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)': {
      pointerEvents: 'none',
    },
  },
  disableFullTable: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      pointerEvents: 'none',
    },
  },
  hoveredCellWithFilters: {
    background: Colors.Default,
  },
  highlightedSuccess: {
    '&::before': {
      opacity: 0.1,
      backgroundColor: theme.palette.success.main,
    },
    '.MuiTableRow-hover&:hover::before': {
      opacity: 0.2,
    },
  },
  highlightedWarning: {
    '&::before': {
      opacity: 0.1,
      backgroundColor: theme.palette.warning.main,
    },
    '.MuiTableRow-hover&:hover::before': {
      opacity: 0.2,
    },
  },
  highlightedError: {
    '&::before': {
      opacity: 0.1,
      backgroundColor: theme.palette.error.main,
    },
    '.MuiTableRow-hover&:hover::before': {
      opacity: 0.2,
    },
  },
}));
