import { ListItemProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import SidebarItemDark from '../../common/SidebarItemDark/SidebarItemDark';

interface Props extends PropsWithChildren<{}> {
  label: string;
  id: string;
  onClick: (id: string) => void;
  isActive: boolean;
  disabled: boolean;
  style?: ListItemProps['style'];
  className?: ListItemProps['className'];
}

export default function PermissionListItem({
  label,
  children,
  id,
  onClick,
  isActive,
  disabled,
  style,
  className,
}: Props) {
  if (!label) return null;
  return (
    <SidebarItemDark
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(id);
      }}
      selected={isActive}
      label={label}
      disabled={disabled}
      style={style}
      className={className}
    >
      {children}
    </SidebarItemDark>
  );
}
