import { Box, Chip } from '@mui/material';
import { CellProps } from 'react-table';
import { useContext, useMemo } from 'react';
import { getRawValue, MetaContext, useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Style';

export default function StatusPill(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { data, row } = props;
  const { size } = useContext(MetaContext);
  const { value } = useRendererOptions(props);

  const rowIndex = useMemo(() => row.index, [row.index]);
  const id = useMemo(() => 'inactive', []);
  const inactive = useMemo(
    () => getRawValue(data[rowIndex], id),
    [data, rowIndex, id],
  );

  const chipContent = useMemo(() => {
    if (value === 'selected') {
      return {
        style: {
          backgroundColor:
            inactive === true ? Colors.Default : Colors.StatusGreen,
          color: inactive === true ? Colors.TextSecondary : Colors.White,
        },
        text: t('status.selected'),
      };
    } else if (value === 'notSelected') {
      return {
        style: {
          backgroundColor:
            inactive === true ? Colors.Default : Colors.StatusYellow,
          color: inactive === true ? Colors.TextSecondary : Colors.White,
        },
        text: t('status.not_selected'),
      };
    } else if (value === 'declined') {
      return {
        style: {
          backgroundColor: inactive === true ? Colors.Default : Colors.Error,
          color: inactive === true ? Colors.TextSecondary : Colors.White,
        },
        text: t('status.declined'),
      };
    } else if (value === 'Active' || value === 'active') {
      return {
        style: {
          backgroundColor: Colors.StatusGreen,
          color: Colors.White,
        },
        text: t('status.active'),
      };
    } else if (value === 'accepted') {
      return {
        style: {
          backgroundColor: Colors.StatusGreen,
          color: Colors.White,
        },
        text: t('common.accepted'),
      };
    } else if (value === 'notAccepted') {
      return {
        style: {
          backgroundColor: Colors.Error,
          color: Colors.White,
        },
        text: t('common.not_accepted'),
      };
    } else if (value === 'archived') {
      return {
        style: {
          backgroundColor: Colors.Inactive,
          color: Colors.LightGray,
        },
        text: t('status.archived'),
      };
    } else if (value === 'inactive') {
      return {
        style: {
          backgroundColor: Colors.Default,
          color: Colors.TextSecondary,
        },
        text: t('status.inactive'),
      };
    } else if (value === '') {
      return {
        style: {
          display: 'none',
        },
      };
    } else if (value === null) {
      return {
        style: {
          backgroundColor: 'transparent',
          color: 'black',
        },
        text: '-',
      };
    }
  }, [t, value, inactive]);

  return (
    <>
      {value && (
        <Box textAlign="center">
          <Chip
            size={size}
            style={chipContent?.style}
            label={chipContent?.text}
          />
        </Box>
      )}
    </>
  );
}
