import { converters as tableConverters } from 'store/table';
import { converters as filesConverters } from 'store/files';
import { IDiscount } from 'types/income';
import { loaders } from './discounts.loaders';
import { Discount } from './discounts.types';

const convertDiscount = (discount: IDiscount): Discount => {
  return {
    id: discount.id,
    name: discount.name,
    supplier: {
      id: discount.supplier.id,
      name: discount.supplier.name,
      logo: discount.supplier.logo
        ? filesConverters.getUploadedFile(discount.supplier.logo)
        : null,
      contact: discount.supplierContact ?? '',
    },
    priority: discount.priority,
    status: discount.status,
    isEnabled: discount.isEnabled,
    image: filesConverters.getUploadedFile(discount.discountImage),
    translations: discount.translations.map((trans) => ({
      language: trans.language,
      title: trans.discountTitle,
      teaser: trans.teaserLine,
      description: trans.discountDescription,
      promo: trans.discountPromo,
    })),
    countries: discount.countries,
    categories: discount.categories,
    validFrom: discount.validFrom,
    validTo: discount.validTo,
    publishedAt: discount.publishDateTime,
  };
};

const convertDiscounts = (discounts: IDiscount[]): Discount[] => {
  return discounts.map(convertDiscount);
};

export const converters = {
  getDiscounts: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDiscounts>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDiscounts(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
