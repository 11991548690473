import { SystemColors } from 'melp-design/style';
import { ComponentPropsWithoutRef } from 'react';
import { Badge } from 'melp-design/components';

// MUI performance issue with SxProps
// https://github.com/microsoft/TypeScript/issues/34801
type Sx = any;

export const variants = [
  'primary',
  'secondary',
  'tertiary',
  'neutral-fill',
  'neutral-outline',
  'danger-fill',
  'danger-outline',
] as const;

export type Variant = (typeof variants)[number];

export const sizes = ['sm', 'md', 'lg'] as const;

export type Size = (typeof sizes)[number];

export const BASE_STYLES: Sx = {
  py: '5px',
  fontWeight: 500,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  transitionDuration: '150ms',
  gap: '6px',
  whiteSpace: 'nowrap',
  flexShrink: 0,
};

export const ICON_BUTTON_STYLES: Sx = {
  px: '5px',
};

const SIZES: Record<Size, number> = {
  sm: 30,
  md: 40,
  lg: 50,
};

export const STYLES_BY_SIZE: Record<Size, Sx> = {
  sm: {
    minHeight: SIZES.sm,
    minWidth: SIZES.sm,
    borderRadius: '4px',
    px: '10px',
    fontSize: 12,
  },
  md: {
    minHeight: SIZES.md,
    minWidth: SIZES.md,
    borderRadius: '6px',
    px: '20px',
    fontSize: 12,
  },
  lg: {
    minHeight: SIZES.lg,
    minWidth: SIZES.lg,
    borderRadius: '10px',
    px: '30px',
    fontSize: 14,
  },
};

export const STYLES_BY_VARIANT: Record<Variant, Sx> = {
  primary: {
    color: SystemColors.white,
    backgroundColor: SystemColors.primary.base,
    '&:hover': {
      backgroundColor: SystemColors.primary.shade,
    },
    '&[disabled]': {
      color: SystemColors.white,
      backgroundColor: SystemColors.grey[90],
    },
  },
  secondary: {
    color: SystemColors.primary.base,
    backgroundColor: SystemColors.white,
    borderColor: SystemColors.primary.base,
    '&:hover': {
      backgroundColor: SystemColors.primary.tint,
    },
    '&[disabled]': {
      color: SystemColors.grey[90],
      backgroundColor: SystemColors.white,
      borderColor: SystemColors.grey[90],
    },
  },
  tertiary: {
    color: SystemColors.primary.base,
    backgroundColor: SystemColors.primary.tint,
    borderColor: SystemColors.primary.tint,
    '&:hover': {
      color: SystemColors.primary.shade,
      borderColor: SystemColors.primary.shade,
    },
    '&[disabled]': {
      color: SystemColors.grey[90],
      backgroundColor: SystemColors.white,
      borderColor: SystemColors.grey[90],
    },
  },
  'neutral-fill': {
    color: SystemColors.black,
    backgroundColor: SystemColors.grey[96],
    '&:hover': {
      borderColor: SystemColors.grey[75],
    },
    '&[disabled]': {
      color: SystemColors.grey[90],
      backgroundColor: SystemColors.white,
      borderColor: SystemColors.grey[90],
    },
  },
  'neutral-outline': {
    color: SystemColors.black,
    backgroundColor: SystemColors.white,
    borderColor: SystemColors.grey[90],
    '&:hover': {
      borderColor: SystemColors.grey[75],
    },
    '&[disabled]': {
      color: SystemColors.grey[90],
      backgroundColor: SystemColors.white,
      borderColor: SystemColors.grey[90],
    },
  },
  'danger-fill': {
    color: SystemColors.white,
    backgroundColor: SystemColors.danger.base,
    '&:hover': {
      backgroundColor: SystemColors.danger.shade,
    },
    '&[disabled]': {
      color: SystemColors.white,
      backgroundColor: SystemColors.grey[90],
    },
  },
  'danger-outline': {
    color: SystemColors.danger.base,
    backgroundColor: SystemColors.white,
    borderColor: SystemColors.grey[90],
    '&:hover': {
      borderColor: SystemColors.grey[75],
    },
    '&[disabled]': {
      color: SystemColors.grey[90],
      backgroundColor: SystemColors.white,
      borderColor: SystemColors.grey[90],
    },
  },
};

export const ICON_STYLES_BY_SIZE: Record<Size, Sx> = {
  sm: {
    fontSize: 17.5,
  },
  md: {
    fontSize: 20,
  },
  lg: {
    fontSize: 22.5,
  },
} as const;

export const BADGE_COLOR_BY_VARIANT: Record<
  Variant,
  ComponentPropsWithoutRef<typeof Badge>['color']
> = {
  primary: 'primary',
  secondary: 'primary',
  tertiary: 'primary',
  'neutral-fill': 'primary',
  'neutral-outline': 'primary',
  'danger-fill': 'error',
  'danger-outline': 'error',
};
