import {
  ButtonOld,
  DeleteIconButton,
  EditIconButton,
  Typography,
} from 'melp-design/components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShopItem } from 'store/shop';
import RichText from '../../../../../components/common/RichText/RichText';
import DeleteConfirmationDialog from '../../../../../components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import FormDialog from '../../../../../components/dialogs/FormDialog/FormDialog';
import { useMarketplaceItemTranslations } from '../../../../../state/MarketplaceItems';
import { useDialog } from '../../../../../utils/Dialog';
import { MarketplaceItemTranslationFormData } from '../../../common/Types';
import { useMarketplaceItemTranslationForm } from './MarketplaceItemTranslationForm';

interface Props {
  marketplaceItemId: string;
  translation: ShopItem['translations'][number];
}

const MarketplaceItemTranslationCard = ({
  translation,
  marketplaceItemId,
}: Props) => {
  const { t } = useTranslation();
  const { update, updating, remove, removing } =
    useMarketplaceItemTranslations(marketplaceItemId);
  const form = useMarketplaceItemTranslationForm();
  const { open, openDialog, closeDialog } = useDialog();
  const {
    open: confirmationOpen,
    openDialog: openConfirmation,
    closeDialog: closeConfirmation,
  } = useDialog();
  const { id, ...rest } = translation;
  const handleUpdate = async (formData: MarketplaceItemTranslationFormData) => {
    await update(id, formData);
    closeDialog();
  };
  const handleDelete = async () => {
    await remove(id);
    closeDialog();
  };
  const [showMore, setShowMore] = useState(false);

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="h2">
            {t(`language.${translation.language}`)}
          </Typography>
        }
      />
      <Divider />
      <Collapse in={showMore} collapsedSize={0}>
        <CardContent>
          <Typography gutterBottom variant="h3">
            {translation.title}
          </Typography>
          <RichText value={translation.description} />
        </CardContent>
        <Divider />
      </Collapse>
      <CardActions>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <ButtonOld
            onClick={() => setShowMore((currentShowMore) => !currentShowMore)}
          >
            {showMore
              ? t('marketplaceItems.hideContent')
              : t('marketplaceItems.showContent')}
          </ButtonOld>
          <Stack direction="row" justifyContent="end" gap="10px">
            <EditIconButton onClick={openDialog}>
              {t('marketplaceItems.editTranslation')}
            </EditIconButton>
            <FormDialog
              actionLabel={t('common.confirm')}
              onCancelClick={closeDialog}
              onSubmitForm={handleUpdate}
              id={`edit-translation-${id}-form-dialog`}
              title={t('marketplaceItems.editTranslationDialogTitle')}
              form={form}
              defaultValues={rest}
              open={open}
              loading={updating}
              width={700}
            />
            <DeleteIconButton onClick={openConfirmation} />
            <DeleteConfirmationDialog
              title={t(`language.${rest.language}`)}
              open={confirmationOpen}
              onCancel={closeConfirmation}
              onConfirm={handleDelete}
              loading={removing}
            />
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default MarketplaceItemTranslationCard;
