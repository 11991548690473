import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { ILog } from 'types/income';
import { LogsFilters } from './logs.types';

interface GetLogs {
  filters: Partial<LogsFilters>;
}

export const loaders = {
  getLogs: ({ filters }: GetLogs) => {
    return api<TableDataResponse<ILog>>({
      url: Endpoints.clientAdmin.logs.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeIds: filters.employees,
          adminIds: filters.admins,
          actions: filters.actions,
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
        },
      },
    });
  },
};
