import { quillDecodeIndent, quillEncodeIndent } from './quill-indent-fix';

export const toQuillValue = (valueFromProps?: string) => {
  if (!valueFromProps) {
    return valueFromProps;
  }

  let convertedValue = valueFromProps;

  // To support multiline strings during transition from text area to rich text
  // editor component.
  if (convertedValue.includes('\n')) {
    convertedValue = convertedValue.replaceAll('\n', '<br>');
  }

  // Convert nested lists to flat lists to support Quill editor.
  // https://github.com/quilljs/quill/issues/979
  if (convertedValue.includes('<li')) {
    convertedValue = quillEncodeIndent(convertedValue);
  }

  return convertedValue;
};

export const toPropsValue = (valueFromQuill: string) => {
  let convertedValue = valueFromQuill;

  // Convert flat lists to nested lists to support mobile app and emails.
  // https://github.com/quilljs/quill/issues/979
  if (convertedValue.includes('ql-indent')) {
    convertedValue = quillDecodeIndent(convertedValue);
  }

  return convertedValue;
};
