import { ComponentPropsWithoutRef, FC } from 'react';
import { ItemDetailsCard, Modal } from 'melp-design/components';
import { Box, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCountry, formatList } from 'utils/format';
import { ArrayElement } from 'types/Common';
import { MarketplaceItem, ShopItemCategory } from 'types/MarketplaceItems';
import { isDefined } from 'utils/isDefined';
import { ModalProps } from 'store/modal';
import {
  GridViewOutlined,
  PlaceOutlined,
  SellOutlined,
  ViewAgendaOutlined,
} from '@mui/icons-material';
import { Currency } from 'types/general';
import { BenefitCategory } from 'store/benefits';
import { getSinglePriceLabel } from '../ItemCard/common/getPriceLabel';

interface Props extends ModalProps {
  item: {
    title: string;
    description: string;
    countries: string[];
    cities: string[];
    prices: MarketplaceItem['prices'];
    currency: Currency;
    image?: string;
    logo?: string;
    benefitCategories: BenefitCategory[];
    categories: ShopItemCategory[];
    supplier: {
      name: string;
      termsAndConditionsLink?: string;
    };
  };
  actions?: ComponentPropsWithoutRef<typeof Modal.Content>['actions'];
}

export const ShopItemDetailsModal: FC<Props> = ({
  closeModal,
  item,
  actions = [],
}) => {
  const { t, i18n } = useTranslation();

  const getPrices = () => {
    const { prices } = item;

    if (!prices?.length) return [];

    const resolvePriceOptionTranslation = ({
      translations,
    }: ArrayElement<typeof prices>) => {
      if (!translations?.length) {
        return { description: '' };
      }
      const findTranslationByLang = (lang: string) =>
        translations.find(({ language }) => language === lang);
      const currentLanguage = i18n.language.toUpperCase();
      const userLangTranslation = findTranslationByLang(currentLanguage);
      if (userLangTranslation) {
        return userLangTranslation;
      }
      const enTranslation = findTranslationByLang('EN');
      return enTranslation ?? translations[0];
    };

    const sortedPrices = prices.toSorted((a, b) => {
      if (a.priceType === 'priceRange') {
        return 1;
      }
      if (b.priceType === 'priceRange') {
        return -1;
      }
      if (a.price === b.price) {
        return 0;
      }
      if (!isDefined(a.price)) {
        return -1;
      }
      if (!isDefined(b.price)) {
        return 1;
      }
      return a.price - b.price;
    });
    const priceOptionLabels = sortedPrices.map((priceOption) => {
      return {
        price: getSinglePriceLabel(priceOption, item.currency),
        description: resolvePriceOptionTranslation(priceOption).description,
      };
    });

    const hasDescription = priceOptionLabels.some(
      (priceOption) => priceOption.description.length,
    );

    return priceOptionLabels.map((option) => ({
      label: hasDescription ? (
        <Stack flexDirection="row" justifyContent="space-between">
          <Box>{option.price}</Box>
          <Box>{option.description}</Box>
        </Stack>
      ) : (
        option.price
      ),
      full: hasDescription,
    }));
  };

  return (
    <Modal.Content
      plain
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.close'),
        },
        ...actions,
      ]}
    >
      <ItemDetailsCard
        title={item.title}
        description={item.description}
        image={item.image}
        info={[
          {
            label: t('benefits.country'),
            icon: PlaceOutlined,
            content: formatList(
              i18n.language,
              item.countries.map((alpha3) =>
                formatCountry(i18n.language, alpha3),
              ),
            ),
          },
          {
            label: t('common.city.label'),
            icon: PlaceOutlined,
            content: formatList(i18n.language, item.cities),
          },
          ...(item.benefitCategories.length
            ? [
                {
                  label: t('marketplace.filtersBenefitCategory'),
                  icon: ViewAgendaOutlined,
                  content: formatList(
                    i18n.language,
                    item.benefitCategories.map((category) =>
                      t(`menu.${category}`),
                    ),
                  ),
                },
              ]
            : []),
          {
            label: t('marketplace.filtersItemCategory'),
            icon: GridViewOutlined,
            content: formatList(
              i18n.language,
              item.categories.map((category) =>
                t(`marketplaceItems.itemCategory-${category}`),
              ),
            ),
          },
          {
            label: t('marketplaceItems.supplier'),
            icon: SellOutlined,
            content: (
              <>
                {item.supplier.name}
                <br />
                {!!item.supplier.termsAndConditionsLink && (
                  <Link
                    href={item.supplier.termsAndConditionsLink}
                    target="_blank"
                    underline="hover"
                  >
                    {t('marketplace.supplierTerms')}
                  </Link>
                )}
              </>
            ),
          },
        ]}
        tags={{
          label: t('marketplace.prices'),
          items: getPrices(),
        }}
      />
    </Modal.Content>
  );
};
