import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IParentCompany } from 'types/income';
import { AdminClientsFilters } from './admin-clients.types';

interface GetClients {
  filters: Partial<AdminClientsFilters>;
}

interface GetClient {
  clientId: string;
}

export interface CreateClient {
  companyName: string;
  companyCountry: string;
  companyCode: string;
  adminEmail: string;
  adminPhone: string;
  adminFirstName: string;
  adminLastName: string;
  internalComment: string;
}

export const loaders = {
  getClients: ({ filters }: GetClients) => {
    return api<TableDataResponse<IParentCompany>>({
      url: Endpoints.melpAdmin.clients.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
          status: filters.status,
          companyIds: filters.companies,
          activityDateFrom: filters.date?.from,
          activityDateTo: filters.date?.to,
        },
      },
    });
  },
  getClient: ({ clientId }: GetClient) => {
    return api<IParentCompany>({
      url: Endpoints.melpAdmin.clients.byId.replace(':clientId', clientId),
    });
  },
  createClient: (values: CreateClient) => {
    return api({
      url: Endpoints.melpAdmin.clients.create,
      data: values,
      method: 'post',
    });
  },
};
