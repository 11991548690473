import { Alert } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string;
}

export const NothingFoundAlert: FC<Props> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Alert severity="warning">{message || t('errors.nothing_found')}</Alert>
  );
};
