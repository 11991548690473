import { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { Typography } from 'melp-design/components';
import { FormControl, FormHelperText } from '@mui/material';
import ReactQuill from 'react-quill';
import RichTextEditor from 'components/common/RichTextEditor/RichTextEditor';

interface Props
  extends Omit<ComponentPropsWithRef<typeof RichTextEditor>, 'error'> {
  label?: string;
  error?: string;
}

export const RichTextInput: FC<Props> = forwardRef<ReactQuill, Props>(
  ({ label, error, ...props }, forwardedRef) => {
    return (
      <FormControl error={!!error} fullWidth>
        {label ? (
          <Typography
            variant="p1"
            color="textSecondary"
            mb="10px"
            component="label"
          >
            {label}
          </Typography>
        ) : null}
        <RichTextEditor error={!!error} {...props} ref={forwardedRef} />
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    );
  },
);

RichTextInput.displayName = 'RichTextInput';
