import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const usePredefinedToasts = () => {
  const { t } = useTranslation();

  return {
    success: {
      added: () => toast.success(t('common.added_successfully')),
      updated: () => toast.success(t('common.updated_succesfully')),
      deleted: () => toast.success(t('common.deleted_succesfully')),
    },
    error: {
      validationGlobal: () => toast.error(t('errors.check_validation')),
    },
  };
};
