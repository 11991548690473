import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { adminClientsKeys } from 'store/admin-clients';
import { loaders } from './admin-client-deposits.loaders';
import { adminClientDepositsKeys } from './admin-client-deposits.queries';

export const useAdminClientCreateDeposit = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createDeposit, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientDepositsKeys.all);
    },
  });
};

export const useAdminClientUpdateDeposit = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateDeposit, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientDepositsKeys.all);
    },
  });
};

export const useAdminClientUpdateDepositSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateSettings, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientDepositsKeys.all);
    },
  });
};

export const useExportToXlsDeposits = () => {
  return useMutation(loaders.exportXlsDeposits, {
    onSuccess: filesConverters.export,
  });
};

export const useExportToXlsBalanceLogs = () => {
  return useMutation(loaders.exportXlsBalanceLogs, {
    onSuccess: filesConverters.export,
  });
};

export const useAdminEnableDeposits = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.enableDeposits, {
    onSuccess: (_, { clientId }) => {
      return Promise.all([
        queryClient.invalidateQueries(adminClientDepositsKeys.all),
        queryClient.invalidateQueries(adminClientsKeys.detail(clientId)),
      ]);
    },
  });
};

export const useAdminCreateDepositEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createDepositEvent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(
        adminClientDepositsKeys.list('deposit-events'),
      );
    },
  });
};

export const useAdminEditDepositEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.editDepositEvent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(
        adminClientDepositsKeys.list('deposit-events'),
      );
    },
  });
};

export const useAdminDeleteDepositEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteDepositEvent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(
        adminClientDepositsKeys.list('deposit-events'),
      );
    },
  });
};
