import { FC } from 'react';
import { AdminShopItemLayout } from 'pages/atoms/admin-shop-item-layout';
import MarketplaceItemPrice from 'containers/MarketplaceItems/MarketplaceItem/views/price/MarketplaceItemPrice';

const AdminShopItemPricesPage: FC = () => {
  return (
    <AdminShopItemLayout>
      <MarketplaceItemPrice />
    </AdminShopItemLayout>
  );
};

export default AdminShopItemPricesPage;
