export const appLanguages = [
  'en',
  'ru',
  'pl',
  'lt',
  'lv',
  'et',
  'bg',
  'cs',
  'de',
] as const;

export type AppLanguage = (typeof appLanguages)[number];

export const contentLanguages = [
  'be',
  'bg',
  'da',
  'de',
  'el',
  'en',
  'et',
  'fi',
  'fr',
  'ga',
  'id',
  'is',
  'it',
  'ja',
  'ka',
  'ko',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'tk',
  'tr',
  'uk',
  'uz',
] as const;

export type ContentLanguage = (typeof contentLanguages)[number];
