import { TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search, Close } from 'melp-design/icons';
import { ButtonOld } from 'melp-design/components';
import { Colors } from 'melp-design/style';
import { useState, useEffect, useRef, ChangeEvent, MouseEvent } from 'react';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 5,
    borderRadius: 6,

    '& $searchButton': {
      padding: 0,
    },

    '& $clearButton': {
      padding: 3,
    },
  },
  input: {
    padding: 0,
    fontSize: 14,
    lineHeight: 1.4,
    width: 140,
    transition: theme.transitions.create('width'),
  },
  searchButton: {
    marginRight: 10,
    marginLeft: 4,
    transition: theme.transitions.create(['margin-right']),

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '& svg': {
      height: 12,
      width: 'auto',
    },
  },
  clearButton: {
    borderRadius: 4,
    marginLeft: 5,
    transition: theme.transitions.create(['all']),

    '&:disabled': {
      color: Colors.primary,
    },

    '& svg': {
      height: 13,
      width: 'auto',
    },
  },
  collapsed: {
    padding: 0,

    '& $searchButton': {
      margin: 0,
      padding: 9,
    },

    '& $input': {
      width: 0,
      height: 12,
    },

    '& $clearButton': {
      width: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: 14,
      margin: 0,
    },
  },
}));

interface Props {
  value?: string;
  onApplyFilter: (value?: string) => void;
  placeholder?: string;
  /**
   * Minimum symbols required to apply the filter
   */
  minSymbols?: number;
  /**
   * Should describe the purpose of this filter
   */
  hint?: string;
}

const TextFilter = ({ minSymbols = 3, ...props }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState(props.value ?? '');
  const [isExpanded, setIsExpanded] = useState(!!props.value);
  useEffect(() => {
    if (isExpanded && !props.value && searchText.length >= minSymbols) {
      setSearchText('');
      setIsExpanded(false);
    }
    // react only to value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const debouncedApply = useDebouncedCallback(props.onApplyFilter, 300);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    if (newValue.length >= minSymbols) {
      debouncedApply(newValue);
    } else if (
      newValue.length === minSymbols - 1 &&
      newValue.length === searchText.length - 1
    ) {
      debouncedApply();
    }
  };

  const searchTextTooShort = searchText.length < minSymbols;

  const handleInputBlur = () => {
    if (searchTextTooShort) {
      props.onApplyFilter();
      setSearchText('');
      setIsExpanded(false);
    }
  };

  const handleSearchClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isExpanded) {
      setIsExpanded(true);
      inputRef.current?.focus();
    }
  };

  const handleClearClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsExpanded(false);
    setSearchText('');
    if (props.value) {
      props.onApplyFilter('');
    }
  };

  const tooltipTitle =
    isExpanded && searchTextTooShort
      ? t('common.start_search', { count: minSymbols })
      : props.hint ?? '';

  return (
    <Tooltip title={tooltipTitle}>
      <TextField
        value={searchText}
        onChange={handleChange}
        placeholder={props.placeholder}
        variant="outlined"
        size="small"
        role={!isExpanded ? 'button' : undefined}
        inputRef={inputRef}
        onBlur={handleInputBlur}
        InputProps={{
          startAdornment: (
            <ButtonOld
              variant="text"
              textColor="primary"
              className={classes.searchButton}
              onMouseDown={handleSearchClick}
            >
              <Search />
            </ButtonOld>
          ),
          endAdornment: (
            <ButtonOld
              variant="text"
              textColor="red"
              className={classes.clearButton}
              onMouseDown={handleClearClick}
            >
              <Close />
            </ButtonOld>
          ),
          classes: {
            root: clsx(classes.root, !isExpanded && classes.collapsed),
            input: classes.input,
          },
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              (e.target as HTMLElement).blur?.();
            }
          },
        }}
      />
    </Tooltip>
  );
};

export default TextFilter;
