import { Endpoints } from '../api/constants';
import { Supplier, SupplierUpdateRequestBody } from '../types/Suppliers';
import { useGet, useCustomSwrMutation } from '../utils/Api';

export const useCreateSupplier = () => {
  const create = useCustomSwrMutation<SupplierUpdateRequestBody, Supplier>(
    Endpoints.marketplaceSuppliers,
    'post',
  );

  return { create };
};

export const useSupplier = (id: string) => {
  const url = Endpoints.marketplaceSupplier(id);
  const { data, loading, mutate } = useGet<Supplier>(url);

  const update = useCustomSwrMutation<SupplierUpdateRequestBody, Supplier>(
    url,
    'put',
    {
      onSuccess: mutate,
    },
  );

  const remove = useCustomSwrMutation<SupplierUpdateRequestBody, Supplier>(
    url,
    'delete',
  );

  return {
    supplier: data,
    loading,
    update,
    remove,
  };
};
