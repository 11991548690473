import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { shopItemCategories } from 'types/MarketplaceItems';
import { shopItemStatuses } from 'store/shop';

export const adminShopItemsFiltersSchema = z
  .object({
    suppliers: z.array(z.string()).catch([]),
    countries: z.array(z.string()).catch([]),
    cities: z.array(z.string()).catch([]),
    categories: z.array(z.enum(shopItemCategories)).catch([]),
    status: z.array(z.enum(shopItemStatuses)).catch([]),
    createdAt: dateRangeSchema,
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
