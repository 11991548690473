import { FC } from 'react';
import { Modal, FileInput } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps, useModalContext } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { useImportDiscountCodesXls } from 'store/admin-discount-codes';
import { Stack } from '@mui/material';
import { DiscountCodesImportErrorsModal } from '..';

interface Values {
  files: File[];
}

interface Props extends ModalProps {
  discountId: string;
}

export const ImportDiscountCodesModal: FC<Props> = ({
  discountId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();

  const { control, handleSubmit } = useForm<Values>({
    values: { files: [] },
    mode: 'onChange',
  });

  const { mutate: importCodes, status } = useImportDiscountCodesXls();

  const onSubmit: SubmitHandler<Values> = (values) => {
    importCodes(
      {
        discountId,
        file: values.files[0],
      },
      {
        onSuccess: ({ data }) => {
          closeModal({ action: 'CLOSE' });

          if (!data.success) {
            openModal({
              component: DiscountCodesImportErrorsModal,
              size: 'lg',
              props: {
                errors: data.errorMessages.map((error) => ({
                  row: error.row,
                  field: error.column,
                  message: error.message,
                })),
                discountId,
              },
            });
          } else {
            predefinedToasts.success.added();
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('shop.vouchers.actions.upload_codes')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('actions.import'),
            disabled: status === 'loading',
            type: 'submit',
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="files"
            control={control}
            render={({ field: { name, onChange, ref } }) => (
              <FileInput
                name={name}
                onChange={onChange}
                accept={['xls', 'xlsx']}
                maxFiles={1}
                required
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
