import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    clearIcon: {},
    clearButton: {
      height: 40,
      width: 40,
      marginLeft: 5,
    },
  }),
);
