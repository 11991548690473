import { FC, ReactNode } from 'react';
import { Typography } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { Box, Unstable_Grid2 as Grid, Paper, Stack } from '@mui/material';
import { type SvgIconComponent as MuiSvgIconComponent } from '@mui/icons-material';
import RichText from 'components/common/RichText/RichText';
import { ReactComponent as ItemImageClipPath } from './assets/item-image-clip-path.svg';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  logo?: string;
  tags?: {
    label?: string;
    items: Array<{ label: ReactNode; full?: boolean }>;
  };
  info?: Array<{
    label: string;
    icon: MuiSvgIconComponent;
    content: ReactNode;
  }>;
}

export const ItemDetailsCard: FC<Props> = ({
  title,
  description,
  image,
  logo,
  tags,
  info,
}) => {
  return (
    <Stack>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ height: 300 }}>
          <Box sx={{ width: '100%', height: 300, position: 'relative' }}>
            <img
              src={image || '/no-item-image-placeholder.svg'}
              alt={title || ''}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                clipPath: 'url(#svgClip)',
              }}
            />
          </Box>
          {logo ? <ItemImageClipPath /> : null}
        </Box>
        {logo ? (
          <Box
            sx={{
              width: 80,
              height: 80,
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -60%)',
              border: `1px solid ${SystemColors.grey[90]}`,
              borderRadius: 1.5,
              background: SystemColors.white,
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              padding: 0.25,
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Box>
        ) : null}
      </Box>

      <Stack gap={3} py={7.5} px={5}>
        {title ? (
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
        ) : null}

        {tags?.items.length ? (
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ p: 1, maxWidth: '75%', margin: 'auto' }}
          >
            {tags.label ? (
              <Typography variant="p1" color="textSecondary">
                {tags.label}
              </Typography>
            ) : null}
            {tags.items.map((tag, i) => (
              <Stack
                key={i}
                sx={{
                  width: tag.full ? '100%' : 'auto',
                  border: `1px solid ${SystemColors.grey[90]}`,
                  borderRadius: 1,
                  px: 2,
                  py: 1,
                }}
              >
                <Typography variant="p1">{tag.label}</Typography>
              </Stack>
            ))}
          </Stack>
        ) : null}

        {info ? (
          <Paper variant="outlined" sx={{ padding: 2, borderRadius: 2 }}>
            <Grid container rowSpacing={3} columnSpacing={2}>
              {info.map((info, i) => {
                const InfoIcon = info.icon;
                return (
                  <Grid xs={6} key={i}>
                    <Stack gap={1}>
                      <Stack
                        gap={1}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <InfoIcon
                          sx={{
                            fill: SystemColors.grey[55],
                            fontSize: '1.15em',
                          }}
                        />
                        <Typography variant="p2" color="textSecondary">
                          {info.label}
                        </Typography>
                      </Stack>
                      <Typography variant="p1" sx={{ textAlign: 'center' }}>
                        {info.content}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        ) : null}

        {description ? (
          <Paper variant="outlined" sx={{ padding: 2, borderRadius: 2 }}>
            <RichText value={description} />
          </Paper>
        ) : null}
      </Stack>
    </Stack>
  );
};
