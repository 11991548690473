import { FC, useState } from 'react';
import { useEmployeesGroups } from 'store/employee-groups';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const EmployeesGroupsFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: employeesGroups, isLoading } = useEmployeesGroups({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={employeesGroups?.items.map((employeesGroup) => ({
        key: employeesGroup.id,
        label: employeesGroup.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
