import { Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Typography } from 'melp-design/components';

interface Props {
  label: string;
  value?: ReactNode;
  fallback?: string;
}

export const LabeledValue: FC<Props> = ({ label, value, fallback = '-' }) => {
  return (
    <Stack gap={0.75}>
      <Typography variant="p1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="p1" color="textPrimary">
        {value || fallback}
      </Typography>
    </Stack>
  );
};
