import { DateTimeInput, Modal } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';

interface Values {
  date: string;
}

const INITIAL_VALUES: Values = {
  date: moment().toISOString(true),
};

interface Props extends ModalProps {
  closeModal: (
    param?: { action: 'CLOSE' } | { action: 'CONFIRM'; date: string },
  ) => void;
}

export const DateSelectModal: FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const onSubmit: SubmitHandler<Values> = ({ date }) => {
    closeModal({ action: 'CONFIRM', date });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('actions.on_specific_date')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
          },
        ]}
      >
        <Controller
          name="date"
          control={control}
          render={({ field: { name, value, onChange, ref } }) => (
            <DateTimeInput
              label={t('common.date')}
              name={name}
              value={value}
              onChange={onChange}
              required
              ref={ref}
            />
          )}
        />
      </Modal.Content>
    </form>
  );
};
