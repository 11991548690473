import { FC, useState } from 'react';
import { useInternalShopItems } from 'store/internal-shop';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const InternalShopItemsFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: shopItems, isLoading } = useInternalShopItems({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={shopItems?.items.map((item) => ({
        key: item.id,
        label: item.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
