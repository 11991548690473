import { FC, MouseEvent } from 'react';
import { ButtonBase as MuiButton } from '@mui/material';
import { SystemColors } from 'melp-design/style';

interface Props {
  label: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
}

export const TriggerButton: FC<Props> = ({ label, isActive, onClick }) => {
  return (
    <MuiButton
      sx={{
        px: '10px',
        py: '5px',
        minHeight: 30,
        minWidth: 30,
        color: isActive ? SystemColors.black : SystemColors.grey[45],
        backgroundColor: SystemColors.white,
        fontWeight: 400,
        fontSize: 12,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: SystemColors.grey[90],
        borderRadius: '6px',
        transitionDuration: '150ms',
        whiteSpace: 'nowrap',
        '&:hover': {
          borderColor: SystemColors.grey[75],
        },
      }}
      onClick={onClick}
    >
      {label}
    </MuiButton>
  );
};
