import { Card as MuiCard, CardProps } from '@mui/material';
import { Colors } from '../../style/Colors';
import { Effects } from '../../style/Effects';

interface Props extends CardProps {
  highlighted?: boolean;
}

const Card = ({ variant = 'outlined', highlighted, sx, ...props }: Props) => {
  return (
    <MuiCard
      {...props}
      variant={variant}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: highlighted ? Colors.primary : undefined,
        backgroundColor: highlighted ? Colors.primaryLight : undefined,
        '&:hover': {
          boxShadow: Effects.smallElementsShadow,
        },
        '& > *:last-child': {
          paddingBottom: '15px',
        },
        ...sx,
      }}
    />
  );
};

export default Card;
