import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles((theme) =>
  createStyles({
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '20px',
      },
    },
  }),
);
