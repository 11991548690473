import { Typography } from '@mui/material';
import { CellProps } from 'react-table';
import { useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';

export default function AllocationFrequency(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { t } = useTranslation();

  return <Typography>{t(`employees.${value}`)}</Typography>;
}
