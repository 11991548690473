import { LocaleData, registerLocale } from 'i18n-iso-countries';
import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AppLanguage } from 'types/general';
import { StorageKeys } from './types/LocalStorage';
import { languageRequest } from './utils/Language';
import { APP } from './config';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const COUNTRIES_BY_LANGUAGE: Record<AppLanguage, LocaleData> = {
  en: require('i18n-iso-countries/langs/en.json'),
  ru: require('i18n-iso-countries/langs/ru.json'),
  pl: require('i18n-iso-countries/langs/pl.json'),
  lt: require('i18n-iso-countries/langs/lt.json'),
  lv: require('i18n-iso-countries/langs/lv.json'),
  et: require('i18n-iso-countries/langs/et.json'),
  bg: require('i18n-iso-countries/langs/bg.json'),
  cs: require('i18n-iso-countries/langs/cs.json'),
  de: require('i18n-iso-countries/langs/de.json'),
};

const backendOptions: HttpBackendOptions = {
  crossDomain: true,
  allowMultiLoading: true,
  loadPath: '{{lng}}|{{ns}}',
  request: languageRequest,
};

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init(
    {
      backend: backendOptions,
      compatibilityJSON: 'v3',
      debug: false,
      lng: localStorage.getItem(StorageKeys.lang) || APP.locales.default,
      fallbackLng: false,
      ns: ['translations'],
      defaultNS: 'translations',
    },
    (err) => {
      if (err) {
        toast.error('Failed to load translations');
      }
    },
  )
  .then(() => {
    const country =
      COUNTRIES_BY_LANGUAGE[
        localStorage.getItem(StorageKeys.lang) as AppLanguage
      ] || COUNTRIES_BY_LANGUAGE[APP.locales.default];
    registerLocale(country);
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  const country =
    COUNTRIES_BY_LANGUAGE[lng as AppLanguage] ||
    COUNTRIES_BY_LANGUAGE[APP.locales.default];
  registerLocale(country);
});

export default i18n;
