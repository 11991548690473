import {
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'melp-design/components';
import { MpControlType, MpForm } from '@mp-react/form';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PriceVariant } from 'store/shop';
import { Currency } from 'types/general';
import { CustomFormControl } from '../../../../../types/Common';
import { CustomControlType } from '../../../../../types/Form';
import { currencyConverters } from '../../../../../utils/Currency';
import { useFormUtils } from '../../../../../utils/Form';
import { usePriceTypes } from './Utils';

// converts cents into main unit
const toInitialFormData = (initialData: Partial<PriceFormData>) => ({
  ...initialData,
  price: initialData.price
    ? currencyConverters.toMainUnitAmount(initialData.price)
    : undefined,
  priceRangeFrom: initialData.priceRangeFrom
    ? currencyConverters.toMainUnitAmount(initialData.priceRangeFrom)
    : undefined,
  priceRangeTo: initialData.priceRangeTo
    ? currencyConverters.toMainUnitAmount(initialData.priceRangeTo)
    : undefined,
});

export interface PriceFormData {
  priceType: PriceVariant;
  price?: number | null;
  priceRangeFrom?: number | null;
  priceRangeTo?: number | null;
  discountPercentage?: string | number;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: PriceFormData) => void;
  loading: boolean;
  title: string;
  initialData: Partial<PriceFormData>;
  currency: Currency;
}

const PriceDialog = ({ initialData, currency, ...props }: Props) => {
  const { t } = useTranslation();

  const { overridables } = useFormUtils();
  const methods = useForm();
  const { clearErrors, setValue, reset } = methods;

  useEffect(() => {
    reset(toInitialFormData(initialData));
  }, [currency, initialData, reset]);

  const priceTypes = usePriceTypes();
  const priceType = useWatch<PriceVariant>({
    name: 'priceType',
    control: methods.control,
  });
  useEffect(() => {
    clearErrors(['price', 'priceRangeFrom', 'priceRangeTo']);
    if (priceType) {
      if (priceType === 'fixedPrice') {
        setValue('priceRangeFrom', null);
        setValue('priceRangeTo', null);
      } else if (priceType === 'priceRange') {
        setValue('price', null);
      }
    }
  }, [clearErrors, priceType, setValue]);

  const isPriceOptionTypeDefined = !!priceType;
  const isFixedPrice = isPriceOptionTypeDefined && priceType === 'fixedPrice';
  const isRangePrice = isPriceOptionTypeDefined && priceType === 'priceRange';

  const controls: CustomFormControl[] = [
    {
      key: 'priceType',
      type: CustomControlType.select,
      label: t('marketplaceItems.priceType'),
      children: priceTypes.map((option) => ({
        type: '' as MpControlType,
        ...option,
      })),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      key: 'price',
      type: CustomControlType.currency,
      label: t('marketplaceItems.price'),
      endAdornment: currency,
      messages: {
        required: t('form.required'),
      },
      required: isFixedPrice,
      disabled: !isFixedPrice,
    },
    {
      key: 'priceRangeFrom',
      type: CustomControlType.currency,
      label: t('marketplaceItems.priceRangeFrom'),
      endAdornment: currency,
      messages: {
        required: t('form.required'),
      },
      required: isRangePrice,
      disabled: !isRangePrice,
    },
    {
      key: 'priceRangeTo',
      type: CustomControlType.currency,
      label: t('marketplaceItems.priceRangeTo'),
      endAdornment: currency,
      messages: {
        required: t('form.required'),
      },
      required: isRangePrice,
      disabled: !isRangePrice,
    },
    {
      key: 'discountPercentage',
      type: CustomControlType.numberWithComma,
      label: t('marketplaceItems.discountPercentage'),
      endAdornment: '%',
      validations: {
        max: 100,
      },
    },
  ];

  const handleClose = () => {
    if (methods.formState.isDirty) {
      if (window.confirm(t('errors.user_leaving_edited_page'))) {
        props.onClose();
      }
    } else {
      props.onClose();
    }
    reset(toInitialFormData(initialData), { isDirty: false });
    clearErrors();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <MpForm
          overridables={overridables}
          controls={controls}
          size="small"
          layout="separated"
          variant="outlined"
          useFormMethods={methods}
        />
      </DialogContent>
      <DialogActions>
        <ButtonOld variant="outlined" onClick={handleClose}>
          {t('common.cancel')}
        </ButtonOld>
        <ButtonOld
          variant="contained"
          color="primary"
          type="submit"
          loading={props.loading}
          onClick={methods.handleSubmit(props.onSubmit)}
        >
          {t('common.confirm')}
        </ButtonOld>
      </DialogActions>
    </Dialog>
  );
};

export default PriceDialog;
