import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-shop-vouchers.loaders';
import { adminShopVouchersKeys } from './admin-shop-vouchers.queries';

export const useExportXlsTemplate = () => {
  return useMutation(loaders.exportXlsTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useExportVouchersXls = () => {
  return useMutation(loaders.exportVouchersXls, {
    onSuccess: filesConverters.export,
  });
};

export const useImportVouchersXls = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importVouchersXls, {
    onSuccess: ({ data }) => {
      if (data.success) {
        return queryClient.invalidateQueries(adminShopVouchersKeys.lists);
      }
    },
  });
};

export const useImportVouchersFiles = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importVouchersFiles, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopVouchersKeys.lists);
    },
  });
};

export const useUpdateStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateStatus, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopVouchersKeys.lists);
    },
  });
};

export const useUpdateShopVoucher = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateShopVoucher, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminShopVouchersKeys.all);
    },
  });
};
