import { z } from 'zod';
import { orderBy } from 'lodash';
import { converters as tableConverters } from 'store/table';
import {
  IAdminClientBalanceLog,
  IAdminClientDeposit,
  IAdminDepositEvent,
  IDepositsMeta,
} from 'types/income';
import { sum } from 'utils/general';
import { sortFiltersSchema } from 'utils/schemas';
import { convertExpenseType } from 'store/expenses';
import { loaders } from './admin-client-deposits.loaders';
import {
  AdminClientBalanceLog,
  AdminClientDeposit,
  AdminDepositEvent,
  ClientCompanyBalanceType,
  DepositsMeta,
} from './admin-client-deposits.types';

const calculateCreditUsed = (balance: number, credit: number) =>
  balance > 0
    ? 0
    : balance < 0 && Math.abs(balance) < credit
    ? Math.abs(balance)
    : balance < 0 && Math.abs(balance) >= credit
    ? credit
    : 0;

const convertDeposit = (
  deposit: IAdminClientDeposit['companies'][number],
): AdminClientDeposit => {
  return {
    id: deposit.id,
    name: deposit.name,
    averageMonthlyTurnover: deposit.averageMonthlyTurnover ?? 0,
    creditLimit: deposit.creditLimit,
    lowDepositAlertThreshold: deposit.lowDepositAlertThreshold,
    totalDeposits: deposit.totalDeposits,
    totalExpenses: deposit.totalExpenses ?? 0,
    remainingCredit: deposit.totalDeposits - (deposit.totalExpenses ?? 0),
    creditModificationDate: deposit.creditModificationDate,
    lastDepositDate: deposit.lastDepositDate,
    lowDepositAlertModificationDate: deposit.lowDepositAlertModificationDate,
  };
};

const convertDeposits = (
  deposits: IAdminClientDeposit['companies'],
): AdminClientDeposit[] => {
  return deposits.map(convertDeposit);
};

const convertDepositTotals = (
  companies: IAdminClientDeposit['companies'],
): DepositsMeta => {
  const balance = sum(
    ...companies.map(
      (company) => company.totalDeposits - (company.totalExpenses ?? 0),
    ),
  );
  const credit = sum(...companies.map((company) => company.creditLimit));
  const creditUsed = sum(
    ...companies.map((company) => {
      return calculateCreditUsed(
        company.totalDeposits - (company.totalExpenses ?? 0),
        company.creditLimit,
      );
    }),
  );
  const creditRemaining =
    sum(...companies.map((company) => company.creditLimit)) - creditUsed;

  return {
    balance,
    credit,
    creditUsed,
    creditRemaining,
  };
};

const convertBalanceType = (
  type: IAdminClientBalanceLog['type'],
): ClientCompanyBalanceType => {
  switch (type) {
    case 'MANUAL_EXPENSES':
      return 'manual';
    case 'SHOP_ORDERS':
      return 'shopOrder';
    case 'CLAIM_REIMBURSEMENTS':
      return 'claimReimbursement';
    case 'MERCHANDISING_ORDERS':
      return 'companyItems';
    case 'DEPOSIT':
      return 'deposit';
    default:
      return type satisfies never;
  }
};

const convertBalanceLog = (
  deposit: IAdminClientBalanceLog,
): AdminClientBalanceLog => {
  return {
    id: deposit.id,
    type: convertBalanceType(deposit.type),
    date: deposit.date,
    invoiceNumber: deposit.invoiceNumber ?? '',
    depositChange: deposit.depositChange,
    remainingBalance: deposit.remainingBalance,
    comment: deposit.comment ?? '',
    owner: deposit.doneBy ? deposit.doneBy : null,
  };
};

const convertBalanceLogs = (
  deposits: IAdminClientBalanceLog[],
): AdminClientBalanceLog[] => {
  return deposits.map(convertBalanceLog);
};

const convertDepositsMeta = (meta: IDepositsMeta): DepositsMeta => {
  const balance = meta.totalDeposits - meta.totalExpenses;
  const creditUsed = calculateCreditUsed(balance, meta.creditLimit);

  return {
    balance,
    credit: meta.creditLimit,
    creditUsed,
    creditRemaining: meta.creditLimit - creditUsed,
  };
};

const convertDepositEvent = (event: IAdminDepositEvent): AdminDepositEvent => {
  return {
    id: event.id,
    type: convertExpenseType(event.type),
    startsAt: {
      date: event.startDate,
      admin: event.startDateChangedByAdmin,
    },
    endsAt: event.endDate
      ? {
          date: event.endDate,
          admin: event.endDateChangedByAdmin,
        }
      : null,
    parentCompany: event.parentCompany,
  };
};

const convertDepositEvents = (
  events: IAdminDepositEvent[],
): AdminDepositEvent[] => {
  return events.map(convertDepositEvent);
};

export const converters = {
  getDeposits: (
    { data }: Awaited<ReturnType<typeof loaders.getDeposits>>,
    sort?: z.infer<typeof sortFiltersSchema>['sort'],
  ) => {
    const items = convertDeposits(data.companies);

    return {
      items: sort ? orderBy(items, [sort.columnKey], [sort.order]) : items,
      meta: convertDepositTotals(data.companies),
    };
  },
  getCompanyBalanceLogs: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCompanyBalanceLogs>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBalanceLogs(data.data),
      meta: convertDepositsMeta(data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepositEvents: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepositEvents>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDepositEvents(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
