import { z } from 'zod';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = <T extends z.ZodTypeAny>(
  schema: T,
): {
  searchParams: z.infer<typeof schema>;
  navigate: (location: {
    url?: string;
    params?: Partial<z.infer<typeof schema>>;
    replace?: boolean;
  }) => void;
  stringifyParams: (options: {
    params?: Partial<z.infer<typeof schema>>;
    replace?: boolean;
  }) => string;
} => {
  const { search: queryString } = useLocation();
  const { push } = useHistory();

  const parsedQuery = qs.parse(queryString, { ignoreQueryPrefix: true });

  const stringifyParams = ({
    params,
    replace = false,
  }: {
    params?: Partial<z.infer<typeof schema>>;
    replace?: boolean;
  }) => {
    return qs.stringify(
      { ...(replace ? {} : parsedQuery), ...params },
      {
        arrayFormat: 'brackets',
        skipNulls: true,
        encodeValuesOnly: true,
        addQueryPrefix: true,
        filter: (_prefix, value) => (value === '' ? undefined : value),
      },
    );
  };

  return {
    searchParams: schema.parse(parsedQuery),
    navigate: ({ url, params, replace }) =>
      push({
        pathname: url,
        search: stringifyParams({ params, replace }),
      }),
    stringifyParams,
  };
};
