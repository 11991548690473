import { FC } from 'react';
import {
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Tile,
  Typography,
} from 'melp-design/components';
import { Divider, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useRouteParams } from 'utils/useIdParam';
import { useSearchParams } from 'utils/navigation';
import {
  adminClientDepositsFiltersSchema,
  useAdminClientDeposits,
  useExportToXlsDeposits,
} from 'store/admin-client-deposits';
import { formatCurrency, formatPercents } from 'utils/format';
import { ROUTES } from 'config';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { AdminClientDepositsActions } from '../atoms';

export const AdminClientDepositsList: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.deposits.list,
  );

  const { searchParams: filters, navigate } = useSearchParams(
    adminClientDepositsFiltersSchema,
  );

  const { data: deposits, isLoading } = useAdminClientDeposits(
    clientId,
    filters,
  );

  const { mutate: exportToXls, isLoading: isExportingToXls } =
    useExportToXlsDeposits();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack direction="row" alignItems="center" gap="15px">
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
              </>
            ) : deposits ? (
              <>
                <Tile
                  title={t('clients.deposits.balance')}
                  items={[formatCurrency(i18n.language, deposits.meta.balance)]}
                />
                <Tile
                  title={t('clients.deposits.credit_used')}
                  items={[
                    formatCurrency(i18n.language, deposits.meta.creditUsed),
                  ]}
                  label={formatPercents(
                    i18n.language,
                    deposits.meta.creditUsed / deposits.meta.credit,
                  )}
                />
                <Tile
                  title={t('clients.deposits.credit_remaining')}
                  items={[
                    formatCurrency(
                      i18n.language,
                      deposits.meta.creditRemaining,
                    ),
                  ]}
                  label={formatPercents(
                    i18n.language,
                    deposits.meta.creditRemaining / deposits.meta.credit,
                  )}
                />
              </>
            ) : null}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={deposits?.items.length} />

            <Stack direction="row" alignItems="center" gap="10px">
              {/* Filters */}

              {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

              <DownloadExcelButton
                listLength={deposits?.items.length ?? 0}
                exportToExcel={() => exportToXls({ clientId, filters })}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={deposits?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('employees.company'),
            cell: ({ row: { original: deposit } }) => {
              return (
                <Link
                  label={deposit.name}
                  to={ROUTES.admin.clients.details.balanceLogs.details
                    .replace(':id', clientId)
                    .replace(':companyId', deposit.id)}
                />
              );
            },
            meta: { sort: true },
          },
          {
            id: 'averageMonthlyTurnover',
            header: t('clients.deposits.average_monthly_turnover.label'),
            cell: ({ row: { original: deposit } }) => {
              return formatCurrency(
                i18n.language,
                deposit.averageMonthlyTurnover,
              );
            },
            meta: { align: 'right' },
          },
          {
            id: 'remainingCredit',
            header: t('clients.deposits.remaining.label'),
            cell: ({ row: { original: deposit } }) => {
              return (
                <Typography
                  color={deposit.remainingCredit < 0 ? 'error' : 'textPrimary'}
                >
                  {formatCurrency(i18n.language, deposit.remainingCredit)}
                </Typography>
              );
            },
            meta: { align: 'right', sort: true },
          },
          {
            id: 'lastDepositDate',
            header: t('clients.deposits.last_deposit_date.label'),
            cell: ({ row: { original: deposit } }) => {
              return deposit.lastDepositDate
                ? moment(deposit.lastDepositDate).format('l')
                : null;
            },
          },
          {
            id: 'credit',
            header: t('common.credit.label'),
            cell: ({ row: { original: deposit } }) => {
              return formatCurrency(i18n.language, deposit.creditLimit);
            },
            meta: { align: 'right' },
          },
          {
            id: 'lowDepositAlertThreshold',
            header: t('clients.deposits.low_deposit_alert.label'),
            cell: ({ row: { original: deposit } }) => {
              return deposit.lowDepositAlertThreshold < 0
                ? '-'
                : formatCurrency(
                    i18n.language,
                    deposit.lowDepositAlertThreshold,
                  );
            },
            meta: { align: 'right' },
          },
          {
            id: 'actions',
            cell: ({ row: { original: deposit } }) => {
              return (
                <AdminClientDepositsActions
                  clientId={clientId}
                  companyId={deposit.id}
                  credit={deposit.creditLimit}
                />
              );
            },
            meta: { align: 'right' },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
      />
    </TableWrapper>
  );
};
