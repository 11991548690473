import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-rewards.loaders';
import { converters } from './recognition-rewards.converters';
import { RecognitionRewardsFilters } from './recognition-rewards.types';

export const recognitionRewardsKeys = queryKeyFactory('recognition-rewards');

export const useRecognitionRewards = (
  filters: Partial<RecognitionRewardsFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionRewardsKeys.list(filters),
    queryFn: () => loaders.getRewards({ filters }),
    select: converters.getRewards,
  });
};

export const useInfiniteRecognitionRewards = (
  filters: Partial<RecognitionRewardsFilters> = {},
) => {
  const query = useInfiniteQuery(
    recognitionRewardsKeys.list('infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getRewards({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getRewards(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );

  return query;
};

export const useRecognitionRewardsConfig = () => {
  return useQuery({
    queryKey: recognitionRewardsKeys.detail('config'),
    queryFn: () => loaders.getConfig(),
    select: converters.getConfig,
  });
};
