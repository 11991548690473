import { TypographyColor } from 'melp-design/components';
import { BenefitStatus } from './benefits.types';

export const COLOR_BY_BENEFIT_STATUS: Record<BenefitStatus, TypographyColor> = {
  draft: 'textSecondary',
  inactive: 'textSecondary',
  active: 'success',
  expired: 'error',
  expiresSoon: 'warning',
};
