import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IIntegration, IIntegrationModel } from 'types/income';
import { IntegrationStatus } from './integrations.types';

interface GetIntegration {
  integrationId: string;
}

interface CreateIntegration {
  integrationModelId: string;
  parentCompanyId: string;
  name: string;
  connectionData: any;
}

interface GetIntegrationModel {
  integrationModelId: string;
}

interface CreateIntegrationModel {
  name: string;
  comment?: string;
  logoId: string | null;
  type: string;
}

interface UpdateIntegrationModel {
  integrationModelId: string;
  data: {
    name: string;
    comment: string;
    logoId: string;
    type: string;
    status: IntegrationStatus;
  };
}

interface DeleteIntegrationModel {
  integrationModelId: string;
}

interface UpdateIntegration {
  integrationId: string;
  name: string;
  status: IntegrationStatus;
  connectionData: any;
}

interface DeleteIntegration {
  integrationId: string;
}

export const loaders = {
  getIntegrations: () => {
    return api<TableDataResponse<IIntegration>>({
      url: Endpoints.clientAdmin.integrations.root,
    });
  },
  getIntegration: ({ integrationId }: GetIntegration) => {
    return api<IIntegration>({
      url: Endpoints.clientAdmin.integrations.byId.replace(
        ':integrationId',
        integrationId,
      ),
    });
  },
  createIntegration: ({
    integrationModelId,
    parentCompanyId,
    name,
    connectionData,
  }: CreateIntegration) => {
    return api<{ webhookToken?: string }>({
      url: Endpoints.clientAdmin.integrations.root,
      data: {
        integrationTypeId: integrationModelId,
        parentCompanyId,
        name,
        connectionData,
      },
      method: 'post',
    });
  },
  updateIntegration: ({
    integrationId,
    name,
    status,
    connectionData,
  }: UpdateIntegration) => {
    return api({
      url: Endpoints.clientAdmin.integrations.byId.replace(
        ':integrationId',
        integrationId,
      ),
      data: {
        name,
        status,
        connectionData,
      },
      method: 'put',
    });
  },
  deleteIntegration: ({ integrationId }: DeleteIntegration) => {
    return api({
      url: Endpoints.clientAdmin.integrations.byId.replace(
        ':integrationId',
        integrationId,
      ),
      method: 'delete',
    });
  },
  getIntegrationModels: () => {
    return api<TableDataResponse<IIntegrationModel>>({
      url: Endpoints.clientAdmin.integrations.models.root,
    });
  },
  getIntegrationModel: ({ integrationModelId }: GetIntegrationModel) => {
    return api<IIntegrationModel>({
      url: Endpoints.clientAdmin.integrations.models.byId.replace(
        ':integrationModelId',
        integrationModelId,
      ),
    });
  },
  createIntegrationModel: ({
    name,
    type,
    comment,
    logoId,
  }: CreateIntegrationModel) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.integrations.models.root,
      data: { name, type, comment, logoId },
      method: 'post',
    });
  },
  updateIntegrationModel: ({
    integrationModelId,
    data,
  }: UpdateIntegrationModel) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.integrations.models.byId.replace(
        ':integrationModelId',
        integrationModelId,
      ),
      data,
      method: 'put',
    });
  },
  deleteIntegrationModel: ({ integrationModelId }: DeleteIntegrationModel) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.integrations.models.byId.replace(
        ':integrationModelId',
        integrationModelId,
      ),
      method: 'delete',
    });
  },
};
