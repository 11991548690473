import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  naked: {
    width: '100%',
  },
  emptyAnchor: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}));
