import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientDepositEventsList } from 'containers/admin';

const AdminClientDepositEventsPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientDepositEventsList />
    </AdminClientLayout>
  );
};

export default AdminClientDepositEventsPage;
