import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { useRendererOptions } from '@mp-react/table';

const KeyLabelMap = (props: CellProps<{}>) => {
  const { value } = props.cell;
  const { renderOptions } = useRendererOptions(props);
  const labelsMap = renderOptions?.valueMap;
  if (!Array.isArray(value) || !labelsMap) {
    return null;
  }
  const text = value.map((key) => labelsMap[key]).join(', ');
  return <Typography variant="body2">{text}</Typography>;
};

export default KeyLabelMap;
