import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IExpense } from 'types/income';
import { exportXlsxConfig, toCents } from 'utils/general';
import { RECOGNITION_SHOP_ID } from 'store/recognitions';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { ExpenseStatus, ExpensesFilters } from './expenses.types';

const getFilters = (filters: Partial<ExpensesFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  search: filters.search || undefined,
  filter: {
    companyId: filters.companies,
    departmentIds: filters.departments,
    employeeIds: filters.employees,
    benefitId:
      filters.source?.filter((source) => source !== RECOGNITION_SHOP_ID) ?? [],
    recognition: filters.source?.includes(RECOGNITION_SHOP_ID) || undefined,
    itemCategory: filters.categories,
    status: filters.status,
    type: filters.type,
    fromDate: filters.date?.from,
    toDate: filters.date?.to,
  },
});

interface GetExpenses {
  filters: Partial<ExpensesFilters>;
}

interface UpdateExpense {
  expenseId: string;
  status: ExpenseStatus;
  date: string;
  benefits: string[];
  recognition: boolean;
  shopCategories: ShopItemCategory[];
  amount: number;
  description: string;
}

interface UpdateStatusBulk {
  expenses: Array<{ id: string; status: ExpenseStatus }>;
}

interface ExportXls {
  filters: Partial<ExpensesFilters>;
}

export const loaders = {
  getExpenses: ({ filters }: GetExpenses) => {
    return api<TableDataResponse<IExpense>>({
      url: Endpoints.clientAdmin.expenses.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  updateExpense: ({
    expenseId,
    status,
    date,
    amount,
    benefits,
    recognition,
    description,
    shopCategories,
  }: UpdateExpense) => {
    return api({
      url: Endpoints.clientAdmin.expenses.byId.replace(':expenseId', expenseId),
      method: 'patch',
      data: {
        date,
        status,
        sourceBenefitIds: benefits,
        sourceRecognition: recognition,
        marketplaceItemCategories: shopCategories,
        expense: toCents(amount),
        description,
      },
    });
  },
  updateStatusBulk: ({ expenses }: UpdateStatusBulk) => {
    return api({
      url: Endpoints.clientAdmin.expenses.update.status.bulk,
      method: 'patch',
      data: {
        data: expenses.map((expense) => ({
          expenseId: expense.id,
          status: expense.status,
        })),
      },
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.expenses.export.xls,
      params: {
        ...getFilters(filters),
      },
      ...exportXlsxConfig,
    });
  },
};
