import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    dialogContentRoot: {
      overflow: 'visible',
      '& .MuiInputLabel-asterisk': {
        whiteSpace: 'nowrap',
      },
    },
    additionalError: {
      marginTop: 15,
    },
    loader: {
      position: 'absolute',
    },
    inputsDisable: {
      '& .MuiGrid-container': {
        '& > .MuiGrid-item.MuiGrid-grid-xs-12': {
          '& .MuiFormControl-root .MuiInputBase-root': {
            background: 'rgba(244, 248, 252, 1)',
            pointerEvents: 'none',
            '& input': {
              color: 'rgba(105, 113, 125, 1)',
            },
          },
        },
      },
    },
  }),
);
