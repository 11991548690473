import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientFeatures } from 'containers/admin';

const AdminClientFeaturesPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientFeatures />
    </AdminClientLayout>
  );
};

export default AdminClientFeaturesPage;
