import { Skeleton, TextField } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  label?: ReactNode;
}

const InputSkeleton = (props: Props) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: (
        <Skeleton sx={{ mt: '15px', width: '100%', maxWidth: '200px' }} />
      ),
      readOnly: true,
    }}
  />
);

export default InputSkeleton;
