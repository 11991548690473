import { converters as tableConverters } from 'store/table';
import {
  IAlternative,
  IAlternativeEmployeesGroup,
  IBaseAlternative,
} from 'types/income';
import { loaders } from './alternatives.loaders';
import {
  Alternative,
  AlternativeEmployeesGroup,
  BaseAlternative,
} from './alternatives.types';

const convertBaseAlternative = (
  alternative: IBaseAlternative,
): BaseAlternative => {
  return {
    id: alternative.id,
    name: alternative.name,
    isActive: alternative.isActive,
    assignments: alternative.assignments,
    benefits: alternative.benefits,
    selections: alternative.selections,
    missingSelections: alternative.missingSelections,
  };
};

const convertAlternative = (alternative: IAlternative): Alternative => {
  return {
    id: alternative.id,
    name: alternative.name,
    isActive: alternative.isActive,
    assignments: alternative.assignedEmployeesCount,
    benefits: NaN,
    selections: NaN,
    missingSelections: NaN,
    assignedGroupsCount: alternative.assignedGroupsCount,
    maxSelections: alternative.maxSelections,
    deadlineType: alternative.deadlineType,
    deadlineRange: alternative.deadlineRange,
    deadlineInDays: alternative.deadlineInDays,
  };
};

const convertBaseAlternatives = (
  alternatives: IBaseAlternative[],
): BaseAlternative[] => {
  return alternatives.map(convertBaseAlternative);
};

const convertEmployeesGroup = (
  employeesGroup: IAlternativeEmployeesGroup,
): AlternativeEmployeesGroup => {
  return {
    id: employeesGroup.id,
    name: employeesGroup.name,
    isAssigned: employeesGroup.isAssigned,
    employeesCount: Number(employeesGroup.employeesCount),
  };
};

const convertEmployeesGroups = (
  employeesGroups: IAlternativeEmployeesGroup[],
): AlternativeEmployeesGroup[] => {
  return employeesGroups.map(convertEmployeesGroup);
};

export const converters = {
  getAlternatives: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAlternatives>>) => {
    return convertBaseAlternatives(data.data);
  },
  getAlternative: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAlternative>>) => {
    return convertAlternative(data);
  },
  getEmployees: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployees>>) => {
    return data;
  },
  getEmployeesGroups: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeesGroups>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeesGroups(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
