import { Edit } from 'melp-design/icons';
import { styled } from '@mui/system';
import { Colors } from 'melp-design/style';
import { ButtonProps } from '../ButtonOld/Button';
import Button from '../ButtonOld/Button';

const PrimaryLightIconButton = styled(Button)({
  '&.EditIconButton': {
    padding: '11px 13px',

    '&:hover': {
      color: Colors.primary,
    },
  },
});

interface Props extends Omit<ButtonProps, 'textColor' | 'className' | 'sx'> {}

const EditIconButton = (props: Props) => {
  return (
    <PrimaryLightIconButton
      {...props}
      textColor="grey"
      className="EditIconButton"
    >
      <Edit style={{ width: 'auto', height: 16 }} />
    </PrimaryLightIconButton>
  );
};

export default EditIconButton;
