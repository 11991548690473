import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAutomation } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { AutomationsFilters, RuleValues } from './automations.types';
import { convertValuesToRule } from './automations.converters';

interface GetAutomations {
  filters: Partial<AutomationsFilters>;
}

interface GetAutomation {
  automationId: string;
}

interface CreateAutomation {
  name: string;
  enabled: boolean;
  rule: RuleValues;
}

interface UpdateAutomation {
  automationId: string;
  name?: string;
  enabled?: boolean;
  rule?: RuleValues;
}

interface ExecuteAutomation {
  automationId: string;
}

interface RemoveAutomation {
  automationId: string;
}

export const loaders = {
  getAutomations: ({ filters }: GetAutomations) => {
    return api<TableDataResponse<IAutomation>>({
      url: Endpoints.clientAdmin.automations.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
      },
    });
  },
  getAutomation: ({ automationId }: GetAutomation) => {
    return api<IAutomation>({
      url: Endpoints.clientAdmin.automations.byId.root.replace(
        ':automationId',
        automationId,
      ),
    });
  },
  createAutomation: ({ name, enabled, rule }: CreateAutomation) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.automations.root,
      data: {
        name,
        enabled,
        rule: convertValuesToRule(rule),
      },
      method: 'post',
    });
  },
  updateAutomation: ({
    automationId,
    name,
    enabled,
    rule,
  }: UpdateAutomation) => {
    return api({
      url: Endpoints.clientAdmin.automations.byId.root.replace(
        ':automationId',
        automationId,
      ),
      data: {
        name,
        enabled,
        rule: rule ? convertValuesToRule(rule) : undefined,
      },
      method: 'patch',
    });
  },
  executeAutomation: ({ automationId }: ExecuteAutomation) => {
    return api<{ subjectCount: number }>({
      url: Endpoints.clientAdmin.automations.byId.execute.replace(
        ':automationId',
        automationId,
      ),
      method: 'post',
    });
  },
  removeAutomation: ({ automationId }: RemoveAutomation) => {
    return api({
      url: Endpoints.clientAdmin.automations.byId.root.replace(
        ':automationId',
        automationId,
      ),
      method: 'delete',
    });
  },
};
