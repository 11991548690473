import { mutate } from 'swr';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import {
  MarketplaceItem,
  MarketplaceItemPriceCreationRequest,
  MarketplaceItemPricesResponse,
  MarketplaceItemPriceUpdateRequest,
  MarketplaceItemTranslationCreationRequestBody,
  MarketplaceItemTranslationUpdateRequestBody,
  MarketplaceItemUpdateRequestBody,
  PriceTranslation,
  PriceTranslationCreationRequest,
  PriceTranslationsResponse,
  PriceTranslationUpdateRequest,
} from '../types/MarketplaceItems';
import { useGet, useCustomSwrMutation } from '../utils/Api';
import { useLoading } from '../utils/Loading';

const useMarketplaceItem = (id: string) => {
  const url = Endpoints.melpAdmin.v2.marketplaceItems.byId(id).root;
  const { data, loading, mutate } = useGet<MarketplaceItem>(url);

  const update = useCustomSwrMutation<
    MarketplaceItemUpdateRequestBody,
    MarketplaceItem
  >(url, 'patch', { onSuccess: (response) => mutate(response) });

  const remove = useCustomSwrMutation<void>(url, 'delete');

  return {
    marketplaceItem: data,
    loading,
    update,
    remove,
    mutate,
  };
};

export const useMarketplaceItemTranslations = (itemId: string) => {
  const { marketplaceItem, loading, mutate } = useMarketplaceItem(itemId);

  const creationState = useLoading();
  const create = async (
    data: MarketplaceItemTranslationCreationRequestBody,
  ) => {
    creationState.startLoading();
    try {
      const response = await makeRequest(
        'post',
        Endpoints.melpAdmin.marketplaceItems.byId(itemId).translations,
        data,
      );
      mutate();
      return response.data as MarketplaceItem;
    } finally {
      creationState.stopLoading();
    }
  };

  const updateState = useLoading();
  const update = async (
    id: string,
    data: MarketplaceItemTranslationUpdateRequestBody,
  ) => {
    updateState.startLoading();
    try {
      const response = await makeRequest(
        'put',
        Endpoints.melpAdmin.marketplaceItems.translations.byId(id).root,
        data,
      );
      mutate();
      return response.data;
    } finally {
      updateState.stopLoading();
    }
  };

  const removeState = useLoading();
  const remove = async (id: string) => {
    removeState.startLoading();
    try {
      const response = await makeRequest(
        'delete',
        Endpoints.melpAdmin.marketplaceItems.translations.byId(id).root,
      );
      mutate();
      return response.data;
    } finally {
      removeState.stopLoading();
    }
  };

  return {
    translations: marketplaceItem?.translations,
    loading,
    create,
    creating: creationState.loading,
    update,
    updating: updateState.loading,
    remove,
    removing: removeState.loading,
  };
};

export const useItemPrices = (itemId: string) => {
  const url = Endpoints.melpAdmin.marketplaceItems.byId(itemId).prices.root;
  const { data, error, mutate, isLoading } =
    useGet<MarketplaceItemPricesResponse>(url);

  const create = useCustomSwrMutation<
    MarketplaceItemPriceCreationRequest,
    void
  >(url, 'post', {
    onSuccess: () => mutate(),
  });

  return {
    data,
    error,
    create,
    isLoading,
  };
};

export const useItemPrice = (itemId: string, priceId: string) => {
  const pricesUrls = Endpoints.melpAdmin.marketplaceItems.byId(itemId).prices;
  const pricesUrl = pricesUrls.root;
  const priceUrl = pricesUrls.byId(priceId).root;

  const commonOpts = {
    onSuccess: () => {
      mutate(pricesUrl);
    },
  };

  const update = useCustomSwrMutation<MarketplaceItemPriceUpdateRequest, void>(
    priceUrl,
    'patch',
    commonOpts,
  );

  const remove = useCustomSwrMutation<void, void>(
    priceUrl,
    'delete',
    commonOpts,
  );

  return {
    update,
    remove,
  };
};

export const usePriceTranslations = (itemId: string, priceId: string) => {
  const url = Endpoints.melpAdmin.marketplaceItems
    .byId(itemId)
    .prices.byId(priceId).translations.root;

  const { data, error, mutate } = useGet<PriceTranslationsResponse>(url);

  const create = useCustomSwrMutation<
    PriceTranslationCreationRequest,
    PriceTranslation
  >(url, 'post', {
    onSuccess: (response) => mutate([...(data ?? []), response]),
  });

  return {
    data,
    error,
    create,
  };
};

export const usePriceTranslation = (
  itemId: string,
  priceId: string,
  translationId: string,
) => {
  const translationsUrls = Endpoints.melpAdmin.marketplaceItems
    .byId(itemId)
    .prices.byId(priceId).translations;
  const translationsUrl = translationsUrls.root;
  const translationUrl = translationsUrls.byId(translationId).root;

  const commonOpts = {
    onSuccess: () => {
      mutate(translationsUrl);
    },
  };

  const update = useCustomSwrMutation<PriceTranslationUpdateRequest, void>(
    translationUrl,
    'patch',
    commonOpts,
  );

  const remove = useCustomSwrMutation<void, void>(
    translationUrl,
    'delete',
    commonOpts,
  );

  return {
    update,
    remove,
  };
};
