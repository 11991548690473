import { z } from 'zod';
import { DiscountStatus } from 'store/discounts';
import { UploadedFile } from 'store/files';
import { Translations } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { FrequencyUnit } from 'types/general';
import { adminDiscountsFiltersSchema } from './admin-discounts.schemas';

export type AdminDiscountTranslations = Translations<{
  title: string;
  teaser: string;
  description: string;
  promo: string;
  attachments: UploadedFile[];
}>;

export const discountPublishStatuses = ['published', 'unpublished'] as const;
export type DiscountPublishStatus = (typeof discountPublishStatuses)[number];

export const discountPackages = ['basic', 'premium', 'internal'] as const;
export type DiscountPackage = (typeof discountPackages)[number];

export interface BaseAdminDiscount {
  id: string;
  name: string;
  supplier: { id: string; name: string };
  priority: number;
  validTo: string | null;
  status: DiscountStatus;
  publishStatus: DiscountPublishStatus;
  viewsCount: number;
  favoritesCount: number;
  packages: DiscountPackage[];
}

export interface AdminDiscount extends BaseAdminDiscount {
  countries: string[];
  categories: ShopItemCategory[];
  comment: string;
  validFrom: string | null;
  createdAt: string;
  supplierContact: string;
  isSupplierContactPublic: boolean;
  code: string;
  link: string;
  companies: string[];
  codeImage: UploadedFile | null;
  image: UploadedFile | null;
  isUniqueCode: boolean;
  revealFrequencyLimit: FrequencyUnit | 'none';
  searchTags: string;
  publishedAt: string | null;
  translations: AdminDiscountTranslations;
}

export type AdminDiscountsFilters = z.infer<typeof adminDiscountsFiltersSchema>;
