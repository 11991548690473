import { TextFieldProps } from '@mui/material';
import { getBorder } from '../../../../constants/Style';
import { Effects, SystemColors } from '../../../style';
import InputSkeleton from '../../InputSkeleton/InputSkeleton';
import { TextField } from '../../TextField/TextField';
import { InternalFieldRenderer } from '../Types';

export type SelectFieldProps = TextFieldProps & {
  loading?: boolean;
};

export const renderSelectField: InternalFieldRenderer<SelectFieldProps> = (
  { loading, ...props },
  field,
  fieldState,
) => {
  if (loading) {
    return <InputSkeleton label={props.label} />;
  }
  return (
    <TextField
      select
      fullWidth
      error={fieldState.invalid}
      helperText={fieldState.message}
      {...field}
      {...props}
      SelectProps={{
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              border: getBorder(SystemColors.grey[90]),
              boxShadow: Effects.smallElementsShadow,
            },
            '& .MuiMenu-list': {
              padding: '5px',
            },
          },
        },
        ...props.SelectProps,
      }}
    />
  );
};
