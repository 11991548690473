import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { Search, TabUnselected } from '@mui/icons-material';
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {
  MetaContext,
  MpFilterProps,
  useFilterItems,
  useFilterOptions,
  useStoredState,
} from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CleanIcon } from '../../../../assets/icons/cross.svg';
import useStyles from './CustomSearchSelect.styles';

const empty: any[] = [];

export default function CustomSearchSelect({
  column,
  value = empty,
  setValue,
  doFiltering,
}: MpFilterProps) {
  const { mpColumn } = column;
  const { translations } = useContext(MetaContext);
  const [searchText, setSearchText] = useStoredState<string>(
    `custom-search-select-${column.mpColumn.key}`,
    '',
  );
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const {
    loading,
    items: asyncItems,
    getItems,
  } = useFilterItems(column, filter);
  const { t } = useTranslation();

  const items = useMemo(() => {
    if (!!asyncItems) return asyncItems;
    if (!filter?.items) return [];
    return filter?.items;
  }, [asyncItems, filter?.items]);

  const itemValues = useMemo(() => {
    if (!!asyncItems) {
      return asyncItems;
    }
    return items?.map((i) => i?.value ?? []);
  }, [asyncItems, items]);

  const allSelected = useMemo(
    () =>
      JSON.stringify(
        value?.filter((val: string) => val !== 'all_selected')?.sort(),
      ) === JSON.stringify(itemValues?.sort()),
    [itemValues, value],
  );

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        if (event.target.name === 'all') {
          setValue([...itemValues, 'all_selected']);
        } else if (!value?.includes(event.target.name)) {
          setValue([...(value ?? []), event.target.name]);
        }
      } else {
        if (event.target.name === 'all') {
          setValue(null);
        } else {
          setValue(value?.filter((val: any) => val !== event.target.name));
        }
      }
    },
    // eslint-disable-next-line
    [value],
  );

  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText],
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering],
  );

  useEffect(() => {
    getItems(searchText);
  }, [getItems, searchText]);

  useEffect(() => {
    setSearchText('');
  }, [setSearchText]);

  const NoSearchBox = useMemo(() => {
    if (loading && searchText?.length > 2) {
      return (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      );
    } else if (searchText.length < 3) {
      return (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
          maxWidth={250}
          margin="0 auto"
          textAlign="center"
        >
          <Typography>{t('common.start_search', { count: 3 })}</Typography>
        </Box>
      );
    } else if (!loading && (items?.length ?? 0) < 1) {
      return (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      );
    } else {
      return;
    }
  }, [loading, items, searchText, t]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingY={1}
        paddingX={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height={50}
      >
        <InputBase
          startAdornment={
            <Search
              className={`${classes.searchIcon} ${
                searchText.length > 0 && classes.activeSearchIcon
              }`}
              color="action"
            />
          }
          value={searchText}
          onChange={handleSearchText}
          placeholder={translations?.search ?? 'Search'}
          autoFocus={true}
          className={classes.naked}
        />
        <Box>
          {searchText.length > 0 ? (
            <IconButton onClick={() => setSearchText('')} size="large">
              <CleanIcon />
            </IconButton>
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={handleClear}
              className={classes.emptyAnchor}
            >
              {translations?.clear ?? 'Clear'}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      {!loading && (items?.length ?? 0) > 0 && searchText.length > 2 && (
        <Box
          paddingY="10px"
          maxHeight={300}
          overflow="auto"
          className={classes.selectWrapper}
        >
          <FormControl
            component="fieldset"
            color="primary"
            className={classes.fullWidth}
          >
            <FormGroup className={classes.fullWidth}>
              {!searchText && (
                <FormControlLabel
                  key="all-items"
                  onChange={handleChange}
                  name="all"
                  className={`${classes.listItem} ${
                    allSelected && classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSelected}
                      className={classes.checkbox}
                    />
                  }
                  label={`${translations?.all ?? 'All'} (${
                    itemValues?.length
                  })`}
                />
              )}
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={`${item?.value ?? item}`}
                  className={`${classes.listItem} ${
                    !!value?.includes(`${item?.value ?? item}`) &&
                    classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={!!value?.includes(`${item?.value ?? item}`)}
                      className={classes.checkbox}
                    />
                  }
                  label={`${item?.label ?? item}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {NoSearchBox}
    </Box>
  );
}
