import { forwardRef } from 'react';
import { Button } from 'melp-design/components';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  isLoading?: boolean;
}

const OptionsButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, isLoading }, forwardedRef) => {
    const { t } = useTranslation();

    return (
      <Button
        label={t('common.actions')}
        variant="neutral-outline"
        size="md"
        onClick={onClick}
        disabled={isLoading}
        ref={forwardedRef}
      />
    );
  },
);

OptionsButton.displayName = 'OptionsButton';

export default OptionsButton;
