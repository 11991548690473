import { useHistory } from 'react-router-dom';
import { useDebounce } from '@mp-react/table';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { ROUTES } from 'config';
import { Endpoints } from '../api/constants';
import { UseGlobalSearch } from '../types/GlobalSearch';
import { EntityTypes, GlobalSearchResponse } from './../types/GlobalSearch';

export const useGlobalSearch = (): UseGlobalSearch => {
  const history = useHistory();
  const debounce = useDebounce();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchQuery, setSearhQuery] = useState<string>('');
  const { data: resultsData, error: resultsError } = useSWR<
    GlobalSearchResponse,
    any
  >(!!searchQuery ? `${Endpoints.globalSearch}/${searchQuery}` : null);

  const results = useMemo(
    () =>
      resultsData?.searchedResults?.map((result) => {
        const benefitPlanType = result.benefitPlanType
          ?.split(/(?=[A-Z])/)
          .map((s) => s.toLowerCase())
          .join('_');
        return { ...result, benefitPlanType } ?? [];
      }),
    [resultsData?.searchedResults],
  );

  const loading = useMemo(
    () => !resultsData && !resultsError,
    [resultsData, resultsError],
  );

  const search = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setSearchInputValue(value);
      debounce(() => setSearhQuery(value));
    },
    [debounce],
  );

  const clearSearch = useCallback(() => {
    setSearhQuery('');
    setSearchInputValue('');
  }, []);

  const handleSearchItemClick = useCallback(
    (entityType: EntityTypes, entityId: string) => {
      clearSearch();
      switch (entityType) {
        case 'benefitPlan':
          history.push(`/benefits/view/${entityId}?tab=benefitsSettings`);
          break;
        case 'employee':
          history.push(
            `${ROUTES.employees.details.replace(
              ':id',
              entityId,
            )}?tab=innerEmployeeEmployeeInformation`,
          );
          break;
        case 'admin':
          history.push(
            `${ROUTES.administrators.details.replace(
              ':id',
              entityId,
            )}?tab=administratorsPermissions`,
          );
          break;
        case 'news':
          history.push('/');
          break;
        case 'employeeGroup':
          history.push(
            `${ROUTES.employeeGroups.details.replace(
              ':id',
              entityId,
            )}?tab=innerGroupAssignBenefits`,
          );
          break;
      }
    },
    [clearSearch, history],
  );

  return {
    loading,
    search,
    results,
    searchQuery,
    searchInputValue,
    clearSearch,
    handleSearchItemClick,
  };
};
