import { AutomationParameter, AutomationOperator } from './automations.types';

export const AUTOMATION_ID = 'automation';

export const AUTOMATION_OPERATOR_BY_CONDITION = {
  employeeBirthDate: ['isToday'] as const,
  employeeAge: ['containsNumbers', 'notContainsNumbers'] as const,
  employeeWorkDuration: ['equals'] as const,
  employeeEndDate: ['isBefore', 'isAfter'] as const,
  employeeCompany: ['containsStrings', 'notContainsStrings'] as const,
  employeeDepartment: ['containsStrings', 'notContainsStrings'] as const,
  employeeGroup: ['containsStrings', 'notContainsStrings'] as const,
  employeeCustomCategory: ['containsStrings', 'notContainsStrings'] as const,
  employeeStartDate: ['isToday'] as const,
} satisfies Record<AutomationParameter, Readonly<AutomationOperator[]>>;
