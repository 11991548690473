import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-shop.loaders';
import { converters } from './admin-shop.converters';
import { AdminShopItemsFilters } from './admin-shop.types';

export const adminShopKeys = queryKeyFactory('admin-shop');

export const useAdminShopItems = (
  filters: Partial<AdminShopItemsFilters> = {},
) => {
  return useQuery({
    queryKey: adminShopKeys.list(filters),
    queryFn: () => loaders.getShopItems({ filters }),
    select: converters.getShopItems,
    keepPreviousData: true,
  });
};

export const useAdminShopItem = (shopItemId: string) => {
  return useQuery({
    queryKey: adminShopKeys.detail(shopItemId),
    queryFn: () => loaders.getShopItem({ shopItemId }),
    select: converters.getShopItem,
    enabled: !!shopItemId,
  });
};

export const useAdminShopItemsInfo = () => {
  return useQuery({
    queryKey: adminShopKeys.list('info'),
    queryFn: () => loaders.getShopItemsInfo(),
    select: converters.getShopItemsInfo,
  });
};
