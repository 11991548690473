import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    formControl: {
      '& .MuiInputBase-root': {
        minHeight: 44,
      },

      '& .MuiAutocomplete-popupIndicator': {
        padding: '5px',
      },
    },
    placeholder: {
      top: -6,
      '&.MuiInputLabel-shrink': {
        display: 'none',
      },
    },
  }),
);
