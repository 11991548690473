import { ReactNode, MouseEvent, useState } from 'react';
import { Box, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { Badge } from 'melp-design/components';
import { calculateFiltersTotal } from 'utils/Filters';
import { TriggerButton } from './atoms';

interface FilterButtonRenderProps<T> {
  applyFilter: (value?: T) => void;
  value?: T;
  clearFilter: () => void;
  close: () => void;
}

interface Props<T extends any> {
  label: string;
  value?: T;
  initialValue?: T;
  onChange?: (value?: T) => void;
  onClose?: () => void;
  /**
   * Render filter content. If not set, component assumes that this is a
   * flag-type filter, i.e., clicking on filter button leads to setting
   * filter value to true and clicking on it again - to undefined.
   */
  children?: (props: FilterButtonRenderProps<T>) => ReactNode;
  /**
   * Disables react portal to allow displaying popper within modal.
   */
  disablePortal?: boolean;
}

export const FilterButton = <T extends any>({
  value,
  initialValue,
  onChange,
  label,
  children,
  disablePortal,
  onClose,
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const id = open ? 'filter-popover' : undefined;
  const appliedValuesCount = calculateFiltersTotal({ value });
  const isActive = Boolean(appliedValuesCount);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleApplyFilter = (newValue?: T) => {
    onChange?.(newValue);
    handleClose();
  };

  const handleClearFilter = () => {
    onChange?.(initialValue);
    handleClose();
  };

  const handleTrigger = (event: MouseEvent<HTMLButtonElement>) => {
    if (children) {
      setAnchorEl(event.currentTarget);
      setOpen((prevState) => !prevState);
    } else {
      handleApplyFilter(!value ? (true as T) : undefined);
    }
  };

  return (
    <>
      <Badge
        badgeContent={appliedValuesCount}
        invisible={!isActive}
        sx={{ '.MuiBadge-badge': { right: 3 } }}
      >
        <TriggerButton
          label={label}
          onClick={handleTrigger}
          isActive={isActive}
        />
      </Badge>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={{ 'z-index': 1500 }}
        disablePortal={disablePortal}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Box mt={1} mr={1}>
              <Paper elevation={8}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ minWidth: 100, maxWidth: 300 }}>
                    {children?.({
                      value,
                      applyFilter: handleApplyFilter,
                      clearFilter: handleClearFilter,
                      close: handleClose,
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  );
};
