import {
  DateInput,
  FileInput,
  Modal,
  NumberInput,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { Stack } from '@mui/material';
import { useAdminShopItem } from 'store/admin-shop';
import { getSinglePriceLabel } from 'components/marketplace/ItemCard/common/getPriceLabel';
import { useImportVouchersFiles } from 'store/admin-shop-vouchers';

interface Values {
  shopItemPriceId: string;
  purchasePrice: number;
  purchaseReference: string;
  expiresAt: string | null;
  files: File[];
}

const INITIAL_VALUES: Values = {
  shopItemPriceId: '',
  purchasePrice: NaN,
  purchaseReference: '',
  expiresAt: null,
  files: [],
};

interface Props extends ModalProps {
  shopItemId: string;
}

export const UploadVoucherFilesModal: FC<Props> = ({
  shopItemId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const { data: shopItem } = useAdminShopItem(shopItemId);

  const { mutate: importVouchersFiles, isLoading: isImporting } =
    useImportVouchersFiles();

  const onSubmit: SubmitHandler<Values> = (values) => {
    importVouchersFiles(
      {
        shopItemId,
        files: values.files,
        shopItemPriceId: values.shopItemPriceId,
        purchaseReference: values.purchaseReference,
        purchasePrice: values.purchasePrice,
        expiresAt: values.expiresAt,
      },
      {
        onSuccess: () => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('global.actions.upload_filetypes', {
          fileTypes: ['.pdf', '.png'].join(', '),
        })}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isImporting,
          },
        ]}
      >
        <Stack gap="20px">
          <Controller
            name="shopItemPriceId"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <SelectInput
                label={t('marketplace.ordersTablePrice')}
                name={name}
                value={value}
                onChange={onChange}
                options={
                  shopItem?.prices
                    .filter((price) => price.priceType === 'fixedPrice')
                    .map((price) => ({
                      label: getSinglePriceLabel(price, shopItem.currency),
                      value: price.id,
                    })) ?? []
                }
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="purchasePrice"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('shop.vouchers.purchasePrice.label')}
                name={name}
                value={value}
                onChange={onChange}
                required
                key={value}
                InputProps={{
                  endAdornment: 'EUR',
                }}
                ref={ref}
              />
            )}
          />

          <Controller
            name="purchaseReference"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.vouchers.purchaseReference.label')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="expiresAt"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <DateInput
                label={t('alternatives.employeeExpirationDate')}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
              />
            )}
          />

          <Controller
            name="files"
            control={control}
            rules={{ required: true }}
            render={({ field: { name, onChange, ref } }) => (
              <FileInput
                name={name}
                onChange={onChange}
                accept={['pdf', 'png']}
                maxFiles={Infinity}
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
