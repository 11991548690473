import {
  useForm,
  FieldValues,
  SubmitHandler,
  FormProvider,
  UnpackNestedValue,
  DeepPartial,
} from 'react-hook-form';
import { ReactNode } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ReadonlyFormContext } from './ReadonlyContext';

interface Props<FormData extends FieldValues> {
  children: ReactNode | ((form: UseFormMethods<FormData>) => ReactNode);
  id?: string;
  defaultValues?: UnpackNestedValue<DeepPartial<FormData>>;
  onSubmit?: SubmitHandler<FormData>;
  /**
   * Makes all form fields disabled.
   */
  readOnly?: boolean;
}

const Form = <FormData extends FieldValues>({
  children,
  onSubmit = () => {},
  id,
  ...props
}: Props<FormData>) => {
  const { ...methods } = useForm<FormData>({
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <ReadonlyFormContext.Provider value={props.readOnly ?? false}>
        <form onSubmit={methods.handleSubmit(onSubmit)} id={id}>
          {typeof children === 'function' ? children(methods) : children}
        </form>
      </ReadonlyFormContext.Provider>
    </FormProvider>
  );
};

export default Form;
