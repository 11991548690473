import { MpFormType } from '@mp-react/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useGlobalPermissionsForm } from '../configs/Forms/GlobalPermissionsForm';
import { useLocalPermissionsForm } from '../configs/Forms/LocalPermissionsForm';
import { PermissionLevels } from '../constants/Administrators';
import {
  LocalPermissionsRequestItem,
  Permission,
  PermissionInRequest,
} from '../types/Administrators';
import { objectLength } from './Common';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionOptions,
} from './../constants/Administrators';

type FormChangesType = FormData | object;
type FormChangesKey = keyof (FormData | object);

export const usePermissionUtils = () => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<string>('allGlobalPermissions');
  const [defaultFormValues, setDefaultFormValues] = useState<FormData | null>(
    null,
  );
  const [formChanges, setFormChanges] = useState<FormChangesType>({});
  const formMethods = useForm();
  const { watch, reset, handleSubmit, setValue } = formMethods;
  const [level, setLevel] = useState<PermissionLevels>(PermissionLevels.Global);
  const globalForm = useGlobalPermissionsForm();
  const localForm = useLocalPermissionsForm();
  const [touchedCompanies, setTouchedCompanies] = useState<string[]>([]);

  const isAllLocal = useMemo(
    () => selectedId === 'allLocalPermissions',
    [selectedId],
  );
  const isAllGlobal = useMemo(
    () => selectedId === 'allGlobalPermissions',
    [selectedId],
  );
  const fieldValues = useMemo(() => watch() as any, [watch]);

  const form = useMemo(
    (): MpFormType =>
      level === PermissionLevels.Global || isAllGlobal ? globalForm : localForm,
    [globalForm, level, localForm, isAllGlobal],
  );

  const formTitle = useMemo((): string => {
    if (!selectedId) return t('administrators.select_permission');
    return level === PermissionLevels.Global
      ? t('administrators.global_permissions')
      : t('administrators.local_permissions');
  }, [level, selectedId, t]);

  const permissionData = useMemo(
    () => ({ ...formChanges, [selectedId]: fieldValues }),
    [fieldValues, formChanges, selectedId],
  );

  const permissionDataIds = useMemo(() => {
    return Object.keys(formChanges);
  }, [formChanges]);

  const wasChanged = useMemo(
    () => permissionDataIds.includes(selectedId),
    [permissionDataIds, selectedId],
  );

  const hasChanges = useMemo(
    () => !!touchedCompanies?.length,
    [touchedCompanies?.length],
  );

  const getOnlyChangedFields = useCallback(
    (
      defValues: FormChangesType,
      newValues: FormChangesType,
      touched: FormChangesType,
    ) => {
      const touchedFields = !!touched ? Object.keys(touched) : [];
      let modified = {};
      for (const [key, value] of Object.entries(newValues)) {
        if (
          (!!defValues && defValues[key as FormChangesKey] !== value) ||
          touchedFields?.includes(key)
        ) {
          modified = { ...modified, [key]: value };
        }
      }
      return modified;
    },
    [],
  );

  const modifiedFields = useMemo(() => {
    return getOnlyChangedFields(
      { ...defaultFormValues },
      fieldValues,
      formChanges[selectedId as FormChangesKey],
    );
  }, [
    defaultFormValues,
    fieldValues,
    formChanges,
    getOnlyChangedFields,
    selectedId,
  ]);
  const modifiedFieldsCount = useMemo(
    () => objectLength(modifiedFields),
    [modifiedFields],
  );

  const currentFormHasChanges = useMemo(() => {
    return (
      !!selectedId &&
      !!defaultFormValues &&
      !!objectLength(defaultFormValues) &&
      !!objectLength(fieldValues) &&
      modifiedFieldsCount > 0
    );
  }, [defaultFormValues, fieldValues, modifiedFieldsCount, selectedId]);

  const updateFieldsWithNewValues = useCallback(
    (stateValues: FormChangesType, newValues: FormChangesType) => {
      let updatedValues = {};
      for (const [key, value] of Object.entries(stateValues)) {
        const mergedValues =
          key !== 'allGlobalPermissions'
            ? Object.assign({ ...value }, newValues)
            : value;
        updatedValues = { ...updatedValues, [key]: mergedValues };
      }
      return updatedValues;
    },
    [],
  );

  const updateFormChanges = useCallback(() => {
    const hasNewChanges = !isEqual(
      formChanges[selectedId as FormChangesKey],
      modifiedFields,
    );
    if (!!modifiedFieldsCount && hasNewChanges) {
      if (isAllLocal) {
        setFormChanges((prevState) => {
          const updatedPrevState = updateFieldsWithNewValues(
            prevState,
            modifiedFields,
          );
          return { ...updatedPrevState, [selectedId]: modifiedFields };
        });
      } else {
        setFormChanges((prevState) => {
          return { ...prevState, [selectedId]: fieldValues };
        });
      }
    }
  }, [
    fieldValues,
    formChanges,
    isAllLocal,
    modifiedFields,
    modifiedFieldsCount,
    selectedId,
    updateFieldsWithNewValues,
  ]);

  const getActualFormValues = useCallback(
    (defaultValues: FormData, changed: FormChangesType, id: string) => {
      if (Object.keys(changed).includes(id)) {
        return Object.assign(
          { ...defaultValues },
          changed[id as FormChangesKey],
        );
      } else if (Object.keys(changed).includes('allLocalPermissions')) {
        return Object.assign(
          { ...defaultValues },
          changed['allLocalPermissions' as FormChangesKey],
        );
      } else return defaultValues;
    },
    [],
  );

  const handlePermissionClick = useCallback(
    (level: PermissionLevels, id: string) => {
      setDefaultFormValues(null);
      setLevel(level);
      setSelectedId(id);
    },
    [],
  );

  const overrideWithAllLocalPermissions = useCallback(
    (id: string, formData: object) => {
      setFormChanges((prevState) => {
        const cleanedState = !!(prevState as any)?.allGlobalPermissions
          ? {
              allGlobalPermissions: (prevState as any)?.allGlobalPermissions,
            }
          : {};
        return { ...cleanedState, [id]: formData };
      });
    },
    [],
  );

  const handleSetAllPermissions = useCallback(
    (permission: string) => {
      const moduleKeys = Object.values(
        level === 'global' ? GlobalPermissionModules : LocalPermissionModules,
      );
      const formData = moduleKeys.reduce((acc, permissionKey) => {
        return {
          ...acc,
          [permissionKey]: permission,
        };
      }, {});

      if (selectedId === 'allLocalPermissions') {
        overrideWithAllLocalPermissions(selectedId, formData);
      }
      Object.entries(formData).forEach(([key, value]) => {
        setValue(key, value);
      });
      setTouchedCompanies((prev) => [...prev, selectedId]);
    },
    [level, overrideWithAllLocalPermissions, selectedId, setValue],
  );

  const removeChanges = useCallback(() => {
    reset(defaultFormValues ?? {});
    setFormChanges({});
    setSelectedId('allGlobalPermissions');
    setTouchedCompanies([]);
  }, [defaultFormValues, reset]);

  useEffect(() => {
    if (currentFormHasChanges) {
      setTouchedCompanies((prev) => {
        if (prev?.includes(selectedId)) return prev;
        return [...prev, selectedId];
      });
      updateFormChanges();
    }
    //eslint-disable-next-line
  }, [modifiedFields, selectedId, currentFormHasChanges]);

  /****** STATE Functions *******/
  const parsePermissionsFormValues = useCallback(
    (permissions: Permission[], isRoot: boolean) => {
      if (isRoot) {
        permissions?.forEach(
          (item: Permission) => (item.permission = PermissionOptions.Edit),
        );
      }

      return permissions.reduce((acc: any, permissionItem: Permission) => {
        const isAllLocal = permissionItem.level === PermissionLevels.AllLocal;
        return {
          ...acc,
          [permissionItem.module]: isAllLocal
            ? null
            : permissionItem.permission,
        };
      }, {});
    },
    [],
  );

  const getParsedPermissions = useCallback(
    (permissions: Permission[], isRoot: boolean) => {
      if (!!formChanges[selectedId as FormChangesKey])
        return formChanges[selectedId as FormChangesKey];
      if (!!permissions) return parsePermissionsFormValues(permissions, isRoot);
      return {};
    },
    [formChanges, parsePermissionsFormValues, selectedId],
  );

  const parsePermissionEntry = useCallback(
    (permissionEntry: [string, any][] | undefined): PermissionInRequest[] => {
      if (!permissionEntry) return [];
      const requestPermissions = permissionEntry.map(([, value]) => {
        const moduleEntries = Object.entries(value);
        return moduleEntries.map(([module, permission]) => ({
          module: module as LocalPermissionModules | GlobalPermissionModules,
          permission: permission as PermissionOptions,
        }));
      });

      return requestPermissions.flat();
    },
    [],
  );

  const getAllLocalPermissions = useCallback(
    (
      localEntries: [string, any] | undefined,
    ): LocalPermissionsRequestItem[] => {
      if (!localEntries) return [];
      const updatedModules = parsePermissionEntry([localEntries]);
      const nonEmptyModules = updatedModules.filter(
        (module) => !!module.permission,
      );
      return [
        {
          modules: nonEmptyModules,
        },
      ];
    },
    [parsePermissionEntry],
  );

  const getCompanyGroupPermissions = useCallback(
    (
      companyGroupEntries: any[][] | undefined,
    ): LocalPermissionsRequestItem[] => {
      if (!companyGroupEntries) return [];
      return companyGroupEntries.map(([id, value]) => {
        const updatedModules = parsePermissionEntry([[id, value]]);
        return {
          companyGroupId: id,
          modules: updatedModules,
        };
      });
    },
    [parsePermissionEntry],
  );

  const getCompanyPermissions = useCallback(
    (companyEntries: any[][] | undefined): LocalPermissionsRequestItem[] => {
      if (!companyEntries) return [];
      return companyEntries.map(([id, value]) => {
        const updatedModules = parsePermissionEntry([[id, value]]);
        return {
          companyId: id,
          modules: updatedModules,
        };
      });
    },
    [parsePermissionEntry],
  );

  return {
    form,
    formTitle,
    handlePermissionClick,
    selectedId,
    formMethods,
    hasChanges,
    removeChanges,
    handleSubmit,
    formChanges,
    permissionData,
    permissionDataIds,
    handleSetAllPermissions,
    wasChanged,
    parsePermissionEntry,
    getAllLocalPermissions,
    getCompanyGroupPermissions,
    getCompanyPermissions,
    getParsedPermissions,
    setDefaultFormValues,
    getActualFormValues,
  };
};
