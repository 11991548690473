import { useState, RefObject, useLayoutEffect } from 'react';

export const useBoundingClientRect = <T extends HTMLElement>(
  node: RefObject<T>['current'],
) => {
  const [rect, setRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    const setPosition = () => setRect(node?.getBoundingClientRect() ?? null);

    setPosition();

    window.addEventListener('resize', setPosition);
    return () => {
      window.removeEventListener('resize', setPosition);
    };
  }, [node]);

  return rect;
};
