import { ComponentPropsWithRef, FC, PropsWithChildren } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';

interface Props {
  title?: string;
  actions?: Array<ComponentPropsWithRef<typeof Button>>;
  plain?: boolean;
}

export const ModalContent: FC<PropsWithChildren<Props>> = ({
  title,
  actions = [],
  plain,
  children,
}) => {
  return (
    <>
      {plain ? null : title ? (
        <DialogTitle>{title}</DialogTitle>
      ) : (
        <div style={{ height: 30 }} />
      )}

      <DialogContent sx={{ ...(plain ? { p: 0 } : {}) }}>
        {children}
      </DialogContent>

      {actions.length ? (
        <DialogActions
          sx={{
            p: 3,
            py: 2,
            position: 'sticky',
            bottom: 0,
            background: SystemColors.white,
          }}
        >
          {actions.map((action, i) => (
            <Button key={i} {...action} />
          ))}
        </DialogActions>
      ) : null}
    </>
  );
};
