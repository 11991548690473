import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IFile } from 'types/income';
import { getFormData } from 'utils/general';

interface GetFile {
  fileId: string;
}

interface GetFiles {
  fileIds: string[];
}

interface UploadFile {
  file: File;
}

export const loaders = {
  getFile: ({ fileId }: GetFile) => {
    return api<IFile>({
      url: Endpoints.files.byId.replace(':fileId', fileId),
    });
  },
  getFiles: ({ fileIds }: GetFiles) => {
    return Promise.all(
      fileIds.map((fileId) => loaders.getFile({ fileId })),
    ).then((res) => ({
      data: res.map(({ data }) => data).flat(),
    }));
  },
  uploadFile: ({ file }: UploadFile) => {
    return api<IFile>({
      url: Endpoints.files.upload,
      method: 'post',
      data: getFormData([{ field: 'file', value: file }]),
    });
  },
};
