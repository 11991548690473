import { FC } from 'react';
import { DateTimeInput, Modal } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { useAdminCreateDepositEvent } from 'store/admin-client-deposits';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { ExpenseType } from 'store/expenses';

interface Values {
  from: string;
  to: string | null;
}

interface Props extends ModalProps {
  clientId: string;
  expenseType: ExpenseType;
}

export const CreateDepositEventModal: FC<Props> = ({
  clientId,
  expenseType,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      from: '',
      to: null,
    },
  });

  const { mutate: createDepositEvent, isLoading: isCreating } =
    useAdminCreateDepositEvent();

  const onSubmit: SubmitHandler<Values> = (values) => {
    createDepositEvent(
      {
        clientId,
        expenseType,
        from: values.from,
        to: values.to ?? undefined,
      },
      {
        onSuccess: () => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('alternatives.dateRange')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.close'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: !isDirty || isCreating,
          },
        ]}
      >
        <Stack gap={4}>
          <Stack gap={2}>
            <Controller
              name="from"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <DateTimeInput
                  label={t('common.from')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  ref={ref}
                />
              )}
            />
            <Controller
              name="to"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <DateTimeInput
                  label={t('common.to')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Stack>
        </Stack>
      </Modal.Content>
    </form>
  );
};
