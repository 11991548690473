const blue = '#19B0F2';
const lightBlue = '#EFF8FF';

export const Colors = {
  black: '#1E1F23',
  grey: '#828896',
  greyHover: '#B9BDC4',
  elementsGrey: '#E0E5EC',
  screenBackgroundGrey: '#F4F5F6',
  lightBackgroundGrey: '#FAFAFA',
  white: '#FFFFFF',
  white80: 'rgba(255, 255, 255, 0.8)',
  blue,
  blueHover: '#038BC6',
  blueSelected: '#016C9A',
  lightBlue,
  green: '#52CE3E',
  red: '#FF141D',
  yellowDarker: '#DD9F00',
  yellow: '#F3AF00',
  lightYellow: '#FFF4CE',
  primaryLight: lightBlue,
  primaryLightest: '#f6fbff',
  primary: blue,
  primaryDark: '#038BC6',
  primaryDarkest: '#016C9A',
};

export const SystemColors = {
  white: '#FFFFFF',
  black: '#1E1F23',
  primary: {
    base: '#19B0F2',
    shade: '#038BC6',
    tint: '#EFF8FF',
  },
  grey: {
    45: '#69707D',
    55: '#828896',
    75: '#B9BDC4',
    90: '#E0E5EC',
    96: '#F4F5F6',
    98: '#FAFAFA',
  },
  success: {
    base: '#52CE3E',
  },
  danger: {
    base: '#FF141D',
    shade: '#E90A13',
  },
  warning: {
    base: '#F3AF00',
    tint: '#FFF4CE',
  },
};
