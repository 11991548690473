import { z } from 'zod';
import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { orderVariants } from './orders.types';

export const ordersFiltersSchema = z
  .object({
    employees: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    suppliers: z.array(z.string()).catch([]),
    items: z.array(z.string()).catch([]),
    orders: z.array(z.string()).catch([]),
    status: z.array(z.string()).catch([]),
    variant: z.enum(orderVariants).catch('all'),
    createdAt: dateRangeSchema,
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
