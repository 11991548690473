import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './integrations.loaders';
import { converters } from './integrations.converters';

export const integrationsKeys = queryKeyFactory('integrations');

export const useIntegrations = () => {
  return useQuery({
    queryKey: integrationsKeys.list(),
    queryFn: () => loaders.getIntegrations(),
    select: converters.getIntegrations,
  });
};

export const useIntegration = (integrationId: string) => {
  return useQuery({
    queryKey: integrationsKeys.detail(integrationId),
    queryFn: () => loaders.getIntegration({ integrationId }),
    select: converters.getIntegration,
    enabled: !!integrationId,
  });
};

export const useIntegrationModels = () => {
  return useQuery({
    queryKey: integrationsKeys.list('models'),
    queryFn: () => loaders.getIntegrationModels(),
    select: converters.getIntegrationModels,
  });
};

export const useIntegrationModel = (integrationModelId: string) => {
  return useQuery({
    queryKey: integrationsKeys.detail('models', integrationModelId),
    queryFn: () => loaders.getIntegrationModel({ integrationModelId }),
    select: converters.getIntegrationModel,
    enabled: !!integrationModelId,
  });
};
