import { FC } from 'react';
import { Link as MuiLink } from '@mui/material';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { Typography } from 'melp-design/components';

interface Props extends LinkProps {
  label: string | number;
}

export const Link: FC<Props> = ({ label, ...linkProps }) => {
  return (
    <MuiLink component={RouterLink} underline="always" {...linkProps}>
      <Typography>{label}</Typography>
    </MuiLink>
  );
};
