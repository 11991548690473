import { UploadedFile } from 'store/files';

export const integrationStatuses = ['active', 'inactive'] as const;

export type IntegrationStatus = (typeof integrationStatuses)[number];

export const integrationModelStatuses = ['active', 'inactive'] as const;

export type IntegrationModelStatus = (typeof integrationModelStatuses)[number];

export const integrationVariants = [
  'vikarina',
  'navision',
  'bamboo-hr',
  'agroup',
  'hibob',
  'hcm',
  'webhook',
] as const;

export type IntegrationVariant = (typeof integrationVariants)[number];

export interface IntegrationModel {
  id: string;
  name: string;
  status: IntegrationModelStatus;
  variant: IntegrationVariant;
  comment: string;
  logo: UploadedFile | null;
  createdAt: string;
}

export interface Integration {
  id: string;
  name: string;
  parentCompanyId: string;
  status: IntegrationStatus;
  model: IntegrationModel;
}
