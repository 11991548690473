import { z } from 'zod';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { expensesFiltersSchema } from './expenses.schemas';

export const expenseStatuses = ['inProgress', 'completed', 'paid'] as const;
export type ExpenseStatus = (typeof expenseStatuses)[number];

export const expenseTypes = [
  'manual',
  'shopOrder',
  'companyItems',
  'claimReimbursement',
] as const;
export type ExpenseType = (typeof expenseTypes)[number];

export interface Expense {
  id: string;
  date: string;
  description: string;
  employee: { id: string; fullName: string } | null;
  source: Array<{ id: string; name: string }>;
  categories: ShopItemCategory[];
  amount: number;
  status: ExpenseStatus;
  type: ExpenseType;
}

export type ExpensesFilters = z.infer<typeof expensesFiltersSchema>;

export interface ExpenseSource {
  id: string;
  name: string;
}

export interface ExpenseSourcesFilters {
  name: string;
}
