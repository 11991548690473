import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import { InternalFieldRenderer } from '../Types';

export const renderRadioGroupField: InternalFieldRenderer = (
  props,
  { label, ...field },
  { message, ...fieldState },
) => (
  <FormControl fullWidth error={fieldState.invalid}>
    {label && <FormLabel>{label}</FormLabel>}
    <RadioGroup {...field} {...props} />
    {message && <FormHelperText>{message}</FormHelperText>}
  </FormControl>
);
