import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './discounts.loaders';
import { converters } from './discounts.converters';
import { DiscountsFilters } from './discounts.types';

export const discountsKeys = queryKeyFactory('discounts');

export const useDiscounts = (filters: Partial<DiscountsFilters> = {}) => {
  return useQuery({
    queryKey: discountsKeys.list(filters),
    queryFn: () => loaders.getDiscounts({ filters }),
    select: converters.getDiscounts,
  });
};

export const useInfiniteDiscounts = (
  filters: Partial<DiscountsFilters> = {},
) => {
  const query = useInfiniteQuery(
    discountsKeys.list('infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getDiscounts({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getDiscounts(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );

  return query;
};
