import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from '@mp-react/form';
import { Box, Button, Popover, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBenefitStore } from '../../../state/Benefits';
import { useEmployeeStore } from '../../../state/Employees';
import { CustomFormControl, Timezone } from '../../../types/Common';
import { getTimezoneFromDate } from '../../../utils/Common';
import useTooltip from '../../../utils/Tooltip';
import TimezoneMenu from '../../common/DateTimePicker/TimezoneMenu/TimezoneMenu';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import useStyles from './Time.styles';

const inputLabelProps = { shrink: true };

function TimeView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultTZ = useMemo(() => moment().utcOffset() / 60, []);
  const tz = useMemo(
    () => (!!value ? getTimezoneFromDate(value) : defaultTZ),
    [defaultTZ, value],
  );
  const tzSign = useMemo(() => (tz >= 0 ? '+' : ''), [tz]);
  const [selectedZone, setSelectedZone] = useState<Timezone>({
    value: tz,
    label: `GMT${tzSign}${tz}`,
  });
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const errorText = useMemo(
    () => (firstMessage ?? hasError ? t('form.pattern') : undefined),
    [firstMessage, hasError, t],
  );
  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control],
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control],
  );
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const {
    setDeactivationDate,
    setPublishDate,
    setVotingEndDate,
    setVotingStartDate,
    setActivationDate,
  } = useBenefitStore();
  const { setStartDate, setEndDate } = useEmployeeStore((state) => state);

  const [inputValue, setInputValue] = useState(
    !value ? null : moment.parseZone(value),
  );

  useEffect(() => {
    const timeValue = !value ? null : moment.parseZone(value);
    if (
      (inputValue && !inputValue.isSame(timeValue)) ||
      (timeValue && !timeValue.isSame(inputValue))
    ) {
      setInputValue(timeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const valueIsValid = moment(inputValue).isValid();

  const handleBlur = () => {
    // If input value is not a valid date, reset form value to avoid incorrect
    // data being entered.
    if (inputValue && !valueIsValid) {
      // If dorm value is already null then useEffect above won't be called.
      // In this case we need to update only input value.
      if (!value) {
        setInputValue(null);
      } else {
        onChange(null);
      }
    }
  };

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleTimezoneChange = useCallback(
    (timezone: Timezone) => {
      if (valueIsValid) {
        setSelectedZone(timezone);
        const tzTime = moment
          .parseZone(value)
          .utcOffset(timezone.value, true)
          .format();
        onChange(tzTime);
        switch (control?.key) {
          case 'publishTime':
            setPublishDate(tzTime, 'time');
            break;
          case 'activationTime':
            setActivationDate(tzTime, 'time');
            break;
          case 'deactivationTime':
            setDeactivationDate(tzTime, 'time');
            break;
          case 'startTime':
            setStartDate(tzTime, 'time');
            break;
          case 'endTime':
            setEndDate(tzTime, 'time');
            break;
          case 'votingStartTime':
            setVotingStartDate(tzTime, 'time');
            break;
          case 'votingEndTime':
            setVotingEndDate(tzTime, 'time');
            break;
        }
        setOpen(false);
      }
    },
    [
      valueIsValid,
      value,
      onChange,
      control?.key,
      setPublishDate,
      setActivationDate,
      setDeactivationDate,
      setStartDate,
      setEndDate,
      setVotingStartDate,
      setVotingEndDate,
    ],
  );

  const handleTimeChange = useCallback(
    (time: Moment | null) => {
      const timeString = moment(time).isValid()
        ? moment(time).utcOffset(selectedZone.value, true).format()
        : null;
      if (moment(time).isValid()) {
        onChange(timeString);
        setHasError(false);
        switch (control?.key) {
          case 'publishTime':
            setPublishDate(timeString, 'time');
            break;
          case 'activationTime':
            setActivationDate(timeString, 'time');
            break;
          case 'deactivationTime':
            setDeactivationDate(timeString, 'time');
            break;
          case 'startTime':
            setStartDate(timeString, 'time');
            break;
          case 'endTime':
            setEndDate(timeString, 'time');
            break;
          case 'votingStartTime':
            setVotingStartDate(timeString, 'time');
            break;
          case 'votingEndTime':
            setVotingEndDate(timeString, 'time');
            break;
        }
      } else {
        setInputValue(time);
      }
    },
    [
      selectedZone.value,
      onChange,
      control?.key,
      setPublishDate,
      setActivationDate,
      setDeactivationDate,
      setStartDate,
      setEndDate,
      setVotingStartDate,
      setVotingEndDate,
    ],
  );

  const TimezoneButton = useMemo(() => {
    return (
      <Button
        onClick={handleToggle}
        disabled={!valueIsValid}
        className={classes.tzButton}
        disableRipple
        ref={anchorRef}
        color="info"
      >
        <Typography variant="body2">{selectedZone.label}</Typography>
      </Button>
    );
  }, [classes.tzButton, handleToggle, selectedZone.label, valueIsValid]);

  useEffect(() => {
    if (!!value && typeof value === 'string') {
      setSelectedZone({
        value: tz,
        label: `GMT${tzSign}${tz}`,
      });
    }
  }, [tz, tzSign, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box position="relative">
        <TimePicker
          ampm={false}
          value={inputValue}
          slotProps={{
            textField: {
              error: !!error || hasError,
              helperText: errorText,
              name: control.key,
              required: control.required,
              placeholder: control.placeholder,
              label: layout === 'separated' ? '' : control.label,
              size,
              fullWidth: true,
              InputLabelProps: inputLabelProps,
              variant,
              onMouseEnter: openTooltip,
              onMouseLeave: closeTooltip,
              onBlur: handleBlur,
            },
            openPickerButton: {
              style: { display: 'none' },
            },
          }}
          onChange={handleTimeChange}
          className={clsx({
            [classes.input]: true,
            [classes.readonly]: readonly,
          })}
          disabled={disabled}
        />
        {TimezoneButton}
        <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        PaperProps={{
          style: { width: 100, maxHeight: 270 },
        }}
        onClose={handleToggle}
        anchorOrigin={{
          vertical: 'bottom' as 'bottom',
          horizontal: 'right' as 'right',
        }}
        transformOrigin={{
          vertical: 'top' as 'top',
          horizontal: 'right' as 'right',
        }}
      >
        <TimezoneMenu
          selectedZone={selectedZone}
          handleTimezoneChange={handleTimezoneChange}
        />
      </Popover>
    </LocalizationProvider>
  );
}

export default function Time({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value }) => (
        <TimeView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
