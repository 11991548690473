import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './alternatives.loaders';
import { converters } from './alternatives.converters';
import {
  AlternativeEmployeesFilters,
  AlternativesFilters,
} from './alternatives.types';

export const alternativesKeys = queryKeyFactory('alternatives');

export const useAlternatives = (filters: Partial<AlternativesFilters> = {}) => {
  return useQuery({
    queryKey: alternativesKeys.list(filters),
    queryFn: () => loaders.getAlternatives({ filters }),
    select: converters.getAlternatives,
    keepPreviousData: true,
  });
};

export const useAlternative = (alternativeId: string) => {
  return useQuery({
    queryKey: alternativesKeys.list(alternativeId),
    queryFn: () => loaders.getAlternative({ alternativeId }),
    select: converters.getAlternative,
    enabled: !!alternativeId,
  });
};

export const useAlternativeEmployees = (
  alternativeId: string,
  filters: Partial<AlternativeEmployeesFilters> = {},
) => {
  return useQuery({
    queryKey: alternativesKeys.list(alternativeId, 'employees', filters),
    queryFn: () => loaders.getEmployees({ alternativeId, filters }),
    select: converters.getEmployees,
    enabled: !!alternativeId,
  });
};

export const useAlternativeEmployeesGroups = (alternativeId: string) => {
  return useQuery({
    queryKey: alternativesKeys.list(alternativeId, 'employees-groups'),
    queryFn: () => loaders.getEmployeesGroups({ alternativeId }),
    select: converters.getEmployeesGroups,
    enabled: !!alternativeId,
  });
};
