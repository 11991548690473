import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-discounts.loaders';
import { converters } from './admin-discounts.converters';
import { AdminDiscountsFilters } from './admin-discounts.types';

export const adminDiscountsKeys = queryKeyFactory('admin-discounts');

export const useAdminDiscounts = (
  filters: Partial<AdminDiscountsFilters> = {},
) => {
  return useQuery({
    queryKey: adminDiscountsKeys.list(filters),
    queryFn: () => loaders.getDiscounts({ filters }),
    select: converters.getDiscounts,
  });
};

export const useAdminDiscount = (discountId: string) => {
  return useQuery({
    queryKey: adminDiscountsKeys.detail(discountId),
    queryFn: () => loaders.getDiscount({ discountId }),
    select: converters.getDiscount,
    enabled: !!discountId,
  });
};
