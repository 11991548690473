import { ButtonOld } from 'melp-design/components';
import { Dropdown } from 'melp-design/icons';
import { ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

const ArrowUp = styled(Dropdown)({
  transform: 'rotate(180deg) translateY(-1px)',
});

const ArrowDown = styled(Dropdown)({
  transform: 'translateY(-1px)',
});

interface Props {
  currentIndex: number;
  totalCount: number;
  move: (from: number, to: number) => void;
}

const OrderChangeButton = ({ move, currentIndex, totalCount }: Props) => {
  if (totalCount < 2) {
    return null;
  }

  const commonProps = {
    iconOnly: true,
    style: { padding: 13 },
  };

  return (
    <ButtonGroup>
      <ButtonOld
        {...commonProps}
        onClick={() => move(currentIndex, currentIndex - 1)}
        disabled={currentIndex === 0}
      >
        <ArrowUp />
      </ButtonOld>
      <ButtonOld
        {...commonProps}
        onClick={() => move(currentIndex, currentIndex + 1)}
        disabled={currentIndex + 1 === totalCount}
      >
        <ArrowDown />
      </ButtonOld>
    </ButtonGroup>
  );
};

export default OrderChangeButton;
