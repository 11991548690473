import { ButtonHTMLAttributes, forwardRef } from 'react';
import { ButtonBase as MuiButton, Stack, Tooltip } from '@mui/material';
import { type SvgIconComponent as MuiSvgIconComponent } from '@mui/icons-material';
import { Badge } from 'melp-design/components';
import {
  BADGE_COLOR_BY_VARIANT,
  BASE_STYLES,
  ICON_BUTTON_STYLES,
  ICON_STYLES_BY_SIZE,
  STYLES_BY_SIZE,
  STYLES_BY_VARIANT,
  Size,
  Variant,
} from './button.styles';

interface CustomProps {
  label?: string;
  variant?: Variant;
  size?: Size;
  icon?: MuiSvgIconComponent;
  tooltip?: string;
  badge?: number;
}

type OmittedAttributes = keyof CustomProps | 'className' | 'color' | 'children';

type Props = CustomProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, OmittedAttributes>;

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      variant = 'primary',
      size = 'md',
      icon: Icon,
      tooltip,
      badge,
      ...buttonProps
    },
    forwardedRef,
  ) => {
    const button = (
      <MuiButton
        sx={{
          ...BASE_STYLES,
          ...STYLES_BY_SIZE[size],
          ...(Icon && !label ? ICON_BUTTON_STYLES : {}),
          ...STYLES_BY_VARIANT[variant],
        }}
        {...buttonProps}
        ref={forwardedRef}
      >
        {Icon ? (
          <Icon
            sx={{
              ...ICON_STYLES_BY_SIZE[size],
            }}
          />
        ) : null}
        {label ?? null}
      </MuiButton>
    );

    const buttonWithBadge =
      typeof badge === 'number' ? (
        <Badge badgeContent={badge} color={BADGE_COLOR_BY_VARIANT[variant]}>
          {button}
        </Badge>
      ) : (
        button
      );

    return tooltip ? (
      <Tooltip title={tooltip}>
        <Stack>{buttonWithBadge}</Stack>
      </Tooltip>
    ) : (
      <>{buttonWithBadge}</>
    );
  },
);

Button.displayName = 'Button';
