import { useCallback, useMemo, useRef } from 'react';
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from '@mp-react/form';
import { Controller } from 'react-hook-form';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { useFile, useUploadFile } from 'store/files';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as AddPhotoIcon } from '../../../assets/icons/add-photo.svg';
import useTooltip from '../../../utils/Tooltip';
import FormTooltip from '../../common/FormTooltip/FormTooltip';
import useStyles from './ImageUpload.styles';

function ImageView({
  value,
  onChange,
  control,
  error,
}: Pick<
  MpControlProps,
  | 'control'
  | 'size'
  | 'layout'
  | 'variant'
  | 'error'
  | 'defaultMessages'
  | 'locale'
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: file } = useFile(value);
  const { mutate: uploadFile, isLoading } = useUploadFile();
  const preview = useMemo(() => file?.url ?? null, [file]);
  const { tooltip, closeTooltip, openTooltip, anchorEl } = useTooltip(control);

  const handleBrowse = useCallback(() => {
    if (!preview) {
      const el = hiddenFileInput.current as any;
      el.click();
    }
  }, [preview]);

  const handleUploadFile = useCallback(
    async (e: any) => {
      e.stopPropagation();
      const { files } = e.target;
      if (files?.length > 0) {
        const file = files[0];
        uploadFile(
          { file },
          {
            onSuccess: ({ data }) => {
              onChange(data.id);
            },
          },
        );
      }
      closeTooltip();
    },
    [onChange, closeTooltip, uploadFile],
  );

  const clearImageInput = useCallback(() => {
    onChange(null);
    (hiddenFileInput.current as any).value = '';
    closeTooltip();
  }, [onChange, closeTooltip]);

  if (isLoading) return <CircularProgress size={40} />;

  return (
    <Box position="relative">
      <Box
        width={160}
        height={120}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        className={clsx({ [classes.emptyImageBox]: !preview })}
        onClick={handleBrowse}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
      >
        {!preview && <AddPhotoIcon />}
        {preview && (
          <Box position="relative" display="flex" width="100%" height="100%">
            <IconButton
              onClick={clearImageInput}
              className={classes.clearIcon}
              size="large"
            >
              <CrossIcon />
            </IconButton>
            <img
              src={preview}
              alt="benefit"
              className={classes.img}
              // Required to avoid issues while getting images programmatically
              // For more info see https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
              crossOrigin="anonymous"
            />
          </Box>
        )}
      </Box>
      <input
        accept="image/png, image/jpeg"
        type="file"
        ref={hiddenFileInput}
        onChange={handleUploadFile}
        style={{ display: 'none' }}
      />
      {!preview && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          paddingTop={1}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t('common.max_upload_size')} 25Mb
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t('common.accepted_formats')} jpeg, png
          </Typography>
        </Box>
      )}
      <FormHelperText error>
        {error?.type
          ? control?.messages?.[error.type as ValidationKeys]
          : undefined}
      </FormHelperText>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function ImageUpload({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ''}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value }) => (
        <ImageView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
