import { IconButtonProps } from '@mui/material';
import { MpControl, MpFormOverridablesMap } from '@mp-react/form';

export interface UseFormUtils {
  overridables: Partial<MpFormOverridablesMap>;
  getFormFieldsName: (name: string, language?: string) => string;
  getFormKeys: (name: string) => string[];
}

export interface FormEndAdornmentProps extends IconButtonProps {
  control: MpControl;
}

type SupportedControls =
  | 'date'
  | 'time'
  | 'dropdown'
  | 'image'
  | 'attachment'
  | 'checkbox'
  | 'switch'
  | 'number'
  | 'phone'
  | 'text'
  | 'multiline'
  | 'password'
  | 'mergedGroup'
  | 'companyAndDepartmentDropdown'
  | 'customDropdown'
  | 'employeeCategoriesDropdown'
  | 'numberWithComma'
  | 'group'
  | 'multiAutocomplete'
  | 'select'
  | 'currency'
  | 'richText'
  | 'multiNumber';

// Using any just as a workaround for non-extendable @mp-react/form control API.
export const CustomControlType: Record<SupportedControls, any> = {
  date: 'date',
  time: 'time',
  dropdown: 'dropdown',
  image: 'image',
  attachment: 'attachment',
  checkbox: 'checkbox',
  switch: 'switch',
  number: 'number',
  phone: 'phone',
  text: 'text',
  multiline: 'multiline',
  password: 'password',
  mergedGroup: 'mergedGroup',
  companyAndDepartmentDropdown: 'companyAndDepartmentDropdown',
  customDropdown: 'customDropdown',
  employeeCategoriesDropdown: 'employeeCategoriesDropdown',
  numberWithComma: 'numberWithComma',
  group: 'group',
  multiAutocomplete: 'multiAutocomplete',
  select: 'select',
  currency: 'currency',
  richText: 'richText',
  multiNumber: 'multiNumber',
};
