import { useMemo } from 'react';
import Information from '../containers/CompanySettings/views/Administrators/AdministratorsInner/views/Information/Information';
import Permissions from '../containers/CompanySettings/views/Administrators/AdministratorsInner/views/Permissions/Permissions';
import ClientInformation from '../containers/ClientsAdministrators/views/Information/Information';
import ClientPermissions from '../containers/ClientsAdministrators/views/Permissions/Permissions';
import { Tab } from '../types/Common';

export enum GlobalPermissionModules {
  EmployeeGroups = 'employeeGroups',
  Benefits = 'benefits',
  FlexBenefit = 'flexBenefit',
  BenefitAlternatives = 'benefitAlternatives',
  Administrators = 'administrators',
  OrganisationStructure = 'organisationStructure',
}

export enum LocalPermissionModules {
  Dashboard = 'dashboardModule',
  Employees = 'employeeModule',
  News = 'newsModule',
  History = 'historyModule',
}

export enum PermissionOptions {
  None = 'none',
  View = 'view',
  Edit = 'edit',
}

export enum PermissionLevels {
  Global = 'global',
  Local = 'local',
  AllLocal = 'localAll',
}

export const useAdministratorTabs = (isHrProgramatic: boolean) => {
  if (isHrProgramatic) {
    return [
      {
        key: 'administratorsInformation',
        component: Information,
        module: undefined,
      },
    ];
  }

  return [
    {
      key: 'administratorsPermissions',
      component: Permissions,
      module: undefined,
    },
    {
      key: 'administratorsInformation',
      component: Information,
      module: undefined,
    },
  ];
};

export const useClientAdministratorTabs = () =>
  useMemo(
    (): Tab[] => [
      {
        key: 'administratorsPermissions',
        component: ClientPermissions,
      },
      {
        key: 'administratorsInformation',
        component: ClientInformation,
      },
    ],
    [],
  );

export const useAdministratorSidebarActons = (): string[] =>
  useMemo(() => ['resend_invitation', 'delete'], []);

export const useClientAdministratorSidebarActons = (): string[] =>
  useMemo(() => ['resend_invitation', 'delete'], []);

export enum AdministratorFilterNames {
  FULL_NAMES = 'fullName',
  EMAIL = 'email',
  PHONE = 'phone',
}
