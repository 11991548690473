import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useInvestmentCalculationsStore } from 'state/InvestmentCalculations';
import { alternativesKeys } from 'store/alternatives';
import { Endpoints } from 'api/constants';
import { loaders } from './employee-groups.loaders';
import { employeeGroupsKeys } from './employee-groups.queries';

export const useAssignBenefitsToGroup = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { resetStatusChanges, resetAllChanges } =
    useInvestmentCalculationsStore();

  const mutation = useMutation(loaders.assignBenefitsToGroup, {
    onSuccess: async () => {
      toast(t('common.assigned_succesfully'), { type: 'success' });
      await queryClient.invalidateQueries(employeeGroupsKeys.all);
      resetStatusChanges();
      resetAllChanges();
    },
  });

  return mutation;
};

export const useAssignEmployeesToGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.assignEmployeesToGroup, {
    onSuccess: () => {
      return Promise.all([
        // TODO: remove 'mutate' after employees-groups are refactored
        mutate(Endpoints.clientAdmin.employeeGroups.root),
        queryClient.invalidateQueries(employeeGroupsKeys.list('employees')),
        queryClient.invalidateQueries(alternativesKeys.lists),
      ]);
    },
  });

  return mutation;
};

export const useRemoveAllEmployeesFromGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.removeAllEmployeesFromGroup, {
    onSuccess: () => {
      return Promise.all([
        // TODO: remove 'mutate' after employees-groups are refactored
        mutate(Endpoints.clientAdmin.employeeGroups.root),
        queryClient.invalidateQueries(employeeGroupsKeys.list('employees')),
      ]);
    },
  });

  return mutation;
};
