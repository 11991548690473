import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Colors } from '../../style/Colors';

const useStyles = makeStyles(() => ({
  typography: {
    fontFamily: 'Inter',
  },
  header1: {
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.1',
  },
  header2: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.4',
  },
  header3: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.4',
  },
  header4: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.4',
  },
  paragraph1: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.4',
  },
  paragraph2: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.4',
  },
  paragraph3: {
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.4',
  },
  textPrimary: {
    color: Colors.black,
  },
  textSecondary: {
    color: Colors.grey,
  },
  textWhite: {
    color: Colors.white,
  },
  errorText: {
    color: Colors.red,
  },
  warningText: {
    color: Colors.yellow,
  },
  successText: {
    color: Colors.green,
  },
  primaryText: {
    color: Colors.blue,
  },
  inheritColor: {
    color: 'inherit',
  },
}));

export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'p1' | 'p2' | 'p3';
type MuiTypographyVariant = TypographyProps['variant'];
export type TypographyColor =
  | 'textPrimary'
  | 'textSecondary'
  | 'textWhite'
  | 'primary'
  | 'error'
  | 'warning'
  | 'success'
  | 'inherit';

interface Props
  extends Omit<TypographyProps, 'variant' | 'color' | 'component'> {
  variant?: TypographyVariant;
  color?: TypographyColor;
  component?: 'span' | 'label';
  maxLines?: number;
}

const Typography = ({
  variant = 'p1',
  color = 'textPrimary',
  className,
  maxLines,
  ...props
}: Props) => {
  const classes = useStyles();
  const styleMap: Record<TypographyVariant, string> = {
    h1: classes.header1,
    h2: classes.header2,
    h3: classes.header3,
    h4: classes.header4,
    p1: classes.paragraph1,
    p2: classes.paragraph2,
    p3: classes.paragraph3,
  };
  const muiVariantMap: Record<TypographyVariant, MuiTypographyVariant> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    p1: 'body1',
    p2: 'body2',
    p3: 'body1',
  };
  const colorStyleMap: Record<TypographyColor, string> = {
    textPrimary: classes.textPrimary,
    textSecondary: classes.textSecondary,
    textWhite: classes.textWhite,
    error: classes.errorText,
    warning: classes.warningText,
    success: classes.successText,
    primary: classes.primaryText,
    inherit: classes.inheritColor,
  };
  return (
    <MuiTypography
      variant={muiVariantMap[variant]}
      className={clsx(
        classes.typography,
        styleMap[variant],
        colorStyleMap[color],
        className,
      )}
      whiteSpace="pre-wrap"
      {...props}
      sx={{
        ...(maxLines
          ? {
              ...props.sx,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: maxLines,
            }
          : props.sx),
      }}
    />
  );
};

export default Typography;
