import { z } from 'zod';
import { adminClientsFiltersSchema } from './admin-clients.schemas';

export const clientFeatures = [
  'benefits',
  'news',
  'recognition',
  'marketplace',
  'claimReimbursement',
  'companyItems',
  'discounts',
  'manualExpenses',
] as const;
export type ClientFeature = (typeof clientFeatures)[number];

export type AdminClientsFilters = z.infer<typeof adminClientsFiltersSchema>;
