import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientDepositsList } from 'containers/admin';

const AdminClientDepositsPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientDepositsList />
    </AdminClientLayout>
  );
};

export default AdminClientDepositsPage;
