import { Typography } from 'melp-design/components';
import { FormControl, FormHelperText } from '@mui/material';
import RichTextEditor from '../../../../components/common/RichTextEditor/RichTextEditor';
import { InternalFieldRenderer } from '../Types';

export const renderRichTextField: InternalFieldRenderer = (
  props,
  { required, label, ...field },
  { invalid, ...fieldState },
) => {
  return (
    <FormControl error={invalid} required={required} fullWidth>
      {label && (
        <Typography
          variant="p1"
          color="textSecondary"
          mb="10px"
          component="label"
          sx={{
            '&::after': {
              content: '" *"',
              display: required ? 'inline' : 'none',
            },
          }}
        >
          {label}
        </Typography>
      )}
      <RichTextEditor error={invalid} {...field} {...props} />
      <FormHelperText>{fieldState.message}</FormHelperText>
    </FormControl>
  );
};
