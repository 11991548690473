import { ComponentPropsWithoutRef, useState } from 'react';
import { SelectFilter } from 'components/filters';
import { useAdminClients } from 'store/admin-clients';

interface Props<Multiple extends boolean>
  extends Pick<
    ComponentPropsWithoutRef<typeof SelectFilter>,
    'onClearFilter' | 'onCancel'
  > {
  value?: Multiple extends true ? string[] : string;
  multiple: Multiple;
  onApplyFilter: (value: Multiple extends true ? string[] : string) => void;
}

export const ParentCompaniesFilter = <Multiple extends boolean>({
  multiple,
  value,
  onApplyFilter,
  onClearFilter,
  onCancel,
}: Props<Multiple>) => {
  const [search, setSearch] = useState('');

  const { data: companies, isLoading } = useAdminClients({ name: search });

  return (
    <SelectFilter
      options={companies?.items.map((company) => ({
        key: company.id,
        label: company.name,
      }))}
      value={value}
      onApplyFilter={onApplyFilter}
      onClearFilter={onClearFilter}
      onCancel={onCancel}
      loadingOptions={isLoading}
      multiple={multiple}
      onSearch={setSearch}
      asyncSearch
    />
  );
};
