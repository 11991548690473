import { converters as tableConverters } from 'store/table';
import {
  IEmployeeGroupBenefit,
  IEmployeeGroupEmployee,
  IEmployeesGroup,
} from 'types/income';
import {
  convertCustomCategories,
  convertEmbeddedCustomCategories,
} from 'store/custom-categories';
import { loaders } from './employee-groups.loaders';
import {
  EmployeesGroup,
  EmployeeGroupBenefit,
  EmployeeGroupEmployee,
} from './employee-groups.types';

const convertEmployeesGroup = (
  employeesGroup: IEmployeesGroup,
): EmployeesGroup => {
  return {
    id: employeesGroup.id,
    name: employeesGroup.name,
  };
};

const convertEmployeesGroups = (
  employeesGroups: IEmployeesGroup[],
): EmployeesGroup[] => {
  return employeesGroups.map(convertEmployeesGroup);
};

const convertEmployeeGroupBenefit = (
  assignment: IEmployeeGroupBenefit,
): EmployeeGroupBenefit => {
  return {
    id: assignment.id,
    name: assignment.name,
    category: assignment.type,
    status: assignment.status,
    investmentAmount: assignment.investmentAmount
      ? assignment.investmentAmount * 100
      : assignment.investmentAmount,
    assignmentStatus: assignment.currentStatus,
    assignments: assignment.statuses.map((assignment) => ({
      type: 'GROUP',
      status: assignment.status,
      startsAt: assignment.fromDate,
      endsAt: assignment.toDate ?? null,
    })),
  };
};

const convertEmployeeGroupBenefits = (
  benefits: IEmployeeGroupBenefit[],
): EmployeeGroupBenefit[] => {
  return benefits.map(convertEmployeeGroupBenefit);
};

const convertEmployeeGroupEmployee = (
  employee: IEmployeeGroupEmployee,
): EmployeeGroupEmployee => {
  return {
    id: employee.id,
    fullName: employee.fullName ?? '',
    position: employee.jobTitle ?? '',
    inGroupStatus: employee.included ? 'included' : 'excluded',
    customCategories: convertEmbeddedCustomCategories(
      employee.customCategories ?? [],
    ),
  };
};

const convertEmployeeGroupEmployees = (
  employees: IEmployeeGroupEmployee[],
): EmployeeGroupEmployee[] => {
  return employees.map(convertEmployeeGroupEmployee);
};

export const converters = {
  getEmployeesGroups: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeesGroups>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeesGroups(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getEmployeeGroupBenefits: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeGroupBenefits>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeGroupBenefits(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getEmployeeGroupEmployees: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeGroupEmployees>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeGroupEmployees(data.data),
      total,
      page,
      pageSize,
      pages,
      customCategories: convertCustomCategories(data.customCategories ?? []),
    };
  },
};
