import {
  IInternalShopInventoryEvent,
  IInternalShopInventoryItem,
  IInternalShopItem,
} from 'types/income';
import { fromCents } from 'utils/general';
import { converters as tableConverters } from 'store/table';
import { convertBaseShopItem } from 'store/shop';
import { loaders } from './internal-shop.loaders';
import {
  InternalShopInventoryEvent,
  InternalShopInventoryItem,
  InternalShopItem,
} from './internal-shop.types';

const convertInternalShopItem = (item: IInternalShopItem): InternalShopItem => {
  return {
    ...convertBaseShopItem(item),
    prices:
      item.prices
        ?.toSorted((a, b) => (a.index ?? 0) - (b.index ?? 0))
        .map((price) => ({
          id: price.id,
          price: fromCents(price.price ?? 0),
          translations: item.translations.map((trans) => {
            const priceTranslation = price.translations?.find(
              (priceTrans) => priceTrans.language === trans.language,
            );
            return {
              language: trans.language,
              description: priceTranslation?.description ?? '',
            };
          }),
          inventory: price.inventory ?? 0,
        })) ?? [],
    trackInventory: item.trackInventory,
    purchaseLimit: item.purchaseLimit,
    purchaseLimitVariant: item.purchaseLimitType,
    purchaseLimitPeriod: item.purchaseLimitPeriod,
    notifyOnOrder: item.notifyOnMerchandisingOrder ?? false,
  };
};

const convertInternalShopItems = (
  items: IInternalShopItem[],
): InternalShopItem[] => {
  return items.map(convertInternalShopItem);
};

const convertInternalShopInventoryItem = (
  item: IInternalShopInventoryItem,
): InternalShopInventoryItem => {
  return {
    id: item.variantId,
    name: item.variantName ?? '',
    itemId: item.itemId,
    itemName: item.itemName,
    remainingInventory: item.remainingInventory,
    lowQuantityAlert: item.lowQuantityAlert,
    trackInventory: item.trackInventory,
  };
};

const convertInternalShopInventoryItems = (
  items: IInternalShopInventoryItem[],
): InternalShopInventoryItem[] => {
  return items.map(convertInternalShopInventoryItem);
};

const convertInternalShopInventoryEvent = (
  event: IInternalShopInventoryEvent,
): InternalShopInventoryEvent => {
  return {
    id: event.id,
    variant: event.eventType,
    inventoryChange: event.inventoryChange,
    doneBy: event.doneBy
      ? {
          id: event.doneBy.id,
          name: event.doneBy.name,
          email: event.doneBy.email,
        }
      : null,
    comment: event.comment ?? '',
    createdAt: event.date,
  };
};

const convertInternalShopInventoryEvents = (
  items: IInternalShopInventoryEvent[],
): InternalShopInventoryEvent[] => {
  return items.map(convertInternalShopInventoryEvent);
};

export const converters = {
  getInternalShopItems: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getInternalShopItems>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertInternalShopItems(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getInternalShopItem: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getInternalShopItem>>) => {
    return convertInternalShopItem(data);
  },
  getInternalShopInventoryItems: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getInternalShopInventoryItems>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertInternalShopInventoryItems(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getInternalShopInventoryEvents: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getInternalShopInventoryEvents>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertInternalShopInventoryEvents(data.data),
      meta: {
        inventory: data.inventory,
        itemId: data.itemId,
        itemName: data.itemName,
        variantId: data.variantId,
        translations: data.variantTranslations,
      },
      total,
      page,
      pageSize,
      pages,
    };
  },
};
