import { useLocation, useHistory } from 'react-router-dom';
import { ROUTES } from 'config';

interface LocationState {
  from?: string;
}

/**
 * A utility hook to get a "from" parameter from location state. It is used
 * within authentication pages to redirect to a page, which a user tried to
 * access before the authentication flow was started.
 */
export const useRedirectTo = () => {
  const location = useLocation<LocationState | undefined>();
  const redirectTo = location.state?.from ?? ROUTES.root.index;

  const history = useHistory();
  const navigateWithRedirectState = (to: string) => {
    history.push({
      pathname: to,
      state: {
        from: redirectTo,
      },
    });
  };

  return { redirectTo, navigateWithRedirectState };
};
