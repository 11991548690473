import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './discounts.loaders';
import { discountsKeys } from './discounts.queries';

export const useBulkUpdateDiscounts = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkUpdate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(discountsKeys.lists);
    },
  });
};
