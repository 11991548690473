import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-suppliers.loaders';
import { converters } from './admin-suppliers.converters';
import { AdminSuppliersFilters } from './admin-suppliers.types';

export const adminSuppliersKeys = queryKeyFactory('admin-suppliers');

export const useAdminSuppliers = (
  filters: Partial<AdminSuppliersFilters> = {},
) => {
  return useQuery({
    queryKey: adminSuppliersKeys.list(filters),
    queryFn: () => loaders.getSuppliers({ filters }),
    select: converters.getSuppliers,
  });
};

export const useAdminSuppliersStatistics = (
  filters: Partial<AdminSuppliersFilters> = {},
) => {
  return useQuery({
    queryKey: adminSuppliersKeys.list('statistics', filters),
    queryFn: () => loaders.getSuppliersStatistics({ filters }),
    select: converters.getSuppliers,
  });
};
