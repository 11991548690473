import { FC } from 'react';
import { Stack } from '@mui/material';
import { ROUTES } from 'config';
import {
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { COLOR_BY_STATUS, useIntegrationModels } from 'store/integrations';
import { IntegrationCreate } from 'containers/admin';

export const AdminIntegrationsList: FC = () => {
  const { t } = useTranslation();

  const { data: integrations, isLoading } = useIntegrationModels();

  return (
    <>
      <ListPageHeader
        title={t('menu.integrations')}
        sideContent={<IntegrationCreate />}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={integrations?.total} />
            <Stack direction="row" alignItems="center" gap="10px">
              {/* Filters */}
            </Stack>
          </Stack>
        }
      />

      <Table
        data={integrations?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('integrations.name'),
            cell: ({ row: { original: integration } }) => (
              <Link
                label={integration.name}
                to={ROUTES.admin.integrations.details.replace(
                  ':id',
                  integration.id,
                )}
              />
            ),
          },
          {
            id: 'comment',
            header: t('clients.comment'),
            cell: ({ row: { original: integration } }) => integration.comment,
          },
          {
            id: 'variant',
            header: t('integrations.type'),
            cell: ({ row: { original: integration } }) => integration.variant,
          },
          {
            id: 'createdAt',
            header: t('integrations.creationDate'),
            cell: ({ row: { original: integration } }) =>
              moment(integration.createdAt).format('l'),
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: integration } }) => (
              <Typography color={COLOR_BY_STATUS[integration.status]}>
                {t(`status.${integration.status}`)}
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};
