import { ButtonOld, NoData, Typography } from 'melp-design/components';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { usePriceTranslations } from '../../../../../state/MarketplaceItems';
import { useDialog } from '../../../../../utils/Dialog';
import PriceTranslationDialog, {
  PriceTranslationFormData,
} from './PriceTranslationDialog';
import PriceTranslationsRow from './PriceTranslationsRow';

const useStyles = makeStyles(() => ({
  translationContainer: {
    padding: '30px 20px',
  },
}));

interface Props {
  itemId: string;
  priceId: string;
}

const PriceTranslations = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const translationDialog = useDialog();

  const translations = usePriceTranslations(props.itemId, props.priceId);

  const handleSubmit = async (data: PriceTranslationFormData) => {
    await translations.create.execute(data);
    translationDialog.closeDialog();
  };

  return (
    <div className={classes.translationContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="30px"
      >
        <Typography variant="h2">
          {t('marketplaceItems.priceTranslations')}
        </Typography>
        <ButtonOld
          variant="contained"
          color="primary"
          onClick={translationDialog.openDialog}
        >
          {t('marketplaceItems.addPriceTranslation')}
        </ButtonOld>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={200}>
              {t('marketplaceItems.priceTranslationLanguage')}
            </TableCell>
            <TableCell style={{ borderRight: 'none' }}>
              {t('marketplaceItems.priceTranslationDescription')}
            </TableCell>
            <TableCell width={120} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!translations.data?.length && (
            <TableRow>
              <TableCell colSpan={3}>
                <NoData />
              </TableCell>
            </TableRow>
          )}
          {!!translations.data?.length &&
            translations.data.map((translation) => (
              <PriceTranslationsRow
                key={translation.id}
                itemId={props.itemId}
                priceId={props.priceId}
                translation={translation}
              />
            ))}
        </TableBody>
      </Table>
      <PriceTranslationDialog
        title={t('marketplaceItems.createPriceTranslation')}
        initialData={{}}
        open={translationDialog.open}
        onClose={translationDialog.closeDialog}
        onSubmit={handleSubmit}
        loading={translations.create.loading}
      />
    </div>
  );
};

export default PriceTranslations;
