import { Button, FormField, Table, Typography } from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { TableToolbar, TableWrapper } from 'melp-design/components';
import {
  useRecognitionAllowanceRules,
  useRecognitionAllowanceRulesMutations,
} from 'state/Recognition';
import {
  CreateRecognitionAllowanceRuleRequest,
  RecognitionAllowanceFrequency,
} from 'types/Recognition';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import AllowanceRuleActions from './AllowanceRuleActions';
import AllowanceRuleDialog from './AllowanceRuleDialog';

const DEFAULT_VALUES = {
  employeeGroups: [],
  frequency: 'monthly' as RecognitionAllowanceFrequency,
  assignAllowanceOnCreation: true,
};

export const RecognitionsRulesList = () => {
  const { t } = useTranslation();

  const pointsLabel = usePointsLabel();

  const allowanceRuleCreationDialog = useDialog();
  const predefinedToasts = usePredefinedToasts();
  const allowanceRulesMutations = useRecognitionAllowanceRulesMutations();

  const allowanceRules = useRecognitionAllowanceRules();

  const handleCreate = async (
    requestData: CreateRecognitionAllowanceRuleRequest,
  ) => {
    await allowanceRulesMutations.create.execute(requestData);
    predefinedToasts.success.added();
    allowanceRuleCreationDialog.closeDialog();
  };

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <Stack spacing={2} p={1}>
            <Typography variant="h2">
              {t('recognition.allowanceRulesTab')}
            </Typography>
            <Typography variant="p1" color="textSecondary">
              {t('recognitions.rules.info.message')}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <Button
              icon={Add}
              label={t('recognition.newAllowanceRule')}
              onClick={allowanceRuleCreationDialog.openDialog}
            />
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={allowanceRules.data ?? []}
        isLoading={allowanceRules.loading}
        columns={[
          {
            id: 'employeeGroups',
            header: t('recognition.allowanceRulesListEmployeeGroups'),
            cell: ({ row: { original: rule } }) =>
              rule.employeeGroups
                .map((employeeGroup) => employeeGroup.name)
                .join(', '),
          },
          {
            id: 'points',
            header: t('recognition.allowanceRulesListPoints', {
              pointsName: pointsLabel,
            }),
            cell: ({ row: { original: rule } }) => rule.points,
            meta: { align: 'right' },
          },
          {
            id: 'frequency',
            header: t('recognition.allowanceRulesListFrequency'),
            cell: ({ row: { original: rule } }) =>
              t(`recognition.allowanceRuleFrequency-${rule.frequency}`),
          },
          {
            id: 'actions',
            cell: ({ row: { original: rule } }) => (
              <AllowanceRuleActions ruleData={rule} />
            ),
          },
        ]}
      />
      <AllowanceRuleDialog
        open={allowanceRuleCreationDialog.open}
        onClose={allowanceRuleCreationDialog.closeDialog}
        title={t('recognition.newAllowanceRule')}
        initialValues={DEFAULT_VALUES}
        submitButtonTitle={t('common.create')}
        onSubmit={handleCreate}
        loading={allowanceRulesMutations.create.loading}
        renderAdditionalFields={() => (
          <FormField
            name="assignAllowanceOnCreation"
            render="checkbox"
            label={t('recognition.allowanceRuleInstantAssign')}
          />
        )}
      />
    </TableWrapper>
  );
};
