import { createStyles, makeStyles } from '@mui/styles';
import { Colors, Shadows } from '../../../constants/Style';

export default makeStyles(() =>
  createStyles({
    popper: {
      zIndex: 1,
    },
    popoverPaper: {
      boxShadow: Shadows.BulkAction,
      zIndex: 1,
      minWidth: 150,
      margin: '5px 0',
    },
    unfilledIndicator: {
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: '-8px',
        borderRadius: '50%',
        backgroundColor: Colors.Error,
        width: 8,
        height: 8,
      },
    },
    green: {
      color: Colors.Success,
    },
    activeItem: {
      fontWeight: 'bold',
    },
  }),
);
