import { DeleteIconButton, EditIconButton } from 'melp-design/components';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import DeleteConfirmationDialog from 'components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useRecognitionAchievementMutations } from 'state/Recognition';
import { RecognitionAchievementsItem } from 'types/Recognition';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';

interface Props {
  data: RecognitionAchievementsItem;
}

const AchievementsItemActions = ({ data }: Props) => {
  const history = useHistory();
  const predefinedToasts = usePredefinedToasts();

  const achievementId = data.id;
  const achievementMutations =
    useRecognitionAchievementMutations(achievementId);

  const removeConfirmation = useDialog();

  const handleRemove = async () => {
    await achievementMutations.remove.execute();
    predefinedToasts.success.deleted();
    removeConfirmation.closeDialog();
  };

  return (
    <Stack direction="row" gap="10px" justifyContent="end">
      <EditIconButton
        onClick={() =>
          history.push(
            ROUTES.recognitions.achievements.details.replace(
              ':id',
              achievementId,
            ),
          )
        }
      />
      <DeleteIconButton onClick={removeConfirmation.openDialog} />
      <DeleteConfirmationDialog
        open={removeConfirmation.open}
        onCancel={removeConfirmation.closeDialog}
        onConfirm={handleRemove}
        loading={achievementMutations.remove.loading}
        title={data.name}
      />
    </Stack>
  );
};

export default AchievementsItemActions;
