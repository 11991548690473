import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './automations.loaders';
import { automationsKeys } from './automations.queries';

export const useCreateAutomation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.createAutomation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(automationsKeys.all);
    },
  });

  return mutation;
};

export const useUpdateAutomation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.updateAutomation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(automationsKeys.all);
    },
  });

  return mutation;
};

export const useExecuteAutomation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.executeAutomation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(automationsKeys.all);
    },
  });

  return mutation;
};

export const useRemoveAutomation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(loaders.removeAutomation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(automationsKeys.lists);
    },
  });

  return mutation;
};
