import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useAuthContext } from '../../../auth/index';
import Header from '../../header/Header/Header';
import useStyles from './Base.styles';

export default function Base({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  const { loggedIn } = useAuthContext();

  return (
    <div>
      {loggedIn && <Header />}
      <Container
        maxWidth="lg"
        disableGutters
        classes={{ root: classes.container, maxWidthLg: classes.maxWidthLg }}
      >
        {children}
      </Container>
    </div>
  );
}
