import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FC, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';
import { QueryClientProvider } from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from 'store/modal';
import { Loader } from 'melp-design/components';
import { queryClient } from 'utils/query-client';
import { routerHistory } from 'utils/router-history';
import { SWROptions } from '../../api/config';
import { AuthProvider } from '../../auth/index';
import ErrorBoundary from '../../components/common/ErrorBoundary/ErrorBoundary';
import Base from '../../components/layouts/Base/Base';
import i18n from '../../i18n';
import { MeProvider } from '../../state/Administrators';
import { ParentCompanyProvider } from '../../state/ParentCompany';
import Melp from '../../styles/themes/Melp';
import '../../utils/FeatureToggle';
import ScrollToTop from '../../utils/ScrollToTop';
import Routes from '../Routes/Routes';

export const App: FC = () => {
  // eslint-disable-next-line no-console
  console.log({ env: process.env.REACT_APP_ENV_NAME });
  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Melp} key="melp">
          <StylesProvider injectFirst>
            <QueryClientProvider client={queryClient}>
              <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <SWRConfig value={SWROptions}>
                    <Suspense fallback={<Loader />}>
                      <ModalProvider>
                        <CssBaseline key="melp" />
                        <Router history={routerHistory}>
                          <ScrollToTop />
                          <AuthProvider>
                            <MeProvider>
                              <ParentCompanyProvider>
                                <Base>
                                  <Routes />
                                </Base>
                              </ParentCompanyProvider>
                            </MeProvider>
                          </AuthProvider>
                        </Router>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={true}
                          newestOnTop
                          rtl={false}
                          closeOnClick={false}
                          draggable={false}
                          pauseOnHover
                        />
                      </ModalProvider>
                    </Suspense>
                  </SWRConfig>
                </LocalizationProvider>
              </I18nextProvider>
            </QueryClientProvider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
