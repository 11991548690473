import { FC } from 'react';
import { useCustomCategories } from 'store/custom-categories';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
> & { customCategoryId: string };

export const CustomCategoryValuesFilter: FC<Props> = ({
  customCategoryId,
  ...props
}) => {
  const { data: categories, isLoading } = useCustomCategories();

  const customCategory = categories?.find(
    (category) => category.id === customCategoryId,
  );

  return (
    <SelectFilter
      {...props}
      options={customCategory?.values.map((value) => ({
        key: value.id,
        label: value.name,
      }))}
      loadingOptions={isLoading}
      multiple
    />
  );
};
