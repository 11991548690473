import { IBaseParentCompany, IParentCompany } from 'types/income';
import { converters as tableConverters } from 'store/table';
import {
  BaseParentCompany,
  ParentCompany,
  convertBaseParentCompany,
  convertParentCompany,
} from 'store/companies';
import { loaders } from './admin-clients.loaders';

const convertClient = (client: IParentCompany): ParentCompany => {
  return convertParentCompany(client);
};

const convertBaseClient = (client: IBaseParentCompany): BaseParentCompany => {
  return convertBaseParentCompany(client);
};

const convertClients = (clients: IBaseParentCompany[]): BaseParentCompany[] => {
  return clients.map(convertBaseClient);
};

export const converters = {
  getClients: ({ data }: Awaited<ReturnType<typeof loaders.getClients>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertClients(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getClient: ({ data }: Awaited<ReturnType<typeof loaders.getClient>>) => {
    return convertClient(data);
  },
};
