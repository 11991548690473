import { useTranslation } from 'react-i18next';
import { MpFormType } from '@mp-react/form';
import { useMemo } from 'react';
import {
  PermissionOptions,
  LocalPermissionModules,
} from '../../constants/Administrators';
import { TooltipControl } from '../../types/Common';

export const useLocalPermissionsForm = (): MpFormType => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const entries = Object.entries(PermissionOptions);
    return entries.map(([, value]) => ({
      type: 'select' as any,
      key: value,
      label: t(`actions.${value}`),
    }));
  }, [t]);

  const controls = useMemo((): TooltipControl[] => {
    const values = Object.values(LocalPermissionModules);
    return values.map((value) => ({
      type: 'dropdown' as any,
      key: value,
      label: t(`permissions.${value}`),
      children: options,
      tooltip: 'tooltip.local_permission_dropdown',
      placeholder: '-',
    }));
  }, [options, t]);

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
