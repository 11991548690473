import { Close } from 'melp-design/icons';
import { SystemColors } from 'melp-design/style';
import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';

export interface DialogProps extends Omit<MuiDialogProps, 'onClose'> {
  /**
   * Sets a specified width for the dialog. It will still shrink, if it doesn't
   * fit the screen.
   */
  width?: number;
  /**
   * Callback to execute when dialog is being closed
   */
  onClose?: () => void;
  /**
   * Indicates whether close button should be displayed. True by default.
   */
  closable?: boolean;
}

export const Dialog = ({
  children,
  closable = true,
  ...props
}: DialogProps) => (
  <MuiDialog
    fullWidth={!!props.width}
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: '10px',
        position: 'relative',
        maxWidth: props.width,
      },
    }}
    data-test="dialog"
    {...props}
  >
    {props.onClose && closable && (
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          color: SystemColors.black,
          background: SystemColors.white,
          zIndex: 100,
          '&:hover': { background: SystemColors.grey[96] },
        }}
        onClick={props.onClose}
        aria-label="close"
      >
        <Close />
      </IconButton>
    )}
    {children}
  </MuiDialog>
);

export const DialogTitle = styled(MuiDialogTitle)({
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.4',
  padding: '30px 30px 20px',

  'button+&': {
    paddingRight: 60,
  },
});

export const DialogContent = styled(MuiDialogContent)({
  padding: 30,
});

export const DialogActions = styled(MuiDialogActions)({
  padding: '0px 30px 30px',

  '&.MuiDialogActions-spacing': {
    gap: 10,

    '& > :not(:first-of-type)': {
      marginLeft: 0,
    },
  },
});
