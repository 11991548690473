import { useTranslation } from 'react-i18next';
import { MpControlType, MpFormType } from '@mp-react/form';
import { contentLanguages } from 'types/general';
import { formatLanguage } from 'utils/format';
import { CustomControlType } from '../../../../../types/Form';
import { CustomFormControl } from '../../../../../types/Common';

export const useMarketplaceItemTranslationForm = () => {
  const { t, i18n } = useTranslation();

  const supportedLanguageOptions = contentLanguages.map((language) => ({
    type: '' as MpControlType,
    key: language.toUpperCase(),
    label: formatLanguage(i18n.language, language),
  }));

  const controls: CustomFormControl[] = [
    {
      key: 'language',
      type: 'select' as MpControlType,
      children: supportedLanguageOptions,
      label: t('marketplaceItems.translationLanguage'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      key: 'title',
      type: CustomControlType.text as unknown as MpControlType,
      label: t('marketplaceItems.translationTitle'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
    {
      key: 'description',
      type: CustomControlType.richText as unknown as MpControlType,
      label: t('marketplaceItems.translationDescription'),
      messages: {
        required: t('form.required'),
      },
      required: true,
    },
  ];

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  } as MpFormType;
};
