import { Currency } from 'types/general';
import { browserLanguage } from './Common';
import { isDefined } from './isDefined';

const zeroDecimals: Currency[] = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'ISK',
  'JPY',
  'KMF',
  'KRW',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

const threeDecimals: Currency[] = [
  'BHD',
  'IQD',
  'JOD',
  'KWD',
  'LYD',
  'OMR',
  'TND',
];

export const getAllowedDecimalPlaces = (currency: Currency) => {
  if (zeroDecimals.includes(currency)) {
    return 0;
  }
  if (threeDecimals.includes(currency)) {
    return 3;
  }
  return 2;
};

const getCurrencyMultiplier = () => {
  return 100;
};

const toMainUnitAmount = (fractionalUnitAmount: number) => {
  const currencyDivisor = getCurrencyMultiplier();
  const dangerouslyRoundedNumberToAvoidIncorrectFloats =
    Math.round(fractionalUnitAmount);
  return dangerouslyRoundedNumberToAvoidIncorrectFloats / currencyDivisor;
};

const toFractionalUnitAmount = (mainUnitAmount: number) => {
  const currencyMultiplier = getCurrencyMultiplier();
  return Math.round(mainUnitAmount * currencyMultiplier);
};

export const currencyConverters = { toMainUnitAmount, toFractionalUnitAmount };

/**
 * Format given number to currency string based on browser locale and given
 * currency.
 * @param amount Value to format
 * @param currency Currency code
 * @returns Formatted value
 */
const formatMainUnitAmount = (amount?: number | null, currency?: Currency) => {
  const locale = browserLanguage;
  if (!isDefined(amount)) {
    return '';
  }
  const options: Intl.NumberFormatOptions = currency
    ? { style: 'currency', currency, currencyDisplay: 'code' }
    : { minimumFractionDigits: 2 };
  return new Intl.NumberFormat(locale, options).format(amount);
};

/**
 * Format fractional unit amount value (e.g., cents) based on browser locale and
 * given currency.
 * @example formatFractionalUnitAmount(1234, "EUR") // -> 12.34 EUR
 * @param fractionalUnitAmount Value to format
 * @param currency Currency code
 * @param skipCurrency Indicates whether currency code should be hidden (e.g. 12.34 instead of 12.34 EUR)
 * @returns Formatted value
 */
const formatFractionalUnitAmount = (
  fractionalUnitAmount?: number | null,
  currency?: Currency,
  skipCurrency?: boolean,
) => {
  if (!isDefined(fractionalUnitAmount) || !currency) {
    return '';
  }
  const mainUnitAmount =
    currencyConverters.toMainUnitAmount(fractionalUnitAmount);
  return formatMainUnitAmount(
    mainUnitAmount,
    skipCurrency ? undefined : currency,
  );
};

export const currencyFormatter = {
  format: formatMainUnitAmount,
  formatFractionalUnitAmount,
};
