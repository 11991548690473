import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useModalContext } from 'store/modal';
import { Button } from 'melp-design/components';
import { calculateFiltersTotal } from 'utils/Filters';
import { FiltersModal } from 'components/modals';
import { FiltersProps } from '..';

type Props<F extends Record<string, any>> = Pick<
  FiltersProps<F>,
  'initialValues' | 'value' | 'onChange' | 'onChangeCallback' | 'children'
> & { keysToExtractFromTotalCalculation?: (keyof F)[] };

export const FiltersInModal = <F extends Record<string, any>>({
  initialValues,
  value,
  onChange,
  onChangeCallback,
  keysToExtractFromTotalCalculation,
  children,
}: Props<F>) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const totalFilters = calculateFiltersTotal(
    value,
    keysToExtractFromTotalCalculation,
  );

  return (
    <Stack flexDirection="row" gap={1}>
      {totalFilters && initialValues ? (
        <Button
          label={t('table.clear')}
          size="sm"
          variant="neutral-fill"
          onClick={() => {
            onChange(initialValues);
          }}
        />
      ) : null}

      <Button
        label={t('marketplace.filters')}
        size="sm"
        variant="neutral-outline"
        badge={totalFilters || undefined}
        onClick={() => {
          openModal({
            component: FiltersModal<F>,
            props: {
              initialValues,
              value,
              onChange,
              onChangeCallback,
              children,
            },
          });
        }}
      />
    </Stack>
  );
};
