import { AppLanguage, appLanguages } from 'types/general';
import { Currency } from 'types/general';
import { APP } from 'config';

export const formatCurrency = (
  language: string,
  amount: number,
  currency: Currency = 'EUR',
): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currencyDisplay: 'code',
    currency,
  };

  const locale: AppLanguage = appLanguages.includes(language as any)
    ? (language as AppLanguage)
    : APP.locales.default;

  return Intl.NumberFormat(locale, options).format(amount / 100);
};
