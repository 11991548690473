import { Box } from '@mui/material';
import { MpTableGlobalActionProps } from '@mp-react/table';
import { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MpBreadcrumbFilterData,
  MpGlobalFilter,
} from '@mp-react/table/build/types/Filter';
import { useHierarchy } from '../../../state/Hierarchy';
import { HeaderVariant, useTableStore } from '../../../state/Table';
import { HierarchyRequest } from '../../../types/ParentCompany';
import { useHierarchyByHeaderVariant } from '../../../utils/Hierarchy';
import BulkActions from '../BulkActions/BulkActions';
import HeaderVariantMap from '../HeaderVariantMap/HeaderVariantMap';
import { useDateFormat } from '../../../utils/Common';
import TableBreadcrumbs from '../TableBreadcrumbs/TableBreadcrumbs';

export default function CustomGlobalActions({
  selectedRowIds,
  selectedRows,
  actions,
  methods,
  translations,
  onGlobalFilter,
  globalFilters,
}: MpTableGlobalActionProps) {
  const { t } = useTranslation();
  const { localeLanguage } = useDateFormat();
  const { getHierarchyRequestByHeaderVariant } = useHierarchyByHeaderVariant();
  const headerVariant =
    useTableStore((state) => state.variant as HeaderVariant) ?? '';
  const variables = useTableStore((state) => state.variables);

  const hierarchyRequest = useMemo<HierarchyRequest>(
    () => getHierarchyRequestByHeaderVariant(headerVariant),
    [getHierarchyRequestByHeaderVariant, headerVariant],
  );

  const { breadcrumbsHierarchy: hierarchy, loading } =
    useHierarchy(hierarchyRequest);

  const breadcrumbFilters = useMemo(
    () => variables.globalFilters ?? globalFilters,
    [globalFilters, variables.globalFilters],
  );

  useEffect(() => {
    const filterCopy = { ...variables.globalFilters?.breadcrumb };
    if (filterCopy?.company_groups?.id === 'ungrouped') {
      filterCopy.company_groups.name = t('companies.ungrouped');
    }
    onGlobalFilter({ key: 'breadcrumb', value: filterCopy });
    //eslint-disable-next-line
  }, [localeLanguage]);

  const handleOnGlobalFilter = useCallback(
    (filter: MpGlobalFilter) => {
      onGlobalFilter(filter);
    },
    [onGlobalFilter],
  );

  const noContent =
    !actions && !hierarchyRequest.module && headerVariant === 'common';

  return (
    <Box
      display="flex"
      paddingBottom={noContent ? '0' : '31px'}
      alignItems="center"
    >
      {!!actions && (
        <BulkActions
          selectedRowIds={selectedRowIds}
          selectedRows={selectedRows}
          actions={actions}
          methods={methods}
          translations={translations}
        />
      )}
      <HeaderVariantMap>
        <TableBreadcrumbs
          loading={loading}
          data={hierarchy as MpBreadcrumbFilterData}
          onGlobalFilter={handleOnGlobalFilter}
          globalFilters={breadcrumbFilters}
          translations={translations}
        />
      </HeaderVariantMap>
    </Box>
  );
}
