import {
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Form,
  FormConnector,
  FormField,
  OptionsItem,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { EmployeesGroupSearchItem } from 'types/EmployeeGroups';
import {
  RecognitionAllowanceFrequency,
  UpdateRecognitionAllowanceRuleRequest,
} from 'types/Recognition';
import EmployeeGroupsField from 'containers/Recognition/common/EmployeeGroupsField';

interface AllowanceRuleFormData
  extends Omit<UpdateRecognitionAllowanceRuleRequest, 'userGroupIds'> {
  employeeGroups: EmployeesGroupSearchItem[];
}

const FREQUENCY_OPTIONS_KEYS: RecognitionAllowanceFrequency[] = [
  'weekly',
  'monthly',
  'quarterly',
  'yearly',
];

interface Props<R, F> {
  open: boolean;
  onClose: () => void;
  title: string;
  onSubmit: (data: R) => void;
  initialValues: UnpackNestedValue<DeepPartial<F>>;
  loading: boolean;
  renderAdditionalFields?: () => ReactNode;
  submitButtonTitle: string;
}

const AllowanceRuleDialog = <
  R extends UpdateRecognitionAllowanceRuleRequest,
  F extends AllowanceRuleFormData,
>(
  props: Props<R, F>,
) => {
  const { t } = useTranslation();

  const pointsLabel = usePointsLabel();

  return (
    <Dialog open={props.open} onClose={props.onClose} width={600}>
      <Form defaultValues={props.initialValues}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Stack gap="20px">
            <EmployeeGroupsField
              name="employeeGroups"
              label={t('recognition.allowanceRuleGroups')}
              rules={{
                required: true,
                validate: (value: any[]) => value.length > 0,
              }}
            />
            <Stack gap="10px">
              <Typography variant="p2" color="textSecondary">
                {t('recognition.allowanceRulePointsAndFrequency', {
                  pointsName: pointsLabel,
                })}
              </Typography>
              <Stack direction="row" gap="20px">
                <Box width={100}>
                  <FormField
                    name="points"
                    render={{ type: 'number', props: { placeholder: '100' } }}
                    rules={{
                      required: true,
                      min: 1,
                      // matches positive integers
                      pattern: /^[1-9]\d*$/,
                    }}
                  />
                </Box>
                <Box width={200}>
                  <FormField
                    name="frequency"
                    render="select"
                    rules={{ required: true }}
                  >
                    {FREQUENCY_OPTIONS_KEYS.map((key) => (
                      <OptionsItem key={key} value={key}>
                        {t(`recognition.allowanceRuleFrequency-${key}`)}
                      </OptionsItem>
                    ))}
                  </FormField>
                </Box>
              </Stack>
            </Stack>
            {props.renderAdditionalFields?.()}
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormConnector>
            {({ handleSubmit }) => (
              <>
                <ButtonOld minWidth={100} onClick={props.onClose}>
                  {t('common.cancel')}
                </ButtonOld>
                <ButtonOld
                  variant="contained"
                  color="primary"
                  minWidth={100}
                  onClick={handleSubmit((formData: AllowanceRuleFormData) => {
                    const { employeeGroups, ...rest } = formData;
                    const requestData = {
                      ...rest,
                      userGroupIds: employeeGroups.map(
                        (employeeGroup) => employeeGroup.id,
                      ),
                    };
                    props.onSubmit(requestData as R);
                  })}
                  loading={props.loading}
                >
                  {props.submitButtonTitle}
                </ButtonOld>
              </>
            )}
          </FormConnector>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AllowanceRuleDialog;
