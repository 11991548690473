import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../constants/Style';

export default makeStyles((theme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.success.main,
      color: Colors.White,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    deactivate: {
      backgroundColor: Colors.StatusYellow,
      color: Colors.White,
      '&:hover': {
        backgroundColor: Colors.StatusYellow,
      },
    },
    inactive: {
      backgroundColor: Colors.Default,
      color: Colors.TextElement,
      '& svg>path': {
        fill: Colors.TextElement,
      },
      '&:hover': {
        backgroundColor: Colors.Default,
      },
    },
    archived: {
      backgroundColor: Colors.Inactive,
      color: Colors.LightGray,
      '& svg>path': {
        fill: Colors.LightGray,
      },
      '&:hover': {
        backgroundColor: Colors.Inactive,
      },
    },
    button: {
      height: 'auto',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: 'rgba(211, 217, 223, 1)',
        color: 'rgba(105, 113, 125, 1)',
      },
    },
  }),
);
