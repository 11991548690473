import {
  MpBulkActionMethods,
  MpBulkActionCallback,
  MpRowActionMethods,
  MpRowActionParameters,
} from '@mp-react/table';
import moment from 'moment';
import { browserLanguage } from './Common';

const deleteCallback: MpBulkActionCallback = (selected) => {
  const ids = selected.selectedRows?.map((row) => {
    const original = row.original as any;
    return original.id;
  });
  const idString = ids?.join(', ');
  alert('Now deleting: ' + idString);
};

const update: MpBulkActionCallback = (selected) => {
  const ids = selected.selectedRows?.map((row) => {
    const original = row.original as any;
    return original.id;
  });
  const idString = ids?.join(', ');
  alert('Now updating: ' + idString);
};

const cancel: MpBulkActionCallback = (selected) => {
  const ids = selected.selectedRows?.map((row) => {
    const original = row.original as any;
    return original.id;
  });
  const idString = ids?.join(', ');
  alert('Now cancelling: ' + idString);
};

const sendReminder: MpBulkActionCallback = () => {
  alert('Reminder was send');
};

const statusSelected: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.status = 'selected';
    return original.status;
  });
  alert('Status of selected rows was changed to ' + newValue);
};

const statusNotSelected: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.status = 'notSelected';
    return original.status;
  });
  alert('Status of selected rows was changed to ' + newValue);
};

const statusDeclined: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.status = 'declined';
    return original.status;
  });
  alert('Status of selected rows was changed to ' + newValue);
};

const statusAccepted: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.status = 'accepted';
    return original.status;
  });
  alert('Status of selected rows was changed to ' + newValue);
};

const statusNotAccepted: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.status = 'notAccepted';
    return original.status;
  });
  alert('Status of selected rows was changed to ' + newValue);
};

const implementationDone: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    const now = moment().locale(browserLanguage).format('L');
    original.implementationDate = now;
    return original.implementationDate;
  });
  alert('Implementation date was changed to done. ' + newValue + ' date.');
};

const implementationPending: MpBulkActionCallback = (selected) => {
  const { selectedRows } = selected;
  const newValue = selectedRows?.map((item) => {
    const { original } = item as any;
    original.implementationDate = null;
    return original.implementationDate;
  });
  alert('Implementation date was changed to pending. ' + newValue);
};

const toggleStatus = (current: MpRowActionParameters) => {
  const { value, rowId } = current;
  alert('Status of ' + rowId + ' changed to ' + value);
};

const activateRow = (current: MpRowActionParameters) => {
  const { value, rowId } = current;
  alert('Activated row ' + rowId + '. Current status: ' + value);
};

const deleteRow = (current: MpRowActionParameters) => {
  const { rowId } = current;
  alert('Deleted row ' + rowId + '.');
};

export const bulkMethods: MpBulkActionMethods = {
  delete: deleteCallback,
  update,
  cancel,
  sendReminder,
  statusSelected,
  statusNotSelected,
  statusDeclined,
  statusAccepted,
  statusNotAccepted,
  implementationDone,
  implementationPending,
};

export const rowMethods: MpRowActionMethods = {
  toggleStatus,
  activateRow,
  deleteRow,
};
