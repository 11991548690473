import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { ConfirmationDialogProps } from '../ConfirmationDialog/ConfirmationDialog';

interface Props
  extends Pick<
    ConfirmationDialogProps,
    'onConfirm' | 'onCancel' | 'loading' | 'open' | 'width'
  > {
  /**
   * Text to display in dialog header section
   */
  header?: ReactNode;
  /**
   * Text to display in dialog content section
   */
  content?: ReactNode;
  /**
   * Entity title for default dialog content text. If property "content" is
   * defined, then this property is ignored.
   */
  title?: string;
}

const DeleteConfirmationDialog = ({ title, header, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <ConfirmationDialog
      id="delete-confirmation"
      title={header}
      content={t('common.areYouSureToDelete', { title })}
      confirmColor="danger"
      confirmLabel={t('actions.delete')}
      {...rest}
    />
  );
};

export default DeleteConfirmationDialog;
