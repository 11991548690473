import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Copy, SuccessOutlined } from 'melp-design/icons';

interface Props {
  value: string;
}

export const CopyButton: FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [isCoppied, setIsCoppied] = useState(false);

  useEffect(() => {
    if (isCoppied) {
      const timeoutId = setTimeout(() => {
        setIsCoppied(false);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isCoppied]);

  const onCopy = async () => {
    await navigator.clipboard.writeText(value);
    setIsCoppied(true);
  };

  return isCoppied ? (
    <Tooltip title={t('common.copied')}>
      <IconButton color="success">
        <SuccessOutlined />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={t('employees.copyCategoryValue')}>
      <IconButton onClick={onCopy}>
        <Copy />
      </IconButton>
    </Tooltip>
  );
};
