import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { expenseTypes } from 'store/expenses';

export const adminClientDepositsFiltersSchema = z
  .object({})
  .merge(sortFiltersSchema);

export const adminClientCompanyBalanceLogsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    date: dateRangeSchema,
    type: z.array(z.enum(expenseTypes)).catch([]),
    admins: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);

export const adminDepositEventsFiltersSchema = z
  .object({
    type: z.array(z.enum(expenseTypes)).catch([]),
    client: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);
