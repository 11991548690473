import { FormField, FormFieldProps } from 'melp-design/components';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect } from 'react';
import { useEmployeesGroupSearch } from '../../../state/EmployeeGroups';
import { EmployeesGroupSearchItem } from '../../../types/EmployeeGroups';

interface Props
  extends Omit<FormFieldProps<EmployeesGroupSearchItem[]>, 'render'> {}

const EmployeeGroupsField = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const handleSearch = useDebouncedCallback((_: any, newValue: string) => {
    setSearchText(newValue);
  }, 300);
  const groups = useEmployeesGroupSearch({
    name: searchText.length ? [searchText] : [],
  });
  const [options, setOptions] = useState<EmployeesGroupSearchItem[]>([]);

  useEffect(() => {
    if (groups.data) {
      setOptions(groups.data);
    }
  }, [groups.data]);

  return (
    <FormField
      {...props}
      render={{
        type: 'autocomplete',
        props: {
          options,
          multiple: true,
          filterOptions: (x: any) => x,
          onInputChange: handleSearch,
          getOptionLabel: (option: any) => option.name,
          isOptionEqualToValue: (option: any, value: any) => {
            return option.id === value.id;
          },
        },
      }}
    />
  );
};

export default EmployeeGroupsField;
