import { Typography } from '@mui/material';
import { useRendererOptions } from '@mp-react/table';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { useCurrency } from '../../../utils/useCurrency';

export default function CurrencyWithFraction(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { getDefaultCurrencyFormat } = useCurrency();

  const currencyValue = useMemo(() => {
    const parsedValue = parseFloat(value);
    if (!parsedValue) {
      return '-';
    }
    let formattedNumber = parsedValue.toFixed(2);
    if (formattedNumber.endsWith('.00')) {
      formattedNumber = formattedNumber.slice(0, -3);
    }
    return getDefaultCurrencyFormat(formattedNumber);
  }, [getDefaultCurrencyFormat, value]);

  return (
    <Typography variant="body2" align="right">
      {currencyValue}
    </Typography>
  );
}
