import { Colors } from 'melp-design/style';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles((theme) =>
  createStyles({
    item: {
      padding: '8px 10px !important', // To override another !important
      borderRadius: 6,
      transition: theme.transitions.create(['color', 'backGround-color']),
      '&:hover': {
        backGroundColor: Colors.screenBackgroundGrey,
      },
      '&::after': {
        display: 'none',
      },
    },
    vAlignCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    backItem: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        alignSelf: 'normal',
      },
    },
    icon: {
      '& path:last-child': {
        stroke: Colors.green,
      },
    },
    bold: {
      fontWeight: 'bold',
      color: 'black',
    },
  }),
);
