import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { ContentLanguage } from 'types/general';
import { TableDataResponse } from 'types/Table';
import { ICompany, IDepartment, IParentCompanyWithStats } from 'types/income';
import { ParentCompaniesFilters } from './companies.types';

interface GetParentCompanies {
  filters: Partial<ParentCompaniesFilters>;
}

interface UpdateCompanySettings {
  language: Uppercase<ContentLanguage>;
  isPersonalCodeRequired: boolean;
  isEmailVisibleInternally: boolean;
}

export const loaders = {
  getCompanies: () => {
    return api<TableDataResponse<ICompany>>({
      url: Endpoints.clientAdmin.companies.root,
    });
  },
  getParentCompanies: ({ filters }: GetParentCompanies) => {
    return api<TableDataResponse<IParentCompanyWithStats>>({
      url: Endpoints.parentCompany,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
      },
    });
  },
  getDepartments: () => {
    return api<Array<{ companies: Array<{ departments: IDepartment[] }> }>>({
      url: Endpoints.clientAdmin.companies.departments,
      params: {
        module: 'benefits',
        permissions: 'edit',
      },
    });
  },
  updateCompanySettings: ({
    language,
    isPersonalCodeRequired,
    isEmailVisibleInternally,
  }: UpdateCompanySettings) => {
    return api({
      url: Endpoints.updateDefaultLanguage,
      method: 'patch',
      data: {
        defaultLanguage: language,
        isPersonalCodeRequiredForLogin: isPersonalCodeRequired,
        isEmailVisibleInternally,
      },
    });
  },
};
