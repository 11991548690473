import { FC } from 'react';
import { Modal, Form, FormField } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { SubmitHandler } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';

interface Values {
  points: number;
  comment: string;
}

interface Props extends ModalProps {
  editable: boolean;
  points: number;
  comment: string;
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'DELETE' }
      | { action: 'SAVE'; points: number; comment: string },
  ) => void;
}

export const RecognitionEditModal: FC<Props> = ({
  closeModal,
  editable,
  points,
  comment,
}) => {
  const { t } = useTranslation();
  const pointsLabel = usePointsLabel();

  const onSubmit: SubmitHandler<Values> = async (values) => {
    closeModal({
      action: 'SAVE',
      points: values.points,
      comment: values.comment,
    });
  };

  return (
    <Form<Values> defaultValues={{ points, comment }} onSubmit={onSubmit}>
      <Modal.Content
        title={t('recognitions.import.title')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'danger-outline',
            onClick: () => closeModal({ action: 'DELETE' }),
            label: t('actions.delete'),
          },
          ...(editable
            ? [
                {
                  variant: 'primary',
                  label: t('common.save'),
                  type: 'submit',
                } as const,
              ]
            : []),
        ]}
      >
        <Stack gap={2}>
          <Box width={100}>
            <FormField
              name="points"
              label={pointsLabel}
              render="number"
              disabled={!editable}
              rules={{
                required: true,
                min: 1,
              }}
            />
          </Box>
          <FormField
            name="comment"
            label={t('recognitions.form.comment.label')}
            render="text"
            disabled={!editable}
            rules={{
              required: true,
              minLength: 10,
            }}
          />
        </Stack>
      </Modal.Content>
    </Form>
  );
};
