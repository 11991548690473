import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../utils/Common';

export default function DateFormat(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);
  const { fullDate } = useDateFormat(value);

  const date = useMemo(() => {
    if (!fullDate) {
      return t('common.dash');
    } else {
      return fullDate;
    }
  }, [fullDate, t]);

  return (
    <Typography variant="body2" align="center">
      {date}
    </Typography>
  );
}
