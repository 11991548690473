import { converters as tableConverters } from 'store/table';
import { IAdminDiscountCode } from 'types/income';
import { loaders } from './admin-discount-codes.loaders';
import { AdminDiscountCode } from './admin-discount-codes.types';

const convertDiscountCode = (
  voucher: IAdminDiscountCode,
): AdminDiscountCode => {
  return {
    id: voucher.id,
    discountId: voucher.discountId,
    employeeId: voucher.employeeId ?? null,
    code: voucher.code,
    status: voucher.status,
    createdAt: voucher.creationDate,
    expiresAt: voucher.expirationDate ?? null,
    revealedAt: voucher.revealDate ?? null,
  };
};

const convertDiscountCodes = (
  suppliers: IAdminDiscountCode[],
): AdminDiscountCode[] => {
  return suppliers.map(convertDiscountCode);
};

export const converters = {
  getDiscountCodes: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDiscountCodes>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDiscountCodes(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
