import { Translations } from 'types/Common';

export const resolveTranslation = <T extends Translations<Record<string, any>>>(
  userLanguage: string,
  defaultLanguage: string,
  translations: T,
): T[number] | null => {
  return (
    translations.find(
      (trans) => trans.language === userLanguage.toUpperCase(),
    ) ||
    translations.find((trans) => trans.language === defaultLanguage) ||
    translations[0] ||
    null
  );
};
