import { ComponentProps } from 'react';
import Lottie from 'react-lottie-player';
import { Box } from '@mui/material';
import { Dialog } from '../Dialog/Dialog';
import animationData from './success-animation.json';

interface Props extends ComponentProps<typeof Dialog> {}

const SuccessDialog = ({ children, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Lottie
          animationData={animationData}
          loop={false}
          play
          style={{
            width: 112,
            height: 112,
            marginBottom: -30,
          }}
        />
      </Box>
      {children}
    </Dialog>
  );
};

export default SuccessDialog;
