import { Box, BoxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Colors } from '../../../constants/Style';
import { ReactComponent as SearchIcon } from '../../../assets/icons/no-data.svg';
import useStyles from './NoData.styles';

interface IProps extends BoxProps {
  table?: boolean;
}

export default function NoData({ table, ...rest }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const wrapperHeight = useMemo(() => (!!table ? 335 : 585), [table]);

  return (
    <Box
      height={wrapperHeight}
      position="relative"
      style={{ overflow: table ? 'visible' : 'hidden' }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={clsx({
          [classes.tableWrapper]: table,
          [classes.wrapper]: !table,
        })}
      >
        <SearchIcon />
        <Box pt="24px" color={Colors.LightGray}>
          <Typography variant="h6">{t('common.no_data_to_show')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
