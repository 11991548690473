import { useMemo } from 'react';
import { Box } from '@mui/material';
import { CellProps } from 'react-table';
import { useRendererOptions } from '@mp-react/table';
import { useTranslation } from 'react-i18next';

export default function Empty(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { t } = useTranslation();

  const name = useMemo(() => {
    if (!value || value === undefined) {
      return t('common.dash');
    } else {
      return value;
    }
  }, [value, t]);

  return (
    <Box
      component="div"
      textOverflow="ellipsis"
      overflow="hidden"
      maxWidth={280}
    >
      {name}
    </Box>
  );
}
