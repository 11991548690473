import { ICurrentUser } from 'types/income';
import { convertParentCompany } from 'store/companies';
import { loaders } from './auth.loaders';
import { CurrentUser } from './auth.types';

const convertCurrentUser = (user: ICurrentUser): CurrentUser => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    role: user.role,
    root: user.root,
    status: user.status,
    creationDate: user.creationDate,
    language: user.language,
    parentCompany: convertParentCompany(user.parentCompany),
    permissions: user.permissions,
  };
};

export const converters = {
  getCurrentUser: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCurrentUser>>) => {
    return convertCurrentUser(data);
  },
};
