import { converters as tableConverters } from 'store/table';
import { IListOrder, IOrder } from 'types/income';
import { converters as filesConverters } from 'store/files';
import { convertEmbeddedCustomCategories } from 'store/custom-categories';
import { loaders } from './orders.loaders';
import { Order } from './orders.types';

const convertListOrder = (order: IListOrder): Order => {
  return {
    id: order.id,
    status: order.status,
    orderNr: order.orderNr,
    coupon: null,
    couponCode: order.couponCode ?? '',
    item: {
      id: order.marketplaceItem.id,
      name: order.marketplaceItem.name,
      translations: order.marketplaceItem.translations ?? [],
    },
    variantName: order.marketplaceItemPriceDescription,
    employee: order.employee
      ? {
          id: order.employee.id,
          fullName: [order.employee.firstName, order.employee.lastName]
            .filter(Boolean)
            .join(' '),
          email: order.employee.email,
          phone: '',
          jobTitle: '',
          department: '',
          categories: [],
        }
      : null,
    company: order.company
      ? {
          id: order.company.id,
          name: order.company.name,
        }
      : null,
    admin: null,
    supplier: order.marketplaceItem.supplier
      ? {
          id: order.marketplaceItem.supplier.id,
          name: order.marketplaceItem.supplier.name,
        }
      : null,
    price: order.marketplaceItemPrice,
    currency: order.marketplaceItem.currency,
    commentForEmployee: order.commentForEmployee ?? '',
    commentForAdmin: order.commentForAdmin ?? '',
    createdAt: order.creationDate,
    lastModifiedAt: order.lastModificationDate,
  };
};

const convertOrder = (order: IOrder): Order => {
  return {
    id: order.id,
    status: order.status,
    orderNr: order.orderNr,
    coupon: order.couponFile
      ? filesConverters.getUploadedFile(order.couponFile)
      : null,
    couponCode: order.couponCode ?? '',
    item: {
      id: order.marketplaceItemId,
      name: order.marketplaceItemName,
      translations: [
        { language: 'EN', title: order.marketplaceItemName, description: '' },
      ],
    },
    variantName: order.marketplaceItemPriceDescription,
    employee: order.employee
      ? {
          id: order.employee.id,
          fullName: order.employee.name,
          email: order.employee.email,
          phone: order.employee.phoneNumber ?? '',
          jobTitle: order.employee.jobTitle ?? '',
          department: order.employee.department ?? '',
          categories: convertEmbeddedCustomCategories(
            order.employee.categories ?? [],
          ),
        }
      : null,
    company: order.company
      ? {
          id: order.company.id,
          name: order.company.name,
        }
      : null,
    admin: order.admin
      ? {
          id: order.admin.id,
          name: order.admin.name,
        }
      : null,
    supplier: null,
    price: order.marketplaceItemPrice,
    currency: order.marketplaceItemCurrency,
    commentForEmployee: order.commentForEmployee ?? '',
    commentForAdmin: order.commentForAdmin ?? '',
    createdAt: order.creationDate,
    lastModifiedAt: order.lastModificationDate,
  };
};

const convertOrders = (orders: IListOrder[]): Order[] => {
  return orders.map(convertListOrder);
};

export const converters = {
  getOrders: ({ data }: Awaited<ReturnType<typeof loaders.getOrders>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertOrders(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getOrder: ({ data }: Awaited<ReturnType<typeof loaders.getOrder>>) => {
    return convertOrder(data);
  },
};
