import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { KeyLabelPair } from '../../../types/Common';
import { Colors } from '../../style/Colors';
import { Effects } from '../../style/Effects';
import Typography from '../Typography/Typography';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '8px 10px !important', // To override another !important
    borderRadius: 6,
    color: Colors.black,
    transition: theme.transitions.create(['color', 'backGround-color']),
    '&:hover': {
      backGroundColor: Colors.screenBackgroundGrey,
    },
    // To override existing styles without impacting other elements
    '&::after': {
      display: 'none',
    },
  },
  linkItem: {
    '&:hover': {
      color: Colors.primary,
    },
  },
  activeItem: {
    color: Colors.primary,
  },
}));

interface HasControlledVisibility {
  visible?: boolean;
}

interface LinkData extends HasControlledVisibility {
  path: string;
  label: string;
}

const isLinkData = (value: {}): value is LinkData => {
  return 'path' in value;
};

type KeyLabelItem<K extends string> = KeyLabelPair<K> & HasControlledVisibility;

type Item = (LinkData | KeyLabelItem<string>) & { id?: string };

interface Props<T extends Item>
  extends Pick<PopperProps, 'open' | 'anchorEl' | 'placement'> {
  /**
   * Callback to execute when dropdown menu closes.
   */
  onClose: () => void;
  /**
   * Items to display in a menu.
   */
  menuItems: T[];
  /**
   * Callback to execute when a menu item is clicked.
   */
  onItemClick?: (item: T) => void;
  /**
   * Allows customization of a menu item label (e.g., changing the color).
   */
  renderItemLabel?: (item: T, defaultElement: ReactNode) => ReactNode;
  /**
   * Limits the height of the menu. When items cannot fit, scrollbar appears.
   */
  maxHeight?: number;
  /**
   * Indicates whether min width of the menu should be the same as anchor width.
   */
  fitToAnchorWidth?: boolean;
  /**
   * By default there is a small space between the anchor element and the menu.
   * This property remove the space.
   */
  stickToAnchor?: boolean;
}

const DropdownMenu = <T extends Item>({
  renderItemLabel,
  fitToAnchorWidth = false,
  ...props
}: Props<T>) => {
  const classes = useStyles();

  const renderMenuItem = (item: T) => {
    const defaultItemElement = (
      <Typography variant="p1" color="inherit">
        {item.label}
      </Typography>
    );
    const itemText =
      typeof renderItemLabel === 'function'
        ? renderItemLabel(item, defaultItemElement)
        : defaultItemElement;

    const commonProps = {
      onClick: () => props.onItemClick?.(item),
    };

    if (isLinkData(item)) {
      return (
        <MenuItem
          key={item.path}
          data-test={item.id}
          className={clsx(classes.item, classes.linkItem)}
          {...commonProps}
          component={NavLink}
          to={item.path}
          activeClassName={classes.activeItem}
        >
          {itemText}
        </MenuItem>
      );
    }

    return (
      <MenuItem key={item.key} className={classes.item} {...commonProps}>
        {itemText}
      </MenuItem>
    );
  };

  const getMenuMinWidth = () => {
    if (
      fitToAnchorWidth &&
      props.anchorEl &&
      'getBoundingClientRect' in props.anchorEl
    ) {
      return props.anchorEl.getBoundingClientRect().width;
    }

    return 110;
  };

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={props.placement ?? 'bottom-start'}
      transition
      // To place popper over other UI elements
      style={{ zIndex: 3 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper
            variant="outlined"
            sx={{
              marginTop: props.stickToAnchor ? 0 : '5px',
              boxShadow: Effects.smallElementsShadow,
              borderRadius: '6px',
            }}
          >
            <ClickAwayListener onClickAway={props.onClose}>
              <MenuList
                sx={{
                  padding: '5px',
                  minWidth: getMenuMinWidth(),
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: props.maxHeight,
                  overflow: 'auto',
                }}
              >
                {props.menuItems
                  ?.filter((item) => item.visible ?? true)
                  .map(renderMenuItem)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default DropdownMenu;
