import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IBenefit, IEmployeeBenefit } from 'types/income';
import { exportXlsxConfig } from 'utils/general';
import {
  BenefitsFilters,
  EmployeeBenefitsFilters,
  LiteBenefit,
  LiteBenefitsFilters,
} from './benefits.types';

interface GetBenefits {
  filters: Partial<BenefitsFilters>;
}

interface GetLiteBenefits {
  filters: Partial<LiteBenefitsFilters>;
}

interface GetEmployeeBenefits {
  employeeId: string;
  filters: Partial<EmployeeBenefitsFilters>;
}

interface ExportXls {
  filters: Partial<BenefitsFilters>;
}

export const loaders = {
  getBenefits: ({ filters }: GetBenefits) => {
    return api<TableDataResponse<IBenefit>>({
      url: Endpoints.clientAdmin.benefits.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name?.filter(Boolean),
          benefitIds: filters.benefits?.filter(Boolean),
          type: filters.category,
          status: filters.status,
          publishStatus: filters.publishStatus,
          deactivationDateFrom: filters.endsAt?.from,
          deactivationDateTo: filters.endsAt?.to,
          employeeCountFrom: filters.employeeCount?.from,
          employeeCountTo: filters.employeeCount?.to,
          investmentAmountFrom: filters.investmentAmount?.from,
          investmentAmountTo: filters.investmentAmount?.to,
          companyIds: filters.companies,
          departmentIds: filters.departments,
        },
        sort: filters.sort
          ? `${filters.sort.order === 'desc' ? '-' : ''}${
              filters.sort.columnKey
            }`
          : undefined,
      },
    });
  },
  getLiteBenefits: ({ filters }: GetLiteBenefits) => {
    return api<{ data: LiteBenefit[] }>({
      url: Endpoints.clientAdmin.benefits.lite,
      params: {
        filter: {
          name: filters.name?.filter(Boolean),
        },
      },
    });
  },
  getEmployeeBenefits: ({ employeeId, filters }: GetEmployeeBenefits) => {
    return api<{ data: IEmployeeBenefit[] }>({
      url: Endpoints.clientAdmin.benefits.employee.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        filter: {
          trackBalance: filters.trackBalance || undefined,
        },
      },
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.benefits.export.xls,
      params: {
        filter: {
          name: filters.name?.filter(Boolean),
          type: filters.category,
          status: filters.status,
          publishStatus: filters.publishStatus,
          deactivationDateFrom: filters.endsAt?.from,
          deactivationDateTo: filters.endsAt?.to,
          employeeCountFrom: filters.employeeCount?.from,
          employeeCountTo: filters.employeeCount?.to,
          investmentAmountFrom: filters.investmentAmount?.from,
          investmentAmountTo: filters.investmentAmount?.to,
          companyIds: filters.companies,
          departmentIds: filters.departments,
        },
        sort: filters.sort
          ? `${filters.sort.order === 'desc' ? '-' : ''}${
              filters.sort.columnKey
            }`
          : undefined,
      },
      ...exportXlsxConfig,
    });
  },
};
